import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../context/AppContext";

export const AuthRoute = ({ path, children }) => {
  const { authenticated, setShowAuth } = useContext(AppContext);

  useEffect(() => {
    if (!authenticated) {
      setShowAuth("login");
      toast.error("You must be logged in to do that!");
    }
  }, [authenticated, setShowAuth]);

  return authenticated ? <Route path={path}>{children}</Route> : <Redirect to="/" />;
};

export const GuestRoute = ({ path, children }) => {
  const { authenticated } = useContext(AppContext);

  return !authenticated ? <Route path={path}>{children}</Route> : <Redirect to="/" />;
};
