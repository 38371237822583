import React, { useState,useEffect } from 'react'
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import logo_header from "@img/logo-header.svg";
import EditLineup from '../TournamentJoinModal/EditLineup';

const EditLineupModal = ({ showModal, closeModal, id, teams, subsAllowed, refreshPage,teamSize,selectedtTab,userHasTeamRegistered }) => {
     
    return (
        <Modal isOpen={showModal} onRequestClose={closeModal} className="modal auth-modal" shouldCloseOnEsc={false}>
            <div className="modal-close" onClick={closeModal}>
                <RiCloseLine />
            </div>
            <div className="login-register-modal">
                <div className="auth-modal-header">
                    <img src={logo_header} alt="Leagues.gg" />
                    <h2 className="bold uppercase">Edit Lineup</h2>
                </div>
                <div className="col-12 pb-3">
                    <p className="text-12 text-grey">
                        These roles can be changed until the signup deadline.
                    </p>
                </div>
                
                <div className="overflow-hidden">
                     <div>
                     <EditLineup teamSize={teamSize} refreshPage={refreshPage} subsAllowed={subsAllowed} existingTeams={teams} id={id} hideModal={closeModal} />
                    </div> 
                </div>
            </div>
        </Modal>
    )
}

export default EditLineupModal
