import React, { useState, useEffect } from "react";
import moment from "moment";
import { ProgressBar } from 'react-bootstrap';
import { Icon } from '@common';
import { makeCountdownString } from "@utils";

const TimerProgressBar = ({time, totalTime, classname, onTimeComplete}) => {
 
    const [remainingTime, setRemainingTime] = useState(moment(new Date(new Date(time).getTime() + totalTime*1000)).diff(moment(), "seconds"))   
    useEffect(() => {
        if (remainingTime > 0) {
            setTimeout(() => {
                setRemainingTime(moment(new Date(new Date(time).getTime() + totalTime * 1000)).diff(moment(), "seconds"))
            }, 1000);
        } else {
            onTimeComplete(new Date())
            setRemainingTime(0)
        }
     }, [remainingTime, time, totalTime])

    return (
            <ProgressBar className={classname} now={totalTime - remainingTime} max={totalTime} label={<><div className="progress-bar-title"> <Icon name="clock" />{makeCountdownString(remainingTime * 1000, true)}</div></>} />
    )
}

export default TimerProgressBar
