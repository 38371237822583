import React, { useState, useEffect } from "react";
import { Checkbox } from "@common";
import { Icon, Loading } from "@common";
import Axios from "axios";
import { useHistory } from 'react-router-dom';
import { TeamMemberListItemType2 } from "@common";
import Collapsible from 'react-collapsible';
import { getTeamsAvgRank } from "@utils";
import { ImArrowUpRight2 as FiArrowUpRight } from "react-icons/im";

const Participants = ({ tournamentData }) => {
  const [teams, setTeams] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [playersChecked, setPlayersChecked] = useState(true);
  const [subsChecked, setSubsChecked] = useState(true);
  const [coachesChecked, setCoachesChecked] = useState(true);

  let ordering = {}, // map for efficient lookup of sortIndex
    sortOrder = tournamentData?.game.configuration.playerTypes.filter(players => !players.name.includes("SUB")).map(players => `Player:${players.name}`);
  for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;

  useEffect(() => {
    setShowLoader(true);
    console.log('asa', tournamentData._id)

    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournamentData._id}/participants`)
      .then(({ data }) => {
        setTeams(data.participants)
      })
      .finally(() => setShowLoader(false));

  }, []);

  const getPlayerPositionData = (roleSplit = "") => {
    if (roleSplit.includes('SUB')) roleSplit = 'SUB'
    if (roleSplit) return (tournamentData.game || {}).configuration.playerTypes.find(({ name }) => name === roleSplit);
    return false;
  };

  const handleGotoTeamPage = (teamId, e) => {
    e.preventDefault();

    const win = window.open(`/teams/${teamId}`, "_blank");
    win.focus();

    e.stopPropagation();
  }

  const handleGotoPlayerProfilePage = (id, e) => {
    e.preventDefault();

    const win = window.open(`/game-profile/${id}`, "_blank");
    win.focus();

    e.stopPropagation();
  }

  const handleGotoCoachProfilePage = (id, e) => {
    e.preventDefault();

    const win = window.open(`/coaches/${id}`, "_blank");
    win.focus();

    e.stopPropagation();
  }

  const positionData = (role) => {
    role = role.split(":")[1];

    if (role) return (tournamentData.game || {}).configuration.playerTypes.find(({ name }) => name === role);
    return false;
  }

  const coachPositionData = (role) => {
    role = role.split(":")[1]

    if (role) return (tournamentData.game || {}).configuration?.coachTypes?.find(({ name }) => name === role);
    return false;
  }

  const subPositionData = (role) => {
    if (role === "Player:SUB") role = role + ":No Specification";
    role = role.split(":")[2]

    if (role) return (tournamentData.game || {}).configuration?.subTypes?.find(({ name }) => name === role);
    return false;
  }

  const collapseTrigger = (_team) => {
    const totalParticipants = () => {
      let accumulator = 0;
      console.log(_team, '=as');
      if (playersChecked) {
        accumulator = accumulator + _team?.players?.length;
      }
      if (subsChecked) {
        accumulator = accumulator + _team?.substituePlayers?.length;
      }
      if (coachesChecked) {
        accumulator = accumulator + _team?.coach?.length;
      }
      return accumulator > 1 ? `${accumulator} PARTICIPANTS` : `${accumulator} PARTICIPANT`;
    }

    return (
      <div className="row justify-content-between align-items-center">
        <div className="col-md-4">
          <div className="par-team-info">
            <div className="team-logo" style={{ backgroundImage: `url(${_team.teamLogo})` }}></div>
            <div className="team-name">{_team.teamName}</div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="par-other-info">
            <div className="par-info-inner mr-2">
              <div className="par-num align-items-centerd">{totalParticipants()}</div>
              <div className="par-rank align-items-centerd">{getTeamsAvgRank(_team.allMembers, tournamentData.game)}</div>
              {!_team.placeHolder ?
                <>
                  <div className="par-goto-team">
                    <a onClick={(e) => handleGotoTeamPage(_team.teamId, e)}>GO TO TEAM PAGE<Icon pureIcon={<FiArrowUpRight />} />
                    </a>
                  </div>
                  <div className="par-arrow ml-2">
                    <Icon name="grey-down-arrow" />
                  </div>
                </>
                : <>
                  <div className="par-goto-team placeholder-team-none">
                    <a onClick={(e) => handleGotoTeamPage(_team.teamId, e)}>GO TO TEAM PAGE<Icon pureIcon={<FiArrowUpRight />} />
                    </a>
                  </div>
                  <div className="par-arrow ml-5">
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    showLoader ? (
      <div className="col-12 text-center">
        <Loading />
      </div>
    ) : (
      <div className="participants-wrapper">
        {/* participants header start  */}
        <div className="participants-header">
          <div className="row justify-content-between">
            <div className="col-4">
              <div className="participant-types" style={{ cursor: "default" }}>
                  <a className="active">{teams?.length} teams</a>
                  <a >{teams?.reduce((accumulator, team) => {
                    if (playersChecked) {
                      accumulator = accumulator + team?.players?.length;
                    }
                    if (subsChecked) {
                      accumulator = accumulator + team?.substituePlayers?.length;
                    }
                    if (coachesChecked) {
                      accumulator = accumulator + team?.coach?.length;
                    }
                    return accumulator;
                  }, 0)} Participants</a>
                </div>
              </div>
              <div className="col-8 text-right">
                <div className="row align-items-center justify-content-end pr-30px">
                  <span className="bold text-grey uppercase font-13">Show:</span>
                  <Checkbox
                    label="Players"
                    className="check-type1"
                    checked={playersChecked}
                    onChange={() => setPlayersChecked(!playersChecked)}
                    game={tournamentData.game.name}
                  />
                  <Checkbox
                    checked={subsChecked}
                    label="Substitutes"
                    className="check-type1"
                    onChange={() => setSubsChecked(!subsChecked)}
                    game={tournamentData.game.name}
                  />
                  <Checkbox
                    checked={coachesChecked}
                    label="Coaches"
                    className="check-type1"
                    onChange={() => setCoachesChecked(!coachesChecked)}
                    game={tournamentData.game.name}
                  />
                </div>
              </div>
            </div>
          </div>
        {/* participants header end  */}
        {/* participants content start  */}
        <div className="participants-content">
            {teams?.map((team, index) => {
            return (
              <Collapsible transitionTime={100} trigger={collapseTrigger(team)} classParentString="collapsible-wrapper" key={team._id}>
                {playersChecked && team.players.sort((a, b) => ordering[a.role] - ordering[b.role]).map((player) => (
                  player && <TeamMemberListItemType2 key={player._id}
                    img={player.player?.avatarImage}
                    title={
                      player.player?.name
                    }
                    subtitle={player.player ? `${player?.player?.user?.firstName} ${player?.player?.user?.nickname ? ` "${player?.player?.user?.nickname}" ` : " "} ${player?.player?.user?.lastName
                      }` : ""}
                    position={positionData(player.role)}
                    role={player.role}
                    nationality={player?.player?.user?.nationality}
                    editable={false}
                    availableNewRoles={[]}
                    actions={[
                      <a
                        onClick={(e) => handleGotoPlayerProfilePage(player?.player?._id, e)}
                        className="text-14 primary-link">
                        View profile
                      </a>
                    ]}
                  />
                ))}


                {subsChecked && team?.substituePlayers?.map((substituePlayer) => (
                  <TeamMemberListItemType2 key={substituePlayer._id}
                    img={substituePlayer?.player?.avatarImage}
                    title={
                      substituePlayer?.player?.name
                    }
                    subtitle={substituePlayer.player ? `${substituePlayer?.player?.user?.firstName}${substituePlayer?.player?.user?.nickname ? ` "${substituePlayer?.player?.user?.nickname}" ` : " "}${substituePlayer?.player?.user?.lastName
                      }` : ""}
                    position={positionData(substituePlayer.role)}
                    // positionData={substituePlayer ? getPlayerPositionData(substituePlayer.role) : false}
                    subPosition={subPositionData(substituePlayer.role)}
                    role="SUB"
                    nationality={substituePlayer?.player?.user?.nationality}
                    editable={false}
                    availableNewRoles={[]}
                    actions={[
                      <a
                        onClick={(e) => handleGotoPlayerProfilePage(substituePlayer?.player?._id, e)}
                        className="text-14 primary-link">
                        View profile
                      </a>
                    ]} />
                ))}


                {coachesChecked && team.coach.map((coach) => (
                  <TeamMemberListItemType2 key={coach._id}
                    img={coach?.avatarImage}
                    title={
                      coach?.coach?.name
                    }
                    subtitle={coach.coach ? `${coach?.coach?.user?.firstName}${coach?.coach?.user?.nickname ? ` "${coach?.coach?.user?.nickname}" ` : " "}${coach?.coach?.user?.lastName
                      }` : ""}
                    subPosition={coachPositionData(coach.role)}
                    role="Coach"
                    nationality={coach?.coach?.user?.nationality}
                    editable={false}
                    availableNewRoles={[]}

                    actions={[
                      <a
                        onClick={(e) => handleGotoCoachProfilePage(coach?.coach?._id, e)}
                        className="text-14 primary-link">
                        View profile
                      </a>
                    ]} />
                ))}
              </Collapsible>
            )
          })}

          </div>
          {/* participants content end  */}
        </div>
    )
  )

};

export default Participants;