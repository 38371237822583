import React, { useContext, useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { CreateCoachModalContext } from "../CreateCoachModal";
import Loading from "../../../_common/Loading/Loading";
import { AppContext } from "@context/AppContext";
import useTranslated from "../../../../helpers/translationHelper";

const CreateCoach = (onClose) => {
  const history = useHistory();
  const { user, selectedGame } = useContext(AppContext);
  const { setCoachData, setStep, afterCreate } = useContext(CreateCoachModalContext);
  const { register, watch, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);

  const game = watch("game", false);
  const [session, setSession] = useState("");
  // let session= localStorage.getItem('sessionToken')
  useEffect(() => {
    let sessionStorage = localStorage.getItem("sessionToken");
    setSession(sessionStorage);
  }, []);

  const lableForCreateCouch = useTranslated("id_pop-up_-_create_coach_7");

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/gamesLimit/${user.state._id}`).then(({ data }) => {
      // setGames(data.filter(({ _id }) => (user.state.coaches ? !user.state.coaches.map(({ game }) => game._id).includes(_id) : true)));
      setGames(() => {
        return data.coachGames.filter((game) => user.state.coaches && game.name !== "No Game");
      });
    });
  }, [user]);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const { data: newCoach } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/coaches`, data);
      toast.success("Successfully created coach");
      afterCreate(newCoach.coach);
      setCoachData(newCoach.coach);

      setLoading(false);
      setStep(1);
      history.push(`/coaches/${newCoach.coach._id}`);
    } catch (e) {
      const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem creating coach";
      setLoading(false);
      toast.error(msg);
    }
  };
  return (
    <div>
      <div className="modal-header">
        <h2>{useTranslated("id_pop-up_-_create_coach_0") /* Create coach account */}</h2>
        <h3>{useTranslated("id_pop-up_-_create_coach_1") /* You can only create one coach profile per game. */}</h3>
      </div>

      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <label htmlFor="game">{useTranslated("id_pop-up_-_create_coach_2") /* Game */}</label>
            <select
              ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
              id="game"
              name="game"
              className={`form-input ${errors.game ? "invalid" : ""} ${games?.find((e) => e._id === game)?.shortName.toLowerCase() === "csgo"?"csgo-sel":games?.find((e) => e._id === game)?.shortName.toLowerCase() === "vl"?"vl-sel":games?.find((e) => e._id === game)?.shortName.toLowerCase() === "lol"?"lol-sel":''}`}
              defaultValue="_DEFAULT_"
            >
              <option value="_DEFAULT_" disabled>
                Choose game
              </option>
              {games.map(({ _id, name, coachLimitFull }) => (
                <option key={_id} value={_id} disabled={coachLimitFull}>
                  {name} {coachLimitFull && "(Account Limit Full)"}
                </option>
              ))}
            </select>
            {errors.game && <div className="form-input-error">{errors.game.message}</div>}
          </div>

          {game && (selectedGame._id === game || !selectedGame._id) && (
            <>
              {" "}
              <div className="form-row">
                <label htmlFor="name">Name</label>

                <input
                  onBlur={(e) => {
                    e.target.value = e.target.value.trim();
                  }}
                  ref={register({ required: "Required", maxLength: { value: 30, message: "Name too long" } })}
                  type="text"
                  id="name"
                  placeholder="Coach name"
                  name="name"
                  maxLength={30}
                  autoComplete="coach-name"
                  className={`form-input ${errors.name ? "invalid" : ""}`}
                />
                {errors.name && <div className="form-input-error">{errors.name.message}</div>}
              </div>
              <div className="form-row">
                <label htmlFor="description"> Coach description (max 200 words) </label>
                <textarea
                  ref={register({ maxLength: { value: 2000, message: "Description too long" } })}
                  type="text"
                  max={2000}
                  rows={4}
                  id="description"
                  name="description"
                  autoComplete="description"
                  placeholder="Write something about yourself"
                  className={`form-input ${errors.description ? "invalid" : ""}`}
                />
                {errors.description && <div className="form-input-error">{errors.description.message}</div>}
              </div>
              {/* <div className="form-row modal-information">
            <h3>Spectating games as a coach</h3>
            <p>
              Be aware that if you, as a coach, intend to spectate your team's matches, you will need to create a player profile as well.
              The match lobby is locked and only players, and coaches with a summoner connected (through a player profile), will be able to
              join.
            </p>
          </div> */}
              <button type="submit" className="button primary thicc" disabled={loading}>
                {loading ? <Loading /> : lableForCreateCouch /* "Create Coach" */}
              </button>{" "}
            </>
          )}
        </form>
        {game && selectedGame._id !== game && games?.find((e) => e._id === game)?.shortName.toLowerCase() === "csgo" && (
          <>
            {" "}
            <div className="create-text">
              {" "}
              <span>
                {" "}
                Right now you are not on this Game
                {/* <a href={`${process.env.REACT_APP_CSGO_URL}?lst=${session}&shortName=CSGO`} > 
             click here for this game </a> */}
              </span>
            </div>
            <a
              // href={`${process.env.REACT_APP_CSGO_API}/authenticate`}
              href={`${process.env.REACT_APP_CSGO_URL}?lst=${session}&shortName=CSGO&type=account&number=3`}
            >
              <button className="button primary thicc csgo-button">Get Me There</button> {/* <span>Get Me There</span> */}
            </a>
          </>
        )}
        {game && selectedGame._id !== game && games?.find((e) => e._id === game)?.shortName.toLowerCase() === "lol" && (
          <>
            {" "}
            <div className="create-text">
              {" "}
              <span>
                {" "}
                Right now you are not on this Game
                {/* <a href={`${process.env.REACT_APP_CSGO_URL}?lst=${session}&shortName=CSGO`} > 
             click here for this game </a> */}
              </span>
            </div>
            <a href={`${process.env.REACT_APP_LOL_URL}?lst=${session}&shortName=LOL&type=account&number=3`}>
              <button className="button primary thicc lol-button">Get Me There</button> {/* <span>Get Me There</span> */}
            </a>
          </>
        )}
        {game && selectedGame._id !== game && games?.find((e) => e._id === game)?.shortName.toLowerCase() === "vl" && (
          <>
            {" "}
            <div className="create-text">
              {" "}
              <span> Right now you are not on this Game</span>
            </div>
            <a
              href={`${process.env.REACT_APP_VALORANT_URL}?lst=${session}&shortName=VL&type=account&number=3`}
              // class="button primary thicc"
            >
              <button className="button primary thicc vl-button">Get Me There</button> {/* <span>Get Me There</span> */}
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateCoach;
