import React, { useContext, useState } from "react";
import { CreateTeamModalContext } from "../CreateTeamModal";
import EditImageModal from "../../EditImageModal/EditImageModal";
import Axios from "axios";
import { toast } from "react-toastify";
import useTranslated from "../../../../helpers/translationHelper";

const AddTeamImages = () => {
  const { teamData, setStep } = useContext(CreateTeamModalContext);
  const [modals, setModals] = useState({
    logo: false,
    banner: false,
  });
  const [images, setImages] = useState({});

  const lableSkip=useTranslated("id_pop-up_-_create_team_29")
  const lableSaveNext=useTranslated("id_pop-up_-_create_team_30")

  const lableLogo=useTranslated("id_pop-up_-_upload_image_2")
  const lableBanner=useTranslated("id_pop-up_-_upload_image_3")

  const updateTeam = async (type, image) => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/${teamData._id}/image`, { type, image });
      setImages({ ...images, [type]: image });
      setModals({ ...modals, [type]: false });
    } catch (err) {
      toast.error("There was a problem updating your team images");
    }
  };

  return (
    <div>
      <div className="modal-header">
        <h2>{useTranslated("id_pop-up_-_create_team_25")}{/* Customise your team page */} </h2>
        <h3>{useTranslated("id_pop-up_-_create_team_26")/* Finish creating your team by customising it with a logo and a banner. */}</h3>
      </div>
      <div className="modal-body">
        <div className="add-images">
          {/* LOGO */}
          <div
            className="image-card round-logo"
            onClick={() => setModals({ ...modals, logo: true })}
            style={{ backgroundImage: `url(${images.logo})` }}
          >
            {useTranslated("id_pop-up_-_create_team_27")/* Add Logo */}
          </div>
          <EditImageModal
            name={lableLogo}/* "Logo" */
            width={250}
            height={250}
            borderRadius={250}
            open={modals.logo}
            onClose={() => setModals({ ...modals, logo: false })}
            afterImageUpload={(file) => updateTeam("logo", file)}
          />

          {/* BANNER */}
          <div
            className="image-card"
            onClick={() => setModals({ ...modals, banner: true })}
            style={{ backgroundImage: `url(${images.banner})` }}
          >
            {useTranslated("id_pop-up_-_create_team_28")/* Add Banner */}
          </div>
          <EditImageModal
            name={lableBanner}/* "Banner" */
            width={800}
            height={200}
            crop={true}
            rotate={false}
            borderRadius={0}
            open={modals.banner}
            onClose={() => setModals({ ...modals, banner: false })}
            afterImageUpload={(file) => updateTeam("banner", file)}
          />

          <div className="next-buttons">
            <button className="button transparent" onClick={() => setStep(3)}>
              {lableSkip/* Skip */}
            </button>
            <button className="button" onClick={() => setStep(3)}>
              {lableSaveNext/* Save & Next */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTeamImages;
