import React, { useState, useContext, useEffect,useMemo } from "react";
import { AppContext } from "../../../../context/AppContext";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Axios from "axios";
import AvatarCover from "./AvatarCover";
import DeleteAccountModal from "./DeleteAccountModal/DeleteAccountModal";
import moment from "moment";
import { countries } from "@utils";
import { TIMEZONES } from "../../../../utils/timezones";
// Below is the custom hook for getting translated word from the selected language
import useTranslated from "../../../../helpers/translationHelper";
import { Countries } from "../../../../utils/transCountries";

const MyAccount = () => {
  const { user } = useContext(AppContext);
  const { register, handleSubmit, setValue, errors, setError, reset } = useForm();
  const [nationalities, setNationalities] = useState([]);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  let _choose_gender_translation = useTranslated("id_dropdown_-_gender_0");
  let _male_gender_translation = useTranslated("id_dropdown_-_gender_1");
  let _female_gender_translation = useTranslated("id_dropdown_-_gender_2");
  let _other_gender_translation = useTranslated("id_dropdown_-_gender_3");
  let _prefer_no_tosay_gender_translation = useTranslated("id_dropdown_-_gender_4");
  let _choose_nationality_translation = useTranslated("id_dropdown_-_nationality_0");

  const monthtranslation=useTranslated('id_utility_-_months_0')
  const Januarymonth=useTranslated('id_utility_-_months_1')
  const Februarymonth=useTranslated('id_utility_-_months_2')
  const Marchmonth=useTranslated('id_utility_-_months_3')
  const Aprilmonth=useTranslated('id_utility_-_months_4')
  const Maymonth=useTranslated('id_utility_-_months_5')
  const Junemonth=useTranslated('id_utility_-_months_6')
  const Julymonth=useTranslated('id_utility_-_months_7')
  const Augustmonth=useTranslated('id_utility_-_months_8')
  const Septembermonth=useTranslated('id_utility_-_months_9')
  const Octobermonth=useTranslated('id_utility_-_months_10')
  const Novembermonth=useTranslated('id_utility_-_months_11')
  const Decembermonth=useTranslated('id_utility_-_months_12')
  let _countries
  useEffect(() => {
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, []);
  useEffect(() => {
    reset({
      nationality: JSON.stringify(user.state.nationality),
      timezone: user.state.timezone,
    });
  }, [user.state]);
  useEffect(()=>{;
    
    if(countries?.length > 0 && (countries[0]?.demonym !== null )){
    _countries = countries
      ?.filter(({ demonym }) => demonym)
      .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
      .sort((a, b) => a.demonym.localeCompare(b.demonym))
       setNationalities(_countries);
    }
  }, [countries]);
  const onDeleteAccount = () => {
    setDeleteAccountModal(true);
  };
  const getUserNationality = (nationality,countries)=>{
    if(countries && countries.length > 0){
     const country = countries.find(el=> el.alpha2Code === nationality.code)
     return JSON.stringify({ code: country.alpha2Code, name: country.demonym })
    }
  }
  const getCountryNameTrans= (code,demonym,countries)=>{
  if(countries && countries.length > 0){
    let country = [..._countries].find(el=> el.alpha2Code === code)
    // debugger
    if(country){
      // console.log("ahfhjhgefjgjhegfgq",country,_countries,countries,nationalities);
    return country && country.demonym
    }else{
      return demonym
    }
  }
  }
  const onSubmit = async (data) => {
    // Create a birthday string from values entered
    const birthday = moment(`${data.birthdayDay} ${data.birthdayMonth} ${data.birthdayYear}`).toISOString();

    if (moment().diff(birthday, "years") < 10) {
      setError("birthday", "tooYoung", "You need to be at least 10 to sign up for Leagues");
      return;
    }

    // Delete old birthday keys
    delete data.birthdayDay;
    delete data.birthdayMonth;
    delete data.birthdayYear;

    try {
      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/auth`, { ...data, birthday });
      user.dispatch({ type: "update", payload: newData.updatedUser });
      toast.success("Details updated");
    } catch (e) {
      const msg = e.response.data ? e.response.data.msg : "Fatal error";
      toast.error(msg);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id="acc-settings-form" className="account-settings-form">
        <div className="row smaller-gutter-row">
          <div className="col-3">
            <div className="form-row">
              {/*  this is the demo of translation helper function */}
              <label htmlFor="firstName">{useTranslated("id_settings_(my_account)_0")}</label>
              {/*  this is the demo of translation helper function */}
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 30, message: "Name too long" } })}
                type="text"
                id="firstName"
                name="firstName"
                autoComplete="off"
                className={`form-input ${errors.firstName ? "invalid" : ""}`}
                defaultValue={user.state.firstName}
                placeholder=/* "John" */ {useTranslated("id_settings_(my_account)_1")}
              />
              {errors.firstName && <div className="form-input-error">{errors.firstName.message}</div>}
            </div>
          </div>
          <div className="col-3">
            <div className="form-row">
              <label htmlFor="lastName">{useTranslated("id_settings_(my_account)_2")}</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 30, message: "Name too long" } })}
                type="text"
                id="lastName"
                name="lastName"
                autoComplete="off"
                className={`form-input ${errors.lastName ? "invalid" : ""}`}
                defaultValue={user.state.lastName}
                placeholder=/* "Doe" */ {useTranslated("id_settings_(my_account)_3")}
              />
              {errors.lastName && <div className="form-input-error">{errors.lastName.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="nickname">{useTranslated("id_settings_(my_account)_4")}</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ maxLength: { value: 30, message: "Nickname too long" } })}
                type="text"
                id="nickname"
                name="nickname"
                autoComplete="off"
                className={`form-input ${errors.nickname ? "invalid" : ""}`}
                defaultValue={user.state.nickname}
                placeholder=/* "Johnny" */ {useTranslated("id_settings_(my_account)_5")}
              />
              {errors.nickname && <div className="form-input-error">{errors.nickname.message}</div>}
            </div>
          </div>

          <div
            className="col-6"
            style={{
              flex: "0 0 70%",
              maxWidth: "70%",
            }}
          >
            <div className="form-row">
              <label htmlFor="birthdayDay">{useTranslated("id_settings_(my_account)_6")}</label>
              <div
                className="row sm-gutters"
                style={{
                  flexWrap: "nowrap",
                }}
              >
                <div className="col-3">
                  <input
                    onBlur={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                    type="number"
                    ref={register({ required: "Required", min: 1, max: 31 })}
                    id="birthdayDay"
                    name="birthdayDay"
                    className={`form-input ${errors.birthdayDay ? "invalid" : ""}`}
                    defaultValue={moment(user.state.birthday).format("D") || ""}
                    placeholder=/* "Day" */ {useTranslated("id_settings_(my_account)_7")}
                  />
                </div>
                <div className="col-6">
                  <select
                    ref={register({ required: "Required" })}
                    id="birthdayMonth"
                    name="birthdayMonth"
                    className={`form-input ${errors.birthdayMonth ? "invalid" : ""}`}
                    defaultValue={moment(user.state.birthday).format("MMMM") || ""}
                  >
                    <option value="" disabled>
                      {monthtranslation}
                    </option>
                    {[
                      Januarymonth,
                      Februarymonth,
                      Marchmonth,
                      Aprilmonth,
                      Maymonth,
                      Junemonth,
                      Julymonth,
                      Augustmonth,
                      Septembermonth,
                      Octobermonth,
                      Novembermonth,
                      Decembermonth,
                    ].map((month) => (
                      <option value={month}>{month}</option>
                    ))}
                  </select>
                </div>
                <div className="col-3">
                  <input
                    onBlur={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                    type="number"
                    ref={register({ required: "Required", min: 1900, max: moment().format("Y") })}
                    id="birthdayYear"
                    name="birthdayYear"
                    className={`form-input ${errors.birthdayYear ? "invalid" : ""}`}
                    defaultValue={moment(user.state.birthday).format("YYYY") || ""}
                    placeholder=/* "Year" */ {useTranslated("id_settings_(my_account)_8")}
                  />
                  {errors.birthdayYear && <div className="form-input-error">Year is wrong</div>}
                </div>
              </div>
              {errors.birthday && <div className="form-input-error">{errors.birthday.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="gender">{useTranslated("id_settings_(my_account)_9")}</label>
              <select
                ref={register({ required: "Required" })}
                id="gender"
                name="gender"
                className={`form-input ${errors.gender ? "invalid" : ""}`}
                defaultValue={user.state.gender || ""}
              >
                <option value="" disabled>
                  {_choose_gender_translation}
                </option>
                <option value="Male">{_male_gender_translation}</option>
                <option value="Female">{_female_gender_translation}</option>
                <option value="Other">{_other_gender_translation}</option>
                <option value="Prefer not to say">{_prefer_no_tosay_gender_translation}</option>
              </select>
              {errors.gender && <div className="form-input-error">{errors.gender.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="nationality">{useTranslated("id_settings_(my_account)_10")}</label>
              <select
                ref={register({ required: "Required" })}
                // defaultValue={getUserNationality(user.state.nationality,countries)}
                id="nationality"
                name="nationality"
                className={`form-input ${errors.nationality ? "invalid" : ""}`}
              >
                <option value="" disabled>
                  {_choose_nationality_translation}
                </option>
                {nationalities
                  .map(({ alpha2Code, demonym },index) => (
                      demonym && <option key={index} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
                        {/* {demonym} */}
                        {getCountryNameTrans(alpha2Code,demonym,_countries)}
                      </option>

                    
                  ))}
              </select>
              {errors.nationality && <div className="form-input-error">{errors.nationality.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="phoneExt">{useTranslated("id_settings_(my_account)_11")}</label>
              <div className="row sm-gutters">
                <div className="col-2">
                  <select name="phoneExt" id="phoneExt" className="form-input" ref={register()} defaultValue={user.state.phoneExt || "+45"}>
                    {[
                      "+30",
                      "+31",
                      "+32",
                      "+33",
                      "+34",
                      "+36",
                      "+39",
                      "+40",
                      "+43",
                      "+44",
                      "+45",
                      "+46",
                      "+47",
                      "+48",
                      "+49",
                      "+92",
                      "+350",
                      "+351",
                      "+352",
                      "+353",
                      "+354",
                      "+356",
                      "+357",
                      "+358",
                      "+359",
                      "+370",
                      "+371",
                      "+372",
                      "+385",
                      "+386",
                      "+420",
                      "+421",
                      "+423",
                    ].map((ext) => (
                      <option value={ext}>{ext}</option>
                    ))}
                  </select>
                </div>
                <div className="col-10">
                  <input
                    onBlur={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                    ref={register({ maxLength: { value: 13, message: "Phone number too long" } })}
                    type="number"
                    id="phone"
                    name="phone"
                    autoComplete="off"
                    className={`form-input ${errors.phone ? "invalid" : ""}`}
                    defaultValue={user.state.phone}
                    placeholder=/* "Phone number" */ {useTranslated("id_settings_(my_account)_12")}
                  />
                  {errors.phone && <div className="form-input-error">{errors.phone.message}</div>}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-row">
              <label htmlFor="timezone">{useTranslated("id_settings_(my_account)_13")}</label>
              <select ref={register()} name="timezone" id="timezone" className={"form-input"}>
                <option value="">Automatic</option>
                {TIMEZONES().map(({ name, value }) => (
                  <option value={value}>{name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12">
            <div className="form-row">
              <label htmlFor="about">{useTranslated("id_settings_(my_account)_14")}</label>
              <textarea
                ref={register({ maxLength: 5000 })}
                name="about"
                id="about"
                className={`form-input ${errors.about ? "invalid" : ""}`}
                rows="10"
                placeholder={useTranslated("id_settings_(my_account)_15")}
              >
                {user.state.about}
              </textarea>
              {errors.about && <div className="form-input-error">Text too long {errors.about.message}</div>}
            </div>
          </div>

          <div className="col-12">
            <AvatarCover />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", width: "-webkit-fill-available" }}>
            <div className="col-6">
              <div className="form-row">
                <button className="button primary" type="submit">
                  {useTranslated("id_settings_(my_account)_18")}
                </button>
              </div>
            </div>
            <div className="col-6 right">
              <div className="form-row">
                <button type="button" className="button danger" onClick={onDeleteAccount}>
                  {useTranslated("id_settings_(my_account)_19")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <DeleteAccountModal isOpen={deleteAccountModal} onClose={() => setDeleteAccountModal(false)} />
    </div>
  );
};

export default MyAccount;
