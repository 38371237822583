import Axios from "axios";
import React, { useEffect, useState, useContext } from "react";

const Advert = ({ refName = "", width = 0, height = 0, style, advertForGame=null }) => {
  const [ad, setAd] = useState(null);

  useEffect(() => {
   
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/adverts/${refName}/randPromo/${advertForGame}`).then(({ data }) => {
        setAd(data);
      });
   
  }, [refName,advertForGame]);

  return (
    ad && (
      <a
        href={`${process.env.REACT_APP_CORE_API}/api/adverts/${refName}/promos/${ad._id}/click`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "block",
          textIndent: -9999,
          width,
          height,
          backgroundImage: `url(${ad.img})`,
          backgroundSize: "contain",
            // backgroundSize: "cover",
            backgroundPosition: "center",
            // background: `none`,
            backgroundRepeat:`no-repeat`,
          ...style,
        }}
      >
        Promo
      </a>
    )
  );
};

export default Advert;
