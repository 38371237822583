import React from "react";
import { List, ListItem, Icon } from "@common";

const PrizesTable = ({ stage }) => {
  const ordinalSuffixOf = (i) => {
    let j = i % 10,
      k = i % 100;

    if (j === 1 && k !== 11) return i + "st";
    if (j === 2 && k !== 12) return i + "nd";
    if (j === 3 && k !== 13) return i + "rd";

    return i + "th";
  };
  
  const prizes = stage?.league?.prizes;
  const prizesLenght = prizes?.length > -1 ? prizes?.length : 4;
  
  const teams = stage?.stageType === 'league' ? 
    stage?.league.teams.sort((a, b) => b.score - a.score).slice(0, prizesLenght) : 
    stage?.tournament.teams.sort((a, b) => b.score - a.score).slice(0, prizesLenght);

  return !teams?.length ? (
    "There are no prizes for this competition"
  ) : (
    <div>
      <div className="list-header">
        <div className="row smaller-gutter-row flex-list-item">
          <div className="col-2">Place</div>
          <div className="col-7">{stage?.stageType === 'league' ? (stage?.league.leagueFinished ? "" : "tentative ") : (stage?.tournament.tournamentFinished ? "" : "tentative ")}TEAM placements</div>
          <div className="col-3 right">Prize</div>
        </div>
      </div>
      <List classes="bordered">
        {teams.map(
          (team, i) =>
            team.team && (
              <ListItem>
                <div className="row smaller-gutter-row flex-list-item prize-item">
                  <div className="text-14 bold col-2">
                    <Icon
                      name={i === 0 ? "trophy-icon-gold" : i === 1 ? "trophy-icon-silver" : i === 2 ? "trophy-icon-bronze" : "trophy-icon"}
                      without_wrapper
                    />
                    <span className="pl-2">{ordinalSuffixOf(i + 1)}</span>
                  </div>
                  <div className="text-14 bold uppercase col-7">
                    <img
                      src={team.team.logo}
                      alt={team.team.name}
                      className={team.team.logoCropped ? "image-40" : "image-40-logoCropped"}
                    />
                    {team.team.name}
                  </div>
                  <div className="text-14 bold uppercase col-3 right">{stage?.stageType === 'league' ? stage?.league.prizes[i] : stage?.tournament.prizes[i]}</div>
                </div>
              </ListItem>
            )
        )}
      </List>
    </div>
  );
};

export default PrizesTable;
