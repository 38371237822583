import React from "react";
import { ChampionsList, TournamentListItem, PositionDisplay } from "@common";
import default_small_member_image from "@img/default-small-member-image.png";

import default_team_member from "@img/default-team-member.png";
import platinium from "@img/platinium.png";
import unranked from "@img/unranked.png";

const TournamentStats = () => {
  const champions = [
    { image: default_small_member_image, name: "Ezreal", kda: 2.86, wr: 62, games: 98 },
    { image: default_small_member_image, name: "Ezreal", kda: 2.86, wr: 62, games: 98 },
    { image: default_small_member_image, name: "Ezreal", kda: 2.86, wr: 62, games: 98 },
    { image: default_small_member_image, name: "Ezreal", kda: 2.86, wr: 62, games: 98 },
    { image: default_small_member_image, name: "Ezreal", kda: 2.86, wr: 62, games: 98 },
    { image: default_small_member_image, name: "Ezreal", kda: 2.86, wr: 62, games: 98 },
    { image: default_small_member_image, name: "Ezreal", kda: 2.86, wr: 62, games: 98 },
    { image: default_small_member_image, name: "Ezreal", kda: 2.86, wr: 62, games: 98 },
  ];

  return (
    <div className="temperary">
      <div className="row smaller-gutter-row pt-3">
        <div className="col-3">
          <PositionDisplay />

          <div className="faded-box-item no-padding mt-2 mb-2">
            <div className="inner flex-row" style={{ padding: "11px" }}>
              <img src={platinium} alt="" className="profile-images mr-3" />
              <div className="flex-column">
                <div className="text-12 bold">Platinium IV / 0 LP</div>
                <div className="text-12 faded pt-1 pb-1">Ranked Solo</div>
                <div className="text-10">
                  <span className="bold">56% WR</span> <span className="faded">175 games</span>
                </div>
              </div>
            </div>
            <hr className="m-0" />
            <div className="inner flex-row" style={{ padding: "11px" }}>
              <img src={unranked} alt="" className="profile-images mr-3" />
              <div className="flex-column">
                <div className="text-12 bold pb-1">Unranked</div>
                <div className="text-12 faded">Ranked Solo</div>
              </div>
            </div>
          </div>

          <div className="panel">
            <header className="panel-header">MOST PLAYED CHAMPIONS</header>

            <div className="panel-body">
              <ChampionsList champions={champions} />
            </div>

            <footer className="panel-footer">view more champions</footer>
          </div>
        </div>

        <div className="col-9">
          <div className="row smaller-gutter-row mr-0">
            <div className="col-12">
              <button className="button default mr-3">All LEAGUES.GG TOURNAMENTS</button>
              <button className="button default mr-3">All seasons</button>
              <button className="button transparent">Reset</button>
            </div>
          </div>

          <div className="row smaller-gutter-row">
            <div className="col-12">
              <div className="tournaments-stats-overview">
                <div className="row smaller-gutter-row">
                  <div className="col-3 tournaments-stats-overview-flex-column">
                    <div className="mini-pie-chart"></div>
                    <div className="flex-column">
                      <div className="text-12 bold">50% WR</div>
                      <div className="text-10 bold faded">Last 20 games</div>
                    </div>
                  </div>
                  <div className="col-3 tournaments-stats-overview-flex-column center">
                    <div className="flex-column">
                      <div className="text-12 bold color-primary center">3.09 KDA</div>
                      <div className="text-10 bold center">4.2 / 5.4 / 12.6</div>
                    </div>
                  </div>
                  <div className="col-3 tournaments-stats-overview-flex-column">
                    <img src={default_team_member} alt="" className="profile-images" />
                    <div className="flex-column">
                      <div className="text-12 bold">43% WR</div>
                      <div className="text-10 bold faded">3W / 4L - 2.79 KDA</div>
                    </div>
                  </div>
                  <div className="col-3 tournaments-stats-overview-flex-column">
                    <img src={default_team_member} alt="" className="profile-images" />
                    <div className="flex-column">
                      <div className="text-12 bold">80% WR</div>
                      <div className="text-10 bold faded">4W / 1L - 3.78 KDA</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {[...Array(6)].map((item) => (
            <TournamentListItem />
          ))}

          {[...Array(6)].map((item) => (
            <TournamentListItem color="danger" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TournamentStats;
