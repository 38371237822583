import React, { useState, useEffect } from "react";
import Axios from "axios";
import Collapsible from "react-collapsible";
import { Checkbox } from "@common";
import { getTeamsAvgRank } from "@utils";
import { Icon, Loading } from "@common";
import { ImArrowUpRight2 as FiArrowUpRight } from "react-icons/im";
import { TeamMemberListItemType2, SimpleTabs } from "@common";
import MultiStageDropdown from "../../../MultiStageDropdown";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Tippy from "@tippyjs/react";
import "tippy.js/animations/shift-toward.css";

const Participants = ({ id, league }) => {
  const [stages, setStages] = useState([]);
  const [teams, setTeams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [playersChecked, setPlayersChecked] = useState(true);
  const [subsChecked, setSubsChecked] = useState(true);
  const [coachesChecked, setCoachesChecked] = useState(true);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [groupStatus, setGroupStatus] = useState([]);

  // const { id } = useParams();
  const [page, setPage] = useState(0);
  const [pages, setAllPages] = useState([]);

  const [isAll, setIsAll] = useState(true);
  useEffect(() => {
    if (page === 0) {
      setIsAll(true);
    } else {
      setIsAll(false);
    }

  }, [page]);

  // let ordering = {}, // map for efficient lookup of sortIndex
  //   sortOrder = league.season.game.configuration.playerTypes
  //     .filter((players) => !players.name.includes("SUB"))
  //     .map((players) => `Player:${players.name}`);
  // for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;
  const getPlayerPositionData = (roleSplit = "") => {
    if (roleSplit.includes("SUB")) roleSplit = "SUB";
    if (roleSplit) return (league.season.game || {}).configuration.playerTypes.find(({ name }) => name === roleSplit);
    return false;
  };
  const handleGotoTeamPage = (teamId, e) => {
    e.preventDefault();

    const win = window.open(`/teams/${teamId}`, "_blank");
    win.focus();

    e.stopPropagation();
  };

  const handleGotoPlayerProfilePage = (id, e) => {
    e.preventDefault();

    const win = window.open(`/game-profile/${id}`, "_blank");
    win.focus();

    e.stopPropagation();
  };

  const handleGotoCoachProfilePage = (id, e) => {
    e.preventDefault();

    const win = window.open(`/coaches/${id}`, "_blank");
    win.focus();

    e.stopPropagation();
  };

  const positionData = (role) => {
    role = role.split(":")[1];

    if (role) return (league.season.game || {}).configuration.playerTypes.find(({ name }) => name === role);
    return false;
  };

  const coachPositionData = (role) => {
    role = role.split(":")[1];

    if (role) return (league?.season?.game || {})?.configuration?.coachTypes?.find(({ name }) => name === role);
    return false;
  };

  const subPositionData = (role) => {
    if (role === "Player:SUB") role = role + ":No Specification";
    role = role.split(":")[2];

    if (role) return (league?.season?.game || {})?.configuration?.subTypes?.find(({ name }) => name === role);
    return false;
  };
  const getTrippyText = (arr) => {
    return arr.toString();
  };

  const collapseTrigger = (_team, groups) => {
    // debugger
    const totalParticipants = () => {
      let accumulator = 0;
      if (playersChecked) {
        accumulator = accumulator + _team?.players?.length;
      }
      if (subsChecked) {
        accumulator += _team?.substituePlayers ? _team?.substituePlayers?.length : 0;
      }
      if (coachesChecked) {
        accumulator += _team?.coach ? _team?.coach?.length : 0;
      }
      return accumulator > 1 ? `${accumulator} PARTICIPANTS` : `${accumulator} PARTICIPANT`;
    };

    return (
      <div className="row justify-content-between align-items-center">
        <div className="col-md-5">
          <div className="par-team-info">
            <div className="team-logo" style={{ backgroundImage: `url(${_team?.logo})` }}></div>
            <div className="team-name">{_team?.name}</div>
          </div>
        </div>
        <div className="col-md-7" style={{ alignItems: "flex-end", display: "contents" }}>
          <div className="par-other-info">
            <div className="par-info-inner" style={{ width: "-webkit-fill-available" }}>
              {groups &&
                groups?.map((gItem, i) => (
                  groups?.length>1?(
                  <Tippy
                    disabled={false}
                    content={gItem?.stages.length > 1 ? getTrippyText(gItem.stages) : gItem.stages[0]}
                    placement="top"
                    animation="shift-toward"
                  >
                    <div className="par-num align-items-centerd" style={{ backgroundColor: `${gItem.color}22` }}>
                      <span
                        style={{ color: gItem.color, textOverflow: "ellipsis", maxWidth: "10ch", whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {gItem.name}
                      </span>
                    </div>
                  </Tippy>
                  // <GroupTag color={gItem.color} groupName={gItem.name} />
                ):(null)))}
              <div className="par-num align-items-centerd">{totalParticipants()}</div>
              <div className="par-rank align-items-centerd">{getTeamsAvgRank(_team?.players, league?.season?.game?.name)}</div>
              {!_team?.placeHolder ? (
                <>
                  <div className="par-goto-team" style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <a onClick={(e) => handleGotoTeamPage(_team?._id, e)}>
                      GO TO TEAM PAGE
                      <Icon pureIcon={<FiArrowUpRight />} />
                    </a>
                  </div>
                  <div className="par-arrow ml-3 align-self-center mt-2">
                    <Icon name="grey-down-arrow" />
                  </div>
                </>
              ) : (
                <>
                  <div className="par-goto-team placeholder-team-none">
                    <a onClick={(e) => handleGotoTeamPage(_team?._id, e)}>
                      GO TO TEAM PAGE
                      <Icon pureIcon={<FiArrowUpRight />} />
                    </a>
                  </div>
                  <div className="par-arrow ml-5"></div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const filterMembers = (item) => {
    if (item && item?.item){
     item.team.coches = [];
    item.team.substituePlayers = [];
    item.team.players = [];

    item.team.members.map((member) => {
      if (member?.role) {
        if (member.role.includes("Coach")) {
          item.team.coches.push(member);
        } else if (member.role.includes("SUB")) {
          item.team.substituePlayers.push(member);
        } else if (!member.role.includes("Owner") && !member.role.includes("Manager")) {
          item.team.players.push(member);
        }
      }
    });
    }
    return item;
  };
  const showAllTeams = () => {
    let _allTeams = [];
    fetchGroups(stages);
    if (page) {
      let stageI = page - 1;
      let stage = stages[stageI];
      if (stage?.groups?.length === 1) {
        stage.groups.map((group, groupI) => {
          let gitem = group;
          group.teams.forEach((item) => {
            item = filterMembers(item);
            let i = _allTeams.findIndex((x) => x?.team?._id === item?.team?._id);
            if (i <= -1) {
              _allTeams.push({ team: item?.team, groups: false });
            }
          });
        });
      } else {
        stage.groups.map((group, groupI) => {
          let gitem = group;
          group.teams.forEach((item) => {
            item = filterMembers(item);
            let i = _allTeams.findIndex((x) => x?.team?._id === item?.team?._id);
            if (i <= -1) {
              _allTeams.push({ team: item.team, groups: [{ ...gitem, stages: [stage?.name] }] });
            } else {
              let group_index = _allTeams[i]?.groups.findIndex((g) => g.name === gitem?.name);
              if (group_index <= -1) {
                _allTeams[i].groups.push({ ...gitem, stages: [stage?.name] });
              } else {
                let stagesIndex = _allTeams[i].groups[group_index].stages.findIndex((g) => g !== stage.name);
                if (stagesIndex >= 0) {
                  _allTeams[i].groups[group_index].stages.push(stage.name);
                }
              }
            }
          });
        });
      }
    } else {
      stages.forEach((stage, stageI) => {
        // debugger
        // Below code can be used if client wants to hide a group tag on All Stages Tab

        // if(stage.groups.length === 1){
        //   stage.groups.map((group, groupI) => {
        //     let gitem = group;
        //     group.teams.forEach((item) => {
        //       item = filterMembers(item);
        //       let i = _allTeams.findIndex((x) => x.team._id === item.team._id); //not found
        //       if (i <= -1) {
        //         _allTeams.push({ team: item.team, groups: false });
        //       }
        //     });
        //   });
        // }else{
        stage.groups.map((group, groupI) => {
          let gitem = group;
          group.teams.forEach((item) => {
            item = filterMembers(item);
            let i = _allTeams.findIndex((x) => x?.team?._id === item?.team?._id); //not found
            if (i <= -1) {
              _allTeams.push({ team: item?.team, groups: [{ ...gitem, stages: [stage?.name] }] });
            } else {
              // let group_index = _allTeams[i].groups.findIndex((g) => g.name === gitem.name);
              // if (group_index <= -1) {
              _allTeams[i].groups.push({ ...gitem, stages: [stage?.name] });
              // } else {
              //   let stagesIndex = _allTeams[i].groups[group_index].stages.findIndex((g) => g !== stage.name);
              //   if (stagesIndex >= 0) {
              //     _allTeams[i].groups[group_index].stages.push(stage.name);
              //   }
              // }
            }
          });
        });
        // }
      });
    }
    // debugger
    setTeams(_allTeams);
    setAllTeams(_allTeams);

    /////////////////////////// Shariq's Work END /////////////////////
  };

  const onTagClick = (data) => {
    console.log("data",data)
    let _groups = [];
    let _selectedGroups = [];
    let groups = [];
    let _groupStatus = [...groupStatus];
    if (selectedGroups.length > 0) {
      groups = selectedGroups.filter((item) => item.groupId !== data);
    }
    console.log("groups",groups)
    _selectedGroups.push(...groups);
    console.log("_selectedGroups",_selectedGroups)
    console.log("groupStatus",groupStatus)
    console.log("allGroups",allGroups)

    allGroups.forEach((item) => {
      if (item.group._id === data) {
        item.group.status = false;
        let g = groupStatus.findIndex((x) => x.groupId === item.group._id); // shariq changed it to findIndex it was filter before like below
        if (g >= 0) {
          _groupStatus[g].status = false;
        }
  
      }
        _groups.push(item);
      
    })
    
    onSeletedChange(_groupStatus);
    setSelectedGroups(_selectedGroups);
    
    // _groups.forEach(el=> el.group.status = true)
    if (_selectedGroups.length > 0) {
      _selectedGroups.forEach((el) => {
        let ind = _groups.findIndex((elem) => elem.group._id === el.groupId);
        if (ind >= 0) {
          _groups[ind].group.status = el.status;
        }
      });
    }
    setAllGroups(_groups);
    console.log("in groups : ", _groups);
  };

  const onSeletedChange = (data) => {
    let selectedTeams = [];
    let _selectedGroups = [];
    let _teams = allTeams;
    data.forEach((item) => {
      if (item.status) {
        if (selectedGroups.length > 0) {
          // debugger
          let found = selectedGroups.find((el) => el.groupId === item.groupId);
          if (!found) {
            // debugger
            _selectedGroups.push(...selectedGroups, item);
          }
        } else {
          _selectedGroups.push(item);
        }
      } else {
        if (selectedGroups.length > 0) {
          // debugger
          let found = selectedGroups.find((el) => el.groupId === item.groupId);
          if (found) {
            let grps = selectedGroups.filter((el) => el.groupId !== item.groupId);
            _selectedGroups.push(...grps);
          }
        }
      }
    });

    if (_selectedGroups && _selectedGroups.length > 0) {
      allTeams.map((team) => {
        for (let index in team.groups) {
          if (_selectedGroups.filter((x) => x.groupId === team.groups[index]._id).length > 0) {
            selectedTeams.push(team);
            break;
          }
        }
      });
      _teams = selectedTeams;
    }

    setGroupStatus(data);
    setSelectedGroups(_selectedGroups);
    setTeams(_teams);

    //data = [{groupId: "", status : true/false},{{groupId: "", status : true/false}}]
  };
  const fetchGroups = (stages) => {
    let _groupsArr = [];
    if (page >= 1) {
      stages.map((stage, sIndex) => {
        if (sIndex === page - 1) {
          stage.groups.map((group, gIndex) => {
            group.status = false;
            if (_groupsArr.filter((x) => x.group._id === group._id).length <= 0) {
              _groupsArr = [..._groupsArr, { stageName: stage.name, group: group }];
            }
          });
        }
      });
    } else {
      stages.map((stage, sIndex) => {
        stage.groups.map((group, gIndex) => {
          group.status = false;
          if (_groupsArr.filter((x) => x.group._id === group._id).length <= 0) {
            _groupsArr = [..._groupsArr, { stageName: stage.name, group: group }];
          }
        });
      });
    }

    if (selectedGroups.length > 0) {
      selectedGroups.forEach((el) => {
        let ind = _groupsArr.findIndex((elem) => elem.group._id === el.groupId);
        if (ind >= 0) {
          _groupsArr[ind].group.status = el.status;
        }
      });
    }
    setAllGroups(_groupsArr);
  };
  useEffect(() => {
    if (stages?.length > 0) {
      if (page <= 0) {
        showAllTeams();
      } else {
        /////////////////////////////////////// START //////////////////////////////////

        ////// Added a function By Shariq so the below code not used so i commented it
        showAllTeams(page);
      }
    }
  }, [stages, page]);

  useEffect(() => {
    setShowLoader(true);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/participants`)
      .then(({ data }) => {
        setStages(data.stages);
        if (data.stages.length > 1) {
          let pages = ["All"];
          pages.push(...data.stages.map((item, itemIndex) => item.name));
          setAllPages(pages);
        }
        // setStages(data.stages[0].groups[0].teams);
      })
      .finally(() => setShowLoader(false));
  }, []);
  return showLoader ? (
    <div className="col-12 text-center">
      <Loading width={50} height={50} />
    </div>
  ) : (
    <Wrapper>
      <div className="participants-wrapper">
        {/* participants header start  */}
        <div style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: -10 }}>
          <div span={4} style={{ alignSelf: "center", flex: "0 0 30%", marginTop: "20px" }}>
            {pages && pages.length > 1 ? <SimpleTabs style={{ width: "30%" }} pages={pages} page={page} setPage={setPage} /> : null}
          </div>

          <div span={6} className="text-right" style={{ alignSelf: "center", flex: "0 0 50%", marginLeft: "22px" }}>
            <div className="row justify-content-end flex-nowrap " style={{ marginTop: "4px" }}>
              <span className="bold text-grey uppercase font-13 row m-0 align-items-center mr-2">Show:</span>
              <Checkbox
                label="Players"
                className="check-type1"
                checked={playersChecked}
                onChange={() => setPlayersChecked(!playersChecked)}
                game={league.season.game.name}
              />
              <Checkbox
                checked={subsChecked}
                label="Substitutes"
                className="check-type1"
                onChange={() => setSubsChecked(!subsChecked)}
                game={league.season.game.name}
              />
              <Checkbox
                checked={coachesChecked}
                label="Coaches"
                className="check-type1"
                onChange={() => setCoachesChecked(!coachesChecked)}
                game={league.season.game.name}
              />
            </div>
          </div>
          <div span={2} style={{ marginTop: "10px", position: "absolute", marginLeft: "calc(83% + 1px)" }}>
            <MultiStageDropdown
              reUpdate={true}
              groups={allGroups}
              onSeletedChange={onSeletedChange}
              isMultiSelect={true}
              selectedTags={selectedGroups}
              isAll={isAll}
              setCustom={true}
            />
          </div>
        </div>
        <div className="participants-header m10 ">
          <Row style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            <Col span={2} className="col-2">
              <div>
                <div className="participant-types" style={{ cursor: "default" }}>
                  <a style={{ font: "normal normal bold 18px/22px Montserrat" }} className="active">
                    {teams.length} teams
                  </a>
                </div>
                <div style={{ "padding-bottom": "5px" }}>
                  <a
                    style={{
                      "text-align": "left",
                      font: "normal normal bold 14px/18px Montserrat",
                      "letter-spacing": "0px",
                      color: "#656D7A",
                      "text-transform": "uppercase",
                      opacity: "1",
                    }}
                  >
                    {teams.reduce((accumulator, team) => {
                      if (playersChecked) {
                        accumulator = accumulator + team?.team?.players?.length;
                      }
                      if (subsChecked) {
                        accumulator += team?.team?.substituePlayers ? team?.team?.substituePlayers?.length : 0;
                      }
                      if (coachesChecked) {
                        accumulator += team?.team?.coches ? team?.team?.coches?.length : 0;
                      }
                      return accumulator;
                    }, 0) + " "}
                    Participants
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <div className="align-items-center" style={{ display: "flex", flexDirection: "row" }}>
            {selectedGroups.length > 0
              ? selectedGroups.map((group) => {
                  // console.log({selectedGroups}),
                  if (page > 0) {
                    let stage = stages[page - 1];
                    let found = stage.groups.findIndex((el) => el._id === group.groupId);
                    if (found >= 0) {
                      return (
                        <div
                          className="par-num align-items-centerd"
                          style={{
                            borderRadius: 20,
                            backgroundColor: `${group.color}22`,
                            paddingLeft: 7,
                            paddingRight: 7,
                            paddingTop: 4,
                            paddingBottom: 4,
                            justifyContent: "center",
                            flexDirection: "row",
                            display: "flex",
                            marginRight: 10,
                            marginBottom: "1.5rem",
                          }}
                        >
                          <span
                            className="uppercase"
                            key={group.groupId}
                            style={{
                              fontSize: 12,
                              fontWeight: "bold",
                              color: group.color ? group.color : false,
                              paddingLeft: 5,
                              alignItems: "center",
                              textOverflow: "ellipsis",
                              maxWidth: "12ch",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {group.name ? group.name : false}
                          </span>
                          <span
                            onClick={(e) => onTagClick(group.groupId)}
                            style={{
                              width: 12.5,
                              height: 12.5,
                              color: group.color ? group.color : false,
                              marginBottom: 10,
                              marginTop: 3,
                              marginLeft: 10,
                              fontWeight: 500,
                              cursor: "pointer",
                            }}
                          >
                            x
                          </span>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div
                        className="par-num align-items-centerd"
                        style={{
                          borderRadius: 20,
                          backgroundColor: `${group.color}22`,
                          paddingLeft: 7,
                          paddingRight: 7,
                          paddingTop: 4,
                          paddingBottom: 4,
                          justifyContent: "center",
                          flexDirection: "row",
                          display: "flex",
                          marginRight: 10,
                          marginBottom: "1.5rem",
                        }}
                      >
                        <span
                          className="uppercase"
                          key={group.groupId}
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            color: group.color ? group.color : false,
                            paddingLeft: 5,
                            alignItems: "center",
                            textOverflow: "ellipsis",
                            maxWidth: "12ch",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {group.name ? group.name : false}
                        </span>
                        <span
                          onClick={(e) => onTagClick(group.groupId)}
                          style={{
                            width: 12.5,
                            height: 12.5,
                            color: group.color ? group.color : false,
                            marginBottom: 10,
                            marginTop: 3,
                            marginLeft: 10,
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          x
                        </span>
                      </div>
                    );
                  }
                })
              : false}
          </div>
          <div className="row justify-content-between"></div>
        </div>
        {/* participants header end  */}
        {/* participants content start  */}
        <div className="participants-content" style={{ marginRight: "30px" }}>
          {teams[0]?.team !==undefined && teams[0]?.team !==null ?
          (teams.map((team, index) => {
            return (
              <Collapsible
                transitionTime={100}
                trigger={collapseTrigger(team?.team, team?.groups)}
                classParentString="collapsible-wrapper"
                key={team?.team?._id}
              >
                {playersChecked &&
                  team?.team?.players?.map(
                      (member) =>
                        member && (
                          <TeamMemberListItemType2
                            key={member?.player?._id}
                            img={member?.player?.avatarImage}
                            title={member?.player?.name}
                            subtitle={
                              member?.player?.user
                                ? `${member?.player?.user?.firstName} ${
                                    member?.player?.user?.nickname ? ` "${member?.player?.user?.nickname}" ` : " "
                                  } ${member?.player?.user?.lastName}`
                                : ""
                            }
                            position={positionData(member?.role)}
                            role={member?.role}
                            nationality={member?.player?.user?.nationality}
                            editable={false}
                            availableNewRoles={[]}
                            game={league?.season?.game?.name}
                            actions={[
                              <a onClick={(e) => handleGotoPlayerProfilePage(member?.player?._id, e)} className="text-14 primary-link">
                                View profile
                              </a>,
                            ]}
                          />
                        )
                    )}
                {subsChecked &&
                  team?.team?.substituePlayers?.map((substituePlayer) => (
                    <TeamMemberListItemType2
                      key={substituePlayer?._id}
                      img={substituePlayer?.player?.avatarImage}
                      title={substituePlayer?.player?.name}
                      subtitle={
                        substituePlayer?.player
                          ? `${substituePlayer?.player?.user?.firstName}${
                              substituePlayer?.player?.user?.nickname ? ` "${substituePlayer?.player?.user?.nickname}" ` : " "
                            }${substituePlayer?.player?.user?.lastName}`
                          : ""
                      }
                      position={positionData(substituePlayer?.role)}
                      // positionData={substituePlayer ? getPlayerPositionData(substituePlayer.role) : false}
                      subPosition={subPositionData(substituePlayer?.role)}
                      role="SUB"
                      nationality={substituePlayer?.player?.user?.nationality}
                      editable={false}
                      availableNewRoles={[]}
                      game={league.season.game}
                      actions={[
                        <a onClick={(e) => handleGotoPlayerProfilePage(substituePlayer?.player?._id, e)} className="text-14 primary-link">
                          View profile
                        </a>,
                      ]}
                    />
                  )
            )}

                {coachesChecked &&
                  team?.team?.coches?.map((coach) => (
                    <TeamMemberListItemType2
                      key={coach?._id}
                      img={coach?.coach?.avatarImage}
                      title={coach?.coach?.name}
                      subtitle={
                        coach.coach
                          ? `${coach?.coach?.user?.firstName}${coach?.coach?.user?.nickname ? ` "${coach?.coach?.user?.nickname}" ` : " "}${
                              coach?.coach?.user?.lastName
                            }`
                          : ""
                      }
                      subPosition={coachPositionData(coach.role)}
                      role="Coach"
                      nationality={coach?.coach?.user?.nationality}
                      editable={false}
                      availableNewRoles={[]}
                      game={league?.season?.game}
                      actions={[
                        <a onClick={(e) => handleGotoCoachProfilePage(coach?.coach?._id, e)} className="text-14 primary-link">
                          View profile
                        </a>,
                      ]}
                    />
                  ))}
              </Collapsible>
            );
        
          })):<div>No Participents Added Yet</div>}
        </div>
        {/* participants content end  */}
      </div>
    </Wrapper>
  );
};

export default Participants;

const Wrapper = styled.div`
  .m10 {
    margin: 10px 0px 10px 0px !important;
  }
`;
