import React from "react";
import { Icon } from "@common";

const PositionDisplay = ({ mostPlayed }) => {
  return (
    <ul className="position-display-list">
      {["TOP", "JUNGLE", "MID", "BOT", "SUPPORT"].map((pos, i) => (
        <li className={mostPlayed === pos && `active`}>
          <Icon name={`position-${i + 2}`} />
        </li>
      ))}
    </ul>
  );
};

export default PositionDisplay;
