import React from "react";
import PageBuilder from '../../../../PageBuilder';
const Blank = ({ /*orgId,*/ tabObject,showPreview,saveContent }) => {

  return (
    <>
      <PageBuilder showPreview={showPreview || false} saveContent={(elements) => saveContent(elements)} initialContent={tabObject[0].content}/>
    </>
  );
};

export default Blank;
