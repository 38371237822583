import React, { useContext, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import Dropdown from "./Dropdown/Dropdown";
import default_profile_picture from "@img/rallerpenos-image.png";
import logo_header from "@img/logo-header.svg";
import { DynamicSearch, Icon } from "@common";
import NotificationsDropdown from "./NotificationsDropdown/NotificationsDropdown";
import GameSwitcher from "./GameSwitcher/GameSwitcher";
import { useState } from "react";
import Axios from "axios";
import GlitchText from "../../_common/GlitchText/GlitchText";
import useTranslated from "../../../helpers/translationHelper";
import TranslationDropdown from "./TranslationDropdown/TranslationDropdown"

const toggleSidebar = (sidebarOpen, setSidebarOpen) => {
  setSidebarOpen(!sidebarOpen);
  localStorage.setItem("sidebarOpen", !sidebarOpen);
};

const HeaderButtons = () => {
  const history = useHistory();
  const { authenticated, setAuthenticated, user, setShowAuth } = useContext(AppContext);


  const Leagues_Admin=useTranslated('id_top_bar_(settings)_0')
  const Ghost_Dashboard=useTranslated('id_top_bar_(settings)_1')
  const Settings=useTranslated('id_top_bar_(settings)_2')
  const Logout=useTranslated('id_top_bar_(settings)_1')
  const organizerUrl = process.env.REACT_APP_ORGANIZER_URL ?? 'https://test-organizer.leagues.gg/'
  const session = localStorage.getItem("sessionToken");
  const logout = () => {
    localStorage.removeItem("sessionToken");
    setAuthenticated(false);
    history.push("/");
  };

  const checkOrganaizerPermission = (user)=> {
    if(user){
      console.log("Share Organaizers",user.sharedOrganizer)
      if(user?.permissions?.user?.OrganizerAccess || user?.permissions?.user?.OrganizerAdmin) return true;
      if(user?.sharedOrganizer > 0) return true;
    }
    return false
  }
  let usertranslate1 = useTranslated("id_top_bar_3");
  let usertranslate2 = useTranslated("id_top_bar_4");
  if (authenticated && user.state) {
    return (
      <React.Fragment>
        <Link to={`/players/${user.state._id}`} className="header-profile-link">
          <img src={user.state.avatarImage || default_profile_picture} style={{ borderRadius: "15px" }} alt="" />
          <span className="bold">{user.state.nickname || user.state.firstName}</span>
        </Link>
        <TranslationDropdown />
        <NotificationsDropdown />

        <Dropdown
          label={<Icon name="cog" />}
          render={() => (
            <>
              {user.state.admin ? (
                <>
                  <a href="https://admin.leagues.gg" target="_blank" rel="noopener noreferrer" className="header-settings-link">
                    <Icon name="cog" /> {Leagues_Admin}
                  </a>
                  <a href="https://pr.leagues.gg/ghost/#/posts" target="_blank" rel="noopener noreferrer" className="header-settings-link">
                    <Icon name="cog" /> {Ghost_Dashboard}
                  </a>
                  <a href={`${organizerUrl}?lst=${session}`} target="_blank" rel="noopener noreferrer" className="header-settings-link">
                    <Icon name="hiChip" /> {'Organizer Panel'}
                  </a>
                </>
              ):
               (checkOrganaizerPermission(user?.state) &&
              //  ((user?.state?.permissions?.user.OrganizerAccess === true || user?.state?.permissions?.user.OrganizerAdmin === true) &&
                 <a href={`${organizerUrl}?lst=${session}`} target="_blank" rel="noopener noreferrer" className="header-settings-link">
                 <Icon name="hiChip" /> {'Organizer Panel'}
               </a>)
              }
              <Link to="/account" className="header-settings-link">
                <Icon name="cog" /> {Settings}
              </Link>
              <div onClick={logout} className="header-settings-link">
                <Icon name="logout" /> {Logout}
              </div>
            </>
          )}
        />

        {/* <UpcomingMatch /> */}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TranslationDropdown />
      <button className="button bordered medium ml-3 mr-3" onClick={() => setShowAuth("login")}>
      {usertranslate1}
      </button>
      <button className="button primary medium" onClick={() => setShowAuth("register")}>
      {usertranslate2}
      </button>
    </React.Fragment>
  );
};

const Header = ({ sidebarOpen, setSidebarOpen }) => {
  const history = useHistory();
  const timeout = useRef();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedregions, setSelectedRegions] = useState([]);
  const [singleSelectedregions, setSingleSelectedRegions] = useState("");

  useEffect(() => {
    let regions = JSON.parse(localStorage.getItem("selectedRegions"));
    setSelectedRegions(regions);
  }, []);
  const { selectedGame, setSelectedGame } = useContext(AppContext);
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const onSearch = async (query) => {
    clearTimeout(timeout.current);
    setResults([]);
    if (!query.length) return setLoading(false);
    setLoading(true);
    let selectedGameId = null;
    if (selectedGame.shortName !== "No Game") {
      selectedGameId = selectedGame._id;
    }

    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch`, {
        query,
        include: "users, players, organisations, teams, coaches",
        selectedGameId,
        selectedRegion: singleSelectedregions ? singleSelectedregions : "",
      });

      // Map data to required output, and remove already selected players
      const searchResults = data.map(({ _id, img, name, user, type, logoCropped }) => ({
        _id,
        type,
        img,
        logoCropped,
        title: name,
        subtitle: user ? `${user.firstName} ${user.lastName}` : false,
      }));

      setResults(searchResults);
      setLoading(false);
    }, 250);
  };

  const onClickSearchResult = (result) => {
    const { _id, type } = result;

    switch (type) {
      case "User":
        history.push(`/players/${_id}`);
        break;
      case "Player":
        history.push(`/game-profile/${_id}`);
        break;
      case "Organisation":
        history.push(`/organisation/${_id}`);
        break;
      case "Team":
        history.push(`/teams/${_id}`);
        break;
      case "Coach":
        history.push(`/coaches/${_id}`);
        break;
      default:
    }
  };

  const handleSelectedRegion = (event) => {
    setSingleSelectedRegions(event.target.value);
  };

  console.log("singleSelectedregions", singleSelectedregions);
  return (
    <React.Fragment>
      <header className="global-header">
        <div className="header-logo">
          <button className="menu-icon" onClick={() => toggleSidebar(sidebarOpen, setSidebarOpen)}>
            {sidebarOpen ? <Icon name="menu-close" /> : <Icon name="menu-open" />}
          </button>
          <div style={{ cursor: "pointer" }}>
            <Link to="/" className="logo-text">
              <GlitchText animateOnHover>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={logo_header} alt="Leagues logo" />
                  <strong className="uppercase logo__text">{useTranslated('id_top_bar_0')}</strong>
                </div>
              </GlitchText>
            </Link>
          </div>
          <span className="header-game-switcher-bleft">&nbsp;</span>
          <GameSwitcher />
        </div>

        <div>
          {selectedregions ? (
            <select
              className="form-input test"
              style={{ paddingRight: "30px", textOverflow: "ellipsis", overflow: "hidden" ,width:'236px'}}
              onChange={(event) => handleSelectedRegion(event)}
            >
              <option className="test1" value="">
                -- Choose Region --
              </option>
              {selectedregions &&
                selectedregions.map((item, index) => {
                  return (
                    <option className="test1" value={item.code}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          ) : null}
        </div>
        <div className="header-search">
          <DynamicSearch
            placeholder={useTranslated("id_top_bar_2")}
            onSearch={onSearch}
            onSelectResult={onClickSearchResult}
            loadingResults={loading}
            results={results}
            mixedResults={true}
            newTab
          />
        </div>

        <div className="header-actions">
          <HeaderButtons />
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
