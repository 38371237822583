import React from "react";
import { ChampionsList } from "@common";
import moment from "moment";

var timeSince = function (date) {
  if (typeof date !== 'object') {
    date = new Date(date);
  }

  var seconds = Math.floor((new Date() - date) / 1000);
  var intervalType;

  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = 'y';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = 'M';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = 'd';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = "h";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = "m";
          } else {
            interval = seconds;
            intervalType = "s";
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    // intervalType += 's';
  }

  return interval + ' ' + intervalType;
};

const TeamStatItem = ({ lanes = [], playerData = {}, championsData, statsType }) => {
  let { totals, mapsPlayed, player, role } = playerData;
  role = role.split(":")[1];
  const leagueEntry = player.lolData.leagueEntries.find(({ queueType }) => queueType === "RANKED_SOLO_5x5") || {};

  const championIdToImg = (championId) => {
    const championData = championsData[championId];

    if (championData) {
      const championVers = championData.version;
      const championImage = championData.image.full;

      return `https://ddragon.leagueoflegends.com/cdn/${championVers}/img/champion/${championImage}`;
    }

    return false;
  };

  const championIdToName = (championId) => {
    const championData = championsData[championId];

    if (championData) {
      return championData.name;
    }

    return false;
  };

  const getLaneIcon = (role) => {
    const data = lanes.find(({ name }) => role.includes(name));

    return data ? data.icon : false;
  };

  const recentlyPlayed = mapsPlayed
    .map((data) => ({
      ...data,
      image: championIdToImg(data.championId),
      name: championIdToName(data.championId),
    }))
    .splice(0, 10);

  const aggregatedChampions = [
    ...mapsPlayed
      .reduce((r, o) => {
        const key = o.championId;

        const item =
          r.get(key) ||
          Object.assign({}, o, {
            wins: 0,
            losses: 0,
            kills: 0,
            deaths: 0,
            assists: 0,
            games: 0,
          });

        item.wins += o.win;
        item.losses += o.loss;
        item.kills += o.kills;
        item.deaths += o.deaths;
        item.assists += o.assists;
        item.games += 1;

        return r.set(key, item);
      }, new Map())
      .values(),
  ]
    .map((champion) => ({
      ...champion,
      image: championIdToImg(champion.championId),
      name: championIdToName(champion.championId),
      kda: ((champion.kills + champion.assists) / champion.deaths).toFixed(2),
      wr: Math.round((champion.wins / (champion.wins + champion.losses)) * 100),
      games: champion.games,
    }))
    .sort((a, b) => b.games - a.games);

  const aggregatedRoles = [
    ...mapsPlayed
      .reduce((r, o) => {
        const key = o.role;

        const item =
          r.get(key) ||
          Object.assign({}, o, {
            wins: 0,
            losses: 0,
          });

        item.wins += o.win;
        item.losses += o.loss;

        return r.set(key, item);
      }, new Map())
      .values(),
  ].splice(0, 2);

  let sum = totals.wins + totals.losses;
  let wr = sum === 0 ? 0 : Math.round((totals.wins / sum) * 100);
  let wr_for_bar = sum === 0 ? 50 : Math.round((totals.wins / sum) * 100);

  return (
    <div className="team-stat-item">
      <a href={`/game-profile/${player._id}`} className={"text-white text-decoration-none"}>
        <div className="team-stat-item-inner player-info">
          <div className="text-14 bold uppercase player-info-logo">
            <img src={player.avatarImage} className="player-logo" alt="Player" />
            <img className="role-icon" src={getLaneIcon(role)} alt={role} />
            {role}
          </div>
          <span className="text-14 bold player-info-name">{player.name}</span>
          <span className="text-12 uppercase text-faded bold player-info-rank">
            {`${leagueEntry?.tier ? leagueEntry?.tier : ""} ${leagueEntry?.rank ? leagueEntry?.rank : ""} (${leagueEntry.leaguePoints ? leagueEntry?.leaguePoints : 0} LP)`}
          </span>
          <div className="player-info-wl-ratio-container">
            <div
              className={`text-12 ${totals.wins == 0 && totals.losses !== 0 ? "player-info-l" : "player-info-w"} uppercase bold`}
              style={{ width: wr_for_bar === 0 ? 12 + "%" : wr_for_bar + "%" }}
            >
              {totals.wins}W
            </div>
            <div
              className={`text-12 ${wr_for_bar === 100 ? "player-info-w" : "player-info-l"} uppercase bold`}
              style={{ width: wr_for_bar === 100 ? 12 + "%" : 100 - wr_for_bar + "%" }}
            >
              {totals.losses}L
            </div>
          </div>
          <span className="text-12 uppercase bold win-rate">{wr}% Win rate</span>
        </div>
      </a>
      <hr />
      <div className="team-stat-item-inner">
        <div className="team-stat-heading uppercase faded bold center">Recently played</div>
        <div className="recently-played-stat">
          {aggregatedRoles.map(({ role, wins, losses }) => (
            <div className="uppercase bold center">
              <span>
                <img width="20px" height="20px" src={getLaneIcon(role)} alt="" />
              </span>
              <span>{Math.round((wins / (wins + losses)) * 100)}% W/R</span>
            </div>
          ))}
        </div>

        {recentlyPlayed.map(({ win, image, name, kills, deaths, assists, gameCreation }) => {
          let color = win ? "success" : "danger";
          return (
            <div className="team-stat-list-item">
              <img src={image} alt={name} />
              <div className="team-stat-list-subitem flex-expand">
                <span className={`team-stat-list-subitem-center ${color} bold`}>
                  {kills} / {deaths} / {assists}
                </span>
              </div>
              <div className="team-stat-list-subitem-center team-stat-list-subitem">
                <span className="faded">{timeSince(gameCreation)}</span>
              </div>
            </div>
          );
        })}
      </div>
      <hr />
      <div className="team-stat-item-inner bg-faded">
        <div className="text-12 team-stat-heading uppercase faded bold center">most played champions</div>

        <ChampionsList champions={aggregatedChampions} />
      </div>
    </div>
  );
};

export default TeamStatItem;
