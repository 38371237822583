import React from "react";

const UserTeams = ({ teams = [] }) => {
  return teams.length ? (
    <div className="user-profile-teams">
      <h2>Teams</h2>

      {teams.map(({ game, name }) => (
        <div className="profile-team">
          <div className="team-avatar" />
          <div className="team-details">
            <p className="team-name">TEAM-NAME</p>
            <p className="team-game">TEAM-NAME</p>
          </div>
        </div>
      ))}
    </div>
  ) : null;
};

export default UserTeams;
