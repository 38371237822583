import React, { useState, createContext, useEffect } from "react";
import Modal from "react-modal";
import CreateTeam from "./CreateTeam/CreateTeam";
import InvitePlayers from "./InvitePlayers/InvitePlayers";
import AddTeamImages from "./AddTeamImages/AddTeamImages";
import AddTeamSocials from "./AddTeamSocials/AddTeamSocials";
import { RiCloseLine } from "react-icons/ri";
import useTranslated from "../../../helpers/translationHelper";

export const CreateTeamModalContext = createContext();

export const CreateTeamModal = ({ open, onClose, organisation, organisationId, game, nationality, afterCreate }) => {
  const [step, setStep] = useState(0);
  const [teamData, setTeamData] = useState({});
  const lableStep2=useTranslated("id_pop-up_-_create_team_17")/* step ({step + 1})/4 */
  const lableStep3=useTranslated("id_pop-up_-_create_team_24")/* step ({step + 1})/4 */
  const lableStep4=useTranslated("id_pop-up_-_create_team_31")/* step ({step + 1})/4 */

  useEffect(() => {
    // reset modal when it changes visibility
    setStep(0);
    setTeamData({});
  }, [open]);

  const PrintingSteps = (key) => {
    switch (key+1) {
      
      case 2:
        return <span className="text-12 uppercase bold color-primary">{lableStep2/* step ({step + 1})/4 */}</span>
        break;
      case 3:
        return <span className="text-12 uppercase bold color-primary">{lableStep3/* step ({step + 1})/4 */}</span>
        break;
      case 4:
        return <span className="text-12 uppercase bold color-primary">{lableStep4/* step ({step + 1})/4 */}</span>
        break;
      default:
        break;
    }
  };

  const steps = [<CreateTeam />, <InvitePlayers />, <AddTeamImages />, <AddTeamSocials />];

  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      {step > 0 ? (
        <div className="modal-step-progress">
          <span className="text-12 uppercase bold color-primary">{PrintingSteps(step)/* step {step + 1}/4 */}</span>
        </div>
      ) : null}
      <CreateTeamModalContext.Provider
        value={{ setStep, onClose, organisation, organisationId, game, nationality, teamData, setTeamData, afterCreate }}
      >
        {steps.map((component, i) => i === step && <div key={i}>{component}</div>)}
      </CreateTeamModalContext.Provider>
    </Modal>
  );
};
