import React from "react";
import TeamsItem from "@components/TeamsRouter/TeamsItem/TeamsItem";


const Teams = ({ teams = [], player_id }) => {
  return (
    <div>
      <div className="row player-overview-row">
        <div className="col-lg-12 row-content">
          <div className="row smaller-gutter-row">
            {teams.map((team) => (
              <TeamsItem player_id={player_id} key={team._id} croppedLogo={team.logoCropped} {...team} classes="col-4" link_to={`/teams/${team._id}`} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
