import React from "react";
import objective_1_primary from "@img/objective-1-primary.svg";
import objective_2_primary from "@img/objective-2-primary.svg";
import objective_3_primary from "@img/objective-3-primary.svg";
import objective_1_danger from "@img/objective-1-danger.svg";
import objective_2_danger from "@img/objective-2-danger.svg";
import objective_3_danger from "@img/objective-3-danger.svg";
import useTranslated from "../../../helpers/translationHelper";
import { match } from "core-js/fn/symbol";

const GameOverviewBox = ({ map, champions, hide_bans }) => {
  // const championIdToImg = (championId) => {
  //   const championData = champions[championId];
  //   const championVers = championData.version;
  //   const championImage = championData.image.full;

  //   return `https://ddragon.leagueoflegends.com/cdn/${championVers}/img/champion/${championImage}`;
  // };
  const bansTrans = useTranslated('id_match_page_(games)_[lol]_0')
  const deathTrans = useTranslated('id_match_page_(games)_[cs:go]_1')
  const cashTrans = useTranslated('id_match_page_(games)_[cs:go]_2')
  const killTrans = useTranslated('id_match_page_(games)_[cs:go]_0')
 
  const calculateTotal = (teamId, statName) => {
    const teamParticipants = map.participants.filter(({ teamId: id }) => id === teamId);

    let total = 0;
    teamParticipants.forEach(({ stats }) => {
      total += stats[statName];
    });

    return total;
  };

  return (
    <div className="faded-box-item">
      <div className="row tiny-gutter-row">
        {/* TEAM 1 */}
        <div className={"col-3"}>
          <div className="row tiny-gutter-row">
           
            <div className={"col-3"}>
              <span className="games-overview-col-header text-12 uppercase bold">{/* Kills */}{killTrans}</span>
              <span className="text-12 uppercase bold">{map.processedteamData.team1.totalKills}</span>
            </div>

            <div className={"col-3"}>
              <span className="games-overview-col-header text-12 uppercase bold">{/* Deaths */}{deathTrans}</span>
              <span className="text-12 uppercase bold">{map.processedteamData.team1.totalDeaths}</span>
            </div>

            {/* <div className={"col-3"}>
              <span className="games-overview-col-header text-12 uppercase bold">Plants</span>
              <span className="text-12 uppercase bold">99</span>
            </div>

            <div className={"col-3"}>
              <span className="games-overview-col-header text-12 uppercase bold">Dufuse</span>
              <span className="text-12 uppercase bold">99</span>
            </div> */}
          </div>
        </div>

        {/* TEAM 2 */}
        <div className={"col-6 center"}>
          <span className="games-overview-col-header text-12 uppercase bold">{/* CASH */}{cashTrans}</span>
          <div className="gold-ratio-bar-wrapper">
            <span className="text-12 bold">{map.processedteamData.team1.totalCash}</span>
            <div className="gold-ratio-bar">
              <div
                className={map.processedteamData.team1.winner ? 'success' : 'danger'}
                style={{
                  width: (map.processedteamData.team1.totalCash / (map.processedteamData.team1.totalCash + map.processedteamData.team2.totalCash)) * 100 +
                    "%",
                }}
              ></div>
              <div
                className={map.processedteamData.team2.winner ? 'success' : 'danger'}
                style={{
                  width: (map.processedteamData.team2.totalCash / (map.processedteamData.team1.totalCash + map.processedteamData.team2.totalCash)) * 100 +
                    "%",
                }}
              ></div>
            </div>
            <span className="text-12 bold">{map.processedteamData.team2.totalCash}</span>
          </div>
        </div>
        <div className={"col-3 right"}>
          <div className="row tiny-gutter-row">
            {/* <div className={"col-3"}>
              <span className="games-overview-col-header text-12 uppercase bold">Dufuse</span>
              <span className="text-12 uppercase bold">{21213}</span>
            </div>
            
            <div className={"col-3"}>
              <span className="games-overview-col-header text-12 uppercase bold">Plants</span>
              <span className="text-12 uppercase bold">{21213}</span>
            </div> */}
            <div className={"col-3"}></div>
            <div className={"col-3"}></div>
            <div className={"col-3"}>
              <span className="games-overview-col-header text-12 uppercase bold">{/* Deaths */}{deathTrans}</span>
              <span className="text-12 uppercase bold">{map.processedteamData.team2.totalDeaths}</span>
            </div>

            <div className={"col-3"}>
              <span className="games-overview-col-header text-12 uppercase bold">{/* Kills */}{killTrans}</span>
              <span className="text-12 uppercase bold">{map.processedteamData.team2.totalKills}</span>
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameOverviewBox;
