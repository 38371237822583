import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import Loading from "../../_common/Loading/Loading";
import { toast } from "react-toastify";
import Axios from "axios";
import { AppContext } from "../../../context/AppContext";

export const JoinScrimModal = (props) => {
  const { selectedGame, authenticated } = useContext(AppContext);
  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const { open, onClose, scrimId, setUserHasTeams } = props;
  const { register, handleSubmit, errors, watch } = useForm();

  const gameChangeWatcher = watch("game", selectedGame?._id);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      
      setGames(data);
    });
  }, []);

  useEffect(() => {
    authenticated &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/myTeamsAsNonPlayer/${gameChangeWatcher}`).then(({ data }) => {
      if(data.length){setUserHasTeams(true)}
        setTeams(data);
      });
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    data = { team: data.team, scrim: scrimId };

    try {
      const { data: scrim } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matchups`, data);
      if (scrim) {
        toast.success("Successfully joined scrim!");
        onClose();
      }
    } catch (e) {
      const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem joining scrim";
      toast.error(msg);
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div>
        <div className="modal-header">
          <h2>Select Team</h2>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <label htmlFor="game">Choose Game</label>
              <select
                ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                id="game"
                name="game"
                className={`form-input ${errors.game ? "invalid" : ""}`}
                defaultValue={selectedGame._id || "_DEFAULT_"}
                disabled
              >
                <option value="_DEFAULT_" disabled>
                  Choose game
                </option>
                {games.map(({ _id, name }) => (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                ))}
              </select>
              {errors.game && <div className="form-input-error">{errors.game.message}</div>}
            </div>

            <div className="row form-row sm-gutters">
              <label htmlFor="name">Choose Team</label>
              <select
                ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                id="team"
                name="team"
                className={`form-input ${errors.team ? "invalid" : ""}`}
                defaultValue="_DEFAULT_"
              >
                <option value="_DEFAULT_" disabled>
                  Choose team
                </option>
                {teams.map(({ _id, name, game }) => (
                  (game && game.name == selectedGame.name) && <option key={_id} value={_id}>
                    {name}
                  </option>
                ))}
              </select>
              {errors.team && <div className="form-input-error">{errors.team.message}</div>}
            </div>

            <button type="submit" className="button primary thicc" disabled={loading}>
              {loading ? (
                <>
                  <Loading width={30} height={30} /> Creating Team
                </>
              ) : (
                "Join Scrim"
              )}
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};
