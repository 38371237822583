import React, { useState, useEffect, useContext } from "react";
import Slider from "../Slider/Slider";
import { useHistory, useParams } from "react-router-dom";
import { Button, SliderItem } from "@common";
import { AppContext } from "../../../context/AppContext";
import Axios from "axios";
import league_image from "@img/league-image.png";

const TournamentsSeasons = ({ data }) => {
  const { selectedGame, setSelectedGame } = useContext(AppContext);
  const history = useHistory();
  const [loadingSeasons, setLoadingSeasons] = useState(true);
  const [seasons, setSeasons] = useState([]);
  const [blueText, setBlueText] = useState("Loading...");
  const [whiteText, setWhiteText] = useState("Loading...");
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [includeAllWithSameName, setIncludeAllWithSameName] = useState(false);

  useEffect(() => {
    if (data.BlueText) {
      setBlueText(data.BlueText);
    }
    if (data.WhiteText) {
      setWhiteText(data.WhiteText);
    }
    if (data.season && data.season.length) {
      setSelectedSeason(data.season.map(({ _id }) => _id).toString());
    }
    if (data.includeAllWithSameName) {
      setIncludeAllWithSameName(data.includeAllWithSameName);
    }
  }, [data]);

  const getFeaturedSeasons = (selectedSeason) => {
    if (includeAllWithSameName) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/getTournamentsSeasonsWithSameNameYear/${selectedSeason}`).then(
        ({ data }) => {
          let dataleagues = [];
          data &&
            data.map((sesSing) => {
              dataleagues.push(sesSing.tournaments);
            });

          setSeasons(dataleagues[0]);
          setLoadingSeasons(false);
        }
      );
    } else {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/getTournamentsOfSelectedSeason/${selectedSeason}`).then(({ data }) => {
        setSeasons(data.tournaments);
        setLoadingSeasons(false);
      });
    }
  };

  useEffect(() => {
    console.log("selected season -- ", selectedSeason);
    selectedSeason && getFeaturedSeasons(selectedSeason);
  }, [selectedGame, selectedSeason]);

  return (
    <>
      <Slider
        isArrowBg
        key={`${blueText}-slide-${blueText}`}
        info={
          <div>
            <h1 className="outline">{blueText}</h1>
            <h1>{whiteText}</h1>

            <Button onClick={() => history.push("/tournaments")} name="cta-button-transparent">
              All Tournaments
            </Button>
          </div>
        }
      >
        {seasons?.length && seasons.length >= 3
          ? seasons.map(({ _id, name, logoImage, country, thumbnailImage, teams }, i) => (
              <SliderItem
                key={i}
                title={name ? name : "Challenger Series Denmark"}
                second_line={
                  <React.Fragment>
                    <img
                      src={country ? `https://flagcdn.com/w20/${country.code?.toLowerCase()}.png` : `https://flagcdn.com/w20/dk.png`}
                      alt="Danish flag"
                      className={"loading"}
                    />
                    {country ? country.name : "Denmark"}
                  </React.Fragment>
                }
                third_line={teams.length === 0 ? "Be the first one to join" : `${teams.length} teams participating`}
                tag=""
                image={thumbnailImage ? thumbnailImage : league_image}
                link_to={`/tournament/${_id}`}
              />
            ))
          : seasons?.length &&
            [1, 2, 3].map(() =>
              seasons.map(({ _id, name, logoImage, country, thumbnailImage, teams }, i) => (
                <SliderItem
                  key={i}
                  title={name ? name : "Challenger Series Denmark"}
                  second_line={
                    <React.Fragment>
                      <img
                        src={country ? `https://flagcdn.com/w20/${country.code?.toLowerCase()}.png` : `https://flagcdn.com/w20/dk.png`}
                        alt="Danish flag"
                        className={"loading"}
                      />
                      {country ? country.name : "Denmark"}
                    </React.Fragment>
                  }
                  third_line={teams.length === 0 ? "Be the first one to join" : `${teams.length} teams participating`}
                  tag=""
                  image={thumbnailImage ? thumbnailImage : league_image}
                  link_to={`/tournament/${_id}`}
                />
              ))
            )}
      </Slider>
    </>
  );
};
export default TournamentsSeasons;
