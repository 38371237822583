import React, { useState } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import { Loading } from "@common";
import Axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const DeleteScrimModal = ({ isOpen, onClose, scrim }) => {
  const history = useHistory();
  const [processing, setProcessing] = useState(false);

  const deleteScrim = async () => {
    setProcessing(true);
    try {
      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/scrims/${scrim._id}`).then((data) => console.log("deleted scrim : ", data));
      toast.success("Successfully deleted");
      history.push("/scrims");
    } catch (e) {
      let error = e.response.data ? e.response.data.msg : "There was a problem deleting this scrim";
      toast.error(error);
    }

    setProcessing(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal">
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>Delete your Scrim</h2>
      </div>
      <div className="modal-body">
        <div className="delete-account-text">
          <p>
          Are you sure you want to delete this scrim? This action is irreversible!
          </p>
          <div className="form-row">
              <button onClick={deleteScrim} className="button danger thicc" disabled={processing}>
                {processing ? <Loading /> : "Delete Scrim"}
              </button>
            </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteScrimModal;
