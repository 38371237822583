import React from 'react';
import { Draggable, state } from 'react-page-maker';
import { RiCloseLine } from 'react-icons/ri';
import './style.scss';
class ModuleHeader extends React.Component {
  state = {
    showColorPicker: false,
    background: '',
    heading: '#WEARELEGION',
    toggleEdit:false,
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  };

  toggleEditField = (fieldName) => {
    this.setState({toggleEdit: fieldName});
  }
  

  handleChangeComplete = (color) => {
    const { id, dropzoneID, parentID }= this.props;
    this.setState({ background: color.hex }, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: { background: color.hex }
      });
    });
  };

  changePayload = (payLoadKey,value) => {
    const { id, dropzoneID, parentID,payload }= this.props;
    this.setState({ [payLoadKey]: value }, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: { ...payload, [payLoadKey]: value }
      });
    });
  }

  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }

  
  render() {
    const {
      id, showBasicContent, showPreview,
      dropzoneID, parentID, 
      // name, 
      payload
    } = this.props;

    const {toggleEdit} =  this.state;
    
    const heading =  payload.heading || this.state.heading;
    const description =  payload.description || this.state.description;
    

    if (showBasicContent) {
      // console.log('this.propsthis.props',this.props)
      const {name} = this.props;
      return (
        <>
        <Draggable
          { ...this.props } 
      >{name}</Draggable></>
      )
    }

    if (showPreview) {
      return (
        <>
        <header >
          <h2 className="pb_ele_header_heading">{heading}</h2>
          <p className="text-faded pb_ele_header_description">{description}</p>
        </header>
        <br />
        </>
      );
    }

    return (
      <Draggable { ...this.props } >
        <div className="actions">
            <span className="action_type" title="Remove element"><RiCloseLine onClick={() => state.removeElement(id, dropzoneID, parentID)} color='white' /></span>
          </div>
        <header >
          
          {toggleEdit === 'heading' ? <input className="transparent-input" type="text" value={heading} onChange={(value) => this.changePayload('heading',value.target.value)}/> : <h2 onClick={() => {this.toggleEditField('heading')}} className="pb_ele_header_heading">{heading}</h2>
          }
          
          
          {toggleEdit === 'description' ? <textarea className="transparent-description" rows={5} cols={70} value={description} onChange={(value) => this.changePayload('description',value.target.value)} /> : <p onClick={() => {this.toggleEditField('description')}} className="text-faded pb_ele_header_description">{description}</p>}
        </header>
      </Draggable>
    )
  }
};

export default ModuleHeader;
