import React from "react";
import { GameProfileList } from "@common";
import GameProfileListCoach from "../../../../_common/GameProfileList/GameProfileListCoach";
import useTranslated from "../../../../../helpers/translationHelper";

const GameProfiles = ({ players = [], coaches = [] }) => {
  const labelNotCreatedProfile=useTranslated("id_user_page_(game_profiles)_1")
  const lableNotCreatedCouches=useTranslated("id_user_page_(game_profiles)_3")
  return (
    <div>
      <div className="mt-4 mb-4 uppercase bold"> {useTranslated("id_user_page_(game_profiles)_0")/* Players */}</div>

      <div className="row player-overview-row">
        <div className="col-lg-12 row-content">
          {players.length ? (
            players.map((player) => <GameProfileList gameName={player.game.name} gameIcon={player.game.whiteIcon} players={[player]} />)
          ) : (
            <div className="league-no-matches">
              <p>{labelNotCreatedProfile/* This user hasn't created any player profiles yet */}</p>
            </div>
          )}
        </div>
      </div>

      <div className="mt-4 mb-4 uppercase bold"> {useTranslated("id_user_page_(game_profiles)_2")/* Coaches */}</div>

      <div className="row player-overview-row">
        <div className="col-lg-12 row-content">
          {coaches.length ? (
            coaches.map((coach) => (
              //<ProfileListItem {...coach} type="coach" />

              <GameProfileListCoach gameName={coach.game.name} gameIcon={coach.game.whiteIcon} coaches={[coach]} />
            ))
          ) : (
            <div className="league-no-matches">
              <p>{lableNotCreatedCouches/* This user hasn't created any coach profiles yet. */}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameProfiles;
