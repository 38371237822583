import React, { createContext, useContext, useState } from "react";
import Modal from "react-modal";
import LoginRegister from "./LoginRegister/LoginRegister";
import { AppContext } from "../../../context/AppContext";
import AcceptTerms from "./AcceptTerms/AcceptTerms";
import VerifyEmail from "./VerifyEmail/VerifyEmail";
import { RiCloseLine } from "react-icons/ri";
import { useEffect } from "react";
import useTranslated from "../../../helpers/translationHelper";

const AuthModalContext = createContext();

const AuthModal = () => {
  const { showAuth, setShowAuth } = useContext(AppContext);
  const [step, setStep] = useState(0);
  const [registrationData, setRegistrationData] = useState({});


  useEffect(() => {
    // RESET everything when modal changes visibility
    setStep(0);
    setRegistrationData({});
  }, [showAuth]);

  const closeModal = () => setShowAuth(false);

  const lableStep2=useTranslated("id_pop-up_-_log_in_(sign_up)_16")/* step ({step + 1})/4 */
  const lableStep3=useTranslated("id_pop-up_-_log_in_(sign_up)_31")/* step ({step + 1})/4 */

  const PrintingSteps = (key) => {
    switch (key+1) {
      
      case 2:
        return <span className="text-12 uppercase bold color-primary">{lableStep2/* step ({step + 1})/4 */}</span>
        break;
      case 3:
        return <span className="text-12 uppercase bold color-primary">{lableStep3/* step ({step + 1})/4 */}</span>
        break;
      default:
        break;
    }
  };

  const steps = [<LoginRegister />, <AcceptTerms />, <VerifyEmail />];

  return (
    <Modal isOpen={!!showAuth} onRequestClose={closeModal} className="modal auth-modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={closeModal}>
        <RiCloseLine />
      </div>
      {step > 0 ? (
        <div className="modal-step-progress">
          <span className="text-12 uppercase bold color-primary">{PrintingSteps(step)}</span>
        </div>
      ) : null}

      <AuthModalContext.Provider value={{ setStep, registrationData, setRegistrationData }}>
        {steps.map((component, i) => i === step && <div key={i}>{component}</div>)}
      </AuthModalContext.Provider>
    </Modal>
  );
};

export { AuthModalContext, AuthModal };
