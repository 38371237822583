import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  formatData,
  connectSocket,
  disconnectSocket,
} from "../../../../MultiStageLeague/MultiStageLeaguesSingle/MultiStageLeaguesSingleTabs/Brackets/BracketInterceptor";
import tmpData from "../../../../Tournaments/TournamentsSingle/TournamentsSingleTabs/Helpers/tmp.json";
import { Loading, Checkbox } from "@common";
import BracketsMap from "./BracketsMap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MdZoomOutMap } from "react-icons/md";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import { AiOutlineZoomOut, AiOutlineZoomIn, AiOutlineFullscreen } from "react-icons/ai";
import Modal from "react-modal";
import MatchModal from "../../../../Tournaments/TournamentsSingle/MatchModal";
import { SimpleTabs } from "@common";
import MultiStageDropdown from "../../../MultiStageDropdown";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

const Brackets = ({ id, data }) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const modalFullScreen = query.get("open_fullscreen");
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [bracketsData, setBracketsData] = useState([]);
  const [selectedWinner, setSelectedWinner] = useState(BRACKETS.WINNING);
  const [selectedLoser, setSelectedLoser] = useState(BRACKETS.LOSING);
  const [hilighted, setHilighted] = useState("");
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [selctedMatchId, setSelctedMatchId] = useState(null);
  const [stages, setStages] = useState([]);
  const [stageGroups, setStageGroups] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [shorts, setShorts] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [formattedGroup, setFormattedGroup] = useState();

  const [page, setPage] = useState(0);
  const [pages, setAllPages] = useState([]);

  useEffect(() => {
    if (modalFullScreen === "true") {
      enterFullScreen();
    }
    // if (data === undefined) {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/bracketData`)
      .then(({ data }) => {
        populateBrackets(data.stages, 0, data.stages[0].groups[0]);
        //  setStages(data.stages);
        // setSelectedGroup(data.stages[0].groups[0]);
        // let formattedGroups = fetchGroups(data.stages);

        // setBracketsData(filteredBracketData(data.stages, data.stages[0]._id, data.stages[0].groups[0]._id, formattedGroups));
        // connectSocket(id, (_data) => setBracketsData(filteredBracketData(_data, data.stages[0].groups[0]._id)));
      })
      .finally(() => setLoading(false));
    // } else {
    //   populateBrackets(data, 0, data[0]?.groups[0]);
    // }
    return () => disconnectSocket();
  }, []);

  const fetchGroups = (stages) => {
    let stageGroups = [];
    let _all = [];
    let _allGroups = [];
    stages.map((stage, sIndex) => {
      let _gr = [];
      let shorts = false;
      stage.groups.map((group, gIndex) => {
        if (_gr.filter((x) => x.group._id === group._id).length <= 0) {
          if (stage.tournament !== undefined) {
            if (stage.tournament.multiStageDuels !== undefined) {
              let _fil = Object.entries(stage.tournament.multiStageDuels).filter(([key, value]) => key === group._id);
              shorts = _fil[0][1].options.short;
            }
            _gr.push({ group, shorts: shorts });
          }
        }
        let _sIndex = stageGroups.findIndex((x) => x.stageId === stage._id);
        if (_sIndex < 0) {
          stageGroups.push({ stageId: stage._id, groups: _gr });
        } else {
          stageGroups[_sIndex].groups = { ...stageGroups[_sIndex].groups, group, shorts: shorts };
        }

        if (_all.filter((y) => y._id === group._id).length <= 0) {
          _all.push({ stageId: stage._id, group, short: shorts });
          _allGroups.push({ stageId: stage._id, group });
        }
      });
    });
    setStageGroups(stageGroups);
    setAllGroups(_allGroups);
    setFormattedGroup(_all);
    return _all;
  };
  const filteredBracketData = (data, stageId, groupId, stageGroups) => {
    let brackData = [[], []];
    const formatedData = formatData(data, stageId, groupId, stageGroups);
    brackData[0] = formatedData[0]
      .filter((round) => round.some((seed) => seed?.matchData))
      .map((el, index) => ({ title: "Round " + (index + 1), seeds: el }));
    brackData[1] = formatedData[1]
      .filter((round) => round.some((seed) => seed?.matchData))
      .map((el, index) => ({ title: "Round " + (index + 1), seeds: el }));
    return brackData;
  };

  const enterFullScreen = () => {
    setModal(!modal);
  };

  const openFullScreenInTab = () => {
    if (!modal) {
      const urlLink = `/single-competition/${id}/brackets?open_fullscreen=true`;
      const win = window.open(urlLink, "_blank");
      win.focus();
    } else {
      enterFullScreen();
    }
  };

  const handleMatchClick = (matchData) => {
    setSelctedMatchId(matchData._id);
    setShowMatchModal(true);
    console.log("match dATA-->", matchData);
  };

  const renderChildContent = (isFullScreen) => (
    <div
      className="w-100 h-100 brackets-wrapper"
      style={{ overflow: `${modal ? "visible" : "hidden"}`, border: isFullScreen ? undefined : "1px solid #22262e", borderRadius: "5px" }}
    >
      <div className="w-100 row align-items-center justify-content-end p-2 m-0">
        {bracketsData[1] && bracketsData[1].length !== 0 ? (
          <>
            <span className="bold text-grey uppercase font-13">Show:</span>
            <Checkbox
              onChange={() => selectedLoser !== false && setSelectedWinner(!selectedWinner)}
              checked={selectedWinner}
              label="Winners' bracket"
              className="check-type1"
            />
            <Checkbox
              onChange={() => selectedWinner !== false && setSelectedLoser(!selectedLoser)}
              checked={selectedLoser}
              label="Losers' bracket"
              className="check-type1"
            />
          </>
        ) : null}
      </div>
      <div className={`p-0 m-0 w-100 position-relative ${!modal ? "h-100" : ""}`}>
        <TransformWrapper
          wheel={{
            wheelEnabled: false,
            step: 20,
          }}
          zoomIn={{
            step: 5,
          }}
          zoomOut={{
            step: 5,
          }}
          options={{
            limitToBounds: false,
            minScale: 0.25,
          }}
          pan={{
            velocityEqualToMove: true,
            velocity: true,
            lockAxisY: true,
          }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => {
            return (
              <>
                <TransformComponent>
                  {selectedWinner && (
                    <BracketsMap
                      selectedLoser={selectedLoser}
                      hilighted={hilighted}
                      setHilighted={setHilighted}
                      data={bracketsData[0]}
                      handleMatchClick={handleMatchClick}
                      id={id}
                    />
                  )}
                  {selectedLoser && (
                    <BracketsMap
                      isShort={shorts}
                      hilighted={hilighted}
                      setHilighted={setHilighted}
                      losingTrack={true}
                      upperBracketData={bracketsData[0]}
                      data={bracketsData[1]}
                      handleMatchClick={handleMatchClick}
                      id={id}
                    />
                  )}
                </TransformComponent>
                <div className="zoom-control-buttons">
                  <button onClick={openFullScreenInTab} className="zoom-control-button">
                    {modal ? <BsFullscreenExit color="#FFFFFF" /> : <BsFullscreen color="#FFFFFF" />}
                  </button>
                  <button onClick={resetTransform} className="zoom-control-button">
                    <MdZoomOutMap color="#FFFFFF" />
                  </button>
                  <button onClick={zoomIn} className="zoom-control-button">
                    <AiOutlineZoomIn color="#FFFFFF" />
                  </button>
                  <button onClick={zoomOut} className="zoom-control-button">
                    <AiOutlineZoomOut color="#FFFFFF" />
                  </button>
                </div>
              </>
            );
          }}
        </TransformWrapper>
      </div>
    </div>
  );

  const onSeletedChange = (data) => {
    //data = [{groupId: "", status : true/false},{{groupId: "", status : true/false}}]
    // setLoading(true);
    let _id = data.filter((x) => x.status === true).map((x) => x.groupId)[0];
    if (_id !== undefined) {
      let _selec = allGroups.filter((x) => x.group._id === _id && x.stageId === stages[page]._id);
      populateBrackets(stages, page, _selec[0].group);
    } else {
      // setLoading(false);
      setSelectedGroup();
      setBracketsData([]);
    }
  };

  const populateBrackets = (stages, stageIndex, group) => {
    setStages(stages);
    setSelectedGroup(group);
    if (
      stages.length > 0 &&
      stages[stageIndex].groups !== undefined &&
      stages[stageIndex].groups !== null &&
      stages[stageIndex].groups.length > 0
    ) {
      let _fil = Object.entries(stages[stageIndex]?.tournament?.multiStageDuels).filter(([key, value]) => key === group?._id);
      if (_fil.length > 0) {
        setShorts(_fil[0][1]?.options?.short);
        let formattedGroups = fetchGroups(stages);
        setBracketsData(filteredBracketData(stages, stages[stageIndex]?._id, group?._id, formattedGroups));
        connectSocket(id, (_data) => setBracketsData(filteredBracketData(_data, group?._id)));
      } else {
        setSelectedGroup();
        setBracketsData([]);
      }
    } else {
      setSelectedGroup();
      setBracketsData([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (stages.length > 0 && pages.length <= 0) {
      let _pages = [];
      _pages.push(...stages.map((item) => item.name));
      setAllPages(_pages);
    }
  }, [stages]);

  useEffect(() => {
    if (stages.length > 0 && pages.length > 0) {
      // setLoading(true);
      if (selectedGroup === undefined) {
        setSelectedGroup(stages[page].groups[0]);
      }
      populateBrackets(stages, page, stages[page].groups[0]);
    }
  }, [page]);

  return loading ? (
    <>
      <div className="text-center league-no-matches">
        <Loading width={50} height={50} />
      </div>
    </>
  ) : (
    <>
      {stages && stages.length > 0 ? (
        <Wrapper>
          <Row>
            <Col className="col-10">{pages && pages.length > 1 ? <SimpleTabs pages={pages} page={page} setPage={setPage} /> : null}</Col>
            <Col className="col-2">
              {allGroups.filter((x) => x.stageId === stages[page]._id).length > 0 && (
                <MultiStageDropdown
                  groups={allGroups.filter((x) => x.stageId === stages[page]._id)}
                  onSeletedChange={onSeletedChange}
                  isMultiSelect={false}
                  setDefault={true}
                  fromBrackets={true}
                />
              )}
            </Col>
          </Row>
          <div className="align-items-center m10" style={{ display: "flex", flexDirection: "row" }}>
            {selectedGroup !== undefined && (
              <div
                className="par-num align-items-centerd"
                style={{
                  borderRadius: 20,
                  background: "rgba(25,219,96,0.1)",
                  paddingLeft: 7,
                  paddingRight: 7,
                  paddingTop: 4,
                  paddingBottom: 4,
                  justifyContent: "center",
                  flexDirection: "row",
                  display: "flex",
                  marginRight: 10,
                }}
              >
                <span
                  key={selectedGroup._id}
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: selectedGroup.color ? selectedGroup.color : false,
                    paddingLeft: 5,
                    alignItems: "center",
                  }}
                >
                  {selectedGroup.name ? selectedGroup.name : false}
                </span>
              </div>
            )}
          </div>
          <div className="row justify-content-between m10"></div>
          <div className="brackets-class">
            {loading && !bracketsData.length !== 0 ? (
              <div className="w-100" style={{ textAlign: "center" }}>
                <Loading />
              </div>
            ) : bracketsData.length === 0 ? (
              <div className="col-12 p-5 text-grey text-center">Nothing to show here!</div>
            ) : (
              renderChildContent()
            )}
            {/* below Model is full screen bracket */}
            {loading && !bracketsData.length !== 0 ? null : (
              <Modal
                className="brackets-class bg-theme p-0 m-0"
                style={{ content: { width: "100%", height: "100%", overflowY: "scroll" } }}
                isOpen={modal}
                shouldCloseOnEsc
                onRequestClose={() => setModal(false)}
              >
                {renderChildContent(true)}
              </Modal>
            )}
          </div>
          {/* below model opens when click on team */}
          <MatchModal showModal={showMatchModal} closeModal={() => setShowMatchModal(false)} id={id} selctedMatchId={selctedMatchId} />
        </Wrapper>
      ) : (
        <div className="col-12 p-5 text-grey text-center">Nothing to show here!</div>
      )}
    </>
  );
};

export default Brackets;

const BRACKETS = {
  WINNING: true,
  LOSING: true,
  BOTH: "BOTH",
};

const Wrapper = styled.div`
  .m10 {
    margin: 10px 0px 10px 0px !important;
  }
`;
