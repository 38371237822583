import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { CreateTeamModalContext } from "../CreateTeamModal";
import { toast } from "react-toastify";
import useTranslated from "../../../../helpers/translationHelper";

const AddTeamSocials = () => {
  const history = useHistory();
  const { teamData, setTeamData, onClose } = useContext(CreateTeamModalContext);
  const { register, handleSubmit, errors } = useForm();

  const lableSaveFinish=useTranslated("id_pop-up_-_create_team_34")

  const onSubmit = async (data) => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/${teamData._id}/socials`, { ...data });

      // Reset modal & close
      history.push(`/teams/${teamData._id}`);
      setTeamData({});
      onClose();
    } catch (err) {
      toast.error("There was a problem updating your team socials");
    }
  };

  // Social fields are dynamically rendered from this array! The value must match the regex in order to be valid
  const socialFields = [
    { name: "Twitch", placeholder: "Twitch.tv/channel", regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitch.tv)\/([A-Za-z0-9-_]+)/y },
    { name: "Facebook", placeholder: "Facebook.com/name", regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com)\/([A-Za-z0-9-_]+)/y },
    { name: "Twitter", placeholder: "Twitter.com/handle", regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/([A-Za-z0-9-_]+)/y },
    {
      name: "Instagram",
      placeholder: "Instagram.com/handle",
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/y,
    },
    {
      name: "YouTube",
      placeholder: "Youtube.com/channel",
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:youtube.com|youtu.be)\/([A-Za-z0-9-_]+)/y,
    },
  ];

  return (
    <div>
      <div className="modal-header">
        <h2>{useTranslated("id_pop-up_-_create_team_32")/* Add social media for your team */}</h2>
        <h3>{useTranslated("id_pop-up_-_create_team_33")/* Let people know what your team is up to, by adding social media to your page. */}</h3>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Social links */}
          {socialFields.map(({ name, regex, placeholder, invalidMsg }) => (
            <div className="form-row">
              <label htmlFor={name.toLowerCase()}>{name}</label>
              <input
                ref={register({
                  validate: (value) => (!value.match(regex) && value !== "" ? invalidMsg || `Invalid ${name} URL` : true),
                })}
                type="text"
                id={name.toLowerCase()}
                name={name.toLowerCase()}
                autoComplete="off"
                placeholder={placeholder}
                className={`form-input ${errors[name.toLowerCase()] ? "invalid" : ""}`}
                defaultValue={teamData[name.toLowerCase()]}
              />
              {errors[name.toLowerCase()] && <div className="form-input-error">{errors[name.toLowerCase()].message}</div>}
            </div>
          ))}

          <button className="button thicc">{lableSaveFinish/* Save & Finish */}</button>
        </form>
      </div>
    </div>
  );
};

export default AddTeamSocials;
