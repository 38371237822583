import React, { useState, useEffect, useContext } from "react";
import HomeHero from "./HomeHero/HomeHero";
import Slider from "./Slider/Slider";
import { Button, SliderItem, Icon, Advert, AdvertByGame, Loader } from "@common";
import { AppContext } from "../../context/AppContext";
import Axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import SelectedTournaments from "./ActiveTournaments/SelectedTournaments";
import LeaguesSeasons from "./HomeSegments/LeaguesSeasons";
import LeaguesManual from "./HomeSegments/LeaguesManual";
import TournamentsSeasons from "./HomeSegments/TournamentsSeasons";
import DotPattern from "./HomeSegments/DotPattern";
import News from "./HomeSegments/News";
import Header from "./HomeSegments/Header";
import Subscription from "./HomeSegments/Subscription";
import GameSelection from "./HomeSegments/GameSelection";
import UpcomingMatchesManual from "./HomeSegments/UpcomingMatchesManual";
import UpcomingMatchesSeason from "./HomeSegments/UpcomingMatchesSeason";
import { ScrollThing, ScrollThingPlay, ScrollThingCross } from "./HomeSegments/ScrollThing";

const Home = ({ userType = null, segmentData = {} }) => {
  const history = useHistory();
  const { shortName } = useParams();
  const { selectedGame, setSelectedGame } = useContext(AppContext);

  const [segmentArray, setSegmentArray] = useState([]);
  const [TournamentsSG, setTournamentsSG] = useState(null);
  const [Upcoming_Matches_ManualSG, setUpcoming_Matches_ManualSG] = useState(null);
  const [Upcoming_Matches_SeasonSG, setUpcoming_Matches_SeasonSG] = useState(null);
  const [NewsSG, setNewsSG] = useState(null);
  const [HeaderSG, setHeaderSG] = useState(null);
  const [SubscriptionSG, setSubscriptionSG] = useState(null);
  const [AdvertSG, setAdvertSG] = useState(null);
  const [Leagues_SeasonSG, setLeagues_SeasonSG] = useState(null);
  const [Leagues_ManualSG, setLeagues_ManualSG] = useState(null);

  useEffect(() => {
    /**
     * Tournaments
     * Upcoming_Matches_Manual
     * Upcoming_Matches_Season
     * News
     * Header
     * Subscription
     * Advert
     * Leagues_Season
     * Leagues_Manual
     */
    if (segmentData.length) {
      setSegmentArray(segmentData);
    }
  }, [segmentData]);

  return (
    <>
      <ScrollThing iconStyle={{ top: "-1200px" }} />
      <ScrollThingPlay iconStyle={{ top: "-900px" }} />
      <ScrollThingCross iconStyle={{ top: "-500px" }} />
      <div className="home-hero-container">
        <DotPattern />
        <HomeHero />
      </div>

      {segmentArray.length > 0 &&
        segmentArray.map(({ segmentType, segmentData = {} }) => {
          if (segmentData) {
            switch (segmentType) {
              case "Tournaments":
                return (
                  <div className="container-fluid no-right-padding">
                    <SelectedTournaments data={segmentData} />
                  </div>
                );
                break;
              case "Tournaments_Season":
                return (
                  <div className="container-fluid no-right-padding">
                    <TournamentsSeasons data={segmentData} />
                  </div>
                );
                break;
              case "News":
                return <News data={segmentData} />;
                break;

              case "Header":
                return <Header data={segmentData} />;
                break;

              case "Subscription":
                return <Subscription />;
                break;

              case "Advert":
                return (
                  <div className="container-fluid" style={{ paddingTop: "36px", paddingBottom: "36px", overflow: "hidden" }}>
                    <AdvertByGame
                      advertForGame={segmentData.game}
                      width={1660}
                      height={168}
                      refName="HOMEPAGE_1660_168_WIDE"
                      style={{ margin: "0 auto" }}
                    />
                  </div>
                );
                break;

              case "Leagues_Season":
                return (
                  <div className="container-fluid no-right-padding">
                    <LeaguesSeasons data={segmentData} />
                  </div>
                );
                break;

              case "Leagues_Manual":
                return (
                  <div className="container-fluid no-right-padding">
                    <LeaguesManual data={segmentData} />
                  </div>
                );
                break;

              case "Game_Selection":
                return (
                  <div className="container-fluid px-0">
                    <GameSelection data={segmentData} />
                  </div>
                );
                // console.log('Goes here-',segmentType,'-',segmentData);// setLeagues_ManualSG(segmentData);
                break;

              case "Upcoming_Matches_Manual":
                return <UpcomingMatchesManual data={segmentData} />;
                // console.log('Goes here-',segmentType,'-',segmentData);
                break;

              case "Upcoming_Matches_Season":
                return <UpcomingMatchesSeason data={segmentData} />;
                // console.log('Goes here-',segmentType,'-',segmentData);
                break;

              default:
            }
          }
        })}
    </>
  );
};

export default Home;
