import Axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
const Advert = ({ refName = "", width = 0, height = 0, style }) => {
  const [ad, setAd] = useState(null);
  const { selectedGame } = useContext(AppContext);
  const [advertGameId,setAdvertGameId] = useState(selectedGame ? selectedGame._id : null);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/adverts/${refName}/randPromo/${advertGameId}`).then(({ data }) => {
      setAd(data);
    });
  }, [refName, selectedGame]);

  return (
    ad && (
      <a
        href={`${process.env.REACT_APP_CORE_API}/api/adverts/${refName}/promos/${ad._id}/click`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "block",
          textIndent: -9999,
          width,
          height,
          backgroundImage: `url(${ad.img})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          // background: `none`,
          backgroundRepeat:`no-repeat`,

          ...style,
        }}
      >
        Promo
      </a>
    )
  );
};

export default Advert;
