import React from "react";
import { FiX } from "react-icons/fi";

const Tag = ({ img, name, logoCropped, onClick }) => {
  return (
    <div className="container-tag">
      <div className={logoCropped || logoCropped === undefined ? "search-result-img" : "search-result-tag-img-notCropped"} style={{ backgroundImage: `url(${img})` }} />
      <span className="text-12 bold uppercase color-white">{name}</span>
      <FiX className="close" onClick={onClick} />
    </div>
  );
};

export default Tag;
