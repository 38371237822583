import React, { useEffect, useState, useContext } from 'react';
import { Loading, DynamicSearch } from '@common';
import Axios from 'axios';
import { FaTimes } from 'react-icons/fa'
import player from "@img/penta.svg";
import { joinAsTeam } from './apis';
import { toast } from 'react-toastify';
import _ from 'lodash'
import { AppContext } from "@context/AppContext";

const JoinAsTeam = ({ hideModal, id, existingTeams, subsAllowed, refreshPage,teamSize }) => {
    const { selectedGame } = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState();
    const [selectedMembers, setSelectedMembers] = useState();
    const [allRolesAssigned,setAllRolesAssigned] = useState(false);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        if (selectedTeam) {
            setSelectedMembers([...selectedTeam.members.filter(el => el.player)]);
        }
        else {
            setSelectedMembers(undefined);
        };

    }, [selectedTeam]);
    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/my`).then(({ data }) => {
            setTeams(data.filter(team => existingTeams.findIndex(_team => _team?.team?._id === team?._id) === -1));
            setLoading(false);
        });
        const playerTypes = selectedGame.configuration.playerTypes.map(type => type.name);
        for (let i = 0; i < subsAllowed; i++) playerTypes.push("SUB " + (i + 1));
        setRoles([...playerTypes]);
    }, []);

    useEffect(()=>{
        if(selectedMembers){
            checkAllRolesAssigned(selectedMembers)
        }
    },[selectedMembers]);

    const removePlayer = index => {
        selectedMembers.splice(index, 1);
        setSelectedMembers([...selectedMembers]);
    }

    const checkAllRolesAssigned = (selectedMembers)=>{
        const memberRoles = selectedMembers.map(member=>member.role);
        const commonRoles = _.intersection(memberRoles, roles);  // it will create array of all common roles
       
         if(teamSize===5 && commonRoles.length===5){
             setAllRolesAssigned(true);
         }
         else if(teamSize===3 && commonRoles.length>=3){
             setAllRolesAssigned(true);
         }
         else if (teamSize===1 && commonRoles.length>=1){
            setAllRolesAssigned(true);
         }
         else{
            setAllRolesAssigned(false);
         }
    }

    const handleUpdateRole = (memberIndex,e)=>{
    
        const currentValue = e.target.value;
        // find index of any member already has selected value
       const memberAlreadyIndex = selectedMembers.findIndex((member)=>member.role === currentValue);
       // if no member already has selected value
       if(memberAlreadyIndex===-1 || selectedGame.configuration.playerRoleReassign){
        selectedMembers[memberIndex].role = currentValue;
        setSelectedMembers([...selectedMembers]);
       }
       else{ // if any member already have selected value
        // members prior valid value
        const membersPriorVal = roles.findIndex((role)=>role===selectedMembers[memberIndex].role)===-1 ? "Select role":selectedMembers[memberIndex].role;
        // swap the values
        selectedMembers[memberAlreadyIndex].role = membersPriorVal;
        selectedMembers[memberIndex].role = currentValue;
        setSelectedMembers([...selectedMembers]);
       }
    }

    const joinTournament = () => {
        // if (selectedMembers.findIndex(member => roles.findIndex(_role => _role === member.role) === -1) !== -1) {
        //     toast.error("Please assign roles to all members");
        //     return;
        // }
        const teamArgs = {
            team: selectedTeam._id,
            members: selectedMembers.filter(member => !member.role.includes("SUB ")).map((member) => {
                let user;
                if (member.user) user = member.user;
                if (member.player) user = member.player;
                if (member.coach) user = member.coach;
                return {
                    role: member.role,
                    player_id: user._id
                }
            }),
            subPlayers: selectedMembers.filter(member => member.role.includes("SUB ")).map((member) => {
                let user;
                if (member.user) user = member.user;
                if (member.player) user = member.player;
                if (member.coach) user = member.coach;
                return {
                    role: member.role,
                    player_id: user._id
                }
            })
        };
        setLoadingBtn(true);
        joinAsTeam(
            id,
            teamArgs,
            () => {
                setLoadingBtn(false);
                hideModal();
                refreshPage();
                toast.success("Joining tournament successfull")
            },
            (err) => {
                setLoadingBtn(false);
                toast.error(err ?? "You can not join this tournament because you are already a part of it");
            });
    }
    if (loading) return (
        <div className="col-12 p-5 text-center">
            <Loading />
        </div>
    );

    return (
        <div className="col-12 p-4 m-0 tournament-join-modal modal-body-contents">
            <p className="bold">
                CHOOSE TEAM
            </p>
            <p className="text-12 text-grey">
                Choose the team you want to participate with in the tournament.
            </p>
            <div className="form-row">
                <select onChange={e => setSelectedTeam(teams[e.target.value])} className="form-input">
                    <option selected value="" disabled>Select team</option>
                    {teams.map((el, i) => (
                        <option value={i} key={i}>
                            {el.name}
                        </option>
                    ))}
                </select>
            </div>
            {selectedTeam ?
                <>
                    <p className="bold mb-0">
                        ASSIGN PLAYER ROLES
                    </p>
                    {/* <DynamicSearch
                        placeholder="Search for members..."
                    // onSearch={onSearch}
                    // onSelectResult={onClickSearchResult}
                    // loadingResults={loading}
                    // results={results}
                    // mixedResults={true}
                    /> */}
                    {selectedMembers ?
                        selectedMembers.map((member, i) => {
                            let user;
                            if (member.user) user = member.user;
                            if (member.player) user = member.player;
                            if (member.coach) user = member.coach;
                            return (
                                <div key={i} className="row p-0 m-0 pt-2 align-items-center justify-content-between">
                                    <img className="team-image mr-2" src={user?.avatarImage ?? player} />
                                    <div style={{ flex: 1 }}>
                                        <p className="p-0 text-12 m-0 bold">{user?.name ?? "Player"}</p>
                                        <p className="pt-1 text-12 m-0 text-faded">
                                            {member.player
                                            ? `${user.user.firstName}${user.user.nickname ? ` "${user.user.nickname}" ` : " "}${
                                                user.user.lastName
                                            }`
                                            : member.coach
                                            ? `${user.user.firstName}${user.user.nickname ? ` "${user.user.nickname}" ` : " "}${user.user.lastName}`
                                            : ""
                                }
                                            </p>
                                    </div>
                                    <div className="col-4 p-0 m-0 mr-2 bordered-all">
                                        <div className="custom-select">
                                            <select
                                                onChange={(e) => { handleUpdateRole(i,e)}} className={`${selectedMembers[i]["role"] === "Select role" ? "select no_role":" select with_role"}`}>
                                                <option >Select role</option>
                                                {roles.map((el, j) => (
                                                    <option  value={el} key={j} selected={selectedMembers[i]["role"]===el}>
                                                        {el}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <FaTimes onClick={() => removePlayer(i)} className="remove-icon" />
                                </div>
                            )
                        })
                        : null}
                </>
                : null}
            <div className="col-12 text-center p-3 text-12">
                <strong>Important</strong>: You can always edit your role setup until the signup deadline for the tournament.
            </div>
            <div className="col-12 p-0 m-0 pt-3">
                <button onClick={joinTournament} className="button primary col-12 disabled-opacity" 
                // disabled={loadingBtn || !selectedTeam || !allRolesAssigned} // Allowing multiple roles
                disabled={loadingBtn || !selectedTeam}
                >
                    {loadingBtn ? "Logging you in" :
                        "JOIN TOURNAMENT"
                    }
                </button>
            </div>
        </div>
    );
}

export default JoinAsTeam;

// const _roles = [
//     "TOP",
//     "JUNGLE",
//     "MID",
//     "BOT",
//     "SUPPORT"
// ]