import React, { useEffect, useState, useRef } from "react";
import { Loading } from "@common";
import Axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import "./lineUp.scss";
import useTranslated from "../../../../helpers/translationHelper";

const SetPlayers = ({
  _id,
  league,
  tournament,
  lolData = {},
  csgoData = {},
  datetime,
  match,
  setPlayersDeadline,
  teamData,
  teamSelector,
  isSetPlayersUrgent,
  updateMatch,
}) => {
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(moment.duration(moment(datetime).diff(moment(), "milliseconds")));
  const [gameData, setGameData] = useState(
    (!!league
      ? league.game?.shortName == "CSGO"
        ? csgoData
        : lolData
      : !!tournament
      ? tournament.game?.shortName == "CSGO"
        ? csgoData
        : lolData
      : {}) || {}
  );
  const [game, setGame] = useState((!!league ? league.game : !!tournament ? tournament.game : {}) || {});
 
  let _set_players_for_matches_display_translation = useTranslated("id_match_display_17")

  let intervalRef = useRef(null);

  useEffect(() => {
    if (moment(datetime).diff(moment(), "days") < 1) {
      intervalRef.current = setInterval(() => {
        setCountdown(moment.duration(moment(datetime).subtract(setPlayersDeadline, "minutes").diff(moment(), "milliseconds")));
      }, 1000);
    }
  }, [datetime]);

  useEffect(() => {
    if (countdown._milliseconds <= 0) clearInterval(intervalRef.current);
  }, [countdown]);

  const pad2 = (n) => {
    return n < 10 ? "0" + n : n;
  };

  const findPlayerWithRole = (roleName) => {
    const member = teamData.members.find(({ role = "" }) => role.includes(roleName));
    const existingSetPlayer = gameData.players && (gameData.players[teamSelector] || []).find(({ role = "" }) => role.includes(roleName));
    if (existingSetPlayer) {
      return existingSetPlayer.player;
    } else if (
      member &&
      member.player &&
      game.shortName == "CSGO" &&
      !((gameData.players && gameData.players[teamSelector]) || []).find(({ player }) => member.player._id == player)
    ) {
      return member.player._id;
    } else {
      return false;
    }
  };
  const [roles, setRoles] = useState(
    game.configuration.playerTypes
      .filter(({ name }) => name.toLowerCase() !== "sub")
      .map(({ icon, name, optional }) => ({
        icon,
        role: name,
        player: findPlayerWithRole(name),
        optional,
      }))
  );

  const [options, setOptions] = useState(
    teamData.members
      .filter(({ role = "" }) => role.includes("Player"))
      .map(({ player }) => player && <option value={player._id}>{player.name}</option>)
  );

  const isAllPlayersSet = () => {
    const requiredRoles = roles.filter(({ optional }) => !optional);

    return game.shortName == "CSGO" || requiredRoles.filter(({ player }) => player).length >= requiredRoles.length;
  };

  const onUpdatePlayer = (roleName, newPlayerId) => {
    let player =
      newPlayerId != "_DEFAULT_"
        ? teamData.members.filter(({ role = "" }) => role.includes("Player")).find(({ player }) => player?._id == newPlayerId).player
        : null;
    setRoles((roles) => roles.map((role) => (role.role === roleName ? { ...role, player: player } : role)));
  };
  const onSetPlayers = async () => {
    if (!isAllPlayersSet()) return false;
    setLoading(true);
    try {
      let api = game.shortName == "CSGO" ? process.env.REACT_APP_CSGO_API : process.env.REACT_APP_LOL_API;
      const { data } = await Axios.post(`${api}/api/matches/${_id}/setPlayers?type=${!!tournament ? "Tournament" : "League"}`, {
        roles,
        teamSelector,
      });
      updateMatch(data.newMatch);
      toast.info("Set players successfully.");
      setLoading(false);
    } catch (e) {
      toast.error("Could not set players for this match");
      setLoading(false);
    }
  };
  return (
    <div className="row pt-3 pb-3">
      <div className="col-12 player-position-form">
        {roles
          .filter(({ role }) => role.toLowerCase() !== "sub")
          .map(({ icon, role, player, optional }, index) => (
            <div className="player-position-option-col">
              <div className="position-info">
                {league?.game?.shortName !== "CSGO" ? (
                  <img src={icon} alt={`Position ${role}`} />
                ) : (
                  <img src={league?.game?.whiteIcon} className="game-logo team-member" alt={league?.game?.name} />
                )}
                <span className="text-14 uppercase bold">{league?.game?.shortName == "CSGO" ? "Player " + index : role}</span>
              </div>
              <div>
                <select className="form-input" value={player?._id || "_DEFAULT_"} onChange={(e) => onUpdatePlayer(role, e.target.value)}>
                  {optional ? (
                    <option value="_NOPLAYER_">No Substitute</option>
                  ) : (
                    <option value="_DEFAULT_" disabled>
                      Select Player
                    </option>
                  )}
                  {game.shortName == "CSGO" && <option value="_DEFAULT_"> Not Set </option>}
                  {teamData.members
                    .filter(({ role = "" }) => role.includes("Player"))
                    .map(({ player }) => player && <option value={player._id}>{player.name}</option>)}
                </select>
              </div>
            </div>
          ))}
      </div>

      <div className="col-12 flex-row pt-4">
        <button className="button primary mr-3" disabled={!isAllPlayersSet() || loading} onClick={onSetPlayers}>
          {loading ? <Loading /> : `${_set_players_for_matches_display_translation}`}
        </button>
        <span className={`mr-2 ` + (isSetPlayersUrgent && " bold color-danger")}>
          {moment(datetime).diff(moment(), "days") < 1
            ? `${pad2(countdown.hours())}:${pad2(countdown.minutes())}:${pad2(countdown.seconds())}`
            : "Deadline " + moment(datetime).fromNow()}
        </span>
        <span className="faded text-12">
          {" "}
          (You can change your setup up to{" "}
          {parseInt(setPlayersDeadline / 60) < 1
            ? ""
            : parseInt(setPlayersDeadline / 60) > 1
            ? parseInt(setPlayersDeadline / 60) + " Hours "
            : parseInt(setPlayersDeadline / 60) + " Hour "}{" "}
          {parseInt(setPlayersDeadline / 60) > 0 && setPlayersDeadline % 60 > 0 ? " and " : ""}
          {setPlayersDeadline % 60 < 1
            ? ""
            : setPlayersDeadline % 60 > 1
            ? (setPlayersDeadline % 60) + " minutes "
            : (setPlayersDeadline % 60) + " minute"}{" "}
          before the match starts)
        </span>
      </div>
    </div>
  );
};

export default SetPlayers;
