import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { CreateCoachModalContext } from "../CreateCoachModal";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const AddCoachSocials = () => {
  const history = useHistory();
  const { coachData, setCoachData, onClose } = useContext(CreateCoachModalContext);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/coaches/${coachData._id}/socials`, { ...data });

      // go to new coach page & close
      history.push(`/coaches/${coachData._id}`);
      setCoachData({});
      onClose();
    } catch (err) {
      toast.error("There was a problem updating socials");
    }
  };

  return (
    <div>
      <div className="modal-header">
        <h2>Add social media for your account</h2>
        <h3>Let people know what you are up to, by adding social media to your account.</h3>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          {["Twitch", "Facebook", "Twitter", "Instagram", "YouTube"].map((socialProfile) => (
            <div className="form-row" key={socialProfile}>
              <label htmlFor={socialProfile}>{socialProfile}</label>
              <input onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register}
                type="url"
                id={socialProfile}
                name={socialProfile.toLowerCase()}
                autoComplete={socialProfile}
                placeholder={`Enter ${socialProfile} profile URL`}
                className={`form-input ${errors[socialProfile.toLowerCase()] ? "invalid" : ""}`}
              />
              {errors[socialProfile.toLowerCase()] && <div className="form-input-error">{errors[socialProfile.toLowerCase()].message}</div>}
            </div>
          ))}

          <button className="button primary thicc">Save & Finish</button>
        </form>
      </div>
    </div>
  );
};

export default AddCoachSocials;
