import React, { useEffect, useState } from "react";
import TeamsItem from "@components/TeamsRouter/TeamsItem/TeamsItem";
import Axios from "axios";
import CreateOrgModal from "../_modals/CreateOrgModal/CreateOrgModal";
import { getGameBackground } from "../../utils/additional-helpers";
import useTranslated from "../../helpers/translationHelper";
const Organisations = () => {
  const [organisations, setOrganisations] = useState([]);
  const [createOrg, setCreateOrg] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/organisations/my`).then(({ data }) => {
      setOrganisations(data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="">
      <div className="row uni-space">
        <div className="col-12">
          <h1>{useTranslated('id_organisations_overview_page_0')}</h1>

          <CreateOrgModal
            open={createOrg}
            onClose={() => setCreateOrg(false)}
            afterCreate={(newOrg) => setOrganisations([...organisations, newOrg])}
          />

          <div className="row smaller-gutter-row">
            <div className="col-12">
              <div className="row smaller-gutter-row">
                {loading && [...Array(12).keys()].map((item) => <TeamsItem key={item} loading classes="col-6" />)}
                {organisations.length ? (
                  organisations.map((org) => (
                    <TeamsItem key={org._id} croppedLogo={org.logoCropped} {...org} classes="col-6" link_to={`/organisation/${org._id}`} />
                  ))
                ) : (
                  <div className="league-no-matches">
                    <p>You’re currently not part of any organisation.</p>
                  </div>
                )}
              </div>

              <button className="button wide-primary" onClick={() => setCreateOrg(true)} style={{ backgroundColor: getGameBackground() }}>
              {useTranslated('id_organisations_overview_page_1')}
              </button>
            </div>
            <div className="col-3">
              <div className="info-placeholder">
                <span>advertisement</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organisations;
