import React from "react";
import { ListItem, Icon } from "@common";

/* this compoenntn is somewhat experimental */
/* Some of the code should be moved to a more general compontnt */
/* And this component should then use composition */
const SortableLeadBoardListItem = ({ value, show_header, sort, setSort, number, team, group, status, lastStage,  groupLength, score, wins, losses, matches, recent_matches, currentBracket,listType,rd=Number(0),leagueFromGame=null }) => {
  /*const header = [
    { title: "#", prop_name: "number", is_sortable: true, alignment: "left" },
    { title: "Team", alignment: "left" },
    { title: "Score", prop_name: "score", is_sortable: true, alignment: "left" },
    { title: "Matches", prop_name: "matches", is_sortable: true, alignment: "left" },
    { title: "Wins", prop_name: "wins", is_sortable: true, alignment: "left" },
    { title: "Losses", prop_name: "losses", is_sortable: true, alignment: "left" },
    { title: "Recent Matches", alignment: "left" },
  ];*/

  const setListSort = (sort_item) => {
    if (sort === sort_item) {
      setSort(false);
    } else {
      setSort(sort_item);
    }
  };

  if (show_header) {
    return (
      <div className="row smaller-gutter-row">
        <div className="col-1">
          <button onClick={() => setListSort("number")}>
            # <Icon name="up-down" />
          </button>
        </div>
        <div className="col-4">Team</div>
        <div className="col-1 right">
          <button onClick={() => setListSort("score")}>
            Score <Icon name="up-down" />
          </button>
        </div>
        <div className="col-2 right">
          <button onClick={() => setListSort("matches")}>
            Matches <Icon name="up-down" />
          </button>
        </div>
        <div className="col-1 right">
          <button onClick={() => setListSort("wins")}>
            Wins <Icon name="up-down" />
          </button>
        </div>
        <div className="col-1 right">
          <button onClick={() => setListSort("losses")}>
            Losses <Icon name="up-down" />
          </button>
        </div>
        <div className="col-2 right">Recent Matches</div>
      </div>
    );
  }

  return (
    <ListItem>
      <div className="row smaller-gutter-row">
        <div className="col-1">{number}</div>
        <div className={currentBracket || group ? "col-2" : "col-2"}>{team}</div>
        {groupLength === 1 ? "" : group && <div className={!lastStage ? "col-1 center" : "col-2 center"}>{group}</div>}
        { status ? <div className="col-1 center">{status}</div> : !lastStage ? <div className="col-1 center"/> : null}
        {listType==="league" && <div className={"col-1 right"}>{score}</div>}
        {currentBracket ? <div className="col-2 center">{currentBracket}</div> : null}
        {leagueFromGame === 'CSGO' && <div className="col-1 right">{rd}</div>}
        <div className={currentBracket || leagueFromGame === 'CSGO' || group ? "col-1 right" : "col-1 right"}>{matches}</div>
        <div className="col-1 right">{wins}</div>
        <div className="col-1 right">{losses}</div>
        <div className="col-2 right">{recent_matches}</div>
      </div>
    </ListItem>
  );
};

export default SortableLeadBoardListItem;
