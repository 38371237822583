import React, { useState, useEffect, useContext, useRef } from "react";
import VetoCard from './VetoCard';
import moment from "moment";
import './_veto.scss'
import { connectSocket, parseVetoData, computeRemainingTime, isTeamPlayer } from "./side-effecrs";
import { AppContext } from "@context/AppContext";
import Axios from "axios";
import VetoSteps from "./VetoSteps/VetoSteps";
import { ToastContainer, toast } from "react-toastify";
import { FaSync } from "react-icons/fa";
import Loading from "@common/Loading/Loading";
import useTranslated from "../../../../helpers/translationHelper";

const Veto = ({ gameData, matchData, setTeamVetoState, maps, updateMatchVeto }) => {
    const { veto, _id } = matchData;
    const { user } = useContext(AppContext);
    const [vetoState, setVetoState] = useState((veto.currentStep > 0 && veto.teamA && veto.teamB) ? "steps" : moment.duration(moment.duration((moment(matchData.datetime).diff(moment(), "milliseconds"))) - (veto.vetoStartTime * 1000)) <= 0 ? "open" : "waiting");
    const [vetoData, setVetoData] = useState(matchData.veto)
    const [loadingTimer, setLoadngTimer] = useState(0)
    const [liveTimer, setLiveTimer] = useState(new Date().toISOString());
    const [canUserSelect, setCanUserSelect] = useState(true)
    const [vetoStarted, setVetoStarted] = useState((veto.currentStep > 0 && veto.teamA && veto.teamB) ? true : false)
    const [mapsArray, setMapsArray] = useState(maps)
    const [vetoEnded, setVetoEnded] = useState(false)
    const toastId = React.useRef(null);
    const [lastStepCompletionTime, setLastStepCompletionTime] = useState(null)
    const [lastSocketEventTime, setLastSocketEventTime] = useState(null)
    const [showLoader, setShowLoader] = useState(true)
    const [errorTr,setErrorTr] = useState([])

    const getStringsArr= (strings)=>{
        let arr =  strings.split(/[!,?,.]/)
         setErrorTr(arr)
       }

    const errorTrans = useTranslated('id_error_message_0')
    if(errorTrans){
        getStringsArr(errorTrans)
    }   
    const toastOptions = {
        autoClose: false,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_CENTER,
        closeButton: (
            <span onClick={() => window.location.reload()}>
                <FaSync />
            </span>
        ),
        closeOnClick: false
    };
    const socketTime = useRef(lastSocketEventTime);
    socketTime.current = lastSocketEventTime;

    useEffect(() => {
        setTimeAndState()
    }, [])
    useEffect(() => {
        setMapsArray(maps)
    }, [maps])

    useEffect(() => {
        if (matchData.datetime && veto && !vetoEnded) {
            if (vetoState == "steps") {
                setVetoStarted(true)
            } else {
                setTimeout(() => {
                    setTimeAndState()
                }, 1000);
            }

        }

    }, [liveTimer, vetoState, vetoEnded]);

    useEffect(() => {
        setTimeout(() => {
            if (!socketTime || !socketTime.current || moment(socketTime.current).diff(moment(lastStepCompletionTime), "seconds") < 0) {
                Axios.get(`${process.env.REACT_APP_CSGO_API}/api/matches/${_id}/veto`).then(({ data }) => {
                    setVetoData(data)
                });
            }
        }, 1000);
    }, [lastStepCompletionTime])

    useEffect(() => {
        if ((matchData.complete || matchData.status == "complete" || ((vetoData.completed) || (vetoData.currentStep > vetoData.steps.length))) && (vetoData.t1ReadyTime && vetoData.t2ReadyTime)) {
            setVetoState("steps")
            setVetoStarted(true)
            setVetoEnded(true)
        } else if ((moment(matchData.datetime).diff(moment(), "seconds") <= 0) && (!vetoData.t1ReadyTime || !vetoData.t2ReadyTime)) {
            setVetoState("steps")
            setVetoStarted(true)
            setVetoEnded(true)
        } else if (matchData.scrim && vetoData.completed) {
            setVetoState("steps")
            setVetoStarted(true)
            setVetoEnded(true)
        } else {
            const data = parseVetoData(matchData, vetoData, user, vetoState, vetoStarted)
            if (data.state && !!data.time) setLiveTimer(data.time)
            if (data.state && data.state != vetoState) setVetoState(data.state)
            if (data.state != 'steps') {
                setTeamVetoState("t1", data.t1State)
                setTeamVetoState("t2", data.t2State)
            }
            setCanUserSelect(data.userVeto)
            if ((vetoState == "picking" || vetoState == "waitforopponent") && moment(liveTimer).diff(moment(), "seconds") <= 0) {
                setVetoStarted(true)
            }
            if (data.state == "open") {
                setVetoStarted(false)
                setVetoState(data.state)
            }
        }
    }, [vetoData])

    useEffect(() => {
        if (vetoStarted) {
            const { steps } = vetoData
            let mapStates = []
            steps.map((step, index) => {
                let action = step.action.toLowerCase()
                if (step.map && (action == "ban" || action == "pick")) mapStates.push({ map: step.map, state: action == "ban" ? "banned" : "picked", order: index })
                if (step.map && action == "side_pick") {
                    mapStates = mapStates.map(mapObj => {
                        if (mapObj.map.id == step.map.id) {
                            mapObj.t = step.t
                            mapObj.ct = step.ct
                        }
                        return mapObj
                    })
                }

            })
            let mapsData = maps.map(mapItem => {
                let item = mapStates.find(mapState => mapState.map.id == mapItem.id)
                mapItem.state = item && item.state ? item.state : null
                mapItem.t = item && item.t ? item.t : null
                mapItem.ct = item && item.ct ? item.ct : null
                mapItem.order = item && item.order ? item.order : steps.length
                return mapItem
            })
            setMapsArray(mapsData)
        }
    }, [vetoStarted, vetoData, maps])



    useEffect(() => {
        setShowLoader(true)
        Axios.get(`${process.env.REACT_APP_CSGO_API}/api/matches/${matchData._id}/veto`)
            .then(({ data }) => {
                if (data) {
                    setVetoData(data)
                    setShowLoader(false)
                }
            })
            .catch((error) => {
                console.log(error)
                setShowLoader(false)
                toast.error("There was a problem retrieving veto data");
            });
        connectSocket(_id, (_data) => {
            setLastSocketEventTime(new Date())
            setVetoData(_data)
            updateMatchVeto(_data)
            if (toast.isActive(toastId.current)) toast.dismiss(toastId.current);
        }, () => {
            // if (!(toast.isActive(toastId.current))) {
            //     toastId.current = toast(<><div>Lost connection to the server. Try <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => window.location.reload()}>refreshing the page</span>.</div></>, toastOptions);
            // }
            if (!(toast.isActive(toastId.current))) {
                toastId.current = toast(<><div> {errorTr[0] ? errorTr[0] : 'Lost connection to the server'}.<span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => window.location.reload()}> {errorTr[1] ? errorTr[1] :'Try refreshing the page'}</span>.</div></>, toastOptions);
            }
        });
    }, [])

    const setTimeAndState = () => {
        const data = computeRemainingTime(matchData, vetoState, vetoData, user)
        if (data.state != vetoState || data.time != liveTimer) {
            setVetoState(data.state)
            setLiveTimer(data.time)
        }
        if (data.state != 'steps') {
            data.t1State && setTeamVetoState('t1', data.t1State)
            data.t2State && setTeamVetoState('t2', data.t2State)
        }

        if (data.state == "admin") setLiveTimer(matchData.datetime)
        if (!vetoStarted && data.state == "steps") {
            setVetoStarted(true)
        }
    }
    const onMapAction = (mapId, action, side) => {
        if (`${user.state._id}` == `${vetoData.steps[(vetoData.currentStep) - 1].selectedBy}`) {
            Axios.post(`${process.env.REACT_APP_CSGO_API}/api/matches/${_id}/veto/selectMap`, (action != "side_pick" ? { map: mapId } : { side: side })).then(({ data }) => {
                // setVetoState("loading")
            });
        }
    }
    const onReady = () => {
        Axios.get(`${process.env.REACT_APP_CSGO_API}/api/matches/${_id}/veto/ready`).then(({ data }) => {

        });
    }
    const pickSide = (team) => {
        Axios.post(`${process.env.REACT_APP_CSGO_API}/api/matches/${_id}/veto/pickSide`, { side: team }).then(({ data }) => {
            // setVetoState("loading")
        });
    }

    return (
        showLoader ?
            <div className="veto-loader"><Loading height={50} width={50}/></div>
            :
            !vetoStarted ?
                <VetoCard vetoState={vetoState} timeLeft={liveTimer} onTimerComplete={setTimeAndState} onReady={onReady} pickSide={pickSide} canUserSelect={canUserSelect} canPerform={matchData.veto.canPerform} />
                :
                <VetoSteps setLastStepCompletionTime={setLastStepCompletionTime} gameData={gameData} vetoEnded={vetoEnded} maps={maps} matchData={matchData} canUserSelect={canUserSelect} vetoData={vetoData} onMapAction={onMapAction} setTeamVetoState={setTeamVetoState} />
    )
}



export default Veto;
