import React from "react";
import TeamsItem from "@components/TeamsRouter/TeamsItem/TeamsItem";

const Teams = ({ teams = [], gameProfileId }) => {
  return (
    <div className="row smaller-gutter-row">
      {!teams.length ? (
        <div className="col-12">Player is not part of any teams</div>
      ) : (
        teams.map((team) => <TeamsItem key={team._id} classes="col-4" link_to={`/teams/${team._id}`} playerRole={team.members.find(({ player }) => player === gameProfileId)?.role} itemForGameProfile={true} {...team} />)
      )}
    </div>
  );
};

export default Teams;
