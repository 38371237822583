import React from "react";

const ChatRoom = ({logo=null,name=null,lastName=null,username=null,role=null,time=null,message=null}) => {
  const removePlayerFromRole = (role) => {
    if (role.includes("Player:")) return role.split(":")[1];

    return role;
  };
  return (
    <div className={`finder-card message-card mt-3 mb-3`}>
      <header style={{ borderBottom: "none", padding: 0 }} className={`noBorder`}>
        <div className="finder-card-info">
          {logo ? (
            <img src={logo} alt={"PP"} className="finder-card-image" />
          ) : (
            <div className="finder-card-image chat-avatar">{name.charAt(0) + (lastName ? lastName.charAt(0) : name.charAt(1))}</div>
          )}
          <div className="finder-card-info-text">
            <div className="finder-card-info-top">
              <span className="bold">{name}</span>
              <span className="pill primary">{role}</span>
            </div>
            <div className="finder-card-info-bottom">
              <span className="faded userNam">{removePlayerFromRole(username)}</span>
            </div>
          </div>
        </div>

        <div className="finder-card-actions">
          <span className="faded">{time}</span>
        </div>
      </header>
      <p className="messageContent" dangerouslySetInnerHTML={{ __html: message }}></p>
    </div>
  );
};

export default ChatRoom;