import React from "react";
import { List, ListItem, Icon } from "@common";
import havoc_logo from "@img/havoc-logo.png";

const Prizes = ({ prizes }) => {
	const ordinalSuffixOf = (i) => {
		let j = i % 10,
		  k = i % 100;
	
		if (j === 1 && k !== 11) return i + "st";
		if (j === 2 && k !== 12) return i + "nd";
		if (j === 3 && k !== 13) return i + "rd";
	
		return i + "th";
	};
	return (
		<div>
			<div className="row smaller-gutter-row player-overview-row pt-2 pb-5">
				<div className="col-4 row-title">
					<span className="text-16 faded bold uppercase">Prizes</span>
				</div>
				<div className="col-8 row-content">
					{prizes.length !== 0 ?
						<>
							<div className="list-header">
								<div className="row smaller-gutter-row flex-list-item">
									<div className="col-2">Place</div>
									<div className="col-7">
										{/* tentative TEAM placements */}
									</div>
									<div className="col-3 right">Prize</div>
								</div>
							</div>


							<List classes="bordered">
								{prizes.map((prize, i) => (
									<ListItem>
										<div key={i} className="row smaller-gutter-row flex-list-item">
											<div className="text-14 bold col-2">
											<Icon
                      name={i === 0 ? "trophy-icon-gold" : i === 1 ? "trophy-icon-silver" : i === 2 ? "trophy-icon-bronze" : "trophy-icon"}
                      without_wrapper
                    />
												<span className="pl-2">{ordinalSuffixOf(i + 1)}</span>
											</div>
											<div className="text-14 bold uppercase col-7">
												{/* <img src={havoc_logo} alt="" className="image-40" /> */}
												{/* {prize} */}
											</div>
											<div className="text-14 bold uppercase col-3 right">{prize}</div>
										</div>
									</ListItem>
								))}
							</List>
						</>
						:
						<div className="col-12 text-center text-grey">
							No information available
						</div>
					}
				</div>
			</div>
		</div>
	);
};

export default Prizes;
