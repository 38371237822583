import React from "react";
import { Item } from "@common";
 
const SliderItem = ({ title, second_line, third_line, tag, ...rest }) => {
  return (
    <Item hide_time_ago={true} classes="slider-item" title={title} above_excerpt={second_line} excerpt={third_line} tag={tag} {...rest} />
  );
};

export default SliderItem;
