import React, { useContext, useState } from "react";
import { AuthModalContext } from "../AuthModal";
import Axios from "axios";
import { toast } from "react-toastify";
import TermsAndConditions from "./TermsAndConditions";
import Loading from "../../../_common/Loading/Loading";
import { Link } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import useTranslated from "../../../../helpers/translationHelper";

const AcceptTerms = () => {
  const { setStep, registrationData } = useContext(AuthModalContext);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [accept, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsLoaded, setTermsLoaded] = useState(false);
  let _accept_translation = useTranslated("id_notifications_5");
  let _accept_terms_conditions_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_17");
  let _ask_read_terms_conditions_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_18");
  let _signup_for_newsletter_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_29");
  let _continue_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_30");



  const finish = async () => {
    setLoading(true);

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/register`, { ...registrationData, newsletter: accept });
      setLoading(false);
      setStep(2);
    } catch (e) {
      const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem signing you up";
      setLoading(false);
      toast.error(msg);
    }
  };

  return (
    <div>
      <div className="modal-header accept-terms">
        <button className="modal-back-button" onClick={() => setStep(0)}>
          <FaAngleLeft />
        </button>
        <h2>{_accept_terms_conditions_translation}</h2>
        <h3>{_ask_read_terms_conditions_translation}</h3>
      </div>
      <div className="modal-body accept-terms">
        {/* terms and conds go in this scrollable div */}
        <div className="auth-terms-container">
          <div className="terms-inner">
            <TermsAndConditions termsLoaded={() => setTermsLoaded(true)} />
          </div>
        </div>

        {termsLoaded && (
          <div className="modal-inner">
            <div className="form-row inline" checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)}>
              <input type="checkbox" className="form-input" id="accept-terms" />
              <label htmlFor="accept-terms">
                I {_accept_translation} the terms and&nbsp;
                <Link to="/p/privacy-policy" target="_blank">
                  privacy policy
                </Link>
              </label>
            </div>

            <div className="form-row inline" checked={accept} onChange={(e) => setAccept(e.target.checked)}>
              <input type="checkbox" className="form-input" id="accept" />
              <label htmlFor="accept">{_signup_for_newsletter_translation}</label>
            </div>

            <button onClick={finish} className="button primary thicc" disabled={!acceptTerms || loading}>
              {loading ? (
                <>
                  <Loading width={30} height={30} /> Creating Account
                </>
              ) : (
                `${_continue_translation}`
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AcceptTerms;
