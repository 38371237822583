import React, { useState, useEffect, useContext } from "react";
import HomeHero from "./HomeHero/HomeHero";
import Slider from "./Slider/Slider";
import { Button, SliderItem, Icon, Advert } from "@common";
import { AppContext } from "../../context/AppContext";
import Axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import ActiveTournaments from "./ActiveTournaments/ActiveTournaments";
import RenderNewsItems from "./HomeSegments/RenderNewsItems";
import RenderFeaturedNewsItem from "./HomeSegments/RenderFeaturedNewsItem";
import DotPattern from "./HomeSegments/DotPattern";

const Home = () => {
  const history = useHistory();
  const { shortName } = useParams();
  const { selectedGame, setSelectedGame } = useContext(AppContext);
  const [loadingSeasons, setLoadingSeasons] = useState(true);
  const [seasons, setSeasons] = useState([]);
  const [posts, setPosts] = useState([]);
  const [featured, setFeatured] = useState(null);

  let context = useContext(AppContext);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      var singleGame = data.filter((game) => game.shortName === shortName);
      if (singleGame.length) {
        setSelectedGame(singleGame[0]);
      }
    });
  }, [shortName]);

  useEffect(() => {
    // featured seasons
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons`, {
      params: { game: selectedGame && selectedGame._id, withLeagues: true, showOnHomepage: true },
    }).then(({ data }) => {
      setSeasons(data);
      setLoadingSeasons(false);
    });

    // Featured blog post
    // context.ghost.posts
    //   .browse({ limit: 1, filter: "featured:true", include: "tags,authors" })
    //   .then((posts) => {
    //     setFeatured(posts);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });

    // sub blog posts
    // context.ghost.posts
    //   .browse({ limit: 3, include: "tags,authors" })
    //   .then((posts) => {
    //     setPosts(posts);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  }, [context.ghost.posts, selectedGame]);

  return (
    <>
      <div className="home-hero-container">
        <DotPattern />
        <HomeHero />
      </div>
      <div className="container-fluid no-right-padding">
        {!loadingSeasons &&
          seasons.map(
            ({ name, leagues, year }) =>
              !!leagues.length && (
                <Slider
                  key={`${name}-slide-${year}`}
                  info={
                    <div>
                      <h1>{name}</h1>
                      <h1 className="outline">{year}</h1>
                      <Button onClick={() => history.push("/leagues")} name="cta-button-transparent">
                        All Leagues
                      </Button>
                    </div>
                  }
                >
                  {[0, 1, 2, 3, 4, 5].map(() => {
                    return leagues.map(({ _id, name, thumbnailImage, leagueDivision, leagueDivisionName, country = {}, teams }) => (
                      <SliderItem
                        key={_id}
                        title={name}
                        second_line={
                          <React.Fragment>
                            <img src={`https://flagcdn.com/w20/${country.code?.toLowerCase()}.png`} alt="Danish flag" />
                            {country.name}
                          </React.Fragment>
                        }
                        third_line={`${teams.length} teams participating`}
                        tag={leagueDivisionName}
                        image={thumbnailImage}
                        link_to={`/league/${_id}`}
                      />
                    ));
                  })}
                </Slider>
              )
          )}
      </div>

      <div className="container-fluid" style={{ paddingTop: "36px", paddingBottom: "36px", overflow: "hidden" }}>
        <Advert width={1660} height={168} refName="HOMEPAGE_1660_168_WIDE" style={{ margin: "0 auto" }} />
      </div>

      <div className="container-fluid no-right-padding">
        <ActiveTournaments />
      </div>

      <div className="container-fluid">
        <div className="cool-heading-container">
          <div className="icon-cross">
            <Icon name="cross" />
          </div>

          <div className="icon-play">
            <Icon name="play" />
          </div>

          <DotPattern />

          <h1 className="center">
            <span className="news-heading-background">NEWS</span>
            <span className="news-heading-foreground">
              <span className="outline">L4t3st</span> news
            </span>
          </h1>
        </div>

        <div className="row smaller-gutter-row">
          <RenderFeaturedNewsItem post={featured} />
          <div className="col-4 news" style={{ position: "absolute", right: "45px" }}>
            <div className="info-placeholder small">
              <Advert width="100%" height={250} refName="HOMEPAGE_ARTICLE_500_250_NO_1" style={{ borderRadius: 2 }} />
            </div>
            <div className="info-placeholder small">
              <Advert width="100%" height={250} refName="HOMEPAGE_ARTICLE_500_250_NO_2" style={{ borderRadius: 2 }} />
            </div>
          </div>
        </div>

        <div className="row smaller-gutter-row">
          <RenderNewsItems posts={posts} />
        </div>
      </div>
    </>
  );
};

export default Home;
