import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
import Loading from "@common/Loading/Loading";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import useTranslated from "../../helpers/translationHelper";
const ResetPassword = () => {
  const { email, token } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordAgain, setNewPasswordAgain] = useState("");


const _resetpassword=useTranslated("id_reset_password_0")
const _newpassword=useTranslated("d_reset_password_1")
const _newpasswordplaceholder=useTranslated("d_reset_password_2")
const _newpasswordagain=useTranslated("d_reset_password_3")
const _newpasswordagainplaceholder=useTranslated("d_reset_password_4")
const _resetpasswordbutton=useTranslated("d_reset_password_5")

  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/verifyResetToken`, { email, token })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setInvalidToken(true);
      });
  }, [email, token]);

  const togglePswdVisibility = () => setPasswordVisible(!passwordVisible);

  const onSubmit = async (e) => {
    e.preventDefault();
    setRequestLoading(true);

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/resetPassword`, {
        email,
        token,
        newPassword,
        newPasswordAgain,
      });

      toast.success("Password reset successful! You can now log in with your new password");
      history.push("/");
    } catch (error) {
      setRequestLoading(false);

      let msg = "Something went wrong.";
      if (error.response && error.response.data) msg = error.response.data.msg;
      toast.error(msg);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="center">
          <Loading className="center" />
        </div>
      ) : invalidToken ? (
        <div className="center">
          <h2>Invalid Request</h2>
          <p>
            Your token may have expired. If this issue persists, <Link to="/contact">contact us</Link>.
          </p>
          <Link to="/">Go to homepage</Link>
        </div>
      ) : (
        <div className="container-fluid resetpass">
          <div className="row">
            <div className="col-6 offset-3">
              <h2>{`${_resetpassword}`}</h2>

              <form onSubmit={onSubmit} className="form">
                <div className="form-row">
                  <label for="email">{`${_newpassword}`}</label>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className="form-input"
                    id="new-password"
                    autoComplete="new-password"
                    placeholder={`${_newpasswordplaceholder}`}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <div onClick={togglePswdVisibility} className="toggle-pswd-visibility">
                    {passwordVisible ? <RiEyeOffLine size="1.2rem" color="#666" /> : <RiEyeLine size="1.2rem" color="#666" />}
                  </div>
                </div>

                <div className="form-row">
                  <label for="email">{`${_newpasswordagain}`}</label>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className="form-input"
                    id="new-password-again"
                    autoComplete="new-password-again"
                    placeholder={`${_newpasswordagainplaceholder}`}
                    value={newPasswordAgain}
                    onChange={(e) => setNewPasswordAgain(e.target.value)}
                  />
                  <div onClick={togglePswdVisibility} className="toggle-pswd-visibility">
                    {passwordVisible ? <RiEyeOffLine size="1.2rem" color="#666" /> : <RiEyeLine size="1.2rem" color="#666" />}
                  </div>
                </div>

                <button type="submit" className="button primary" disabled={requestLoading}>
                  {requestLoading ? <Loading /> : `${_resetpasswordbutton}`}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
