import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { GameItem, Button, Item, SliderItemFrontEnd } from "@common";
import league_image from "@img/league-image.png";
import SliderFrontend from "../Slider/SliderFrontend";
import { AppContext } from "../../../context/AppContext";


const GameSelection = ({ data }) => {
  const [games, setGames] = useState([]);
  const [allGames, setAllGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedGames, setSelectedGames] = useState([]);
  const [needAWheel, setNeedAWheel] = useState(false);
  const { selectedGame } = useContext(AppContext);

  useEffect(() => {
    if (selectedGames.length) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/selected/${selectedGames.toString()}`).then(({ data }) => {
        console.log("game data -- ", data);
        setGames(data.selectedGames);
        setAllGames(() => {
          return data.allGames?.filter((game) => game.name !== "No Game");
        });
        // setGames(data);
        setLoading(false);
      });
    }
  }, [selectedGames]);

  useEffect(() => {
    if (Object.keys(data).length) {
      setSelectedGames([data.game_1, data.game_2, data.game_3]);
      setNeedAWheel(data.needAWheel);
    }
  }, [data]);

  // console.log("games --", games);
  return games?.length > 0 ? (
    <div className="gameSelection">
      <div className={`bar ${needAWheel ? `withwheel` : `withoutwheel`}`}>
        <span></span>
      </div>
      <div className="row" style={{ marginBottom: "36px" }}>
        {games.length > 0 &&
          games.map((game) => (
            game && 
            <GameItem
              glitchDisabled={true}
              image={game.frontPageImage}
              title={game.name}
              column="4"
              imageContainerClass="frontpage-game-item"
              link_to={`/game/${game.shortName}`}
              hide_time_ago={true}
              selectedGame={selectedGame}
            />
          ))}
      </div>
      {needAWheel && (
        <>
          {/* <div className={`bar ${needAWheel ? `sliderwithwheel` : `sliderwithoutwheel`}`}>
              <span></span>
            </div> */}
          <div className="row mb-0">
            <SliderFrontend isArrowFullOpacity needAWheel={needAWheel}>
              {allGames.length > 0 &&
                [...allGames, ...allGames, ...allGames, ...allGames, ...allGames, ...allGames].map(
                  ({ _id, name, shortName, horizontalDisplayImage }, i) => (
                    <SliderItemFrontEnd
                      gameStyle={{ minWidth: "200px" }}
                      key={i}
                      title={name ? `${name}` : `Challenger Series Denmark`}
                      image={horizontalDisplayImage ?? league_image}
                      link_to={`/game/${shortName}`}
                    />
                  )
                )}{" "}
            </SliderFrontend>
          </div>
        </>
      )}
    </div>
  ) : (
    ""
  );
};

export default GameSelection;
