import React, { useEffect, useState } from "react";
import { Item, Icon } from "@common";
import default_tournament_image from "@img/default-tournament-image.png";
import moment from 'moment';
import { makeCountdownString } from "@utils";

const TournamentItemImageInner = ({ text, color,IconName,startTime }) => {
  return (
    <React.Fragment>
      <div className="image-cover"></div>
      {moment() < moment(startTime) ?(
        <span className={`tournament-item-image-inner ${color ?? ""}`}>
        <Icon name={IconName??'clock'} />
        {text ?? ""}
      </span>
      ):(
        null
      ) }
      
    </React.Fragment>
  );
};

const TournamentItem = (props) => {
  const { image, title, subtitle, color, date, startTime, reg_date,registerationDeadline, onClick, ...props_rest } = { ...props };
  const isRegDateAfterToday = moment(moment(registerationDeadline)).isAfter(moment());
  const [countDown, setCountDown] = useState("00:00:00:00");

  useEffect(() => {
    if (startTime) {
      const coundownEndDateTime = isRegDateAfterToday ? registerationDeadline:startTime;
      const _interval = setInterval(() => {
        const now = moment();
        const _startTime = moment(coundownEndDateTime);
        var duration = _startTime.diff(now);
        if (duration > 0)
          setCountDown(makeCountdownString(duration));
        else {
          clearInterval(_interval);
        }
      }, 1000);
    }
  }, []);

  return (
    <Item
      onClick={onClick}
      column="3"
      classes="tournament-item"
      image={image ?? default_tournament_image}
      title={title ?? "TESD - Closed Qualifier"}
      item_image_inner={<TournamentItemImageInner text={countDown ?? "COMING SOON"} color={color ?? "red"} IconName={isRegDateAfterToday? 'clock' : 'white-pencil'} startTime={startTime} />}
      hide_time_ago={true}
      excerpt={
        <div>
          {subtitle ?? title ?? "Lorem ipsum"}
          <div className="tournament-item-datetime">{date}</div>
          {reg_date ? <div className="tournament-item-datetime">{reg_date}</div> : null}
        </div>
      }
      {...props_rest}
    />
  );
};

export default TournamentItem;
