import React from "react";
import default_team_logo from "@img/default-team-logo.png";
import PageBuilder from "../../../../PageBuilder";
const About = ({ logo, originalLogo, showLogo, croppedLogo, title, about, tagline, orgId, tabObject, showPreview, saveContent }) => {
  return (
    <>
      <div className="wide-page-with-tabs-heading-wrapper without_margin">
        <div className="wide-page-with-tabs-heading">
          <a href={originalLogo ?? default_team_logo} target="_blank">
            <img
              className={croppedLogo || croppedLogo === undefined ? "wide-page-with-tabs-logo" : "wide-page-with-tabs-logo_withoutCropped"}
              alt="Team"
              src={logo ?? default_team_logo}
            />
          </a>
          <div className="wide-page-with-tabs-heading-text">
            <h1>{title}</h1>
            <span className="text-faded uppercase">{tagline}</span>
          </div>
        </div>
      </div>

      {!about ? "" : <div className="narrow-content teams-content" dangerouslySetInnerHTML={{ __html: about }} />}

      <PageBuilder
        showPreview={showPreview || false}
        saveContent={(elements) => saveContent(elements)}
        initialContent={tabObject[0].content}
      />
    </>
  );
};

export default About;
