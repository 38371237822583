import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PageWithTabs, PageWithTabsHeader, Loading } from "@common";
import cover_image from "@img/game-profile-cover.png";
import esport_series_logo from "@img/esport-series-logo.png";
import { parse } from 'query-string';
import moment from 'moment';
import _ from 'lodash';

import { Overview, Matches, Rules, LeadBoard, Prizes, Brackets, Participants } from "./TournamentsSingleTabs";
import Axios from "axios";
import TournamentJoinModal from "./TournamentJoinModal";
import EditLineUpModal from "./EditLineUpModal";
import { AppContext } from "../../../context/AppContext";

const TournamentsSingle = () => {
  const { name } = parse(useLocation().search);
  const { authenticated, setShowAuth,user,selectedGame, setSelectedGame } = useContext(AppContext);
  const { id } = useParams();
  const [tournamentData, setTournamentData] = useState();
  const [joinButtonText, setJoinButtonText] = useState("...");
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showEditLineupModal, setShowEditLineupModal] = useState(false);
  const [userHasTeamRegistered, setUserHasTeamRegistered] = useState(false);
  const [upcoming, setUpcoming] = useState(false);

  const getTournamentData = () => {
    setTournamentData(undefined);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}`).then(({ data }) => {
      if (data.game.shortName !== selectedGame.shortName) {
        setSelectedGame(data.game);
        return window.location.reload();
      } else {
        setTournamentData(data);
        setUpcoming(moment().unix() < moment(data?.startDate).unix());
        // if tournament has teams
        console.log("[MATCHES] Tournament Single: ", data);
        if (data.teams && data.teams.length > 0) {
          getUserTeams(data.teams);
        }
      }
    });
  };
  // get users data
  const getUserTeams = (tournamentTeams) => {
    if (authenticated && user && user.state) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/my`).then(({ data }) => {
        if (data && data.length > 0) {
          checkIfUserHasRegisteredTeam(tournamentTeams, data);
        }
      });
    }
  };
  // Check if user has registered teams
  const checkIfUserHasRegisteredTeam = (tournamentTeams, userTeams) => {
    if (tournamentTeams) {
      const tids = tournamentTeams.map((team) => team.team?._id);
      const uids = userTeams.map((team) => team._id);
      const commonTeams = _.intersection(uids, tids);
      if (commonTeams.length > 0) {
        setUserHasTeamRegistered(true);
      }
    }
  };

  // change the join button text according to conditions
  const CheckAndSetButtonText = () => {
    if (!userHasTeamRegistered) {
      if (tournamentData && !tournamentData.isOpen) {
        setJoinButtonText("CLOSED TOURNAMENT");
      } else if (moment() > moment(tournamentData?.registerationDeadline)) {
        setJoinButtonText("SIGNUP CLOSED");
      } else if (tournamentData?.teams?.length >= tournamentData?.teamSlots) {
        setJoinButtonText("TOURNAMENT FULL");
      } else {
        setJoinButtonText("JOIN TOURNAMENT");
      }
    } else {
      setJoinButtonText("EDIT LINEUP");
    }
  };

  const handleJoinButtonClick = () => {
    if (!authenticated) {
      setShowAuth("login");
    }
    if (authenticated && !userHasTeamRegistered && moment() <= moment(tournamentData?.registerationDeadline)) {
      setShowJoinModal(true);
    } else if (authenticated && userHasTeamRegistered && moment() <= moment(tournamentData?.registerationDeadline)) {
      setShowJoinModal(true);
    } else if (authenticated && userHasTeamRegistered && moment() > moment(tournamentData?.registerationDeadline)) {
      setShowEditLineupModal(true);
    } else if (authenticated && !userHasTeamRegistered && moment() > moment(tournamentData?.registerationDeadline)) {
      return false;
    }
  };

  useEffect(() => {
    CheckAndSetButtonText();
  }, [tournamentData, userHasTeamRegistered]);

  useEffect(() => {
    getTournamentData();
    getUserTeams();
  }, []);
  let data = {...tournamentData}
  data.selectedGame = selectedGame;
  let pages = {
    Overview: !tournamentData ? <_Loading /> : <Overview id={id} {...data} />,
    Brackets: !tournamentData ? <_Loading /> : <Brackets id={id} {...tournamentData} />,
    Matches: !tournamentData ? <_Loading /> : <Matches id={id} {...tournamentData} />,
    Participants: !tournamentData ? <_Loading /> : <Participants id={id} tournamentData={tournamentData} />,
    Leaderboard: !tournamentData ? <_Loading /> : <LeadBoard id={id} {...tournamentData} />,
    Prizes: !tournamentData ? <_Loading /> : <Prizes {...tournamentData} />,
    Rules: !tournamentData ? <_Loading /> : <Rules slug={tournamentData.rulesGhostSlug} />,
  };
  return (
    <>
     <div className="">
      <PageWithTabs
        isTournament
        tournamentData={tournamentData}
        showAdsInTabs
        pages={pages}
        image={tournamentData?.logoImage || null}
        cover_image={tournamentData?.coverImage ?? ""}
        title={tournamentData?.name ?? name}
        above_title="TOURNAMENT"
        below_title={
          tournamentData ? (
            <React.Fragment>
              {tournamentData?.hostedBy ?? "Hosted by LEAGUES.GG"}
              <span className={`pill ${moment() < moment(tournamentData?.startDate) ? "warning" : ""}`}>
                {moment(tournamentData?.startDate).format("DD MMMM, YYYY - HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr()}
              </span>
              <span className={"pill " + (moment() < moment(tournamentData?.startDate) ? "primary" : "")}>
                {moment() > moment(tournamentData?.startDate) && !tournamentData.tournamentFinished
                  ? "Ongoing"
                  : moment() > moment(tournamentData?.startDate) && tournamentData.tournamentFinished
                  ? "Finished"
                  : tournamentData.isOpen
                  ? "Open"
                  : "Closed"}
              </span>
            </React.Fragment>
          ) : null
        }
        base_route={`/tournament/${id}`}
        append_to_header={
          tournamentData ? (
            <PageWithTabsHeader
              registerationDeadline={tournamentData?.registerationDeadline}
              date={tournamentData?.startDate}
              hideDate={!upcoming}
              danger={!userHasTeamRegistered}
              warning={userHasTeamRegistered}
              onButtonClick={handleJoinButtonClick}
              disabledButton={
                (!userHasTeamRegistered && !(upcoming && tournamentData.isOpen)) ||
                tournamentData?.teams?.length >= tournamentData?.teamSlots
              }
              button_text={joinButtonText}
            />
          ) : null
        }
      />
      <TournamentJoinModal
        selectedtTab={userHasTeamRegistered ? "editLineup" : null}
        teamSize={tournamentData?.teamSize}
        refreshPage={getTournamentData}
        subsAllowed={tournamentData?.numberOfSubs ?? 0}
        teams={tournamentData?.teams}
        id={id}
        showModal={showJoinModal}
        closeModal={() => setShowJoinModal(false)}
        userHasTeamRegistered={userHasTeamRegistered}
      />
      <EditLineUpModal
        teamSize={tournamentData?.teamSize}
        refreshPage={getTournamentData}
        subsAllowed={tournamentData?.numberOfSubs ?? 0}
        teams={tournamentData?.teams}
        id={id}
        showModal={showEditLineupModal}
        closeModal={() => setShowEditLineupModal(false)}
      />
      </div>
    </>
  );
};

export default TournamentsSingle;

const _Loading = () => (
  <div className="col-12 text-center">
    <Loading />
  </div>
)