import React, { useEffect, useState } from "react";
import { SortableList, SortableLeadBoardListItem, Loading } from "@common";
import Axios from "axios";
import { formatData } from '../Helpers/interceptors';
import { getTracks } from "./getTracks";;

const LeadBoard = ({ teams, recentMatches, id }) => {
  const [loading, setLoading] = useState(true);
  const [bracketsInfo, setBracketsInfo] = useState([[], []]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}/bracket_data`)
      .then(({ data }) => {
        const brackets = formatData(data);
        setBracketsInfo(brackets);
        const tracks = getTracks(brackets);
        if (brackets[1].length > 0) {
          teams.forEach(({ team }, i) => {
            teams[i].currentBracket = "Out";
            if (tracks.winnigIds.includes(team?._id)) teams[i].currentBracket = "Winners'";
            if (tracks.losingIds.includes(team?._id)) teams[i].currentBracket = "Losers'";
          });
        }
      })
      .finally(() => setLoading(false));
  }, []);

  let num_teams = teams.length;
  const _teams = teams.map((el) => ({ ...el, recent_matches: recentMatches[el.team?._id] })).sort((a, b) => b.score - a.score);

  if (loading && !bracketsInfo.length !== 0)
    return (
      <div className="w-100" style={{ textAlign: "center" }}>
        <Loading />
      </div>
    );

  return (
    <div className="pb-5">
      <h2 className="text-16 bold uppercase pt-0 pb-4 m-0">{num_teams} teams</h2>
      <SortableList config={bracketsInfo[1].length === 0 ? columnsSingleElim : columnsDoubleElim}>
        {_teams.map(({ team, score, mapsWon, mapsLost, matches, recent_matches, currentBracket }, i) => (
          <SortableLeadBoardListItem
            key={i}
            number={i + 1}
            team={
              team ? (
                <React.Fragment>
                  <img src={team.logo} alt="" className="border-radius-100 image-40" />
                  {team.name}
                </React.Fragment>
              ) : (
                "[DELETED]"
              )
            }
            score={team && score}
            matches={team && matches}
            wins={team && mapsWon}
            losses={team && mapsLost}
            currentBracket={bracketsInfo[1].length === 0 ? undefined : currentBracket}
            recent_matches={
              <React.Fragment>
                {recent_matches
                  ? recent_matches.map((el, i) => (
                      <span
                        key={i}
                        className={"pill round small " + (determineResult(el, team._id || null) === "L" ? "danger" : "success")}
                      />
                    ))
                  : null}
              </React.Fragment>
            }
            listType="tournament"
          />
        ))}
      </SortableList>
    </div>
  );
};

export default LeadBoard;

const determineResult = (match, selfId) => {
  const winner = match.t1Score > match.t2Score ? "t1" : "t2";
  return match[winner] === selfId ? "W" : "L"
}

const columnsSingleElim = [
  { col: 1, title: "#", prop_name: "number", alignment: "left" },
  { col: 4, title: "Team", prop_name: "team", alignment: "left" },
  { col: 3, title: "Matches", prop_name: "matches", alignment: "right" },
  { col: 1, title: "Maps Won", prop_name: "wins", alignment: "right" },
  { col: 1, title: "Maps Lost", prop_name: "losses", alignment: "right" },
  { col: 2, title: "Recent Matches", prop_name: "recent_matches", alignment: "right" },
];

const columnsDoubleElim = [
  { col: 1, title: "#", prop_name: "number", alignment: "left" },
  { col: 3, title: "Team", prop_name: "team", alignment: "left" },
  { col: 2, title: "Current bracket", prop_name: "score", alignment: "center" },
  { col: 1, title: "Matches", prop_name: "matches", alignment: "right" },
  { col: 1, title: "Maps Won", prop_name: "wins", alignment: "right" },
  { col: 1, title: "Maps Lost", prop_name: "losses", alignment: "right" },
  { col: 2, title: "Recent Matches", prop_name: "recent_matches", alignment: "right" },
];
