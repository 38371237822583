//Library imports
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Switch } from "antd";

const SwitchItem = ({ checked, onChangeHandler, groupId }) => {
  return (
    <Wrapper>
      <div className="d-flex align-items-center">
        <Switch onChange={(e) => onChangeHandler(e, groupId)} checked={checked} />
      </div>
    </Wrapper>
  );
};
export default SwitchItem;

const Wrapper = styled.div`
  vertical-align: middle;
  margin-right: 0.5em;
  color: #717171;
  font-weight: 700;
  font-size: 16px;
  font-family: "robotoregular";
  @media all and (max-width: 1439px), (max-height: 729px), (-webkit-device-pixel-ratio: 1.25) {
    font-size: 12px;
  }
`;
