/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SimpleTabs, SortableList, SortableListItem, Loading } from "@common";
import Axios from "axios";
import { Col } from "react-bootstrap";
import MultiStageDropdown from "../../../MultiStageDropdown";

const TopPlayers = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [allStages, setAllStages] = useState([]);
  const [stageGroupNames, setStageGroupNames] = useState([]);
  const [stageTabs, setStageTabs] = useState(["All"]);
  const [stageAllGroups, setStageAllGroups] = useState([]);
  const [page, setPage] = useState(0);
  const [stagePage, setStagePage] = useState(0);
  const [selectedStage, setSelectedStage] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isAll, setIsAll] = useState(true);
  const [groupStatus, setGroupStatus] = useState([]);

  console.log({allStages,stageTabs,stageAllGroups,page,stagePage,selectedStage,selectedGroups,isAll,groupStatus})
  // const [filteredStage, setFileteredStage] = useState([]);
  let pages = ["KDA", "CS PR MIN", "KILLS", "ASSISTS", "VISION SCORE", "KILL PARTICIPATION"];
  let prop_names = ["kda", "csPerMin", "kills", "assists", "visionScore", "killParticipation"];

  useEffect(()=>{
    if(stagePage === 0){
      setIsAll(true)
    }else{
      setIsAll(false)
    }
  }, [stagePage]);

  const setData = (stage) => {
    let _groupsArr = [];
    let groupNames = [];
    if (stageTabs[stagePage] === "All") {
      stage.map((el) => {
        return el?.groups.map((group) => {
          if(selectedGroups.length> 0){
            let found = selectedGroups.find(el=> el._id === group._id)
            if(found){
              _groupsArr = [..._groupsArr, { stageName: el?.name, group: {...group, status:true} }];
            }else{
              _groupsArr = [..._groupsArr, { stageName: el?.name, group: {...group,status: false }}];

            }

          }else{

            _groupsArr = [..._groupsArr, { stageName: el?.name, group: {...group,status: false }}];
          }
          groupNames.push(group?.name);
        });
      });
    } else {
      groupNames = stage?.groups?.map((group) => {
        if(selectedGroups.length> 0){
          let found = selectedGroups.find(el=> el._id === group._id)
          if(found){
            _groupsArr = [..._groupsArr, { stageName: stage?.name, group: {...group, status:true} }];
          }else{
            _groupsArr = [..._groupsArr, { stageName: stage?.name, group: {...group,status: false }}];

          }
        }else{

          _groupsArr = [..._groupsArr, { stageName: stage?.name, group: {...group,status: false }}];
        }
        // _groupsArr = [..._groupsArr, { stageName: stage?.name, group: group }];
        return group?.name;
      });
    }
    // debugger
    
    // if (_groupsArr.length > 0) {
    //   setSelectedGroups([_groupsArr[0].group]);
    // }
    setStageGroupNames(groupNames);
    setStageAllGroups(_groupsArr);
  };

  const onSeletedChange = async (data) => {
    let _selectedGroups = [];
    let valu;
    // debugger
    if (stageTabs[stagePage] === "All") {
      allStages.map((stage) => {
        return stage?.groups.forEach((g, i) => {
          valu = data.find((data) => g._id.toString() === data.groupId.toString());
          if (valu?.status) {
            g.stageName = stage?.name;
            g.status = true
            _selectedGroups.push(g);
            return g;
          }
        });
      });
    } else {
      allStages.map((el) => {
        return el?.groups.forEach((g, i) => {
          valu = data.find((data) => g._id.toString() === data.groupId.toString());
          if (valu?.status) {
            g.stageName = el?.name;
            g.status = true
            _selectedGroups.push(g);
            return g;
          }else{
            // debugger
            let found = selectedGroups.find(elem=> elem._id === g._id)
            if(found){
              _selectedGroups.push(found)
            }
          }
        });
      });
      // allStages[stagePage - 1].groups.forEach((g, i) => {
      //   let val = data.find((data) => g._id.toString() === data.groupId.toString());
      //   if (val.status) {
      //     // debugger;
      //     g.stageName = el?.name
      //     selectedGroups.push(g);
      //     return g;
      //   }
      // });
    }
    setSelectedGroups(_selectedGroups);
  };

  const setSelectedStagesForAll = (data) => {
    const stageName = data?.stageName;

    if (stageTabs[stagePage] === "All") {
      data.playerStatsInGroup.forEach((el) => (el.stageName = stageName));

      setSelectedStage([...selectedStage, data]);
    } else {
      data.playerStatsInGroup.forEach((el) => (el.stageName = stageName));

      setSelectedStage([data]);
    }
  };
  const onTagClick = (data) => {
    let _groups = [];
    let _selectedGroups = [...selectedGroups];

    // selectedGroups.filter((item) => {
    //   if (item._id !== data) {
    //     _selectedGroups.push(item);
    //   }
    // });

    stageAllGroups.map((item) => {
      var gitem = item;
      if (gitem.group._id === data) {
        gitem.group.status = false;
        let g = selectedGroups.filter((x) => x.groupId === gitem.group._id);
        if (g.length > 0) {
          g[0].status = false;
        }
      } 
      // else {
      //   let g = selectedGroups.filter((x) => x._id === gitem.group._id);
      //   if (g.length > 0) {
      //     _selectedGroups = g
      //     gitem.group.status = g[0].status;
      //   }
      // }
      _groups.push(gitem);
    });

    // onSeletedChange(groupStatus);
    setSelectedGroups(_selectedGroups);
    setStageAllGroups(_groups);
  };
  const fetchTopPlayers = async (stagesData) => {
    setStageGroupNames([]);
    setSelectedStage([]);
    setStageAllGroups([]);
    // setLoading(true);
    if (stageTabs[stagePage] === "All") {
      setData(stagesData);
      stagesData.map((el, index) => {
        // setLoading(true);
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${el?._id}/topStagePlayers`)
          .then(({ data }) => {
            setSelectedStagesForAll(data.data);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            toast.error("There was a problem in fetching top players");
          });
      });
    } else {
      setData(stagesData[stagePage - 1]);
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${stagesData[stagePage - 1]?._id}/topStagePlayers`) // "/topPlayers" was used earlier but that api was not returning stageName
        .then(({ data }) => {
          // let plyrStats = [];
          // data.data.playerStatsInGroup.forEach((group) => {
          //   group.playerStats.forEach(player => {
          //     player.group = group.name;
          //     player.kda = player.deaths === 0 ? Infinity : parseFloat((player.kills + player.assists) / player.deaths).toFixed(2);
          //     player.csPerMin = parseFloat(player.cs / (player.totalTimePlayed / 60)).toFixed(1);
          //   })
          //   plyrStats = plyrStats.concat(group.playerStats);
          // })
          setSelectedStagesForAll(data.data);
          // setFileteredStage(plyrStats);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          toast.error("There was a problem in fetching top players");
        });
    }
  };
  const checkGroupSelected = (stats) => {
    const groups = [...selectedGroups];
    if (groups.length > 0) {
      const found = groups.find((el) => {
        if (el.name === stats.name && el.stageName === stats.stageName) {
          return true;
        } else {
          return false;
        }
      });
      return found;
    }
  };

  useEffect(() => {
    setLoading(true);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage`) // Fetch all stages
      .then(({ data }) => {
        setData(data.data);
        const stageNames = data.data.map((stage) => stage.name);
        if (stageNames.length > 1) {
          setStageTabs(["All", ...stageNames]);
        } else {
          setStageTabs(stageNames);
        }
        setAllStages(data.data);
        setLoading(false);
        fetchTopPlayers(data.data);
      })
      .catch(() => {
        setLoading(false);
        toast.error("There was a problem in fetching top players");
      });
  }, []);

  useEffect(() => {
    if (allStages.length > 0) {
      fetchTopPlayers(allStages);
    }
  }, [stagePage]);

  return loading ? (
    <>
      <div className="text-center league-no-matches">
        <Loading width={50} height={50} />
      </div>
    </>
  ) : (
    <>
      {
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
      // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) && 
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
      // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) && 
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
      // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) && 
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
      // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) && 
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
      // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) && 
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
      // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) && 
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
      // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) && 
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
      // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) && 
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
      // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) && 
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
      // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) && 
        // (stageTabs[stagePage] !== "All" ? allStages[stagePage - 1]?.groups.length > 1 : true) &&
        <>
          <div style={{ display: "flex", flexDirection: "row", marginTop: "9px" }}>
            <div style={{ width: "80%" }}>
              {stageTabs?.length > 1 && <SimpleTabs pages={stageTabs} page={stagePage} setPage={setStagePage} />}
            </div>
            <div style={{ position: "absolute", marginLeft: "calc(83% + 1px)", marginTop: -9 }}>
              <MultiStageDropdown
                groups={stageAllGroups}
                onSeletedChange={onSeletedChange}
                isMultiSelect={true}
                setDefault={false}
                reUpdate={true}
                isAll={isAll}
                setCustom={true}
              />
            </div>
          </div>
          <div className="align-items-center" style={{ display: "flex", flexDirection: "row" }}>
            {selectedGroups.length > 0
              ? selectedGroups.map((group) => {

                  if (stagePage > 0) {
                    let stage = allStages[stagePage - 1];
                    let found = stage.groups.findIndex((el) => el._id === group._id);
                    if (found >= 0) {
                      return (
                        <div
                          className="par-num align-items-centerd"
                          style={{
                            borderRadius: 20,
                            background: `${group.color}22`,
                            paddingLeft: 7,
                            paddingRight: 7,
                            paddingTop: 4,
                            paddingBottom: 4,
                            justifyContent: "center",
                            flexDirection: "row",
                            display: "flex",
                            marginRight: 10,
                            marginTop: "1.5rem",
                            marginBottom: 5,
                          }}
                        >
                          <span
                            className="uppercase"
                            key={group._id}
                            style={{
                              fontSize: 12,
                              fontWeight: "bold",
                              color: group.color ? group.color : false,
                              paddingLeft: 5,
                              alignItems: "center",
                              textOverflow: "ellipsis",
                              maxWidth: "10ch",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {group.name ? group.name : false}
                          </span>
                          <span
                            onClick={() => onTagClick(group._id)}
                            style={{
                              width: 12.5,
                              height: 12.5,
                              color: group.color ? group.color : false,
                              marginBottom: 10,
                              marginTop: 3,
                              marginLeft: 10,
                              fontWeight: 500,
                              cursor: "pointer",
                            }}
                          >
                            x
                          </span>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div
                        className="par-num align-items-centerd"
                        style={{
                          borderRadius: 20,
                          background: `${group.color}22`,
                          paddingLeft: 7,
                          paddingRight: 7,
                          paddingTop: 4,
                          paddingBottom: 4,
                          justifyContent: "center",
                          flexDirection: "row",
                          display: "flex",
                          marginRight: 10,
                          marginTop: "1.5rem",
                          marginBottom: 5,
                        }}
                      >
                        <span
                          className="uppercase"
                          key={group._id}
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            color: group.color ? group.color : false,
                            paddingLeft: 5,
                            alignItems: "center",
                            textOverflow: "ellipsis",
                            maxWidth: "12ch",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {group.name ? group.name : false}
                        </span>
                        <span
                          onClick={() => onTagClick(group._id)}
                          style={{
                            width: 12.5,
                            height: 12.5,
                            color: group.color ? group.color : false,
                            marginBottom: 10,
                            marginTop: 3,
                            marginLeft: 10,
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          x
                        </span>
                      </div>
                    );
                  }
                })
              : false}
          </div>
        </>
      }
      <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
        <SimpleTabs pages={pages} page={page} setPage={setPage} />
      </div>
      {selectedStage && selectedStage?.length !== 0 ? (
        <SortableList
          className="mt-3"
          config={[
            { col: 1, title: "#", prop_name: "number", is_sortable: true, alignment: "left" },
            { col: 3, title: "Player", prop_name: "player", alignment: "left" },
            { col: 3, title: "Team", prop_name: "team", alignment: "left" },
            { col: 1, title: "Position", prop_name: "position", is_sortable: true, alignment: "left", style: { marginLeft: "55px" } },
            { col: 1, title: "Maps Played", prop_name: "mapsPlayed", is_sortable: true, alignment: "right" },
            { col: 1, title: pages[page], prop_name: prop_names[page], is_sortable: true, alignment: "right" },
            {
              col: 1,
              title: "Action",
              prop_name: "action",
              alignment: "center",
              style: { marginLeft: "40px" },
            },
          ]}
        >
          {selectedGroups && selectedStage?.length !== 0
            ? selectedStage.map((stage) => {
                return stage.playerStatsInGroup.map((stats) => {
                  const groupSelected = checkGroupSelected(stats);
                  if (groupSelected) {
                    return stats.playerStats
                      .sort((a, b) => b[prop_names[page]] - a[prop_names[page]])
                      .map((player, i) => {
                        const playerInnerData = player.player;
                        return (
                          <SortableListItem
                            number={i + 1}
                            team={
                              <>
                                {player?.team ? (
                                  <React.Fragment>
                                    <img
                                      src={player.team?.logo}
                                      alt={player.team?.name}
                                      className={
                                        player.team?.logoCropped || player.team?.logoCropped === undefined
                                          ? "image-40 border-radius-100"
                                          : "image-40"
                                      }
                                    />
                                    {player.team?.name}
                                  </React.Fragment>
                                ) : (
                                  "[Deleted]"
                                )}
                              </>
                            }
                            player={
                              playerInnerData ? (
                                <React.Fragment>
                                  <img
                                    src={playerInnerData.avatarImage}
                                    alt={playerInnerData.name}
                                    className="image-40 border-radius-100"
                                  />
                                  {playerInnerData.name}
                                </React.Fragment>
                              ) : (
                                "[Deleted]"
                              )
                            }
                            position={player.role}
                            // position_number={i % 3}
                            kda={player.kda === Infinity ? "Perfect" : player.kda}
                            csPerMin={player.csPerMin}
                            kills={player.kills}
                            assists={player.assists}
                            killParticipation={
                              // (((player.kills + player.assists) / Math.max(1, player.teamKills - player.kills)) * 100).toFixed(2) + "%"
                              (((player.kills + player.assists) / Math.max(1, player.teamKills)) * 100).toFixed(2) + "%"
                            }
                            visionScore={player.visionScore}
                            mapsPlayed={player.mapsPlayed}
                            action={
                              playerInnerData && (
                                <React.Fragment>
                                  <Link to={`/game-profile/${playerInnerData._id}`} className="text-12 primary-link">
                                    Go to player
                                  </Link>
                                </React.Fragment>
                              )
                            }
                          />
                        );
                      });
                  }
                });
              })
            : null}
        </SortableList>
      ) : null}
    </>
  );
};
export default TopPlayers;
