import React, { useEffect, useState } from "react";
import { SortableList, SortableListItem, Loading } from "@common";
import Axios from "axios";

const ChampionStats = ({ player }) => {
  const [champions, setChampions] = useState([]);
  const [championsData, setChampionsData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [championsLoading, setChampionsLoading] = useState(true);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_LOL_API}/api/players/${player._id}/championStats`).then(({ data }) => {
      setChampions(data);
      setDataLoading(false);
    });

    Axios.get(`${process.env.REACT_APP_LOL_API}/api/riot/champions`).then(({ data }) => {
      setChampionsData(data);
      setChampionsLoading(false);
    });
  }, [player._id]);

  const championIdData = (championId) => {
    const championData = championsData[championId];
    const championVers = championData.version;
    const championImage = championData.image.full;

    return { image: `https://ddragon.leagueoflegends.com/cdn/${championVers}/img/champion/${championImage}`, name: championData.name };
  };

  return championsLoading || dataLoading ? (
    <div className="col-12 text-center p-5">
      <Loading />
    </div>
  ) : (
    <div>
      {/* <div className="row tiny-gutter-row">
        <div className="col-4">
          <PositionDisplay />
        </div>

        <div className="col-8 flex-row-between">
          <span>
            <button className="button default mr-3">All queues</button>
            <button className="button default mr-3">season 10</button>
            <button className="button transparent">Reset</button>
          </span>
          <span>
            <button className="button primary">Compare players</button>
          </span>
        </div>
      </div> */}
      <div className="row smaller-gutter-row pt-5">
        <div className="col-12">
          <SortableList
            classes="champions-stats-sortable-list"
            config={[
              { col: 1, title: "Rank", prop_name: "rank", is_sortable: true, alignment: "left" },
              { col: 2, title: "Champion", prop_name: "champion", alignment: "left" },
              { col: 1, title: "Played", prop_name: "played", is_sortable: true, alignment: "center" },
              { col: 1, title: "Wr", prop_name: "wr", suffix: "%", is_sortable: true, alignment: "center" },
              { col: 1, title: "KDA", prop_name: "kda", is_sortable: true, alignment: "center" },
              { col: 1, title: "GOLD", prop_name: "gold", suffix: "K", is_sortable: true, alignment: "center" },
              { col: 1, title: "CS", prop_name: "cs", is_sortable: true, alignment: "center" },
              { col: 1, title: "MAX KILLs", prop_name: "max_kills", is_sortable: true, alignment: "center" },
              { col: 1, title: "MAX DEATHS", prop_name: "max_deaths", is_sortable: true, alignment: "center" },
              { col: 1, title: "AVG. DMG Dealt", prop_name: "avg_dmg_dealt", suffix: "K", is_sortable: true, alignment: "center" },
              { col: 1, title: "AVG. DMG taken", prop_name: "avg_dmg_taken", suffix: "K", is_sortable: true, alignment: "center" },
            ]}
          >
            {champions.map(
              (
                {
                  championId,
                  wins,
                  losses,
                  kills,
                  deaths,
                  assists,
                  games,
                  totalDamageDealt,
                  totalDamageTaken,
                  goldEarned,
                  csEarned,
                  maxKills,
                  maxDeaths,
                },
                i
              ) => {
                return (
                  <SortableListItem
                    rank={i + 1}
                    champion={
                      <React.Fragment>
                        <img src={championIdData(championId).image} alt={championIdData(championId).name} className="image-40" />
                        {championIdData(championId).name}
                      </React.Fragment>
                    }
                    played={games}
                    wr={Math.round((wins / (wins + losses)) * 100)}
                    gold={Math.round(goldEarned / 1000)}
                    kda={deaths === 0 ? "Perfect" : parseFloat((kills + assists) / deaths).toFixed(2)}
                    cs={csEarned}
                    max_kills={maxKills}
                    max_deaths={maxDeaths}
                    avg_dmg_taken={Math.round(totalDamageTaken / games / 1000)}
                    avg_dmg_dealt={Math.round(totalDamageDealt / games / 1000)}
                  />
                );
              }
            )}
          </SortableList>
        </div>
      </div>
    </div>
  );
};

export default ChampionStats;
