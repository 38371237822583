import React, { useState } from "react";
import Modal from "react-modal";
import Close from "./assets/close.svg";
import Axios from "axios";
import moment from "moment";
import useTranslated from "../../../helpers/translationHelper";
const AcceptRequest = ({
  onClose = () => {},
  matchDate = "",
  rescheduleDate = "",
  matchId,
  cbSuccess = () => {},
  cbFailure = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(true);
  let useTranslated6 = useTranslated("id_notifications_6");
  const confirmReschedule = async (e) => {
    e.preventDefault();
    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/rescheduleRequests/accept/${matchId}`);
      if (!data.error) {
        setIsOpen(false);
        onClose();
        cbSuccess(null, "Match Rescheduled Successfully");
      } else {
        cbFailure(data.msg);
      }
    } catch (error) {
      cbFailure(error?.response?.data?.msg || "Failed to confirm Reschedule");
    }
  };

  const declineReschedule = async (e) => {
    e.preventDefault();
    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/rescheduleRequests/decline/${matchId}`);
      if (!data.error) {
        setIsOpen(false);
        onClose();
        cbSuccess(null, `Successfully ${useTranslated6} reschedule request)`);
      } else {
        cbFailure(data.msg);
      }
    } catch (error) {
      cbFailure(error?.response?.data?.msg || `Failed to ${useTranslated6} reschedule request`);
    }
  };

  return (
    <Modal isOpen={isOpen} className="modal" style={{ position: "relative" }} onRequestClose={onClose} shouldCloseOnOverlayClick={false}>
      <div className="modal-header">
        <h2 className="headerTitle">SUGGEST A NEW MATCH DATE</h2>
        <p className="text-muted text-center description">
          Send a request to the opponent team to move the match date. If the opponent {useTranslated("id_notifications_6")} or fail to
          answer your request, the match date will not be changed
        </p>
        <hr />
        <div className="modal-body">
          <form>
            <div className="form-row">
              <label htmlFor="currentDate">previous DATE</label>
              <input
                type="datetime"
                id="currentTime"
                placeholder=""
                autoComplete="datetime"
                className="form-input"
                value={moment(matchDate).format()}
                required
                disabled
              />
            </div>
            <div className="form-row">
              <label htmlFor="newTime">NEW DATE</label>
              <input
                type="datetime"
                id="currentTime"
                placeholder=""
                autoComplete="datetime"
                className="form-input"
                value={rescheduleDate}
                required
                disabled
              />
            </div>
            <button className="button button-primary my-1" onClick={confirmReschedule}>
              Confirm new match date
            </button>
            <button className="button button-danger my-1" onClick={declineReschedule}>
              {useTranslated("id_notifications_6")}
            </button>
          </form>
        </div>
      </div>
      <div className="closeButton" style={{ display: "absolute", rigth: "-12px" }}>
        <img
          src={Close}
          onClick={() => {
            onClose();
            setIsOpen(false);
          }}
        />
      </div>
    </Modal>
  );
};

export default AcceptRequest;
