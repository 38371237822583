const LoL = [
    {
        name: "North America",
        code: "NA",
        type: "regionType"
    },
    {
        name: "Europe West",
        code: "EUW",
        type: "regionType"
    },
    {
        name: "Europe Nordic & East",
        code: "EUN",
        type: "regionType"
    },
    {
        name: "Korea",
        code: "KR",
        type: "countryType"
    },
    {
        name: "Brazil",
        code: "BR",
        type: "countryType"
    },
    {
        name: "Japan",
        code: "JP",
        type: "countryType"
    },
    {
        name: "Russia",
        code: "RU",
        type: "countryType"
    },
    {
        name:"Oceania",
        code: "OCE",
        type: "regionType"
    },
    {
        name: "Turkey",
        code: "TR",
        type: "countryType"
    },
    {
        name: "Latin America North",
        code: "LAN",
        type: "regionType"
    },
    {
        name: "Latin America South",
        code: "LAS",
        type: "regionType"
    }
];

const Valorant = [
    {
        name: "Asia",
        code: "AS",
        type: "regionType"
    },
    {
        name: "Europe",
        code: "EU",
        type: "regionType"
    },
    {
        name: "United States",
        code: "US",
        type: "countryType"
    }
];

export const regions = {
    LoL, VL: Valorant
}