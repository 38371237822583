import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "@context/AppContext";
import Axios from "axios";
import logo_header from "@img/logo-header.svg";
import Loading from "@common/Loading/Loading";
// import Loading from "../../_common/Loading/Loading";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ConnectCSGO = ({ afterConnect }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { steamId } = useParams();
  const { user } = useContext(AppContext);
  const [inGameName, setInGameName] = useState(null);
  // const [game, setGame] = useState(null);
  const [primaryRole, setPrimaryRole] = useState(null);
  const [secondaryRole, setSecondaryRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const onCreate = (data, type) => {
    user.dispatch({ type: "update", payload: { [type]: [...user.state[type], data] } });
  };

  let query = useQuery();
  let steamUsername = query.get("steamUsername");

  const getGames = () => {
    Axios.get(`${process.env.REACT_APP_CSGO_API}/api/game`).then(({ data }) => {
      setRoles(data.configuration.playerTypes.filter(({ name }) => !name.includes("SUB")) || []);
      // setGame(data);
    });
  };

  const onSubmit = async (e) => {
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_CSGO_API}/api/connect`,
        { steamUsername, steamId, inGameName, primaryRole, secondaryRole }
      );
      toast.success("Successfully created CSGO player!");
      setLoading(false);

      onCreate(data.player, "players");

      history.push(`/game-profile/${data.player._id}`);
    } catch (e) {
      console.log("eeee=", e);
      const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem creating player";
      toast.error(msg);
      setLoading(false);
      if (msg !== "In Game Name already registered") {
        history.push(`/`);
      }
    }
  };

  useEffect(() => {
    getGames();
    setInGameName(steamUsername);
  }, [steamUsername, steamId]);

  return (
    <div className="global-pnf">
      <div className="container-fluid">
        <div className="area-logo">
          <Link to="#" className="logo-text">
            <img src={logo_header} alt="Leagues logo" />
            <strong className="uppercase">Please wait...</strong>
          </Link>
        </div>

        <div>
          <div className="form-row">
            <label>Set your in game name and press next.</label>
            <input className="customInput" value={inGameName} onChange={(e) => setInGameName(e.target.value)} disabled={true} />
          </div>
          <div className="form-row">
            <label htmlFor="primaryRole">Preferred Primary Role</label>
            <select
              id="primaryRole"
              className="form-input"
              value={primaryRole || "_DEFAULT"}
              onChange={(e) => setPrimaryRole(e.target.value)}
            >
              <option value="_DEFAULT" disabled>
                Choose preferred primary role
              </option>
              {roles.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-row">
            <label htmlFor="secondaryRole">Preferred Secondary Role</label>
            <select
              id="secondaryRole"
              className="form-input"
              value={secondaryRole || "_DEFAULT"}
              onChange={(e) => setSecondaryRole(e.target.value)}
            >
              <option value="_DEFAULT" disabled>
                Choose preferred secondary role
              </option>
              {roles.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={() => onSubmit()}
            className="button primary thicc mt-4"
            disabled={loading || secondaryRole === null || primaryRole === null || inGameName === null || inGameName === ""}
          >
            {loading ? (
              <>
                <Loading width={30} height={30} /> Please wait...
              </>
            ) : (
              "Next"
            )}
          </button>
        </div>

        <div className="area-logo">
          <strong className="uppercase">Do not refresh or close this window.</strong>
        </div>
      </div>
    </div>
  );
};

export default ConnectCSGO;
