import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Icon } from "@common";
import PrizesTable from "../Prizes/PrizesTable";
import moment from "moment";
import { AppContext } from "../../../../../context/AppContext";

const Overview = ({ league }) => {
  
  const aboutCards = [
    league.stages[0].stageType === "league" ? 
    ( 
      { icon: <Icon name="globe-icon" />, title: `${league.season.game.name}, EU West`, subtitle: "Game & Region" },
      league.stages[0].league?.bestOf && { icon: <Icon name="game-icon" />, title: `Best Of ${league.stages[0]?.league.bestOf}`, subtitle: "Format" },
      !!league.stages[0].league?.prizes.length && { icon: <Icon name="wallet-icon" />, title: league.stages[0].league?.prizes[0], subtitle: "Prize Pool" },
      { icon: <Icon name="team-icon" />, title: league.stages[0].league?.teams.length, subtitle: "Team Size" },
      league.stages[0].league?.startDate && { icon: <Icon name="calendar" />, title: moment(league.stages[0].league?.startDate).format("MMMM Do YYYY"), subtitle: "Start Date" },
      league.stages[0].league?.estDuration && { icon: <Icon name="clock-icon" />, title: league.stages[0].league?.estDuration, subtitle: "Estimated Duration" }
    )
    :
    (
      { icon: <Icon name="globe-icon" />, title: `${league.season.game.name}, EU West`, subtitle: "Game & Region" },
      league.stages[0].tournament?.bestOf && { icon: <Icon name="game-icon" />, title: `Best Of ${league.stages[0]?.tournament.bestOf}`, subtitle: "Format" },
      !!league.stages[0].tournament?.prizes.length && { icon: <Icon name="wallet-icon" />, title: league.stages[0].tournament?.prizes[0], subtitle: "Prize Pool" },
      { icon: <Icon name="team-icon" />, title: league?.stages[0]?.tournament?.teams.length, subtitle: "Team Size" },
      league.stages[0].tournament?.startDate && { icon: <Icon name="calendar" />, title: moment(league?.stages[0]?.tournament?.startDate).format("MMMM Do YYYY"), subtitle: "Start Date" },
      league.stages[0].tournament?.estDuration && { icon: <Icon name="clock-icon" />, title: league.stages[0].tournament?.estDuration, subtitle: "Estimated Duration" }
    )
  ]
  
 
  // (
  // const aboutCards = [
    
  // ])
  const {
    selectedGame: { shortName },
  } = useContext(AppContext);
  return (
    <div>
      <div className="row overview" style={{flexWrap:"inherit"}}>
        <div className="col-8">
          <div className="image-text">
            <img 
            src={league?.stages[0]?.stageType === "league" 
            ? 
            league?.stages[0]?.league?.logoImage 
            : 
            league?.stages[0]?.tournament?.logoImage} 
            alt={
            league?.stages[0]?.stageType === "league" 
            ? 
            league?.stages[0]?.league?.name 
            : 
            league?.stages[0]?.tournament?.name }
            />

            <div>
              <h1 className="text-32">{league.name}</h1>
              <span className="text-14">
                
                League - {league?.stages[0]?.stageType === "league" ? league?.stages[0]?.league?.startDate ? moment(league?.stages[0]?.league?.startDate).format("MMMM Do YYYY") + " - " : "" : league.stages[0].tournament.startDate ? moment(league.stages[0].tournament.startDate).format("MMMM Do YYYY") + " - " : ""}
                {league.season.name + (league?.sponsor ? ` - Hosted by ${league?.sponsor}` : "")}
              </span>
            </div>
          </div>

          {/* Left start */}
          <div className="row smaller-gutter-row player-overview-row pt-5">
            <div className="col-12 row-content">
              {league?.twitch && league?.isTwitchLive && (
                <iframe
                  title="twitch-video"
                  width="80%"
                  height="500"
                  style={{ display: "block", margin: "30px 0" }}
                  src={`https://player.twitch.tv/?channel=${league?.twitch.split("/")[league?.twitch.split("/").length - 1]}&parent=${
                    process.env.REACT_APP_FRONTEND_URL
                  }`}
                  frameborder="0"
                  getEnded={(e)=> {debugger}}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              )}

              <strong className="text-16 faded uppercase mt-3">About</strong>

              {league?.video && (
                <iframe
                  title="youtube-video"
                  width="80%"
                  height="500"
                  style={{ display: "block", margin: "30px 0" }}
                  src={`https://youtube.com/embed/${league?.video.split("?v=")[league?.video.split("?v=").length - 1]}`}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              )}

              <p className="mt-3" style={{ whiteSpace: "break-spaces" }}>
                {league?.about}
              </p>
            </div>
          </div>

          {/* <div className="row smaller-gutter-row player-overview-row pt-5">
            <span className="text-16 faded bold uppercase title">
              <h3>Powered by</h3>
            </span>

            <img src={telia_logo} alt="" />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              <br />
              <br />
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum.
            </p>
          </div> */}
        </div>

        {/* Left end */}

        {/* right start */}
        <div className="col-4">
          {/* Right side */}
          <span className="text-16 faded bold uppercase">Format</span>

          <div className="row smaller-gutter-row">
            {/* ABOUT CARDS */}
            {aboutCards.map(
              (card, i) =>
                card && (
                  <div className="col-12 contains-card" key={i}>
                    <Card icon={card.icon} title={card.title} subtitle={card.subtitle} />
                  </div>
                )
            )}
          </div>

          {league && league?.stages[0]?.stageType === "league" ?
          (
            <div className="row smaller-gutter-row">
              <div className="col-12">
                <a
                  href={league?.stages[0]?.league?.discord}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="primary-bg-link"
                  style={shortName === "CSGO" ? { backgroundColor: "rgba(229, 93, 19,0.2)" } : null}
                >
                  <span>
                    <Icon name={shortName === "CSGO" ? "discord-icon-csgo" : "discord-icon"} />
                    Discord server:
                    <span className="ml-2 link">{league?.stages[0]?.league?.discord}</span>
                  </span>
                  <Icon name={shortName === "CSGO" ? "external-primary-csgo" : "external-primary"} />
                </a>
              </div>
            </div>
          )
          :
          (
            <div className="row smaller-gutter-row">
              <div className="col-12">
                <a
                  href={league?.stages[0]?.tournament?.discord}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="primary-bg-link"
                  style={shortName === "CSGO" ? { backgroundColor: "rgba(229, 93, 19,0.2)" } : null}
                >
                  <span>
                    <Icon name={shortName === "CSGO" ? "discord-icon-csgo" : "discord-icon"} />
                    Discord server:
                    <span className="ml-2 link">{league?.stages[0]?.tournament?.discord}</span>
                  </span>
                  <Icon name={shortName === "CSGO" ? "external-primary-csgo" : "external-primary"} />
                </a>
              </div>
            </div>
          )
        }

          {league && league?.stages[0]?.stageType === "league" ? (
            <div className="row smaller-gutter-row">
              <div className="col-12">
                <a
                  href={league?.stages[0]?.league?.twitch}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="primary-bg-link"
                  style={{ background: "#110c24", color: "#883ef0", marginTop: 10 /* twitch brand colours */ }}
                >
                  <span>
                    <Icon name="twitch-coloured" />
                    Twitch Channel:
                    <span className="ml-2 link">{ league?.stages[0]?.league?.twitch  }</span>
                  </span>
                  <Icon name="external-primary-twitch" />
                </a>
              </div>
            </div>
          )
          :
          (
            <div className="row smaller-gutter-row">
              <div className="col-12">
                <a
                  href={league?.stages[0]?.tournament?.twitch}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="primary-bg-link"
                  style={{ background: "#110c24", color: "#883ef0", marginTop: 10 /* twitch brand colours */ }}
                >
                  <span>
                    <Icon name="twitch-coloured" />
                    Twitch Channel:
                    <span className="ml-2 link">{ league?.stages[0]?.league?.twitch }</span>
                  </span>
                  <Icon name="external-primary-twitch" />
                </a>
              </div>
            </div>
          )
        }
        {league.stages[0].stageType === "league" ? 
          <div className="row smaller-gutter-row pt-3">
            <div className="col-6 pb-2">
              <div className="pb-1 text-14 faded">Registered teams</div>
              <div className="text-14">{ league?.stages[0]?.league?.teams?.length }</div>
            </div>

            {league.country && (
              <div className="col-6 pb-2">
                <div className="pb-1 text-14 faded">Open for</div>
                <div className="text-14">
                  <img
                    src={`https://flagcdn.com/w20/${league?.stages[0]?.league?.country?.code?.toLowerCase()}.png`}
                    alt={league.stages[0].league?.country?.name}
                    className="league-country-icon mr-2"
                  />
                  {league?.stages[0]?.league?.country?.name}
                </div>
              </div>
            )}

            {league.matchDays && (
              <div className="col-6 pb-2">
                <div className="pb-1 text-14 faded">Match days</div>
                <div className="text-14" style={{ whiteSpace: "pre" }}>
                  {league.matchDays}
                </div>
              </div>
            )}
          </div>
          :
          <div className="row smaller-gutter-row pt-3">
            <div className="col-6 pb-2">
              <div className="pb-1 text-14 faded">Registered teams</div>
              <div className="text-14">{ league?.stages[0]?.tournament?.teams?.length }</div>
            </div>

            {league.country && (
              <div className="col-6 pb-2">
                <div className="pb-1 text-14 faded">Open for</div>
                <div className="text-14">
                  <img
                    src={`https://flagcdn.com/w20/${league?.stages[0]?.tournament?.country?.code?.toLowerCase()}.png`}
                    alt={league?.stages[0]?.tournament?.country?.name}
                    className="league-country-icon mr-2"
                  />
                  {league?.stages[0]?.tournament?.country?.name}
                </div>
              </div>
            )}

            {league.matchDays && (
              <div className="col-6 pb-2">
                <div className="pb-1 text-14 faded">Match days</div>
                <div className="text-14" style={{ whiteSpace: "pre" }}>
                  {league.matchDays}
                </div>
              </div>
            )}
          </div>
        }
          {/* <div className="row smaller-gutter-row player-overview-row pt-5">
            <div className="col-12 row-title">
              <span className="text-16 faded bold uppercase">Important dates</span>
            </div>
            <div className="col-12 row-content">
              <List>
                <DateListItem title="Registration for Pre-qualifiers closes" date="10 June, 2020 - 19:00" />
                <DateListItem title="Registration for Pre-qualifiers closes" date="10 June, 2020 - 19:00" />
                <DateListItem title="Registration for Pre-qualifiers closes" date="10 June, 2020 - 19:00" />
                <DateListItem title="Registration for Pre-qualifiers closes" date="10 June, 2020 - 19:00" />
              </List>
            </div>
          </div> */}

          <div className="row smaller-gutter-row player-overview-row pt-5">
            <div className="col-12 row-title">
              <span className="text-16 faded bold uppercase">Leaderboard</span>
            </div>
            <div className="col-12">
              <PrizesTable hideHeader={true} limit={4} teams={league.teams} prizes={league.prizes} />
            </div>
            <div className="col-12 row-content leaderboard-preview">
              <Link
                to="leaderboard"
                className="button wide-primary"
                style={shortName === "CSGO" ? { backgroundColor: "rgba(229, 93, 19,0.2)" } : null}
              >
                VIEW LEADERBOARD
              </Link>
            </div>
          </div>
        </div>
        {/* Right end */}
      </div>

      {/* <h3 className="text-16 faded uppercase pt-3 pb-2">Related leagues</h3>
      <div className="row smaller-gutter-row">
        {[...Array(3)].map((i) => (
          <LeagueItem
            key={i}
            title="Challenger Series Denmark"
            second_line={
              <React.Fragment>
                <img src={danish_flag} alt="Danish flag" />
                Denmark
              </React.Fragment>
            }
            third_line="64 teams perticipating"
            tag="1. Division"
            column="4"
            image={league_image}
            link_to="/league/1"
          />
        ))}
      </div> */}
    </div>
  );
};

export default Overview;
