import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SimpleTabs, SortableList, SortableListItem, Loading } from "@common";
import Axios from "axios";

const TopPlayers = ({ playerStats, teamId, page, players }) => {
  // const [page, setPage] = useState(page);
  const [loading, setLoading] = useState(true);
  const [topPlayers, setTopPlayers] = useState([]);

  console.log("playerStats-in dd players=", teamId, players);
  console.log("playerStats-in dd topPlayers", topPlayers);
  console.log("playerStats-in dd playerStats", playerStats);

  useEffect(() => {
    // Axios.get(`${process.env.REACT_APP_LOL_API}/api/leagues/${leagueId}/topPlayers`).then(({ data }) => {
    var playerStatsWe = playerStats.filter(({ team }) => team._id === teamId);
    console.log("playerStats-in dd playerStatsWe-", playerStatsWe);
    var teamplayerList =
      playerStatsWe.length > 0
        ? playerStatsWe.map((player) => {
            if (player.team._id === teamId) {
              player.kda = player.deaths === 0 ? Infinity : parseFloat((player.kills + player.assists) / player.deaths).toFixed(2);
              player.csPerMin = parseFloat(player.cs / (player.totalTimePlayed / 60)).toFixed(1);
              // player.playerdetail = player.player //players.filter(({player:singlePlayerIns}) => singlePlayerIns._id === player.player);
              return player;
            }
          })
        : [];
    setTopPlayers(teamplayerList);
    setLoading(false);
    // });
  }, [playerStats]);

  let pages = ["KDA", "CS PR MIN", "KILLS", "ASSISTS", "SCORE", "KILL PARTICIPATION"];
  let prop_names = ["kda", "csPerMin", "kills", "assists", "visionScore", "killParticipation"];
  console.log("topPlayers", topPlayers);
  return loading ? (
    <div className="text-center league-no-matches">
      <Loading width={50} height={50} />
    </div>
  ) : (
    <div>
      {/* <SimpleTabs pages={pages} page={page}  /> */}
      <div className="secondary-items">
        <SortableList
          config={[
            // { col: 1, title: "#", prop_name: "number", is_sortable: true, alignment: "left" },
            { col: 4, title: "Player", prop_name: "player", alignment: "left" },
            { col: 3, title: "Nationality", prop_name: "nationality", alignment: "left" },
            // { col: 2, title: "Team", prop_name: "team", alignment: "left" },
            { col: 1, title: "Role", prop_name: "position", is_sortable: true, alignment: "left" },
            { col: 2, title: "Maps Played", prop_name: "mapsPlayed", is_sortable: true, alignment: "right" },
            { col: 2, title: pages[page], prop_name: prop_names[page], is_sortable: true, alignment: "right" },
            // { col: 1, title: "Actions", prop_name: 'action', is_sortable: false, alignment: "right" },
          ]}
        >
          {topPlayers
            .sort((a, b) => b[prop_names[page]] - a[prop_names[page]])
            .map((player, i) => {
              const playerInnerData = player.player;

              return (
                <SortableListItem
                  // number={i + 1}
                  // team={
                  //   <React.Fragment>
                  //     <img src={player.team.logo} alt={player.team.name} className="image-40 border-radius-100" />
                  //     {player.team.name}
                  //   </React.Fragment>
                  // }
                  action={
                    playerInnerData && (
                      <React.Fragment>
                        <Link to={`/game-profile/${playerInnerData._id}`} className="text-12 primary-link">
                          Go to player
                        </Link>
                      </React.Fragment>
                    )
                  }
                  player={
                    playerInnerData ? (
                      <React.Fragment>
                        <Link to={`/game-profile/${playerInnerData._id}`} className="text-12 primary-link">
                          <img src={playerInnerData.avatarImage} alt={playerInnerData.name} className="image-40 border-radius-100" />
                        </Link>
                        {playerInnerData.name}
                      </React.Fragment>
                    ) : (
                      "[Deleted]"
                    )
                  }
                  position={player.role}
                  nationality={
                    playerInnerData && (
                      <>
                        <img
                          src={`https://flagcdn.com/w20/${playerInnerData.user.nationality.code?.toLowerCase()}.png`}
                          alt={playerInnerData.user.nationality.name}
                        />
                        <span className="text-14 uppercase bold">{playerInnerData.user.nationality.name}</span>
                      </>
                    )
                  }
                  // position_number={i % 3}
                  kda={player.kda === Infinity ? "Perfect" : player.kda}
                  csPerMin={player.csPerMin}
                  kills={player.kills}
                  assists={player.assists}
                  killParticipation={Math.round((player.kills + player.assists) / Math.max(1, player.teamTotalKills - player.kills)) + "%"}
                  visionScore={player.visionScore}
                  mapsPlayed={player.mapsPlayed}
                />
              );
            })}
        </SortableList>
      </div>
    </div>
  );
};

export default TopPlayers;
