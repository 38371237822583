import React, { useState, useContext } from "react";
import { SimpleTabs, TeamMemberListItem, List } from "@common";
import { Link } from "react-router-dom";
import { AppContext } from "@context/AppContext";
import MissingData from "../MissingData/MissingData";
import moment from "moment-timezone";
import SetPlayers from "./SetPlayers";
import useTranslated from "../../../../helpers/translationHelper";

const LineUp = ({
  gameData,
  tournamentCodes,
  datetime,
  match,
  setPlayersDeadline,
  teams = { t1: {}, t2: {} },
  setPlayers = { t1: [], t2: [] },
  setMatchData,
  tournamentCodesButton = null,
}) => {
  const visitProfileTrans = useTranslated('id_match_page_(lineup)_0')
  const missingdataTrans = useTranslated('id_match_page_(lineup)_1')
  const playersTrans = useTranslated('id_match_page_(lineup)_2')
  const substituteTrans = useTranslated('id_match_page_(lineup)_3')
  const coachesTrans = useTranslated('id_match_page_(lineup)_4')
  const staffTrans = useTranslated('id_match_page_(lineup)_5')
  const viewProfileTrans = useTranslated('id_match_page_(lineup)_6')
  const [page, setPage] = useState(0);
  let pages = [teams?.t1?.name || "[DELETED]", teams?.t2?.name || "[DELETED]"];
  const { user: authUser } = useContext(AppContext);

  // Deadline is 6 hours before match start (fixed at the moment) - and can't set players more than 2 weeks out
  const isWithinDeadline =
    moment(datetime).diff(moment(), "minutes") >= setPlayersDeadline && moment(datetime).diff(moment(), "hours") < 336;

  // Decide whether setting players is allowed...
  const showSetPlayers = () => {
    let teamMembers = teams[`t${page + 1}`]?.members || [];
    let isOrgOwner = false;
    let isTeamMember = false;

    if (teams[`t${page + 1}`]?.organisation?.owners.find(({ user }) => user === authUser.state._id)) {
      isOrgOwner = true;
    }

    if (
      teamMembers.find(
        ({ user, coach }) =>
          (user && user._id === authUser.state._id) ||
          (coach && typeof coach.user !== "object" && coach.user === authUser.state._id) ||
          (coach && typeof coach.user == "object" && coach.user._id === authUser.state._id)
      )
    ) {
      isTeamMember = true;
    }
    if (isWithinDeadline && (isOrgOwner || isTeamMember)) {
      return true;
    } else {
      return false;
    }
  };

  // if (!tournamentCodes || (!setPlayers.t1.length && !setPlayers.t2?.length)) {
  //   return (
  //     <>
  //       <div className="compare-players-tabs-container">
  //         <SimpleTabs pages={pages} page={page} setPage={setPage} />
  //         <Link className="button primary" style={{ paddingTop: "15px" }} to={`/teams/${teams[`t${page + 1}`]._id}`}>
  //           Visit Team Profile
  //         </Link>
  //       </div>
  //       <MissingData text="Once the team have set their players for the match, we will display their team data here on this page." />
  //     </>
  //   );
  // }

  const lineupData = [
    { role: /* "Players" */`${playersTrans}`, players: setPlayers[`t${page + 1}`]?.filter(({ role }) => !role.toLowerCase().includes("sub")) },
    { role: /* "Coaches" */`${coachesTrans}`, players: teams[`t${page + 1}`]?.members?.filter(({ coach }) => coach) },
    {
      role: /* "Staff" */`${staffTrans}`,
      players: teams[`t${page + 1}`]?.members?.filter(({ user }) => user),
    },
  ];

  // console.log("🚀 ~ file: LineUp.jsx ~ line 70 ~ lineupData", lineupData)
  return (
    <React.Fragment>
      <div className="compare-players-tabs-container">
        <SimpleTabs pages={pages} page={page} setPage={setPage} />
        <div className="d-flex row">
          {tournamentCodesButton}
          {/* <div className="col p-0 justify-content-center align-items-center"> */}
          {teams[`t${page + 1}`]?._id ? (
            <Link className={`button primary ${teams[`t${page + 1}`]?.placeHolder && "disabled-link"}`} style={{ paddingTop: "15px", marginRight: "30px" }} to={`/teams/${teams[`t${page + 1}`]._id}`} target="_blank">
              {/* Visit Team Profile */}{visitProfileTrans}
            </Link>
          ) : null}
          {/* </div> */}
        </div>
      </div>
      {!lineupData[0]?.players?.filter(({ player }) => !!player).length ? (
        <MissingData text={missingdataTrans}/* "Once the team have set their players for the match, we will display their team data here on this page." */ />
      ) : (
        lineupData.map(
          ({ role, players }) =>
            !!players?.length && (
              <div className="row mb-4">
                <div className="col-3">
                  <span className="text-faded uppercase bold">{role}</span>
                </div>

                <div className="col-9">
                  <List>
                    {players.map((singleMember) => {
                      const { _id, user, player, coach, role } = singleMember;
                      const posData = gameData.configuration.playerTypes.find(({ name }) => name === role);
                      if (player?._id || coach?._id || user?._id) {
                        return (
                          <TeamMemberListItem
                            key={_id}
                            img={player?.avatarImage || coach?.avatarImage || user?.avatarImage}
                            title={player?.name || coach?.name || `${user?.firstName} ${user?.lastName}`}
                            position={posData}
                            role={role}
                            nationality={
                              player ? player.user?.nationality : coach ? coach.user?.nationality : user ? user.nationality : false
                            }
                            actions={
                              [
                                <a
                                  href={
                                    player?._id
                                      ? "/game-profile/" + player?._id
                                      : coach?._id
                                        ? "/coaches/" + coach?._id
                                        : user?._id
                                          ? "/players/" + user?._id
                                          : false
                                  }
                                  className="text-14 primary-link"
                                >
                                  {/* View profile */}
                                  {viewProfileTrans}
                                </a>,
                              ]}
                          />
                        );
                      }
                    })}
                  </List>
                </div>
              </div>
            )
        )
      )}

      {showSetPlayers() && (
        <div>
          <SetPlayers
            {...match}
            setPlayersDeadline={setPlayersDeadline}
            teamData={teams[`t${page + 1}`]}
            match={match}
            teamSelector={`t${page + 1}`}
            updateMatch={(uMatch) => {
              let leagueOrTournament = uMatch.league || uMatch.tournament;
              let data = leagueOrTournament.game?.shortName == "CSGO" ? uMatch.csgoData : uMatch.lolData;
              let finalData;
              if (leagueOrTournament.game?.shortName == "CSGO") {
                finalData = { ...match, csgoData: data };
              } else {
                finalData = { ...match, lolData: data };
              }
              setMatchData(finalData);
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default LineUp;
