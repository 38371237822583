import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { CreateOrgModalContext } from "../CreateOrgModal";
import Loading from "../../../_common/Loading/Loading";
import { countries } from "@utils";
import useTranslated from "../../../../helpers/translationHelper";

const CreateOrg = () => {
  const { setOrgData, setStep, afterCreate } = useContext(CreateOrgModalContext);
  const { register, handleSubmit, errors } = useForm();
  const [nationalities, setNationalities] = useState([]);
  const [loading, setLoading] = useState(false);
  const labelCreateOrganisation =useTranslated("id_pop-up_-_create_organization_12")

  useEffect(() => {
    // Get list of nationalities from external API (europe only)
    // Axios.get("https://restcountries.eu/rest/v2/all?fields=demonym;alpha2Code", { headers: false }).then(({ data }) => {
    //   setNationalities(
    //     data
    //       .filter(({ demonym }) => demonym)
    //       .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    //       .sort((a, b) => a.demonym.localeCompare(b.demonym))
    //   );
    // });
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const { data: newOrg } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations`, data);
      toast.success("Successfully created organisation");
      setOrgData(newOrg.organisation);
      afterCreate(newOrg.organisation);
      setLoading(false);
      setStep(1);
    } catch (e) {
      const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem creating the organisation";
      setLoading(false);
      toast.error(msg);
    }
  };

  return (
    <div>
      <div className="modal-header">
        <h2>{useTranslated("id_pop-up_-_create_organization_0")/* Create an organisation */}</h2>
        <h3>{useTranslated("id_pop-up_-_create_organization_1")/* An organization can be used to structure and manage teams across different games. */}</h3>
      </div>

      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <label htmlFor="name">{useTranslated("id_pop-up_-_create_organization_2")/* Organisation name */}</label>
            <input onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
              ref={register({ required: useTranslated("id_pop-up_-_create_organization_9" /* Required */), maxLength: { value: 200, message: "Name too long" } })}
              type="text"
              id="name"
              placeholder={useTranslated("id_pop-up_-_create_organization_3")}/* "Organisation name" */
              name="name"
              autoComplete="org-name"
              className={`form-input ${errors.name ? "invalid" : ""}`}
            />
            {errors.name && <div className="form-input-error">{errors.name.message}</div>}
          </div>

          <div className="row form-row sm-gutters">
            <div className="col-6">
              <label htmlFor="nationality">{useTranslated("id_pop-up_-_create_organization_4")/* Nationality */}</label>
              <select
                ref={register({ required: useTranslated("id_pop-up_-_create_organization_9" /* Required */) })}
                id="nationality"
                name="nationality"
                className={`form-input ${errors.nationality ? "invalid" : ""}`}
                defaultValue=""
              >
                <option value="" disabled>
                  Choose nationality
                </option>
                {nationalities.map(({ alpha2Code, demonym }) => (
                  <option key={alpha2Code} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
                    {demonym}
                  </option>
                ))}
              </select>
              {errors.nationality && <div className="form-input-error">{errors.nationality.message}</div>}
            </div>

            <div className="col-6">
              <label htmlFor="website">{useTranslated("id_pop-up_-_create_organization_5")/* Website (optional) */}</label>
              <input onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register()}
                type="text"
                id="website"
                name="website"
                placeholder={useTranslated("id_pop-up_-_create_organization_6")}/* "Leagues.gg" */
                autoComplete="website"
                className={`form-input ${errors.website ? "invalid" : ""}`}
              />
              {errors.website && <div className="form-input-error">{errors.website.message}</div>}
            </div>
          </div>

          <div className="form-row">
            <label htmlFor="description">{useTranslated("id_pop-up_-_create_organization_7")/* Organisation description (max 200 words) */}</label>
            <textarea
              ref={register({ maxLength: { value: 2000, message: "Organisation description too long" } })}
              type="text"
              max={2000}
              rows={4}
              id="description"
              name="description"
              autoComplete="description"
              placeholder={useTranslated("id_pop-up_-_create_organization_8")}/* "Write something about the organisation" */
              className={`form-input ${errors.description ? "invalid" : ""}`}
            />
            {errors.description && <div className="form-input-error">{errors.description.message}</div>}
          </div>

          <button type="submit" className="button primary thicc" disabled={loading}>
            {loading ? (
              <>
                <Loading width={30} height={30} /> {labelCreateOrganisation/* Creating Organisation */}
              </>
            ) : (
              "Create Organisation"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateOrg;
