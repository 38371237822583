import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { AppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import Loading from "@common/Loading/Loading";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import useTranslated from "../../helpers/translationHelper";

const ChangePassword = () => {
  // const { email, token } = useParams();
  const history = useHistory();
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  // const [invalidToken, setInvalidToken] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [newPasswordAgainVisible, setNewPasswordAgainVisible] = useState(false);
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  const toggleOldPswdVisibility = () => setOldPasswordVisible(!oldPasswordVisible);
  const toggleNewPswdVisibility = () => setNewPasswordVisible(!newPasswordVisible);
  const toggleNewPswdAgainVisibility = () => setNewPasswordAgainVisible(!newPasswordAgainVisible);

  const changePassTrans = useTranslated('id_settings_(change_password)_0')
  const oldPassTrans = useTranslated('id_settings_(change_password)_1')
  const oldPassPlaceTrans = useTranslated('id_settings_(change_password)_2')
  const newPassTrans = useTranslated('id_settings_(change_password)_3')
  const newPassPlaceTrans = useTranslated('id_settings_(change_password)_4')
  const newPassAgainTrans = useTranslated('id_settings_(change_password)_5')
  const newPassAgainPlaceTrans = useTranslated('id_settings_(change_password)_6')
  const resetPassTrans = useTranslated('id_settings_(change_password)_7')

  const passMessageTrans = useTranslated('id_settings_(message)_0')
  const onSubmit = async (e) => {
    e.preventDefault();
    setRequestLoading(true);
    setLoading(false)
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/changePassword`, {
        email: user.state.email,
        oldPassword,
        newPassword,
        newPasswordAgain,
      });

      toast.success(/* "Password change successful! From now you can log in with your new password" */passMessageTrans);
      history.push("/");
    } catch (error) {
      setRequestLoading(false);

      let msg = "Something went wrong.";
      if (error.response && error.response.data) msg = error.response.data.msg;
      toast.error(msg);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="center">
          <Loading className="center" />
        </div>
      ) : (
        <div className="container-fluid resetpass">
          <div className="row">
            <div className="col-6 offset-3">
              <h2>{/* Change Password */}{changePassTrans}</h2>

              <form onSubmit={onSubmit} className="form">
                <div className="form-row">
                  <label for="email">{/* Old Password */}{oldPassTrans}</label>
                  <input
                    type={oldPasswordVisible ? "text" : "password"}
                    className="form-input"
                    id="old-password"
                    autoComplete="old-password"
                    placeholder=/* "Old password" */{oldPassPlaceTrans}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  <div onClick={toggleOldPswdVisibility} className="toggle-pswd-visibility">
                    {oldPasswordVisible ? <RiEyeOffLine size="1.2rem" color="#666" /> : <RiEyeLine size="1.2rem" color="#666" />}
                  </div>
                </div>

                <div className="form-row">
                  <label for="email">{/* New Password */}{newPassTrans}</label>
                  <input
                    type={newPasswordVisible ? "text" : "password"}
                    className="form-input"
                    id="new-password"
                    autoComplete="new-password"
                    placeholder=/* "New password" */{newPassPlaceTrans}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <div onClick={toggleNewPswdVisibility} className="toggle-pswd-visibility">
                    {newPasswordVisible ? <RiEyeOffLine size="1.2rem" color="#666" /> : <RiEyeLine size="1.2rem" color="#666" />}
                  </div>
                </div>

                <div className="form-row">
                  <label for="email">{/* New Password Again */}{newPassAgainTrans}</label>
                  <input
                    type={newPasswordAgainVisible ? "text" : "password"}
                    className="form-input"
                    id="new-password-again"
                    autoComplete="new-password-again"
                    placeholder=/* "Confirm password" */{newPassAgainPlaceTrans}
                    value={newPasswordAgain}
                    onChange={(e) => setNewPasswordAgain(e.target.value)}
                  />
                  <div onClick={toggleNewPswdAgainVisibility} className="toggle-pswd-visibility">
                    {newPasswordAgainVisible ? <RiEyeOffLine size="1.2rem" color="#666" /> : <RiEyeLine size="1.2rem" color="#666" />}
                  </div>
                </div>

                <button type="submit" className="button primary" disabled={requestLoading}>
                  {requestLoading ? <Loading /> : /* "Reset Password" */`${resetPassTrans}`}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
