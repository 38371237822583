import React from "react";
import { Link } from "react-router-dom";
import default_profile_pic from "@img/default-team-member.png";
import RankData from "./includes/RankData";
import RankDataCSGO from "./includes/RankDataCSGO";

const GameProfileList = ({ gameIcon, gameName, players = [] }) => {
  return (
    <div className="game-profile-list">
      {/* <div className="title text-16 uppercase bold">
        <img src={gameIcon} alt={gameName} /> {gameName}
      </div> */}
      <div className="game-profile-list-th">
        <div className="row tiny-gutter-row">
          <div className="col-2">
            <span className="text-12 faded bold uppercase">Name</span>
          </div>
          <div className="col-2">
            <span className="text-12 faded bold uppercase">Game</span>
          </div>
          <div className="col-2">
            <span className="text-12 faded bold uppercase">{gameName !== "League of Legends" ? `Division` : `Ranked solo`}</span>
          </div>
          <div className="col-3">
            <span className="text-12 faded bold uppercase">{gameName !== "League of Legends" ? `Team` : `Ranked flex`}</span>
          </div>
          <div className="col-2">
            <span className="text-12 faded bold uppercase">Role</span>
          </div>
        </div>
      </div>

      {players.map(({ _id, name, avatarImage, game, primaryRole }) => {
        const getLaneIcon = (role = "") => {
          const data = game.configuration.playerTypes.find(({ name }) => role.includes(name));

          return data ? data.icon : false;
        };

        return (
          <div className="game-profile-list-item">
            <div className="row tiny-gutter-row">
              <div className="col-2">
                <img src={avatarImage || default_profile_pic} className="game-profile-lg-image" alt="" />
                <span className="text-12 bold">{name}</span>
              </div>

              <div className="col-2">
                <div className="game-profile-sm-image" style={{ backgroundImage: `url(${gameIcon})` }} />
                <span className="text-12 bold">{gameName}</span>
              </div>

              {gameName.toLowerCase() !== "league of legends" ? <RankDataCSGO playerId={_id} /> : <RankData playerId={_id} />}

              <div className="col-2">
                {primaryRole ? (
                  <>
                    <img src={getLaneIcon(primaryRole)} className="position-icon" alt="" width="20" />
                    <span className="text-12 uppercase bold">{primaryRole}</span>
                  </>
                ) : (
                  "No role"
                )}
              </div>

              <div className="col-1 align-right">
                <Link to={`/game-profile/${_id}`} className="text-12 primary-link">
                  View Profile
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GameProfileList;
