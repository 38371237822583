import React from "react";
import { Editor, RichUtils, EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import { FaBold, FaUnderline, FaItalic, FaHeading, FaQuoteLeft, FaListOl, FaListUl } from "react-icons/fa";

export class RichEditor extends React.Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  componentDidMount() {
    const blocksFromHTML = convertFromHTML(this.props.defaultValue);
    const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

    this.setState({ editorState: EditorState.createWithContent(content) });
  }

  onChange = (editorState) => {
    this.setState({ editorState });
    this.props.onChange(this.refs.editor.editor.innerHTML);
  };

  focus = () => this.refs.editor.focus();

  handleKeyCommand = (command) => {
    const editorState = this.state.editorState;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  onTab = (e) => {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  };
  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };
  toggleInlineStyle = (inlineStyle) => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  };
  render() {
    const editorState = this.state.editorState;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }
    return (
      <div className="RichEditor-root">
        <InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
        <BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
        <div className={className} onClick={this.focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onTab={this.onTab}
            ref="editor"
            placeholder={this.props.placeholder}
            spellCheck={true}
          />
        </div>
      </div>
    );
  }
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  {
    label: (
      <>
        <FaHeading />
        <small> 1</small>
      </>
    ),
    style: "header-one",
  },
  {
    label: (
      <>
        <FaHeading />
        <small> 2</small>
      </>
    ),
    style: "header-two",
  },
  {
    label: (
      <>
        <FaHeading />
        <small> 3</small>
      </>
    ),
    style: "header-three",
  },
  {
    label: (
      <>
        <FaHeading />
        <small> 4</small>
      </>
    ),
    style: "header-four",
  },
  {
    label: (
      <>
        <FaHeading />
        <small> 5</small>
      </>
    ),
    style: "header-five",
  },
  {
    label: (
      <>
        <FaHeading />
        <small> 6</small>
      </>
    ),
    style: "header-six",
  },
  { label: <FaQuoteLeft />, style: "blockquote" },
  { label: <FaListUl />, style: "unordered-list-item" },
  { label: <FaListOl />, style: "ordered-list-item" },
];
const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type, i) => (
        <StyleButton key={i} active={type.style === blockType} label={type.label} onToggle={props.onToggle} style={type.style} />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  { label: <FaBold />, style: "BOLD" },
  { label: <FaItalic />, style: "ITALIC" },
  { label: <FaUnderline />, style: "UNDERLINE" },
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type, i) => (
        <StyleButton key={i} active={currentStyle.has(type.style)} label={type.label} onToggle={props.onToggle} style={type.style} />
      ))}
    </div>
  );
};
