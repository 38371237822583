import React, { useEffect, useState } from "react";
import moment from "moment";
import default_image from "@img/image.jpg";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
import { applyBrowserTimezone } from "../../../../utils/time-functions";
import { allCountries } from "../../../../utils/countriesWithCode";
import { Loading } from "@common";
import useTranslated from "../../../../helpers/translationHelper";

const MatchRowForLeagueBox = ({ match, needmstatuss, leagueDivisionName, leagueCountry, league }) => {
  const [matchData, setMatchData] = useState({});
  const [loading, setLoading] = useState(true);
  let _VS_match_translation = useTranslated('id_match_display_4')


  useEffect(() => {
    if (Object.keys(match).length) {
      setMatchData(match);
      setLoading(false);
    }
  }, [match]);

  const ordinalSuffixOf = (i) => {
    let j = i % 10,
      k = i % 100;

    if (j === 1 && k !== 11) return i + "st";
    if (j === 2 && k !== 12) return i + "nd";
    if (j === 3 && k !== 13) return i + "rd";

    return i + "th";
  };

  const leagueTable = league ? league?.teams?.sort((a, b) => b.score - a.score) : [];
  const t1TablePos = !matchData.t1 ? "N/A" : ordinalSuffixOf(leagueTable?.findIndex(({ team }) => team === matchData.t1._id) + 1);
  const t2TablePos = !matchData.t2 ? "N/A" : ordinalSuffixOf(leagueTable?.findIndex(({ team }) => team === matchData.t2._id) + 1);

  return (
    <div className="matchDetail">
      {loading ? (
        <div className="col-12 text-center">
          <Loading />
        </div>
      ) : (
        <Link className="text-decoration-none" to={`/match/${matchData._id}`}>
          <div className="matchDetail-date-country-division m-0 row">
            <div className="matchDetail-date col-5 p-0">
              <Tippy
                content={
                  applyBrowserTimezone(matchData.datetime).format("D MMM YYYY, HH:mm") + applyBrowserTimezone(matchData.datetime).zoneAbbr()
                }
                placement="right"
                animation="shift-toward"
              >
                <span>
                  {applyBrowserTimezone(matchData.datetime).format("D MMM YYYY, HH:mm")}{" "}
                  {applyBrowserTimezone(matchData.datetime).zoneAbbr()}
                </span>
              </Tippy>
            </div>
            {/**10 June 2020, 19:00 */}
            <div className="matchDetail-country col-3 p-0">
              {
                leagueCountry && <>
                  <img
                    src={`https://flagcdn.com/w20/${leagueCountry?.code?.toLowerCase()}.png`}
                    width={18}
                    height={13}
                    alt={leagueCountry?.name}
                    className="ml-2"
                  />
                <span style={{ marginLeft: "5px" }}>{allCountries[leagueCountry.code]}</span>
                </>
              }
            </div>
            <div className="matchDetail-division col-4 p-0">{leagueDivisionName}</div>
          </div>

          <div className="matchDetail-team-vs-team m-0 row">
            <div className="matchDetail-team-score score-left col-2 p-0">
              {t1TablePos}
              {/* <span className="innert-score inner-score-left">1.11</span> */}
            </div>
            <div className="matchDetail-team-vs col-8 p-0">
              <div className="team-vs-team m-0 row">
                <div className="team-detail team-d-left p-0 float-left col-4">
                  <div>
                    <img
                      src={(matchData.t1 && matchData.t1.logo) || default_image}
                      className={`team-detail-img ${matchData.t1?.logoCropped || matchData.t1?.logoCropped === undefined ? "border-radius-100" : ""
                        }`}
                    />
                  </div>
                  <div className="team-name-with-dots">{(matchData.t1 && matchData.t1.name) || "Deleted"}</div>
                </div>
                {needmstatuss === "complete" ? (
                  <div class="col-4 pill large small frontend-result-row  m-0 primary">
                    {matchData.t1Score} <span>-</span> {matchData.t2Score}
                  </div>
                ) : (
                  <div className="vs-text col-4">{_VS_match_translation}</div>
                )}

                <div className="team-detail team-d-right p-0 float-right col-4">
                  <div>
                    <img
                      src={(matchData.t2 && matchData.t2.logo) || default_image}
                      className={`team-detail-img ${matchData.t2?.logoCropped || matchData.t2?.logoCropped === undefined ? "border-radius-100" : ""
                        }`}
                    />
                  </div>
                  <div className="team-name-with-dots">{(matchData.t2 && matchData.t2.name) || "Deleted"}</div>
                </div>
              </div>
            </div>
            <div className="matchDetail-team-score score-right col-2 p-0">
              {t2TablePos}
              {/* <span className="innert-score inner-score-right">1.41</span> */}
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default MatchRowForLeagueBox;
