import React from "react";

const ImageWithContent = ({isOdd=true,Heading='Loading...',SubHeading="Loading...",Image=null, Description='Loading...'}) => {
return (
    <div className="imageWithContentSection row smaller-gutter-row image-with-content-area" style={{flexDirection: isOdd ? `row-reverse` : `row`}}>
            
        <div className="col-6 content-section">
            <div className="content-inner">
                <span className="subheading">{SubHeading}</span>
                <p className="heading">{Heading}</p>
                <p className="para">{Description}</p>
            </div>
        </div>

        <div className="col-6 image-section" style={{backgroundImage:`url('${Image}')`, backgroundRepeat:`no-repeat`}}>
        </div>

    </div>
)
}

export default ImageWithContent;