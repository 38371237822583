import React, { useState } from "react";
import { Button, Icon } from '@common';
import Loading from "../../../_common/Loading/Loading";
import './_veto.scss'
import { useEffect } from "react";
import { Timer } from '@common';


const VetoCard = ({ vetoState, timeLeft, onTimerComplete, onReady, pickSide, canUserSelect, canPerform }) => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(false);
    }, [vetoState])
    return (
        <div className={`col-8 veto-wrapper ${vetoState}`} >
            <div className="header">{card[vetoState].title}</div>
            <div className="text">{card[vetoState].text}</div>
            {vetoState === 'open' && canPerform ? !loading ?
                <Button onClick={() => {
                    setLoading(true)
                    onReady()
                }}
                    disabled={loading} name="open-button my-4">
                    Ready
                </Button>
                :
                <> <Loading width={30} height={70} /> Please Wait </>
                :
                vetoState === 'picking' ? !loading ?
                    <div>
                        <Button onClick={() => { setLoading(true); pickSide("A"); }} disabled={loading || !canUserSelect} name={`picking-button my-4 ${!canUserSelect ? "faded-btn" : ''}`}>
                            Pick Team A
                        </Button>
                        <Button onClick={() => { setLoading(true); pickSide("B"); }} disabled={loading || !canUserSelect} name={`picking-button my-4 ml-1 ${!canUserSelect ? "faded-btn" : ''}`}>
                            Pick Team B
                        </Button>
                    </div>
                    :
                    <> <Loading width={30} height={70} /> Please Wait </>
                    :
                    <div style={{ height: 70 }} />
            }
            {timeLeft ? <Timer key={timeLeft} time={timeLeft} onTimeComplete={onTimerComplete} style={{justifyContent: "center"}}></Timer> : null}
        </div>
    )
}

export default VetoCard

const card = {
    open: {
        title: "Veto is open!",
        text: "When you are ready for veto, press the “Ready” button. The one from your team who presses the “Ready” button is the decision maker in veto.",
    },
    waiting: {
        title: "Waiting for Veto to open",
        text: "Please wait for veto to open. Once both teams have joined, you will start to pick to maps.",
    },
    loading: {
        title: "VETO IS LOADING… GET READY!",
        text: "Both teams are ready. When veto starts, you will start banning maps, be ready!"
    },
    opponent: {
        title: "Waiting for opponent team",
        text: "Please wait while your opponent team joins veto. Once the team joins, you will start picking maps."
    },
    picking: {
        title: "PICK TEAM A OR B",
        text: "Team A starts banning in veto. Since you’re the highest seeded of the both teams, you get to decide who starts in veto."
    },
    waitforopponent: {
        title: "Opponent team is choosing priority",
        text: "Since your opponent team is the highest seed team, they get to decide who goes first in veto."
    }, 
    steps: {
        title: "get ready",
        text: "steps will start soon"
    },
    admin: {
        title: "Waiting for the teams to get Ready",
        text: "Once both teams are ready, you will see the veto process"
    },
}