import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AppContext } from "@context/AppContext";
import { WidePageWithTabs } from "@common";
import { About, Members, TeamStats, Matches } from "./TeamsSingleTabs";
import Axios from "axios";
import { Loading } from "@common";
import { FaExternalLinkAlt } from "react-icons/fa";
import useTranslated from "../../../helpers/translationHelper";

const TeamsSingle = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const { user, authenticated, selectedGame, setSelectedGame } = useContext(AppContext);
  const [team, setTeam] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  const lableManageTeam=useTranslated("id_team_page_0")
  const lableGoToOrganisation=useTranslated("id_team_page_1")
  const lableOverview=useTranslated("id_team_page_2")
  const lableMembers=useTranslated("id_team_page_3")
  const lableTeamStas=useTranslated("id_team_page_4")
  const lableMatches=useTranslated("id_team_page_5")

  useEffect(() => {
    setTeam(false);
    setLoading(true);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/${id}`)
      .then(({ data }) => {
        if (data.game.shortName !== selectedGame.shortName) {
          setSelectedGame(data.game);
          return window.location.reload();
        } else {
          setTeam(data);
          setLoading(false);
        }
      })
      .catch(() => {
        history.push("/404");
      });
  }, [id, history, reload]);

  // TABS CONFIGURATION
  // pages starts empty, and only if the tabs are set as not hidden we will add pages, and their page content to the pages object
  if (!loading && team) {
    const pages = {};

    const isOwner =
      team &&
      authenticated &&
      team.members &&
      (team.members.find(
        ({ user: userData, role }) => userData && user.state._id === userData._id && ["Owner", "Manager"].includes(role)
      ) ||
        (team.organisation && team.organisation.owners.find(({ user: userData }) => userData && userData._id === user.state._id)) ||
        user.state.admin);
    const isManager =
      team &&
      authenticated &&
      (team.members.find(({ user: userData, role }) => userData && user.state._id === userData._id && ["Manager"].includes(role)) ||
        user.state.admin);

    const actions = [isOwner ? { title:lableManageTeam /* "Manage team" */, action: () => history.push(`/teams/${id}/manage`) } : false];

    const pageContent = {
      /* Overview */[lableOverview]: (
        <About
          about={team.about}
          leaguesParticipating={team.leaguesParticipating}
          leaguesParticipatingHistory={team.leaguesParticipatingHistory}
          tournamentParticipating={team.tournamentParticipating}
          tournamentParticipatingHistory={team.tournamentParticipatingHistory}
          organisation={team.organisation}
          recentMatches={team.recentMatches}
          team={team}
          onReload={() => setReload(!reload)}
        />
      ),
      /* Members */[lableMembers]: <Members team={team} isOwner={isOwner} isManager={isManager} />,
      /* "Team Stats" */[lableTeamStas]: <TeamStats team={team} />,
      /* Matches */[lableMatches]: <Matches team={team} />,
    };

    team.profileConfig.tabs.forEach(({ name, hide }) => {
      if (!hide) {
        // debugger
        if (name==="About"){
          pages[name == "About" ? lableOverview/* "Overview" */ : name] = pageContent[name == "About" ? lableOverview /* "Overview"  */ : name];
        }if (name==="Members"){
          pages[name == "Members" ? lableMembers/* "Overview" */ : name] = pageContent[name == "Members" ? lableMembers /* "Overview"  */ : name];

        }if (name==="Team Stats"){
          pages[name == "Team Stats" ? lableTeamStas/* "Overview" */ : name] = pageContent[name == "Team Stats" ? lableTeamStas /* "Overview"  */ : name];

        }if (name==="Matches"){
          pages[name == "Matches" ? lableMatches/* "Overview" */ : name] = pageContent[name == "Matches" ? lableMatches /* "Overview"  */ : name];

        }
      }
    });

    return loading ? (
      <Loading width={27} height={27} />
    ) : (
      <WidePageWithTabs
        key={lableOverview}
        pages={pages}
        croppedLogo={team.logoCropped}
        base_route={`/teams/${id}`}
        showLogo={team.profileConfig.logoInBanner}
        title={team.name + ` (${team.initials})`}
        label={team.tagline}
        actions={actions}
        cover_image={team.banner}
        logo={team.logo || team.game.defaultTeamLogo}
        fullLogo={team.originalLogo}
        type="teams"
        isPageBuilderEnabled={false}
        game={team.game}
        nationality={team.nationality}
        tab_link={
          team.organisation && team.organisation ? (
            <a href={team.organisation && team.organisation ? `/organisation/${team.organisation._id}` : "#"}>
              {lableGoToOrganisation/* Go to organisation */} <FaExternalLinkAlt />
            </a>
          ) : undefined
        }
      />
    );
  } else {
    return (
      <div className="text-center mt-3">
        <Loading width={50} height={50} />
      </div>
    );
  }
};

export default TeamsSingle;
