import { SET_ALL_LANGUAGES, SET_LANGUAGE } from "../action/types";
const initialState = {
  Selected: {},
  AllLanguages: []
};

// Use the initialState as a default value
export default function selectLanguageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        Selected: action.payload,
      };
    case SET_ALL_LANGUAGES:
      return {
        ...state,
        AllLanguages: action.payload
      }

    default:
      return state;
  }
}
