import React, { useRef } from "react";
import { Icon } from "@common";
import { FiX } from "react-icons/fi";

const SearchInput = ({ button_label, onChange, value, placeholder = null, hideButton = false, style, inputStyle }) => {
  const queryRef = useRef();

  return (
    <div className="finder-search-wrapper" style={style}>
      <div className="search-icon-container">{!hideButton && <Icon name="search" />}</div>
      <input
        ref={queryRef}
        onBlur={(e) => {
          e.target.value = e.target.value.trim();
        }}
        type="text"
        placeholder={placeholder || "Search players, ranks, roles and rank"}
        onChange={onChange}
        value={value}
        style={inputStyle}
      />
      {hideButton ? (
        value.length !== 0 ? (
          <FiX
            className="input-icon"
            onClick={() => {
              onChange({ target: { value: "" } });
              queryRef.current.focus();
            }}
            style={{ marginRight: "10px" }}
          />
        ) : (
          <Icon name="search" style={{ marginRight: "10px" }} />
        )
      ) : (
        <button className="uppercase bold">{button_label ?? "Search"}</button>
      )}
    </div>
  );
};

export default SearchInput;
