import Axios from "axios";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Loading } from "../_common";

const SummonerRedirect = () => {
  const { accountId } = useParams();
  const history = useHistory();

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/players/byAccountId/${accountId}`)
      .then(({ data }) => {
        const gameProfileId = data._id;

        if (gameProfileId) history.replace(`/game-profile/${gameProfileId}`);
        else history.replace("/error");
      })
      .catch(() => {
        history.replace("/error");
      });
  }, [history, accountId]);

  return (
    <div className="center" style={{ margin: 50 }}>
      <Loading />
      <p>Redirecting...</p>
    </div>
  );
};

export default SummonerRedirect;
