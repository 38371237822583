import React from "react";
import { Link } from "react-router-dom";

const UserPlayers = ({ players = [] }) => {
  return players.length ? (
    <div className="user-profile-players">
      <h2>Players</h2>

      {players.map(({ _id, game, name }) => (
        <Link key={_id} to={`/players/${_id}`} className="profile-player">
          <h3>{game.name} Profile</h3>
          <p>{name}</p>
        </Link>
      ))}
    </div>
  ) : null;
};

export default UserPlayers;
