import React, { useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../_common/Loading/Loading";
import { useHistory } from "react-router-dom";
import { FaSteam } from "react-icons/fa";
const ConnectCSGO = ({ gameData, afterConnect }) => {

  return (
    <>
      <a
        href={`${process.env.REACT_APP_CSGO_API}/authenticate`}
        class="steambutton">
        <span>Connect to Steam</span>
        <div class="icon">
          <FaSteam />
        </div>
      </a>
    </>
  );
};

export default ConnectCSGO;
