import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { GameItem, Button, Item, SliderItemFrontEnd, MirrorText, SimplePageWithTabs } from "@common";
import { Link } from "react-router-dom";
import default_image from "@img/image.jpg";
import GlitchClip from "react-glitch-effect/core/Clip";
import SliderFrontend from "../Slider/SliderFrontend";
import LeagueBoxForMatches from "./includes/LeagueBoxForMatches";
import { AppContext } from "../../../context/AppContext";
import { allCountries } from "../../../utils/countriesWithCode";

export const ItemSingle = ({ needstatus, leagues = [], filterContry = null, results }) => {
  const [leaguesSorted, setLeaguesSorted] = useState([]);
  useEffect(() => {
    if (filterContry === null) {
      setLeaguesSorted(leagues);
    } else {
      setLeaguesSorted(leagues.filter(({ country }) => allCountries[country.code] === filterContry));
    }
  }, [leagues, filterContry]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="tabs-container">
          <div className="row smaller-gutter-row">
            {leaguesSorted.map((league, i) => {
              if (i > 3) return;
              return <LeagueBoxForMatches needmstatus={needstatus} league={league} results={results} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const UpcomingMatchesSeason = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [leagues, setLeagues] = useState([]);
  const [contries, setContries] = useState([]);
  const [filterContry, setFilterContry] = useState(null);
  const [selectedLeagues, setSelectedLeagues] = useState(null);
  const [blueText, setBlueText] = useState("Loading...");
  const [whiteText, setWhiteText] = useState("Loading...");
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [seasons, setSeasons] = useState([]);
  const [includeAllWithSameName, setIncludeAllWithSameName] = useState(false);

  const tabs = {
    "ongoing & upcoming": <ItemSingle needstatus="upcoming" leagues={leagues} filterContry={filterContry} />,
    Results: <ItemSingle needstatus="complete" leagues={leagues} filterContry={filterContry} results />,
  };

  useEffect(() => {
    if (data.BlueText) {
      setBlueText(data.BlueText);
    }
    if (data.WhiteText) {
      setWhiteText(data.WhiteText);
    }
    if (data.season && data.season.length) {
      setSelectedSeason(data.season.map(({ _id }) => _id).toString());
    }
    if (data.includeAllWithSameName) {
      setIncludeAllWithSameName(data.includeAllWithSameName);
    }
  }, [data]);
  // console.log('seasons-',selectedSeason)
  useEffect(() => {
    if (selectedSeason) {
      if (includeAllWithSameName) {
        // console.log('macthsssss iff')
        selectedSeason &&
          Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/getSeasonsWithSameNameYear/${selectedSeason}`, {
            params: { withMatches: true },
          }).then(({ data }) => {
            let dataleagues = [];
            let dataleagueCountries = [];
            console.log("upcoming matches for same season -", data);
            data.map((sesSing) => {
              dataleagues.push(sesSing.leagues);
            });
            // console.log('dataleagues-',dataleagues)

            dataleagues[0].map(({ country }) => {
              if(country){
                if (!dataleagueCountries.includes(allCountries[country.code])) {
                  dataleagueCountries.push(allCountries[country.code]);
                }
              }
            });

            setLeagues(dataleagues[0]);
            setContries(dataleagueCountries);
            setLoading(false);
          });
      } else {
        // console.log('macthsssss else')
        selectedSeason &&
          Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/findBySeason/${selectedSeason}`, {
            params: { withMatches: true },
          }).then(({ data }) => {
            let dataleagueCountries = [];
            data.map(({ country }) => {
              if (!dataleagueCountries.includes(allCountries[country.code])) {
                dataleagueCountries.push(allCountries[country.code]);
              }
            });
            setContries(dataleagueCountries);

            setLeagues(data);
            setLoading(false);
          });
      }
    }
  }, [selectedSeason]);

  return (
    <div className="UpcomingMatchesSeason">
      {!loading && (
        <SimplePageWithTabs
          title={
            <div className="maintitle">
              <h1>
                <span className="outline">{blueText}</span> {whiteText}
              </h1>
            </div>
          }
          tabs={tabs}
          tabs_props={{
            show_filter: true,
            custom_filter: (
              <>
                <select onChange={(e) => setFilterContry(e.target.value === "ALL" ? null : e.target.value)} className="form-input matchesDropdown">
                  <option selected hidden>
                    FILTER
                  </option>
                  <option disabled>FILTER</option>
                  {/* <option disabled selected>
                    FILTER
                  </option> */}
                  <option>ALL</option>
                  {contries &&
                    contries.length &&
                    contries.map((country) => {
                      return <option selected={filterContry === country}>{country}</option>;
                    })}
                </select>
              </>
            ),
          }}
        />
      )}
    </div>
  );
};

export default UpcomingMatchesSeason;
