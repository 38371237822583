import React, { useState, useEffect, useContext } from "react";
import Step from "./Step";
import './_steps.scss'
import StepTabs from  "./StepTabs";

const VetoSteps = ({ gameData, matchData, canUserSelect, vetoData, maps, onMapAction, setTeamVetoState, vetoEnded, setLastStepCompletionTime }) => {
  const stepTabLabels = {"BAN": "Ban Map", "PICK" : "Pick Map", "SIDE_PICK": "Pick Side"}
  const [timer, stepTimer] = useState(0)
  const [turn, setTurn] = useState(true)
  const [mapsArray, setMapsArray] = useState(maps)
  const [veto, setVeto] = useState(vetoData)
  const [currentStep, setCurrentStep] = useState(vetoData && vetoData.currentStep ? vetoData.currentStep : 1)
  const [stepData, setStepData] = useState(vetoData && vetoData.steps && vetoData.currentStep ? vetoData.steps[currentStep -1] : [])



  useEffect(() => {
    setMapsArray(maps)
  }, [maps])

  useEffect(() => {
    setVeto(vetoData)
    vetoData.currentStep && setCurrentStep(vetoData.currentStep)
  }, [vetoData])

  useEffect(() => {
    if (veto.steps && currentStep <= veto.steps.length) {
      let step = veto.steps[currentStep - 1]
      if (step.action && step.action.toLowerCase() == "side_pick") {
        let previousStep = veto.steps[currentStep - 2]
        step.map = previousStep.map 
      }
      step && setStepData(step)
    } 
  }, [currentStep, vetoData])

 
  const goToNextStep = () => {
    // setCurrentStep(currentStep + 1)
    // setTurn(true)
  }
  const stepTabs = []
  veto && veto.steps.map(step => {
        stepTabs.push({"title" : stepTabLabels[step.action], "content" : <Step  setLastStepCompletionTime={setLastStepCompletionTime} maps={mapsArray} setTeamVetoState={setTeamVetoState} type={step.action.toLowerCase()} canUserSelect = {canUserSelect} matchData={matchData} stepData={stepData} onMapAction={onMapAction} gameData={gameData} title={stepTabLabels[step.action]} goToNextStep={goToNextStep} isTurn={turn} vetoData={veto} vetoEnded={vetoEnded}/>, "number" : step.no})
      
  })
  stepTabs.push({"title": '', "content": <Step setLastStepCompletionTime={setLastStepCompletionTime} maps={mapsArray} setTeamVetoState={setTeamVetoState} canUserSelect = {canUserSelect} matchData={matchData} onMapAction={onMapAction} gameData={gameData} isTurn={turn} vetoData={veto} vetoEnded={vetoEnded}/>, "final" : true })
 
  return (
    maps && maps.length ?
     <StepTabs pages={stepTabs} current={currentStep}/>
     : <div> No maps available </div>
  );
};

export default VetoSteps;
