import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "@context/AppContext";
import Icon from "@common/Icon/Icon";
import Tippy from "@tippyjs/react";
import "tippy.js/animations/shift-toward.css";
import { FaUserFriends,FaNetworkWired } from "react-icons/fa";
import {IconTournament} from '@tabler/icons';
import { IoLogoGameControllerB } from "react-icons/io";
import {AiOutlineBars} from "react-icons/ai"
import { RiTrophyLine } from "react-icons/ri";
import useTranslated from "../../../helpers/translationHelper";

const Sidebar = ({ open }) => {
  const { authenticated } = useContext(AppContext);
  const { selectedGame } = useContext(AppContext);
  const sidebarItems = {
    section1: [
      {
        tabName: "Overview" ,
        path: '/overview',
        displayName:useTranslated("id_sidebar_0"),
        icon:  <Icon name="overview" pureIcon={<AiOutlineBars size={`2.3em`} />} />
      }
    ],
    section2: [
      {
        tabName: "Leagues",
        path: '/leagues',
        icon: <Icon name="bracket" pureIcon={<FaNetworkWired size={`2.3em`} />} />,
        displayName:useTranslated("id_sidebar_1")
      },
      {
        tabName: "Scrim Finder",
        path: '/scrims',
        icon: <Icon name="scrims" pureIcon={<IoLogoGameControllerB size={`2.3em`} />} /> ,
        displayName:useTranslated("id_sidebar_2") 
      },
      {
        tabName: "Tournaments",
        path: '/tournaments',
        icon:  <Icon name="tournaments" pureIcon={<RiTrophyLine size={`2.3em`} />} />,
        displayName:useTranslated("id_sidebar_3")
      },
      {
        tabName: "Multi Stage Leagues",// not changing tabName becuase it will make button disabled
        path: '/competitions',
        icon:  <Icon name="competitions" pureIcon={<IconTournament size={`2.3em`}  />} style={{width:"30px"}}/>,
        displayName:'Competitions'  //useTranslated("id_sidebar_") //'Multi Stage Leagues'
      }
    ],
    section3: [
      {
        tabName: "Player Finder",
        path: '/finder',
        icon: <Icon name="finder" /> ,
        displayName:useTranslated("id_sidebar_4") 
      },
      {
        tabName: "Streams",
        path: '/streams',
        icon:  <Icon name="streams" />,
        displayName:useTranslated("id_sidebar_5") 
      },
      {
        tabName: "News",
        path: '/news',
        icon: <Icon name="news" />,
        displayName:useTranslated("id_sidebar_6") //'News'
      }
    ],
    section4: [
      {
        tabName: "Teams",
        path: '/teams',
        icon: <Icon name="teams" />,
        displayName:useTranslated("id_sidebar_7") //'Teams'
      },
      {
        tabName: "Organisations",
        path: '/organisations',
        icon: <Icon name="organisations" /> ,
        displayName:useTranslated("id_sidebar_8") //'Organisations'
      },
      {
        tabName: "Your Matches",
        path: '/your-matches',
        icon: <Icon name="calendar" />,
        displayName:useTranslated("id_sidebar_9") //'Your Matches'
      }
    ],

  }
  const renderSidebarItems = (key) => {
    return sidebarItems[key].map((item) => {
      let isAccessible = !selectedGame || !selectedGame.accessibility || (selectedGame && selectedGame.accessibility[item.tabName]);
      return (
        <li style={item.tabName == "Overview" ? { marginTop: "24px", marginBottom: "0px" } : {}}>
          <Tippy disabled={open || !isAccessible} content={item.displayName} placement="right" animation="shift-toward">
            <Link
              to={item.path}
              disabled={!isAccessible}
              style={!isAccessible ? { opacity: 0.5, cursor: "none", pointerEvents: "none" } : {}}
            >
              {item.icon}
              {item.displayName}
            </Link>
          </Tippy>
        </li>
      );
    });
  };

  return (
    <nav className={`global-sidebar ${!open ? "closed" : ""}`}>
      <ul> {renderSidebarItems("section1")} </ul>
      <hr style={{ marginTop: "22px" }} />

      <ul> {renderSidebarItems("section2")} </ul>
      <hr />

      <ul> {renderSidebarItems("section3")} </ul>

      {authenticated && (
        <>
          <hr />
          <ul> {renderSidebarItems("section4")} </ul>
        </>
      )}
    </nav>
  );
};

export default Sidebar;
