import Axios from "axios";
import React, { useState,useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { getTeamsAvgRank } from "@utils";
import { Icon,Loading } from "@common";
import {useHistory} from 'react-router-dom';
import {TeamStats} from '../../../TeamsRouter/TeamsSingle/TeamsSingleTabs'

const SingleTeamStats = ({teamId})=>{
  const [teamData, setTeamData] = useState(null);
  const history = useHistory();
  
  useEffect(() => {
    if(teamId){
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/${teamId}`)
      .then(({ data }) => {
        if(data){
          setTeamData(data)
        }        
      })
      // .finally(() => setShowLoader(false));
    }
    
  }, [teamId]);


  const handleGotoTeamPage = (teamId,e)=>{
    e.preventDefault();
    // history.push(`/teams/${teamId}`);
    // e.stopPropagation();

    // e.preventDefault();
  // history.push(`/teams/${teamId}`);

  const win = window.open(`/teams/${teamId}`, "_blank");
win.focus();

  e.stopPropagation();

  }

  const collapseTrigger = (team) =>{
    return(
      <div className="row justify-content-between align-items-center">
        <div className="col-md-5">
          <div className="par-team-info">
            <div className="team-logo" style={{backgroundImage:`url(${team.logo})`}}></div>
            <div className="team-name">{team.name}</div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="par-other-info">
            <div className="par-info-inner">
              <div className="par-num">{team.members.filter((member)=>(member.player)).length} PARTICIPANTS</div>
              <div className="par-rank">{getTeamsAvgRank(team.members,team.game)}</div>
              <div className="par-goto-team"><a href="#" onClick={(e)=>handleGotoTeamPage(team._id,e)}>GO TO TEAM PAGE  <Icon name="external-primary" /></a></div>
            </div>
            <div className="par-arrow ml-3"><Icon name="grey-down-arrow"/></div>
          </div>
        </div>
      </div>
    )
  }
  if(teamData){
    return(
      <Collapsible transitionTime={200} trigger={collapseTrigger(teamData)} classParentString="collapsible-wrapper" key={teamData._id}>
        <TeamStats team={teamData} showFilters={false}/>
      </Collapsible>
    )
  }
  else{
    return(
      null
    )
  }
   
}
export default SingleTeamStats;