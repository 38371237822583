import React, { useState } from 'react';
import SingleTeamStats from  './SingleTeamStats';
const TeamStatsTab = ({matchData}) => {
  console.log("MatchData in teamstats",matchData);
  return ( 
    <div className="team-stats-wrapper">
      {matchData ? (
        <>
          <SingleTeamStats teamId = {matchData?.t1?._id}/>
          <SingleTeamStats teamId = {matchData?.t2?._id}/>
        </>
      ):null}
       
    </div>
   );
}
 
export default TeamStatsTab;