import store from "./../store/store";
import Axios from "axios";
import { toast } from "react-toastify";
import { SET_ALL_LANGUAGES, SET_TRANSLATION } from "./types";
import { setLanguage, setLanguagesAction } from "./setLanguageAction";
const backendServerURL = process.env.REACT_APP_CORE_API;

export const getTranslationData = () => (dispatch) => {
  Axios.get(`${backendServerURL}/api/language/all`)
    .then((res) => {
        const { data } = res;
      // console.log('TOURNAMENTS API RESPONSE', res);
      dispatch({ type: SET_TRANSLATION, payload: data });
        const lang = localStorage.getItem("selectedLanguage");
        const languages = res.data.map((el) => ({
            _id:el._id,
            isavailable:el.isavailable,
            language:el.language,
            endonym:el.endonym,
            flag:el.flag,
            langCode:el.langCode,
            isDefault:el.isDefault,
      }))
      dispatch(setLanguagesAction(languages))
      if (!lang) {
        let defaultLang = languages.find((el) => el.isDefault === true);
        localStorage.setItem("selectedLanguage", JSON.stringify(defaultLang));
        dispatch(setLanguage(defaultLang));
      } else {
        dispatch(setLanguage(JSON.parse(lang)));
      }
    })
    .catch((err) => {
      console.log("Get Translations Error", err);
    });
};
