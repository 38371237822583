import React, { useContext, useEffect, useState } from "react";
import { SimpleCard } from "@common";
import Axios from "axios";
import { AppContext } from "../../../../../context/AppContext";
import { Loading } from "@common";

const Rules = ({ slug = false }) => {
  const { ghost } = useContext(AppContext);
  const [rules, setRules] = useState(false);
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(slug ? true : false);

  useEffect(() => {
    if (slug) {
      ghost.pages
        .read({ slug })
        .then((post) => {
          setLoading(true);
          setRules(post.html);
          setContents(
            post.html.match(/<h1 (.+)>.+<\/h1>/g).map((match) => ({
              divId: match.match(/id="(.*?)"/g)[0].replace(/id="(.*?)"/g, "$1"),
              chapter: match.replaceAll(/<[^>]*>/g, ""),
            }))
          );
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, [slug, ghost.pages]);

  return loading ? (
    <div className="col-12 text-center">
      <Loading />
    </div>
  ) : !rules ? "No rules have been added to this tournament yet. Check back later!" : (
    <div>
      <div className="row smaller-gutter-row mt-4 rules-row">
        <div className="col-4">
          <div className="table-of-contents">
            <h1 className="pt-0">Table of contents</h1>

            <ol style={{ listStyle: "none" }}>
              {contents.map(({ chapter, divId }, i) => (
                <li className="text-14 uppercase bold mt-4">
                  <a
                    href={`#${divId}`}
                    className="faded"
                    style={{ textDecoration: "none" }}
                    key={i}
                    dangerouslySetInnerHTML={{ __html: chapter }}
                  />
                </li>
              ))}
            </ol>
          </div>
        </div>
        <div className="col-8">
          <div className="leagues-gg-rules-body" dangerouslySetInnerHTML={{ __html: rules }}></div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
