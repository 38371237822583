import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import Axios from "axios";
import { toast } from "react-toastify";
import DynamicSearch from "@common/DynamicSearch/DynamicSearch";
import { useEffect } from "react";

const AddTeamMemberModal = ({ open, onClose, team = {}, members = [], pendingInvites = [], isManager, isOwner, afterAdd, allMembers = [] }) => {
  // const { teamData, setStep } = useContext(CreateTeamModalContext);
  const timeout = useRef();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [membersRoles, setMembersRoles] = useState([]);
  const [inviting, setInviting] = useState(false);
  const [teamMembers, setTeamMembers] = useState([])

  useEffect(() => {
     team && team.members && team.members.length && setTeamMembers(team.members)
  }, [team])

  const onSearch = async (query) => {
    if (!query.length) return setResults([]);
    setLoading(true);

    let selectedGameId = null;
    if (team && team.game) {
      selectedGameId = team.game._id;
    }

    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch/inviteMembers`, {
        query,
        include: "users, players",
        selectedGameId: team.game._id,
      });
      // Map data to required output
      // Remove
      //  - already selected players
      //  - already added members & already invited members
      let palyersIds = selectedMembers.filter(member => member.type === 'Player').map(member => member._id)
      let teamMemberIds = teamMembers.filter(member => typeof member.player !== 'undefined').map(member => member.player._id)
      let pendingInviteIds = pendingInvites.filter(member => typeof member.player !== 'undefined').map(member => member.player._id)

      const searchResults = data
        .filter(({_id}) => !palyersIds.includes(_id))
        .filter(({_id}) => !teamMemberIds.includes(_id))
        .filter(({_id}) => !pendingInviteIds.includes(_id))
        .map(({ _id, img, name, user, type, player, coach }) => ({
          _id,
          type,
          img,
          player,
          user: user?._id ?? _id,
          coach,
          title: name,
          subtitle: user ? `${user.firstName} ${user.lastName}` : false,
        }));

      setResults(searchResults);
      setLoading(false);
    }, 500);
  };

  const countRoleOfPlayer = (role) => {
    let roleCount = 0;

    allMembers.forEach((member) => {
      if (member.role.includes(role)) roleCount++;
    })

    pendingInvites.forEach((pendingMembers) => {
      if (pendingMembers.role.includes(role)) roleCount++;
    })

    return roleCount;
  }

  const addMember = (player) => {
    setResults(false);

    const gamePlayerTypes = team.game.configuration.playerTypes.map(({ name }) => "Player:" + name)
    // console.log('team.game.configuration.playerTypes=>',team.game.configuration.playerTypes)
    // console.log('teamMembers=>',teamMembers)
    // console.log('pendingInvites=>',pendingInvites)
    // console.log('gamePlayerTypes=>',gamePlayerTypes)
    let existingRoles = teamMembers.concat(pendingInvites)
    // console.log('existingRoles=>',existingRoles)
    // console.log('player=>',player)
    let roles = [];
    switch (player.type) {
      case "User":
        // roles = isOwner || isManager ? ["Coach", ...gamePlayerTypes] : ["Manager", "Owner", "Coach", ...gamePlayerTypes];
        roles = ["Manager", "Owner", "Coach", ...gamePlayerTypes];
        let userRoles = existingRoles.filter(member => member.user && member.user._id === player._id).map(member => member.role)
        if (userRoles.includes('Owner')) userRoles.push('Manager')
        if (userRoles.includes('Manager')) userRoles.push('Owner')
        roles = roles.filter(role => !userRoles.includes(role))
        // console.log({userRoles})
        // console.log({roles})
        break;
      case "Player":
        roles = [...gamePlayerTypes];
        roles = roles.filter(role => !existingRoles.find((member) => member.player && member.player._id === player._id && member?.role === role))
        break;
      default:
    }
    // console.log('roles=>',roles)
    roles = roles.filter(role => {
      let roleCheck = team.game.configuration.playerTypes.filter((playerRole) => {
        if (role.includes(playerRole?.name) && playerRole?.numberOfPlayers &&
        Number(playerRole.numberOfPlayers) !== 0 && Number(playerRole.numberOfPlayers) <= countRoleOfPlayer(role)) return role;
      })
      // console.log('roles1=>',roles)

      if (roleCheck && !roleCheck.length) return role;
    });

    setSelectedMembers([...selectedMembers, { ...player, role: roles[0], roles }]);
  };

  const editMember = (playerId, role = "", index) => {
    let selectedPlayer = selectedMembers.find((player) => player._id === playerId);
    if (selectedPlayer.type === "Player") {
      if (["Manager", "Owner"].includes(role)) {
        let data = membersRoles.filter((player) => player._id !== selectedPlayer.coach?._id);
        setMembersRoles([...data, { ...selectedPlayer.user, role }]);
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: true } : player)));
      } else if (["Coach"].includes(role)) {
        let data = membersRoles.filter((player) => player._id !== selectedPlayer.user?._id);
        setMembersRoles([...data, { ...selectedPlayer.coach, role }]);
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: true } : player)));
      } else {
        setMembersRoles(
          membersRoles.filter((player) => player._id !== selectedPlayer.user?._id && player._id !== selectedPlayer.coach?._id)
        );
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: false } : player)));
      }
    } else if (selectedPlayer.type === "User") {
      /*if (["Player"].includes(role.split(":")[0])) {
        let data = membersRoles.filter((player) => player._id !== selectedPlayer.coach?._id);
        setMembersRoles([...data, { ...selectedPlayer.player, role }]);
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: true } : player)));
      } else if (["Coach"].includes(role)) {
        let data = membersRoles.filter((player) => player._id !== selectedPlayer.player?._id);
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: true } : player)));
        setMembersRoles([...data, { ...selectedPlayer.coach, role }]);
      } else {*/
        setMembersRoles(
          membersRoles.filter((player) => player._id !== selectedPlayer.player?._id && player._id !== selectedPlayer.coach?._id)
        );
        setSelectedMembers(selectedMembers.map((player, i) => (i == index && player._id === playerId ? { ...player, role, remove: false } : player)));
      /*}*/
    } else if (selectedPlayer.type === "Coach") {
      if (["Manager", "Owner"].includes(role)) {
        let data = membersRoles.filter((player) => player._id !== selectedPlayer.coach?._id);
        setMembersRoles([...data, { ...selectedPlayer.user, role }]);
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: true } : player)));
      } else if (["Player"].includes(role.split(":")[0])) {
        let data = membersRoles.filter((player) => player._id !== selectedPlayer.coach?._id);
        setMembersRoles([...data, { ...selectedPlayer.player, role }]);
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: true } : player)));
      } else {
        setMembersRoles(
          membersRoles.filter((player) => player._id !== selectedPlayer.player?._id && player._id !== selectedPlayer.coach?._id)
        );
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: false } : player)));
      }
    } else {
      setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role } : player)));
    }
  };

  const removeMember = (idToRemove, index) => {
    let selectedPlayer = selectedMembers.find((player, i) => player._id === idToRemove && index == i);
    setMembersRoles(
      membersRoles.filter(
        (player) =>
          player._id !== selectedPlayer.player?._id && player._id !== selectedPlayer.coach?._id && player._id !== selectedPlayer.user?._id
      )
    );
    setSelectedMembers(selectedMembers.filter(({ _id }, i) => !(_id === idToRemove && i == index)));
  };

  // actually attempt to invite players and move to next step
  const inviteMembers = async () => {
    // setInviting(true);
    try {
      let body = [...membersRoles, ...selectedMembers.filter((el) => !el.remove)];
      let error = false;
      /*body.filter((el) => {
        let members = body.filter((member) => member._id === el._id);
        if (el.role === "Coach") el.role = "Coach:No Specification";
        if (members.length >= 2) {
          error = true;
          return;
        }
      });
      if (error) {
        toast.error("Duplicate Members Found");
        return
      };*/
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/invites`, {
        members: body,
        team: team._id,
      });

      toast.success(`Successfully invited ${selectedMembers.length} members`);
      afterAdd(data.invites);
      setSelectedMembers([]);
      onClose();
    } catch (e) {
      const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem inviting selected members";
      toast.error(msg);
    }

    setInviting(false);
  };
  // useEffect(()=>{
  //   // console.log('roles=>',roles);
  //   // console.log('selected Members =>',selectedMembers);
  // },[selectedMembers])

  const getAvailableRoles = (roles,user)=>{
    const currRoles = teamMembers.filter(el=> el.user._id === user).map(el=> el.role)
    const result= currRoles.some(el => el.includes('Player'));
    if(result){
    const curArr = roles.filter(role => !role.includes('Player'))
   if( !curArr.length){
    toast.error("Maximum roles already selected")
   setSelectedMembers([])
  }
    return curArr;
    }
    return roles;
  }
  
  if (!team._id) return null;

  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>

      <div className="modal-header">
        <h2>
          Invite members to <strong>{team.name}</strong>
        </h2>
        <h3>Grow your team by inviting players, coaches, managers, and owners.</h3>
      </div>
      <div className="modal-body">
        <div className="add-players">
          <DynamicSearch
            placeholder="Search for team members"
            onSearch={onSearch}
            onSelectResult={addMember}
            loadingResults={loading}
            results={results}
            mixedResults={true}
          />

          <div className="dynamic-search-selected-items" style={{ overflowY: "inherit" }}>
            {selectedMembers.map(({ _id, img, title, subtitle, role, roles,user }, index) => (
              <div key={_id} className="selected-item">
                <div className="item-details">
                  {img ?
                    <div className="item-img" style={{ backgroundImage: `url(${img})` }} /> :
                    <div className="no_profile" style={{ backgroundColor: `${team.game.mainColour}1A` }}>{title && title.charAt(0)}</div>
                  }
                  <div className="item-name-multi">
                    <p>{title}</p>
                    {subtitle && <small>{subtitle}</small>}
                  </div>
                </div>
                <select name="role" id="role" className="form-input" value={role} onChange={(e) => editMember(_id, e.target.value, index)}>
                  {getAvailableRoles(roles,user).map((role) => {
                    if(roles.length >0){
                    let obj = selectedMembers.find(o => o.type === 'User');

                    const roleSplit = role.split(":"); // Players come in the format Player:TOP
                    const roleStr = roleSplit[1] || roleSplit[0];

                    return <option value={role}>{roleStr}</option>;
                  }
                  })}
                </select>
                <button onClick={() => removeMember(_id, index)}>
                  <RiCloseLine size={27} />
                </button>
              </div>
            ))}
          </div>

          <button className="button primary thicc" onClick={inviteMembers} disabled={inviting || selectedMembers.length < 1}>
            {inviting ? "Inviting Members..." : "Invite Members"}
          </button>
        </div>
      </div>
    </Modal >
  );
};

export default AddTeamMemberModal;
