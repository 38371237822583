import React from "react";
import { Icon } from "@common";
import { FiPlay } from "react-icons/fi";
import { RiCloseLine } from "react-icons/ri";

export const ScrollThing = ({ iconStyle }) => {
  return (
    <div className="scroll-thing box">
      <div className="thing" id="my-element" style={iconStyle}></div>
    </div>
  );
};

export const ScrollThingPlay = ({ iconStyle }) => {
  return (
    <div className="scroll-thing play">
      <Icon name="play" pureIcon={<FiPlay size={`1.5em`} />} iconStyle={iconStyle} />
    </div>
  );
};

export const ScrollThingCross = ({ iconStyle }) => {
  return (
    <div className="scroll-thing cross">
      {/* <Icon name="cross" /> */}
      <Icon name="play" pureIcon={<RiCloseLine size={`1.5em`} />} iconStyle={iconStyle} />
    </div>
  );
};
  