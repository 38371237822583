import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import useTranslated from "../../../../../helpers/translationHelper";

const GamesOverviewTeamMembersList = ({ lanes = [], teamId, map, champions }) => {
  const teamParticipants = map.participants
    .map((participant, i) =>
      participant.teamId === teamId ? { ...participant, summonerData: map.participantIdentities[i].player } : false
    )
    .filter((p) => p);

  const championIdToImg = (championId) => {
    const championData = champions[championId];
    const championVers = championData.version;
    const championImage = championData.image.full;

    return `https://ddragon.leagueoflegends.com/cdn/${championVers}/img/champion/${championImage}`;
  };

  const calculateTotal = (statName) => {
    let total = 0;
    teamParticipants.forEach(({ stats }) => {
      total += stats[statName];
    });

    return total;
  };

  const getLaneIcon = (lane, role) => {
    const data = lanes.find(({ name }) => role.includes(name)) || lanes.find(({ name }) => lane.includes(name));

    return data ? data.icon : false;
  };
  const posTrans = useTranslated('id_match_page_(games)_[lol]_4')
  const kdaTrans = useTranslated('id_match_page_(games)_[lol]_5')
  const csTrans = useTranslated('id_match_page_(games)_[lol]_6')
  const vsTrans =useTranslated('id_match_page_(games)_[lol]_7')
  const kpTrans = useTranslated('id_match_page_(games)_[lol]_8')
  const goldTrans = useTranslated('id_match_page_(games)_[lol]_3')

  return (
    <div className="games-overview-team-members-list">
      <div className="row smaller-gutter-row">
        <div className="col-12">
          <div className="faded-box-item matchMatricsHeadings m-0">
            <div className="row smaller-gutter-row">
              <div className="col-4 flex-row"></div>
              <div className="col-8 multi-flex-column">
                <span className="text-12 uppercase bold headtitles">{/* Position */}{posTrans}</span>
                <span className="text-12 uppercase bold headtitles">{/* KDA */}{kdaTrans}</span>
                <span className="text-12 uppercase bold headtitles">{/* CS */}{csTrans}</span>
                <span className="text-12 uppercase bold headtitles">{/* GOLD */}{goldTrans}</span>
                <span className="text-12 uppercase bold headtitles">{/* VS */}{vsTrans}</span>
                <span className="text-12 uppercase bold headtitles">{/* KP */}{kpTrans}</span>
              </div>
            </div>
          </div>
        </div>

        {!teamParticipants.length ? (
          <div className="game-no-set-players">
            <RiErrorWarningLine />
            <p>No data available</p>
          </div>
        ) : (
          teamParticipants.map(({ championId, stats, timeline, summonerData }) => {
            return (
              <div className="col-12">
                <div className="faded-box-item m-0 darkLine">
                  <div className="row smaller-gutter-row">
                    <div className="col-4 flex-row">
                      <img src={championIdToImg(championId)} alt={championId} className="team-member-image image-40 border-radius-100" />
                      <span className="text-12 bold">
                        <Link style={{ textDecoration: "none" }} to={`/summoner-redirect/${summonerData.accountId}`}>
                          {summonerData ? summonerData.summonerName : "??"}{" "}
                        </Link>
                      </span>
                    </div>
                    <div className="col-8 multi-flex-column">
                      <span className="text-12 uppercase bold">
                        <img src={getLaneIcon(timeline.lane, timeline.role)} alt={`${timeline.lane}, ${timeline.role}`} width={20} />{" "}
                      </span>
                      <span className="text-12 uppercase bold">
                        {" "}
                        {stats.kills}/{stats.deaths}/{stats.assists}
                      </span>
                      <span className="text-12 uppercase bold">
                        {" "}
                        {stats.totalMinionsKilled + stats.neutralMinionsKilledTeamJungle + stats.neutralMinionsKilledEnemyJungle}
                      </span>
                      <span className="text-12 uppercase bold">{parseFloat(stats.goldEarned / 1000).toFixed(1)}K</span>
                      <span className="text-12 uppercase bold">{stats.visionScore}</span>
                      <span className="text-12 uppercase bold">
                        {" "}
                        {Math.round(((stats.kills + stats.assists) / calculateTotal("kills")) * 100, 0)}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default GamesOverviewTeamMembersList;
