import React, { useState } from "react";
import { CheckboxSlider } from "@common";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { RiMenuLine } from "react-icons/ri";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useTranslated from "../../../../helpers/translationHelper";

const SortableItem = SortableElement(({ i, name, hide, setHide }) => {
  const Aboutsseting = useTranslated("id_manage_organisation_(profile_settings)_1");
const Teamssseting = useTranslated("id_manage_organisation_(profile_settings)_2");
const Calendarseting = useTranslated("id_manage_organisation_(profile_settings)_3");
const Contactseting = useTranslated("id_manage_organisation_(profile_settings)_4");
const newsseting = useTranslated("id_manage_organisation_(profile_settings)_5");
const hideseting = useTranslated("id_manage_organisation_(profile_settings)_6");

   if(name==="About") name=Aboutsseting
    if(name==="Teams")(name=Teamssseting)
   if( name==="Calendar")(name=Calendarseting)
    if(name==="News")(name=Contactseting)
    if(name==="Contact")(name=newsseting)
  return(
  <div className={`reorder-item ${hide ? "hidden" : ""}`}>
    {name}
    <span className="input">
      <input type="checkbox" id={`hide-${i}`} checked={hide} onChange={(e) => setHide(e.target.checked, i)} />
      <label htmlFor={`hide-${i}`}>{hideseting}</label>
      <RiMenuLine />
    </span>
  </div>
)});

const SortableList = SortableContainer(({ items, setHide }) => {
  return (
    <div className="reorder-list">
      {items.map((item, i) => (
        <SortableItem key={`item-${item.name}`} index={i} i={i} {...item} setHide={setHide} />
      ))}
    </div>
  );
});

const ProfileSettings = ({ profileConfig, setProfileConfig }) => {
  const { id } = useParams();
  const [tabs, setTabs] = useState(profileConfig.tabs);
  const [logoInBanner, setLogoInBanner] = useState(profileConfig.logoInBanner);
  const Tabsseting = useTranslated("id_manage_organisation_(profile_settings)_0");

  const optionsseting = useTranslated("id_manage_organisation_(profile_settings)_7");
  const Showlogoinbanner = useTranslated("id_manage_organisation_(profile_settings)_8");
  const savesettings = useTranslated("id_manage_organisation_(profile_settings)_9");

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTabs((items) => arrayMove(items, oldIndex, newIndex));
  };

  const onSetHide = (val, index) => {
    tabs[index].hide = val;
    setTabs([...tabs]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${id}/profileConfig`, { tabs, logoInBanner });
      setProfileConfig(data.profileConfig);
      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }
  };

  return (
    <form onSubmit={onSubmit} className="account-settings-form">
      <div className="row">
        <div className="col-6">
          <div className="form-row">
            <label>{Tabsseting}</label>

            <SortableList items={tabs} setHide={onSetHide} onSortEnd={onSortEnd} />
          </div>
        </div>

        <div className="col-6">
          <div className="form-row">
            <label>{optionsseting}</label>
            <CheckboxSlider value={logoInBanner} onChange={(e) => setLogoInBanner(e.target.checked)} label={Showlogoinbanner}/>
          </div>
        </div>
      </div>

      <button className="button primary">{savesettings}</button>
    </form>
  );
};

export default ProfileSettings;
