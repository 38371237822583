import React from "react";
import { Link } from "react-router-dom";
import youtube from "@img/youtube-round.svg";
import facebook from "@img/facebook-round.svg";
import twitter from "@img/twitter-round.svg";
import twitch from "@img/twitch-round.svg";
import instagram from "@img/instagram-round.svg";
import { Card, Icon } from "@common";

const Overview = () => {
  return (
    <div style={{ filter: "blur(5px)" }}>
      <div className="row smaller-gutter-row">
        <div className="col-3 row-title">
          <span className="faded bold uppercase">About</span>
        </div>
        <div className="col-9">
          <div className="row smaller-gutter-row">
            <div className="col-4 pt-3 pb-3">
              <Card icon={<Icon name="game-icon" />} title="PLATINIUM IV" subtitle="Rank (Ranked Solo)" />
            </div>

            <div className="col-4 pt-3 pb-3">
              <Card icon={<Icon name="trophy-icon" />} title="44% WR (44w 66l)" subtitle="Ranked Solo" />
            </div>

            <div className="col-4 pt-3 pb-3">
              <Card icon={<Icon name="trophy-icon" />} title="60% WR (60W 40L)" subtitle="Tournaments" />
            </div>

            <div className="col-4 pt-3 pb-3">
              <Card icon={<Icon name="globe-icon" />} title="LoL, EU West" subtitle="Game & Region" />
            </div>

            <div className="col-4 pt-3 pb-3">
              <Card icon={<Icon name="game-icon" />} title="PLATINIUM IV" subtitle="Rank (Ranked Solo)" />
            </div>

            <div className="col-4 pt-3 pb-3">
              <Card icon={<Icon name="game-icon" />} title="PLATINIUM IV" subtitle="Rank (Ranked Solo)" />
            </div>
          </div>

          <div className="row smaller-gutter-row pb-3 pt-2">
            <div className="col-12">
              <Link to="/" className="primary-bg-link">
                <span>
                  <Icon name="discord-icon" />
                  Rallerpenos #61231
                </span>
                <Icon name="external-primary" />
              </Link>
            </div>
          </div>

          <div className="row smaller-gutter-row">
            <div className="col-3 pt-2 pb-2">
              <div className="text-14 bold uppercase">Denmark</div>
              <div className="text-14 faded">Country</div>
            </div>
            <div className="col-2 pt-2 pb-2">
              <div className="text-14 bold uppercase">Male</div>
              <div className="text-14 faded">Gender</div>
            </div>
            <div className="col-2 pt-2 pb-2">
              <div className="text-14 bold uppercase">21 year</div>
              <div className="text-14 faded">Age</div>
            </div>
            <div className="col-5 pt-2 pb-2">
              <div className="text-14 bold uppercase">Rallerpenos #61231</div>
              <div className="text-14 faded">Discord</div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row smaller-gutter-row player-overview-row">
        <div className="col-3 row-title">
          <span className="faded bold uppercase">BIO</span>
        </div>
        <div className="col-9 row-content">
          <p>
            Im a midmain and have played the game for nearly 10 years. The past 6 seasons, i have been plat or above and the past 2 seasons
            i have been a diamond player jumping around the different divisions. Prefer playing control mages, but can also do assassins if
            it suits the comp. Im a flexible player, but still open to learn new champions if needed. Furthermore i got a decent amount of
            team experience from playing on my last team (VHE). Highest peek ever: D1 My op.gg:
            https://euw.op.gg/summoner/userName=Legenden%20Lalle
          </p>
          <div className="social-links">
            <a href="/#">
              <img src={twitch} alt="Twitch" />
            </a>
            <a href="/#">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="/#">
              <img src={twitter} alt="Twitter" />
            </a>
            <a href="/#">
              <img src={instagram} alt="Instagram" />
            </a>
            <a href="/#">
              <img src={youtube} alt="Youtube" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
