//import React, { useState, useEffect, useContext } from "react";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { AppContext } from "@components/../context/AppContext";
import cover_image from "@img/teams-default-image.png";
import default_player_image from "@img/default-team-member.png";
import { Overview, TournamentStats } from "./CoachProfileTabs";
import { FaExternalLinkAlt } from "react-icons/fa";
import { PageWithTabs, Loading } from "@common";

const CoachProfile = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [coach, setCoach] = useState();
  const context = useContext(AppContext);
  const { selectedGame, setSelectedGame } = context;

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/coaches/${id}`).then(({ data }) => {
      if (data.game.shortName !== selectedGame.shortName) {
        setSelectedGame(data.game);
        return window.location.reload();
      } else {
        setCoach(data);
        setLoading(false);
      }
    });
  }, [id, context.user.state]);

  let pages = {
    Overview: <Overview />,
    "Leagues.gg stats": <TournamentStats />,
    //"Ranked stats": <TournamentStats />,
    //"Champion stats": <ChampionStats />,
    //"Teams (2)": <Teams />,
  };

  return loading ? (
    
    <div className="col-12 text-center p-5">
      <Loading />
    </div>
  ) : (
   <div className="">
     <PageWithTabs
      pages={pages}
      tab_link={
        <a href={"/players/" + coach.user._id}>
          User profile <FaExternalLinkAlt />
        </a>
      }
      base_route={`/coaches/${id}`}
      image={default_player_image}
      cover_image={cover_image}
      title={coach.name}
      above_title={coach.user ? coach.user.firstName : "[Deleted]"}
      below_title={
        <React.Fragment>
          Signed up {moment(coach.createdAt).fromNow()}
          <span className="pill">Coach</span>
        </React.Fragment>
      }
      type="under-contruction"
    />
   </div>
   
  );
};

export default CoachProfile;
