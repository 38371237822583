import React from 'react';
import { Draggable, state } from 'react-page-maker';
import { RiEditLine,RiCloseLine } from 'react-icons/ri';
import { toast } from "react-toastify";
import EditMultiImageModal from "../../_modals/EditImageModal/EditMultiImageModal";
import './style.scss';
class DraggablePartnerLogo extends React.Component {
  state = {
    showColorPicker: false,
    background: '',
    description: 'descriptiondescription',
    toggleEdit:false,
  
    showImageModal:false,
    logo_0: '',
    logo_1: '',
    logo_2: '',
    imageModalFor:false
  };

   onEditImage = async (image,keyNumber) => {
    try {
      this.changePayload('logo_'+keyNumber,image,keyNumber);
      this.toggleShowImageModal();
    } catch (err) {
      toast.error("There was a problem updating image.");
    }
  };

  toggleShowImageModal = (keyNumber=false) => {
    if(this.state.showImageModal){
      this.setState({showImageModal:!this.state.showImageModal,imageModalFor:false});
    }else{
      this.setState({showImageModal:!this.state.showImageModal,imageModalFor:keyNumber});
    }
      
  }
  toggleEditField = (fieldName) => {
    if(this.validURL(this.state.url)){
      this.setState({toggleEdit: fieldName});
    }else{
      toast.success("Please check URL carefully.");
    }
    
  }
  

  handleChangeComplete = (color) => {
    const { id, dropzoneID, parentID }= this.props;
    this.setState({ background: color.hex }, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: { background: color.hex }
      });
    });
  };

  validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  changePayload = (payLoadKey,value,keyNumber) => {
    // console.log('testing-',payLoadKey,value,keyNumber)
  // const {imageModalFor} = this.setState;
      const { id, dropzoneID, parentID,payload }= this.props;
      this.setState({ [payLoadKey]: value }, () => {
        state.updateElement(id, dropzoneID, parentID, {
          payload: { ...payload, [payLoadKey]: value }
        });
      });
  
    
    // this.setState({toggleEdit: ''});
  }

  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }

  
  render() {
    const {
      id, showBasicContent, showPreview,
      dropzoneID, parentID, 
      // name, 
      payload
    } = this.props;


    const {showImageModal,imageModalFor} =  this.state;
    
    // const heading =  payload && payload.heading || this.state.heading;
    const logo_0 = payload.logo_0 || this.state.logo_0;
    const logo_1 = payload.logo_1 || this.state.logo_1;
    const logo_2 = payload.logo_2 || this.state.logo_2;
  
    

    if (showBasicContent) {
      // console.log('this.propsthis.props',this.props)
      const {name} = this.props;
      return (
        <>
        <Draggable
          { ...this.props } 
      >{name}</Draggable></>
      )
    }

    if (showPreview) {
      return (
        <>
        <header >
        
        <h2 className="pb_ele_header_heading">PARTNERS</h2>
        

        <div className="form-row">
        {logo_0 && <div
                className="upload-image-btn partnerLogo"
                style={{ backgroundImage: `url(${logo_0})`, width: '30%', height: 93 }}
              />
        }
        {logo_1 && <div
              className="upload-image-btn partnerLogo"
              style={{ backgroundImage: `url(${logo_1})`, width: '30%', height: 93 }}
            />
      }
      {logo_2 && <div
              className="upload-image-btn partnerLogo"
              style={{ backgroundImage: `url(${logo_2})`, width: '30%', height: 93 }}
            />
      }
      </div>
          <br/>
          
        </header>
         </>
      );
    }

    return (
      <Draggable { ...this.props } >
        <div className="actions">
            <span className="action_type" title="Remove element"><RiCloseLine onClick={() => state.removeElement(id, dropzoneID, parentID)} color='white' /></span>
          </div>
        <header >
        
        <h2 className="pb_ele_header_heading">PARTNERS</h2>
        


        <div className="form-row">
            
              <div
                className="upload-image-btn partnerLogo"
                style={{ backgroundImage: `url(${logo_0})`, width: '30%', height: 93 }}
                onClick={() => this.toggleShowImageModal(0)}
              >
                <RiEditLine />
              </div>

              <div
                className="upload-image-btn partnerLogo"
                style={{ backgroundImage: `url(${logo_1})`, width: '30%', height: 93 }}
                onClick={() => this.toggleShowImageModal(1)}
              >
                <RiEditLine />
              </div>

              <div
                className="upload-image-btn partnerLogo"
                style={{ backgroundImage: `url(${logo_2})`, width: '30%', height: 93 }}
                onClick={() => this.toggleShowImageModal(2)}
              >
                <RiEditLine />
              </div>
            </div>

            <EditMultiImageModal
              name="partner logo"
              width={197}
              height={93}
              crop={false}
              rotate={false}
              borderRadius={0}
              open={showImageModal}
              onClose={() => this.toggleShowImageModal(0)}
              afterImageUpload={(file) => this.onEditImage(file,imageModalFor)}
              
            />




          <br/>
          
        </header>
      </Draggable>
    )
  }
};

export default DraggablePartnerLogo;
