import React from "react";
import useChat from "@utils/useChat";
import moment from "moment";
import ChatItem from "./ChatItem";
import { getTeamName } from "../../../../../utils/additional-helpers";

const ChatRoom = (props) => {
  const { id, scrim } = props;
  const { messages, sendMessage } = useChat(id);
  const [newMessage, setNewMessage] = React.useState("");

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = () => {
    sendMessage(newMessage.replace(/\n/g, "<br>"));
    setNewMessage("");
  };

  const getUserData = (message) => {
    let userData = getNameAndRole(scrim.team, message.userId);
    if (!userData || !userData.role) userData = getNameAndRole(scrim.acceptedMatchUp.team, message.userId);
    return userData;
  };
  const getNameAndRole = (team, messageUser) => {
    let role, player;
    let name = `${messageUser.firstName} ${messageUser.lastName}`;
    player = team.organisation && team.organisation.owners.find(({ user }) => messageUser._id === user || messageUser._id === user._id);
    if (player) role = player.role;
    else if (!player && !role) {
      player = team.members.find(
        ({ user: userId, role, player, coach }) => coach && (messageUser._id === coach.user._id || messageUser._id === coach.user)
      );

      if (!player)
        player = team.members.find(
          ({ user: userId, role, player }) =>
            (userId && messageUser._id === userId) ||
            (userId && messageUser._id === userId._id) ||
            (player && (messageUser._id === player.user._id || messageUser._id === player.user))
        );

      if (player) {
        role = player.role;
        name = role && role.toLowerCase() == "coach" ? player.coach.name : name;
      }
    }
    return { role: role, name: name, firstName: messageUser.firstName, lastName: messageUser.lastName };
  };
  return (
    <>
      <div>
        {messages.map((message, i) => {
          if (!message.scrim) message.scrim = scrim;
          let userData = getUserData(message);
          return (
            <ChatItem
              logo={message.userId.avatarImage}
              name={userData.name}
              lastName={userData.lastName}
              username={userData.role}
              role={getTeamName(message.scrim.team, message.scrim.acceptedMatchUp.team, message.userId._id)}
              time={moment(message.createdAt).fromNow()}
              message={message.message}
            />
          );
        })}
      </div>
      <textarea
        value={newMessage}
        onChange={handleNewMessageChange}
        placeholder="Write new message..."
        className="new-message-input-field"
      />
      <div className="postButton">
        <button onClick={handleSendMessage} className=" mt-3 button primary thicc">
          Post message
        </button>
      </div>
    </>
  );
};

export default ChatRoom;
