import React, { useState, useEffect } from "react";
import Axios from "axios";
import GlitchClip from "react-glitch-effect/core/Clip";
import moment from "moment";
import { Button, Item, SliderItem } from "@common";
import Slider from "../Slider/Slider";
import danish_flag from "@img/default-team-nationality.png";
import league_image from "@img/league-image.png";
import { Link } from "react-router-dom";
const SelectedTournaments = ({ data }) => {
  const [tournaments, setTournaments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blueText, setBlueText] = useState("Loading...");
  const [whiteText, setWhiteText] = useState("Loading...");
  const [selectedTournaments, setSelectedTournaments] = useState(null);

  useEffect(() => {
    if (data.BlueText) {
      setBlueText(data.BlueText);
    }
    if (data.WhiteText) {
      setWhiteText(data.WhiteText);
    }
    if (data?.tournaments?.length) {
      setSelectedTournaments(data.tournaments.map(({ _id }) => _id).toString());
    }
  }, [data]);

  useEffect(() => {
    console.log("getFeaturedSeasons in selectedTournament");
    selectedTournaments &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/selected/${selectedTournaments}`).then(({ data }) => {
        setTournaments(() => data.filter((dataset) => dataset !== null));
        console.log("getFeaturedSeasons in selectedTournament : ", data);

        setLoading(false);
      });
  }, [selectedTournaments]);
  // console.log('tournaments==',tournaments)
  return (
    <Slider
      isArrowBg
      info={
        <div>
          <h1 className="outline">{blueText}</h1>
          <h1>{whiteText}</h1>
          <Link to="/tournaments">
            <Button name="cta-button-transparent">All Tournaments</Button>
          </Link>
        </div>
      }
    >
      {tournaments && tournaments.length >= 3
        ? tournaments.map(({ _id, name, logoImage, country, thumbnailImage, teams }, i) => (
            <SliderItem
              key={i}
              title={name ? name : "Challenger Series Denmark"}
              second_line={
                <React.Fragment>
                  <img
                    src={country ? `https://flagcdn.com/w20/${country.code?.toLowerCase()}.png` : `https://flagcdn.com/w20/dk.png`}
                    alt="Danish flag"
                    className={"loading"}
                  />
                  {country ? country.name : "Denmark"}
                </React.Fragment>
              }
              third_line={teams.length === 0 ? "Be the first one to join" : `${teams.length} teams participating`}
              tag=""
              image={thumbnailImage ? thumbnailImage : league_image}
              link_to={`/tournament/${_id}`}
            />
          ))
        : tournaments &&
          [1, 2, 3].map(() => {
            return tournaments.map(({ _id, name, logoImage, country, thumbnailImage, teams }, i) => (
              <SliderItem
                key={i}
                title={name ? name : "Challenger Series Denmark"}
                second_line={
                  <React.Fragment>
                    <img
                      src={country ? `https://flagcdn.com/w20/${country.code?.toLowerCase()}.png` : `https://flagcdn.com/w20/dk.png`}
                      alt="Danish flag"
                      className={"loading"}
                    />
                    {country ? country.name : "Denmark"}
                  </React.Fragment>
                }
                third_line={teams.length === 0 ? "Be the first one to join" : `${teams.length} teams participating`}
                tag=""
                image={thumbnailImage ? thumbnailImage : league_image}
                link_to={`/tournament/${_id}`}
              />
            ));
          })}
    </Slider>
  );
};

export default SelectedTournaments;
