import React from "react";
import SlickSlider from "react-slick";

const Slider = ({ children, isArrowFullOpacity, needAWheel }) => {
  const settings = {
    className: `slider variable-width ${isArrowFullOpacity && "arrows"}`,
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    speed: 50,
  };
  return (
    <div className="slider-container">
      {/* <div className={`bar`}><span></span></div> */}
      <div className="slider-items smallSliderGameSelection">
        {needAWheel && (
          <div className={`bar ${needAWheel ? `sliderwithwheel` : `sliderwithoutwheel`}`}>
            <span></span>
          </div>
        )}
        <SlickSlider {...settings} style={{ overflow: "hidden" }}>
          {children}
        </SlickSlider>
      </div>
    </div>
  );
};

export default Slider;
