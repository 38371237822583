import React, { useEffect, useState } from "react";
import { SimpleTabs, LeaguesResultItem, List, Icon, Loading } from "@common";
import Axios from "axios";
import { toast } from "react-toastify";
import { MatchListingByDate, SimplePageWithTabs } from "../../../../_common";
import Calendar from "./Calendar/Calendar";
const tabs = ["Matches" /*"Calender"*/];

const Matches = ({ team }) => {
  const [page, setPage] = useState(0);
  // const [loading, setLoading] = useState(false);
  // const [matches, setMatches] = useState([]);

  // useEffect(() => {
  //   // On page change...
  //   setLoading(true);

  //   let currentTab = tabs[page];
  //   let filter = currentTab === "Ongoing & Upcoming" ? "upcoming" : currentTab === "Results" ? "completed" : "all";
  //   let api = currentTab === "Results" ? "team-matches" : "matches";

  //   setMatches([]);

  //   Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}/${api}`, { params: { filter } })
  //     .then(({ data }) => {
  //       console.log("team matches --", data);
  //       if (currentTab === "Results") {
  //         // data.sort((a, b) => b.week - a.week);
  //         setMatches(data);
  //       } else {
  //         // data.sort((a, b) => a._id.week - b._id.week);
  //         let sortedData = [];

  //         data.map(({ week, matches = [] }) =>
  //           matches.map((match) => (match.league || match.tournament || match.scrim) && sortedData.push(match))
  //         );
  //         let sortedMatches = [];
  //         let lastWeek = 0;
  //         let sameAs = false;
  //         sortedData.map((match) => {
  //           if (lastWeek === match.week) {
  //             sameAs = true;
  //           } else {
  //             sameAs = false;
  //             lastWeek = match.week;
  //           }
  //           sortedMatches.push({ _id: { week: match.week, sameAs: sameAs }, datetime: match.datetime, matches: [match] });
  //           // sortedMatches.push({'_id':{'week':match.week},'datetime':match.datetime,'matches':[match]})
  //         });
  //         sortedMatches.sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime());
  //         setMatches(sortedMatches);
  //       }

  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e, "");
  //       toast.error("There was a problem retrieving matches");
  //     });
  // }, [team._id, page]);

  return (
    <React.Fragment>
      {/* <SimpleTabs pages={tabs} page={page} setPage={setPage} />
      {tabs[page] !== "Calendar" &&
        (loading ? (
          <div className="text-center league-no-matches">
            <Loading width={50} height={50} />
          </div>
        ) : !matches.length ? (
          <div className="league-no-matches">
            <Icon name="calendar" />
            <p>There are no matches to show</p>
          </div>
        ) : (
          matches.map(({ week, _id, matches = [] }) => {
            console.log("matches : ", matches, _id);
            return (
              <div className="row smaller-gutter-row mb-4 in-small-gutter2">
                <div className="col-12">
                  {tabs[page] === "Ongoing & Upcoming" ? (
                    _id && !_id.sameAs && <div className="mt-4 uppercase bold">{_id.week < 1 ? "Others" : `Week ${_id.week}`}</div>
                  ) : (
                    <div className="mt-4 uppercase bold"> {week !== "undefined" && week > 1 ? week : "Others"}</div>
                  )} */}
      <SimpleTabs pages={tabs} page={page} setPage={setPage} />
      {page == 0 && <MatchListingByDate id={team._id} page={"TEAM_MATCHES"} fullScreen={true} />}
      {/* {page == 1 && <Calendar team={team} />} */}

      {/* {loading ? (
        <div className="text-center league-no-matches">
          <Loading width={50} height={50} />
        </div>
      ) : !matches.length ? (
        <div className="league-no-matches">
          <Icon name="calendar" />
          <p>There are no matches to show</p>
        </div>
      ) : (
        matches.map(({ week, _id, matches = [] }) => {
          console.log("matches : ", matches, _id);
          return (
            <div className="row smaller-gutter-row mb-4 in-small-gutter2">
              <div className="col-12">
                {tabs[page] === "Ongoing & Upcoming" ? (
                  _id && !_id.sameAs && <div className="mt-4 uppercase bold">{_id.week < 1 ? "Others" : `Week ${_id.week}`}</div>
                ) : (
                  <div className="mt-4 uppercase bold"> {week !== "undefined" && week > 1 ? week : "Others"}</div>
                )}

                <List>
                  {matches.map((match) => (
                    <LeaguesResultItem key={match._id} show_result={page} match={match} matchesOfTeam={team} />
                  ))}
                </List>
              </div>
            </div>
          );
        })
      )} */}
    </React.Fragment>
  );
};

export default Matches;
