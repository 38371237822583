import React from "react";

const Button = ({ children, name, onClick, disabled }) => {
  let classes = name ?? "default";
  return (
    <button disabled={disabled} className={`button ${classes}`} onClick={onClick}>
      {children}
      <span className="top"></span>
      <span className="bottom"></span>
      <span className="left"></span>
      <span className="right"></span>
      <span className="right-corner"></span>
      <span className="left-corner"></span>
    </button>
  );
};

export default Button;
