import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { toast } from "react-toastify";
import { CreateTeamModalContext } from "../CreateTeamModal";
import Loading from "../../../_common/Loading/Loading";
import { countries } from "@utils";
import useTranslated from "../../../../helpers/translationHelper";
import { lol, regions } from "../../../../utils/region";

const CreateTeam = () => {
  const { organisation, organisationId, game, nationality, setTeamData, setStep, afterCreate } = useContext(CreateTeamModalContext);
  const { register, handleSubmit, errors } = useForm();
  const [games, setGames] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingGames, setLoadingGames] = useState(true);
  const [selectedGame, setSelectedGame] = useState(game ? game._id : "_DEFAULT_");
  const [selectedForRegion, setSelectedForRegion] = useState("_DEFAULT_");
  const [selectedOrg, setSelectedOrg] = useState(organisationId || "_DEFAULT_");
  const [selectedRegion, setSelectedRegion] = useState("_DEFAULT_");

  const lableRequired=useTranslated("id_pop-up_-_create_team_13")
  const lableCreateTeam=useTranslated("id_pop-up_-_create_team_16")
  const lablegame=useTranslated("id_pop-up_-_create_team_2")
  const lableOrganization=useTranslated("id_pop-up_-_create_team_3")
  const labelCreateOrganisation=useTranslated("id_pop-up_-_create_team_4")
  const lableTeamName=useTranslated("id_pop-up_-_create_team_5")
  const lableTeamName1=useTranslated("id_pop-up_-_create_team_6")
  const lableTeamInitials=useTranslated("id_pop-up_-_create_team_7")
  const lableMin2Characters=useTranslated("id_pop-up_-_create_team_15")
  const lableTMN=useTranslated("id_pop-up_-_create_team_8")
  const lableNationality=useTranslated("id_pop-up_-_create_team_9")
  const lablewebsite=useTranslated("id_pop-up_-_create_team_10")
  const lableTeamDescription=useTranslated("id_pop-up_-_create_team_12")
  const lableLeagues=useTranslated("id_pop-up_-_create_team_11")
  const lableTeamAtLeast=useTranslated("id_pop-up_-_create_team_14")


  useEffect(() => {
    // Get list of games
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      // setGames(data);
      setGames(() => {
        return data.filter((game) => game.name !== "No Game");
      });
    });

    // Get list of my organisations
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/organisations/my`).then(({ data }) => {
      setOrganisations(data);
      setLoadingGames(false);
    });

    // Get list of nationalities from external API (europe only)
    // Axios.get("https://restcountries.eu/rest/v2/all?fields=demonym;alpha2Code", { headers: false }).then(({ data }) => {
    //   setNationalities(
    //     data
    //       .filter(({ demonym }) => demonym)
    //       .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    //       .sort((a, b) => a.demonym.localeCompare(b.demonym))
    //   );
    // });
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const { data: newTeam } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams`, data);
      toast.success("Successfully created team!");
      setTeamData(newTeam.team);
      setLoading(false);
      setStep(1);
    } catch (e) {
      const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem creating your team";
      setLoading(false);
      toast.error(msg);
    }
  };

  return (
    <div>
      <div className="modal-header">
        <h2>{useTranslated("id_pop-up_-_create_team_0")/* Create a team */}</h2>
        <h3>{useTranslated("id_pop-up_-_create_team_1")/* A team is needed to assemble players and perform in leagues and some tournaments. */}</h3>
      </div>
      {!loadingGames ? (
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <label htmlFor="game">{lablegame/* Game */}</label>
              <select
                ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : lableRequired/* "Required" */ ) })}
                id="game"
                name="game"
                className={`form-input ${errors.game ? "invalid" : ""}`}
                value={selectedGame}
                onChange={(e) => {
                  const index = games.findIndex(g => g._id === e.target.value);
                  setSelectedForRegion(games[index].shortName);
                  setSelectedGame(e.value);
                }}
              >
                <option value="_DEFAULT_" disabled>
                  Choose game
                </option>

                {games.map(({ _id, name }) => (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                ))}
              </select>
              {errors.game && <div className="form-input-error">{errors.game.message}</div>}
            </div>

            <div className="form-row">
              <label htmlFor="organisation">{lableOrganization/* Organisation */} (optional)</label>
              <select
                //ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                id="organisation"
                name="organisation"
                className={`form-input ${errors.organisation ? "invalid" : ""}`}
                onChange={(e) => setSelectedOrg(e.value)}
                value={selectedOrg}
              >
                <option value="_DEFAULT_" disabled>
                  {labelCreateOrganisation/* Choose organisation */}
                </option>
                {organisations.map(({ _id, name }) => (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                ))}
              </select>
              {errors.organisation && <div className="form-input-error">{errors.organisation.message}</div>}
            </div>

            <div className="row form-row sm-gutters">
              <div className="col-8">
                <label htmlFor="name">{lableTeamName/* Team name */}</label>
                <input
                  onBlur={(e) => {
                    e.target.value = e.target.value.trim();
                  }}
                  ref={register({
                    required: {lableRequired}/* "Required" */,
                    minLength: { value: 2, message: lableTeamAtLeast/* "Team name must be at least 2 characters" */ },
                    maxLength: { value: 30, message: "Team name too long" },
                  })}
                  type="text"
                  id="name"
                  name="name"
                  maxLength={30}
                  autoComplete="team-name"
                  placeholder={lableTeamName1}/* "Team name" */
                  className={`form-input ${errors.name ? "invalid" : ""}`}
                />
                {errors.name && <div className="form-input-error">{errors.name.message}</div>}
              </div>
              <div className="col-4">
                <label htmlFor="initials">{lableTeamInitials/* Team Initials */}</label>
                <input
                  onBlur={(e) => {
                    e.target.value = e.target.value.trim();
                  }}
                  ref={register({
                    required: {lableRequired}/* "Required" */,
                    minLength: { value: 2, message:lableMin2Characters /* "Min. 2 characters" */ },
                    maxLength: { value: 4, message: "Max 4 characters" },
                  })}
                  type="text"
                  id="initials"
                  name="initials"
                  maxLength={4}
                  autoComplete="team-initials"
                  placeholder={lableTMN}/* "TMN" */
                  className={`form-input ${errors.initials ? "invalid" : ""}`}
                />
                {errors.initials && <div className="form-input-error">{errors.initials.message}</div>}
              </div>
            </div>
            
            <div className="row form-row sm-gutters">
              <div className="col-6">
                <label htmlFor="nationality">{lableNationality/* Nationality */}</label>
                <select
                  ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : lableRequired/* "Required" */) })}
                  id="nationality"
                  name="nationality"
                  className={`form-input ${errors.nationality ? "invalid" : ""}`}
                  defaultValue={nationality || "_DEFAULT_"}
                >
                  <option value="_DEFAULT_" disabled>
                    Choose nationality
                  </option>
                  {nationalities.map(({ alpha2Code, demonym }) => (
                    <option key={alpha2Code} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
                      {demonym}
                    </option>
                  ))}
                </select>
                {errors.nationality && <div className="form-input-error">{errors.nationality.message}</div>}
              </div>
              <div className="col-6">
                <label htmlFor="website">{lablewebsite/* Website (optional) */}</label>
                <input
                  onBlur={(e) => {
                    e.target.value = e.target.value.trim();
                  }}
                  ref={register()}
                  type="text"
                  id="website"
                  name="website"
                  autoComplete="website"
                  placeholder={lableLeagues}/* "Leagues.gg" */
                  className={`form-input ${errors.website ? "invalid" : ""}`}
                />
                {errors.website && <div className="form-input-error">{errors.website.message}</div>}
              </div>
            </div>

            {
              regions[selectedForRegion] ? 
                <div className="form-row">
                  <label htmlFor="region">Region</label>
                  <select
                    ref={register({ validate: (val) => (val && val !== "_DEFAULT_" ? true : "Required") })}
                    id="region"
                    name="region"
                    className={`form-input ${errors.region ? "invalid" : ""}`}
                  >
                    <option value="_DEFAULT_" disabled>
                      Choose Region
                    </option>
                      {
                      regions[selectedForRegion].map(({ name, code }) => (
                        <option key={name} value={JSON.stringify({ code, name })}>
                          {`${code}  -  ${name}`}
                        </option>
                      ))}
                  </select>
                  {errors.region && <div className="form-input-error">{errors.region.message}</div>}
                </div> : <div />
            }

            <div className="form-row">
              <label htmlFor="description">{lableTeamDescription/* Team description (max 200 words) */}</label>
              <textarea
                ref={register({ maxLength: { value: 2000, message: "Team description too long" } })}
                type="text"
                max={2000}
                rows={4}
                id="description"
                name="description"
                autoComplete="description"
                placeholder="Write something about the team"
                className={`form-input ${errors.description ? "invalid" : ""}`}
              />
              {errors.description && <div className="form-input-error">{errors.description.message}</div>}
            </div>

            <button type="submit" className="button primary thicc" disabled={loading}>
              {loading ? (
                <>
                  <Loading width={30} height={30} /> Creating Team
                </>
              ) : (
                lableCreateTeam
              )}
            </button>
          </form>
        </div>
      ) : (
        <div className="text-center mt-3">
          <Loading width={30} height={30} />
        </div>
      )}
    </div>
  );
};

export default CreateTeam;
