import React from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import useTranslated from "../../../../../helpers/translationHelper";
const localizer = momentLocalizer(moment);

const Calendar = () => {
	return (
		<div>
			<h1 className="text-18">{useTranslated('id_organisation_page_(calendar)_0')}</h1>
			<BigCalendar events={[]} localizer={localizer} startAccessor="start" endAccessor="end" style={{ height: "auto" }} />
		</div>
	);
};

export default Calendar;
