import React, { useState,useEffect } from 'react'
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import logo_header from "@img/logo-header.svg";
import JoinAsParty from './JoinAsParty';
import JoinAsTeam from './JoinAsTeam';
import EditLineup from './EditLineup';

const TournamentJoinModal = ({ showModal, closeModal, id, teams, subsAllowed, refreshPage,teamSize,selectedtTab,userHasTeamRegistered }) => {
    const [selectedTab, setSelectedTab] = useState(selectedtTab?selectedtTab:"joinAsTeam");
    const [partyName, setPartyName] = useState("");

    useEffect(() => {
        setSelectedTab(userHasTeamRegistered?"editLineup":"joinAsTeam");
      }, [userHasTeamRegistered]);

    const tabs = [
        { name: "joinAsParty", disabled: true, title: "JOIN AS PARTY", component: <JoinAsParty refreshPage={refreshPage} id={id} partyName={partyName} setPartyName={setPartyName} /> },
        { name: "joinAsTeam", disabled: false, title: "JOIN AS TEAM", component: <JoinAsTeam teamSize={teamSize} refreshPage={refreshPage} subsAllowed={subsAllowed} existingTeams={teams} id={id} hideModal={closeModal} /> },
    ]
    const editLineupTab = 
        { name: "editLineup", disabled: false, title: "EDIT LINEUP", component: <EditLineup teamSize={teamSize} refreshPage={refreshPage} subsAllowed={subsAllowed} existingTeams={teams} id={id} hideModal={closeModal} /> };
    // if user has team registered in the tournament
    if(userHasTeamRegistered){
        tabs.push(editLineupTab);
    }
    console.log("Selected Tab",selectedTab);
    return (
        <Modal isOpen={showModal} onRequestClose={closeModal} className="modal auth-modal" shouldCloseOnEsc={false}>
            <div className="modal-close" onClick={closeModal}>
                <RiCloseLine />
            </div>
            <div className="login-register-modal">
                <div className="auth-modal-header">
                    <img src={logo_header} alt="Leagues.gg" />
                    <h2 className="bold uppercase">Join Tournament</h2>
                </div>
                <div className="col-12 pb-3">
                    {/* col-12 text-center p-3 text-12 */}
                    <p className="text-12 text-grey text-center">
                        Sign-up with a team and choose the desired roles for the players. These roles can be changed until the signup deadline.
                    </p>
                </div>
                <div className="auth-modal-tabs">
                    {tabs.map(({ name, title, disabled }) => (
                        <div key={name} className={`tab ${selectedTab === name ? "active" : ""} ${disabled ? "text-grey" : ""}`} onClick={disabled ? undefined : () => setSelectedTab(name)}>
                            {title}
                        </div>
                    ))}
                </div>
                <div className="overflow-hidden">
                    {tabs.map(({ name, component }) => selectedTab === name && <div key={name}>
                        {component}
                    </div>)}
                </div>
            </div>
        </Modal>
    )
}

export default TournamentJoinModal
