import React from "react";
import useTranslated from "../../../../helpers/translationHelper";

const MissingData = ({ text = "" }) => {
  const missingData = useTranslated('id_match_page_5')
  const missingDisc = useTranslated('id_match_page_(games)_0')
  return (
    <div>
      <div className="text-22 uppercase bold color-primary center" style={{ paddingTop: "45px", paddingBottom: "18px" }}>
        &gt; {missingData}_
      </div>
      <p className="text-14 faded center" style={{ maxWidth: "600px", margin: "0px auto" }}>
        This page currently has no data, sorry! {text}
      </p>
    </div>
  );
};

export default MissingData;
