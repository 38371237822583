import React from "react";
import NewsItem from "@components/News/NewsItem/NewsItem";
const RenderFeaturedNewsItem = ({ post }) => {
    if (post === null || typeof post[0] === "undefined") {
      return <div>Loading</div>;
    }
  
    const { title, feature_image, excerpt, published_at, slug, primary_tag } = post[0];
  
    return <NewsItem large tag={primary_tag} slug={slug} title={title} image={feature_image} excerpt={excerpt} published={published_at} />;
  };

  export default RenderFeaturedNewsItem;