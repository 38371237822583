import React from "react";

const ChampionsList = ({ champions }) => {
  let items = champions ?? [];

  return (
    <React.Fragment>
      {items.map((item, i) => {
        let color = item.wr > 50 ? "color-success" : "color-danger";
        return (
          <div className="champions-list" key={i}>
            <img src={item.image} alt={item.name} />
            <div className="team-stat-list-subitem flex-expand">
              <span className="text-10 bold">{item.name}</span>
              <span className="text-10 bold uppercase faded">{item.kda} kda</span>
            </div>
            <div className="team-stat-list-subitem">
              <span className={`text-10 bold uppercase ${color}`}>{item.wr}% wr</span>
              <span className="text-10 bold uppercase faded">{item.games} {item.games > 1 ? 'games' : 'game'}</span>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default ChampionsList;
