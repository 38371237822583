const usersReducer = (state, action) => {
  switch (action.type) {
    case "load": {
      return action.payload;
    }
    case "update": {
      return state ? { ...state, ...action.payload } : { ...action.payload };
    }
    default: {
      throw new Error();
    }
  }
};

export default usersReducer;
  