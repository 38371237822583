import React, { useState, useEffect, useContext } from "react";
import HomeHeroCustomized from "./HomeSegments/HomeHeroCustomized";
import Slider from "./Slider/Slider";
import { Button, SliderItem, Icon, Advert, Loading } from "@common";
import { AppContext } from "../../context/AppContext";
import Axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import ActiveTournaments from "./ActiveTournaments/ActiveTournaments";
import RenderNewsItems from "./HomeSegments/RenderNewsItems";
import RenderFeaturedNewsItem from "./HomeSegments/RenderFeaturedNewsItem";
import DotPattern from "./HomeSegments/DotPattern";
import ImageWithContent from "./HomeSegments/ImageWithContent";
import { RiCheckLine } from "react-icons/ri";

const Home = () => {
  const [pageData,setPageData] = useState([]);
  const [loading,setLoading] = useState(true);
  const { selectedGame, setSelectedGame, authenticated, user } = useContext(AppContext);
  useEffect(() => {
    if(selectedGame && selectedGame.frontpage){
            
      setPageData(selectedGame.frontpage.OrgUpgrade); //If Loggedin user is Org Updated
           
    }
    setLoading(false);
}, [selectedGame]);

  return (
    <>
      <div className="home-hero-container" > 
        <DotPattern />
        <HomeHeroCustomized 
          backgroundType={`image`} // image or video
          backgroundMedia={selectedGame.frontPageImage || '/static/media/image.fadeddec.jpg'} // File URL
          subheading='ORGANIZE, ANALYZE AND MANAGE WITH LEAGUES PRO (ORG Update)' // Small Top Text
          heading='TRY THE LEAGUES EXPERIENCE TODAY' //Big Heading
          showButton={true}
          buttonText="TRY FREE TODAY"
          buttonLink="#"
        />
      </div>
      
      <div className="container-fluid" style={{marginTop:'-225px'}} >
        <div className="cool-heading-container">
          <div className="icon-cross">
            <Icon name="cross" />
          </div>

          <div className="icon-play">
            <Icon name="play" />
          </div>

          <DotPattern />

         
        </div>
        {loading ? <Loading />  : 
          pageData.map((value,key) => {
          return <ImageWithContent 
            isOdd={!(key % 2  == 0)} 
            Heading={value.segmentData && value.segmentData.Heading} 
            SubHeading={value.segmentData && value.segmentData.SubHeading} 
            Image={value.segmentData && value.segmentData.image} 
            Description={value.segmentData && value.segmentData.Description} 
          />
        })}


        <div className="row smaller-gutter-row image-with-content-area">
            
            <div className="col-6 right">
              <div className="col-container mb-5 mt-5">
                <div className="pricing floatright card-deck flex-column flex-md-row mb-3">
                  <div className="card card-pricing text-left px-3 mb-4">
                    <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm status">MOST POPULAR</span>
                        
                    <div className="bg-transparent card-header pt-4 border-0">
                      <h1 className="h1 font-weight-normal text-primary text-left mb-0" data-pricing-value="15"><span className="h6 text-muted ml-2 text-left">PREMIUM</span></h1>
                      <span className="planPrice">$7.99 / YEAR</span>
                      <p className='price-desc'>Pay out prize money directly through Leagues.gg, by adding your Lunar bank credentials</p>
                    </div>
                    <div className="card-body pt-0">
                      <ul className="list-unstyled mb-4 p-0 featureListing">
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Access the Leagues website editor and built your very own esport organisation page</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Team and organisation calendar</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Customizable sponsor page</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Customizable news page</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Get access to the analysis tool</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Manage contracts with the contract system</li>
                      </ul>
                      <div className='text-center'>
                        <Button name="cta-button" >
                        Try for free today
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="col-6 right">
              <div className="col-container mb-5 mt-5">
                <div className="pricing card-deck flex-column flex-md-row mb-3">
                  <div className="card card-pricing text-left px-3 mb-4">
                       
                    <div className="bg-transparent card-header pt-4 border-0">
                      <h1 className="h1 font-weight-normal text-primary text-left mb-0" data-pricing-value="15"><span className="h6 text-muted ml-2 text-left">MEDIUM</span></h1>
                      <span className="planPrice">$7.99 / YEAR</span>
                      <p className='price-desc'>Pay out prize money directly through Leagues.gg, by adding your Lunar bank credentials</p>
                    </div>
                    <div className="card-body pt-0">
                      <ul className="list-unstyled mb-4 p-0 featureListing">
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Team and organisation calendar</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Customizable sponsor page</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Customizable news page</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Get access to the analysis tool</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Manage contracts with the contract system</li>
                      </ul>
                      <div className='text-center'>
                        <Button name="cta-button" >
                        Try for free today
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
          
        </div>

<p className='trailNote'>Terms and conditions apply. 7 day free trial not available for users who have already tried PRO.<br /><a href="">Contact Us</a> if you have questions.</p>
      </div>
    
    </>
  );
};

export default Home;
