import React, { useState, useEffect } from "react";
import { MirrorText, SimplePageWithTabs } from "@common";
import TeamsItem from "@components/TeamsRouter/TeamsItem/TeamsItem";
import Axios from "axios";
import { CreateTeamModal } from "../../_modals/CreateTeamModal/CreateTeamModal";
import { set } from "core-js/library/fn/dict";
import { getGameBackground } from "../../../utils/additional-helpers";
import useTranslated from "../../../helpers/translationHelper";
const TeamsTab = ({ filter, loading, teams, createTeam, setTeams, setCreateTeam }) => {
  const [createTeam2,setCreateTeam2] =useState(false)
  return(
  <div>
    <div className="row smaller-gutter-row">
      <div className="col-12">
        <div className="row smaller-gutter-row">
          {!loading ? (
            teams.length ? (
              filter !== "all" ? (
                teams
                  .filter(({ game }) => game.shortName === filter)
                  .map((team) => <TeamsItem key={team._id}  croppedLogo={team.logoCropped} classes="col-4" {...team} link_to={`/teams/${team._id}`} />)
              ) : (
                teams.map((team) => <TeamsItem key={team._id}  croppedLogo={team.logoCropped} classes="col-4" {...team} link_to={`/teams/${team._id}`} />)
              )
            ) : (
              <div className="league-no-matches">
                <p>You’re currently not part of any team</p>
              </div>
            )
          ) : (
            [...Array(12).keys()].map((item) => <TeamsItem key={item} loading classes="col-4" />)
          )}
        </div>

        <CreateTeamModal open={createTeam2} onClose={() => setCreateTeam2(false)} afterCreate={(newTeam) => setTeams([...teams, newTeam])} />

        <button className="button wide-primary" onClick={() => setCreateTeam2(true)} style={{ backgroundColor: getGameBackground() }}>
          Create New Team
        </button>
      </div>
      <div className="col-3">
        <div className="info-placeholder">
          <span>advertisement</span>
        </div>
      </div>
    </div>
  </div>
);
}

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [createTeam, setCreateTeam] = useState(false);

  const [loading, setLoading] = useState(true);
  const [allTeams, setAllTeams] = useState([]);


  const trams=useTranslated('id_teams_overview_page_0')
  const Createnewteam=useTranslated('id_teams_overview_page_1')
  const alltab=useTranslated('id_teams_overview_page_2')
  const CH4LL3NG3RD14M0ND=useTranslated('id_teams_overview_page_3')
  const PL4T1N1UM=useTranslated('id_teams_overview_page_4')
  const Nodivision=useTranslated('id_teams_overview_page_5')


  useEffect(() => {
    if (teams.length === 0) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/myTeams`).then(({ data }) => {
        setTeams(data);
        setAllTeams((data && data.length) ? data.filter(team => !!team.game) : []);
        setLoading(false);
      });
    }
  }, []);

  /**
   * dynamic tabs to only show the tabs of the games having one or more teams.
   */
  const calculatedTabs = [...new Set(allTeams.map((team) => team?.game?._id))]
    .map((_gameId) => allTeams.find(({ game }) => game._id === _gameId))
    .map(({ game: { name, shortName } }) => ({
      [`${name} (${allTeams.filter((_team) => _team.game.name === name).length})`]: (
        <TeamsTab
          filter={shortName}
          loading={loading}
          teams={allTeams}
          createTeam={createTeam}
          setTeams={setAllTeams}
          setCreateTeam={setCreateTeam}
        />
      ),
    }));

  const tabs = {
    [`${alltab} (${allTeams.length})`]: (
      <TeamsTab
        filter={`all`}
        loading={loading}
        teams={allTeams}
        createTeam={createTeam}
        setTeams={setAllTeams}
        setCreateTeam={setCreateTeam}
      />
    ),
    ...calculatedTabs.reduce((acc, tab) => ({ ...acc, ...tab }), {}),
  };

  return (
    <SimplePageWithTabs
      isTeams
      title={trams}
      tabs={tabs}
      type="teams"
      top_right={
        <React.Fragment>
          <MirrorText text={[CH4LL3NG3RD14M0ND, PL4T1N1UM]} is_static />
          <CreateTeamModal
            open={createTeam}
            onClose={() => setCreateTeam(false)}
            afterCreate={(newTeam) => setTeams([...teams, newTeam])}
          />
          <button className="button primary ml-4" onClick={() => setCreateTeam(true)}>
            {Createnewteam}
          </button>
        </React.Fragment>
      }
    />
  );
};

export default Teams;
