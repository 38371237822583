import React, { useState } from "react";
import { FaMoon, FaSun, FaLeaf, FaCanadianMapleLeaf, FaRegSnowflake, FaQuestion } from "react-icons/fa";
import { ordinalSuffixOf, getCountryCode } from "@utils";
import { Icon } from "@common";
import { convertToInternationalCurrencySystem } from "../../../utils/additional-helpers";
import { toast } from "react-toastify";

const FilterTabItem = ({ tab, filters, setFilters, setIsFilterSet }) => {
  const Icons = {
    Spring: FaLeaf,
    Summer: FaSun,
    Fall: FaCanadianMapleLeaf,
    Winter: FaRegSnowflake,
    Offseason: FaMoon,
    Other: FaQuestion,
  };

  const dynamicIcon = (name) => {
    let IconComp;
    if (name !== null) IconComp = Icons[name];
    else IconComp = Icons["Other"];
    return <IconComp />;
  };
  const setSelection = (filter, index) => {
    let count = 0;
    filter.forEach((f) => {
      if (f.selected) count = count + 1;
    });
    setIsFilterSet(true);
    if (count === 1 && filter[index].selected) {
      filter[index].selected = true;
      // if (index === 0) filter[0].selected = false;
      return;
    }
    filter[index].selected = !filter[index].selected;

    filters[tab] = filter;
    setFilters({ ...filters });
  };
  // const selectAtleastOne = (filter = [], index) => {
  //   let count = 0;
  //   filter.forEach((f) => {
  //     if (f.selected) count = count + 1;
  //   });
  //   if (count === 1 && filter[index].selected) {
  //     filter[index].selected = !filter[index].selected;
  //     filter[0].selected = true;
  //     filters[tab] = filter;
  //     // toast.warn("Atleast one item must be selected from each tab!");
  //     // return false;
  //   }
  //   return true;
  // };

  return (
    <div className="filter-tab-content">
      {filters &&
        filters[tab].map(({ name, matches, category, selected }, index) => {
          return (
            <div className={`filter-row  ${index == filters[tab].length - 1 ? 'no-border' : ''}`} onClick={() => setSelection(filters[tab], index)}>
             <div className={`filter-content ${selected ? "selectedRow" : ""}`} style={{display: 'flex'}}>
                <div className="filter-item">
                  {tab === SELECTEDTAB.SEASON ? (
                    dynamicIcon(category)
                  ) : tab === SELECTEDTAB.NATIONALITY ? (
                    <React.Fragment>
                      <img src={`https://flagcdn.com/w20/${getCountryCode(name)?.toLowerCase() || "id"}.png`} alt="Flag" />
                    </React.Fragment>
                  ) : (
                    <div className="text-14 bold" style={{display: 'flex', alignItems: 'center'}}>
                      <Icon
                        name={
                          name === 0
                            ? "trophy-icon-gold"
                            : name === 1
                              ? "trophy-icon-silver"
                              : name === 2
                                ? "trophy-icon-bronze"
                                : "trophy-icon"
                        }
                        without_wrapper
                      />
                    </div>
                  )}

                  <span className="uppercase border" style={{ fontSize: "13px", fontWeight: "bold", marginLeft: "7px" }}>
                    {tab === SELECTEDTAB.DIVISION && name !== "Uncategorized" ? ordinalSuffixOf(name) : name}
                  </span>
                </div>
                <div className="uppercase bold item-count">{convertToInternationalCurrencySystem(matches)}</div>
              </div>

            </div>
          );
        })}
    </div>
  );
};

export default FilterTabItem;

const SELECTEDTAB = {
  SEASON: "seasons",
  NATIONALITY: "groups",
  DIVISION: "divisions",
};
