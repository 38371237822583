import React, { useEffect, useState } from "react";
import { SortableList, SortableLeadBoardListItem, Loading } from "@common";
import Axios from "axios";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
import moment from "moment";

const LeadBoard = ({ leagueId, league }) => {
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState({});
  const [leagueFromGame, setLeagueFromGame] = useState(null);

  useEffect(() => {
    if (league && league.game && league.game.shortName) {
      setLeagueFromGame(league.game.shortName);
    }
  }, [league]);
  // console.log('league-',leagueFromGame);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${leagueId}/leaderboard`).then(({ data }) => {
      setTeams(data.teams);
      setLoading(false);
    });
  }, [leagueId]);

  const getCalculatedRD = (latestMatches = [], teamId = null) => {
    let sumOfRD = Number(0);
    if (latestMatches.length && teamId !== null) {
      latestMatches.map((lmatch) => {
        lmatch.csgoData &&
          lmatch.csgoData.mapData.map((singMap) => {
            singMap &&
              Object.values(singMap.processedteamData).map((singleTeamMap) => {
                // if(singleTeamMap.roundDiffrence > 0 && singleTeamMap.teamDbId === teamId){ // Uncomment if only calculate positive numbers
                if (singleTeamMap.teamDbId === teamId) {
                  sumOfRD = sumOfRD + singleTeamMap.roundDiffrence;
                }
              });
          });
      });
    }

    if (Number(sumOfRD) > 0) {
      return <span style={{ color: "#22db60" }}>+{sumOfRD}</span>;
    } else if (Number(sumOfRD) < 0) {
      return <span style={{ color: "#fd1049" }}>{sumOfRD}</span>;
    } else {
      return 0;
    }
  };

  return loading ? (
    <div className="text-center league-no-matches">
      <Loading width={50} height={50} />
    </div>
  ) : (
    <div className="pb-5">
      <h2 className="text-16 bold uppercase pt-0 pb-4 m-0">{teams.length} teams</h2>

      <SortableList
        config={[
          { col: 1, title: "#", prop_name: "number", is_sortable: true, alignment: "left" },
          { col: 3, title: "Team", prop_name: "team", alignment: "left" },
          { col: 2, title: "Score", prop_name: "score", alignment: "right" },
          leagueFromGame && leagueFromGame === "CSGO" && { col: 1, title: "RD", prop_name: "rd", alignment: "right" },
          { col: leagueFromGame && leagueFromGame === "CSGO" ? 1 : 2, title: "Matches", prop_name: "matches", alignment: "right" },
          { col: 1, title: "Wins", prop_name: "wins", alignment: "right" },
          { col: 1, title: "Losses", prop_name: "losses", alignment: "right" },
          { col: 2, title: "Recent Matches", prop_name: "recent_matches", alignment: "right" },
        ]}
      >
        {teams
          .sort((a, b) => league.overviewManualSort ? a.order -b.order : b.score - a.score)
          .map(({ _id, team, score, matches, mapsWon, mapsLost, latestMatches }, i) => (
            <SortableLeadBoardListItem
              key={_id}
              number={i + 1}
              team={
                <>
                  <Link to={`/teams/${team._id}`} className="text-12 primary-link text-white">
                    <img
                      src={team.logo}
                      alt={team.name}
                      className={
                        team?.logoCropped || team?.logoCropped === undefined
                          ? "image-40 border-radius-100"
                          : "image-40"
                      }
                      style={{ display: "inline-block", verticalAlign: "middle" }}
                    />
                    <span style={{ display: "inline-block", verticalAlign: "middle" }}>{team.name}</span>
                  </Link>
                </>
              }
              score={score}
              rd={getCalculatedRD(latestMatches, team._id)}
              leagueFromGame={leagueFromGame}
              matches={matches}
              wins={mapsWon}
              losses={mapsLost}
              recent_matches={Array(5)
                .fill()
                .map((_, i) =>
                  latestMatches[i] ? (
                    <Tippy
                      content={`${latestMatches[i]?.t1?.name || "No team name"} ${latestMatches[i]?.t1Score} - ${
                        latestMatches[i]?.t2Score
                      } ${latestMatches[i]?.t2?.name || "No team name"} (${moment(latestMatches[i]?.datetime).fromNow()})`}
                      placement="top"
                      animation="shift-toward"
                    >
                      <Link
                        to={`/match/${latestMatches[i]._id}`}
                        className={`pill small round ${i === 3 ? "size-75" : i === 4 ? "size-50" : ""} ${
                          latestMatches[i]?.win === true
                            ? "success"
                            : latestMatches[i]?.win === false
                            ? "danger"
                            : latestMatches[i]?.draw === true
                            ? "warning"
                            : ""
                        }`}
                      />
                    </Tippy>
                  ) : (
                    <span className={`pill small round ${i === 3 ? "size-75" : i === 4 ? "size-50" : ""}`}></span>
                  )
                )}
              listType="league"
            />
          ))}
      </SortableList>
    </div>
  );
};

export default LeadBoard;
