import React, { useState, useEffect, useContext } from "react";
import Slider from "../Slider/Slider";
import { useHistory, useParams } from "react-router-dom";
import { Button, SliderItem, Icon, Advert, AdvertByGame } from "@common";
import { AppContext } from "../../../context/AppContext";
import Axios from "axios";

const LeaguesManual = ({ data }) => {
  // console.log('Leagues_SeasonSG--datya-0-',data)
  const { selectedGame, setSelectedGame } = useContext(AppContext);
  const history = useHistory();
  const [loadingLeagues, setLoadingLeagues] = useState(true);
  const [seasons, setLeagues] = useState([]);
  const [blueText, setBlueText] = useState("Loading...");
  const [whiteText, setWhiteText] = useState("Loading...");
  const [selectedLeagues, setselectedLeagues] = useState(null);

  useEffect(() => {
    if (data.BlueText) {
      setBlueText(data.BlueText);
    }
    if (data.WhiteText) {
      setWhiteText(data.WhiteText);
    }
    if (data.leagues && data.leagues.length) {
      setselectedLeagues(data.leagues.map(({ _id }) => _id).toString());
    }
  }, [data]);

  // console.log('seasons-',seasons)

  const getFeaturedLeagues = (selectedLeagues) => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/findByIds/`, {
      params: { ids: selectedLeagues },
    }).then(({ data }) => {
      setLeagues(data);
      setLoadingLeagues(false);
    });
  };

  useEffect(() => {
    // featured seasons
    selectedLeagues && getFeaturedLeagues(selectedLeagues);
  }, [selectedGame, selectedLeagues]);

  return (
    <>
      <Slider
        isArrowBg
        key={`${blueText}-slide-${blueText}`}
        info={
          <div>
            <h1>{whiteText}</h1>
            <h1 className="outline">{blueText}</h1>
            <Button onClick={() => history.push("/leagues")} name="cta-button-transparent">
              All Leagues
            </Button>
          </div>
        }
      >
        {seasons.length &&
          [1, 2, 3, 4, 5].map(() => {
            return seasons.map(({ _id, name, thumbnailImage, leagueDivisionName, country = {}, teams }) => (
              <SliderItem
                key={_id}
                title={name}
                second_line={
                  <React.Fragment>
                    <img src={`https://flagcdn.com/w20/${country.code?.toLowerCase()}.png`} alt="Danish flag" />
                    {country.name}
                  </React.Fragment>
                }
                third_line={`${teams.length} teams participating`}
                tag={leagueDivisionName}
                image={thumbnailImage}
                link_to={`/league/${_id}`}
              />
            ));
          })}
      </Slider>
    </>
  );
};
export default LeaguesManual;
