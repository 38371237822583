import React from 'react';
import { Draggable, state } from 'react-page-maker';
import { RiCloseLine } from 'react-icons/ri';
import ReactPlayer from 'react-player'
import { toast } from "react-toastify";
import './style.scss';
class DraggableVideo extends React.Component {
  state = {
    showColorPicker: false,
    background: '',
    description: 'descriptiondescription',
    toggleEdit:false,
    url:'https://www.youtube.com/watch?v=dgwXgi2BVXs'
    
  };

  toggleEditField = (fieldName) => {
    if(this.validURL(this.state.url)){
      this.setState({toggleEdit: fieldName});
    }else{
      toast.success("Please check URL carefully.");
    }
    
  }
  

  validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  changePayload = (payLoadKey,value) => {
  
      const { id, dropzoneID, parentID,payload }= this.props;
      this.setState({ [payLoadKey]: value }, () => {
        state.updateElement(id, dropzoneID, parentID, {
          payload: { ...payload, [payLoadKey]: value }
        });
      });
  
    
    // this.setState({toggleEdit: ''});
  }

  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }

  
  render() {
    const {
      id, showBasicContent, showPreview,
      dropzoneID, parentID, 
      // name,
       payload
    } = this.props;

   
    const {toggleEdit} =  this.state;
    
    // const heading =  payload && payload.heading || this.state.heading;
    const url =  payload.url || this.state.url;
    
    

    if (showBasicContent) {
      // console.log('this.propsthis.props',this.props)
      const {name} = this.props;
      return (
        <>
        <Draggable
          { ...this.props } 
      >{name}</Draggable></>
      )
    }

    if (showPreview) {
      return (
        <>
        <header >
        <h2 className="pb_ele_header_heading">VIDEO</h2>
        <ReactPlayer config={{
          youtube: {
            playerVars: { showinfo: 1 }
          }
        }} playing={true} loop={true} controls={false} width='100%' url={url} />
         </header>
         </>
      );
    }

    return (
      <Draggable { ...this.props } >
        <div className="actions">
            <span className="action_type" title="Remove element"><RiCloseLine onClick={() => state.removeElement(id, dropzoneID, parentID)} color='white' /></span>
          </div>
        <header >
        
        <h2 className="pb_ele_header_heading">VIDEO</h2>
        <ReactPlayer 
        config={{
          youtube: {
            playerVars: { showinfo: 1 }
          }
        }}
        playing={true} loop={true} controls={false} width='100%' url={url} />

          <br/>
          {toggleEdit === false && <button className="button wide-primary socialIconSave" onClick={() => this.toggleEditField('url')} >
                Edit URL
            </button>
            }
          {toggleEdit === 'url' && <>
            <input placeholder='Ex. https://www.youtube.com/watch?v=ysz5S6PUM-U' className="form-input" type="url" value={url} onChange={(value) => this.changePayload('url',value.target.value)}/>
            <button className="button wide-primary socialIconSave" onClick={() => this.toggleEditField(false)} >
                Save URL
            </button></>
            }
       
        </header>
      </Draggable>
    )
  }
};

export default DraggableVideo;
