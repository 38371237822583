import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@common";
import default_picture from "@img/finder-card-image.png";
import { makeCountdownString, getTimeString, getTeamsAvgRank, ordinalSuffixOf } from "@utils";
import moment from "moment";
import { AppContext } from "../../../context/AppContext";
import { applyBrowserTimezone, getCurrentTimeZone } from "../../../utils/time-functions";
import { isMemberOfTeam } from "../../../utils/additional-helpers";
import { JoinScrimModal } from "../../_modals/JoinScrimModal/JoinScrimModal";

const FinderCard = ({ color, scrim, button_label, button_link_to, item, showApplications, isScrimUser, croppedLogo }) => {
  let classes = "";
  const { user, selectedGame, authenticated } = useContext(AppContext);
  const [contentShow, setContentShow] = useState(true);
  const [countDown, setCountDown] = useState("");
  const [teamSelectModal, setTeamSelectModal] = useState(false);
  const [hasTeams, setHasTeams] = useState(false);
  if (typeof scrim !== "undefined") {
    classes += "scrim-finder";
  }

  const {
    division,
    rank,
    minRank,
    maxRank,
    date,
    numberOfMatches,
    game,
    teamMatches,
    map,
    createdBy,
    acceptedMatchUp,
    timeZone,
    team: { initials, _id, name = null, logo, nationality, members },
  } = item || {};

  useEffect(() => {
    const _interval = setInterval(() => {
      let now = moment();
      now = timeZone ? now.tz(timeZone) : now;
      let _date = moment(date);
      _date = timeZone ? _date.tz(timeZone) : _date;
      // const now = moment();
      // console.log("timezone -- ", _date, timeZone);
      var duration = _date.diff(now, "milliseconds", true);
      if (duration > 0) setCountDown(makeCountdownString(duration));
      else {
        clearInterval(_interval);
      }
    }, 1000);
  }, [date]);

  // const getTeamsAvgRank = (teammembers) => {
  //   if(!teammembers.length || teammembers === []){return "No Rank"}
  //   let mainPlayersType = ["Player:TOP", "Player:JUNGLE", "Player:MID", "Player:BOT", "Player:SUPPORT"];
  //   const mainPlayers = teammembers.filter(({ role }) => mainPlayersType.includes(role));
  //   let arrayofPlayerRanks = [];
  //   // console.log('mainPlayers-',mainPlayers)
  //   mainPlayers.filter(({ player }) => {
  //     if(player){
  //      const leagueEntry = player.lolData.leagueEntries.find(({ queueType }) => queueType === "RANKED_SOLO_5x5") || {};
  //      arrayofPlayerRanks.push(`${leagueEntry.tier} ${leagueEntry.rank}`)
  //     }
  //   })
  //   if(!arrayofPlayerRanks.length || arrayofPlayerRanks === []){return "No Rank"}
  //   let calculatedRank = Math.round(arrayofPlayerRanks.map((rank) => game.gameRanks.indexOf(rank)).reduce((a, b) => a + b, 0)/arrayofPlayerRanks.length);
  //   return `${game.gameRanks[calculatedRank]}`;
  // }

  const getTeamsWinRate = (teamMatches) => {
    if (teamMatches === [] || !teamMatches.length) {
      return (
        <span>
          <span className="text-10 bold uppercase color-success">{`0W`}</span> <span className="text-10 bold uppercase faded">{`0L`}</span>{" "}
          {`(0% WR)`}
        </span>
      );
    }
    var winMatches = teamMatches.filter(({ win }) => win === true);
    var winrate = (winMatches.length * 100) / teamMatches.length;
    return (
      <span>
        <span className="text-10 bold uppercase color-success">{`${winMatches.length}W`}</span>{" "}
        <span className="text-10 bold uppercase faded">{`${teamMatches.length - winMatches.length}L`}</span>{" "}
        {`(${Math.round(winrate)}% WR)`}
      </span>
    );
  };
  return (
    <div className={`finder-card bg-faded mt-3 mb-3 ${color} ${classes}`}>
      <header>
        <div className="finder-card-info">
          <Link to={`/teams/${_id}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
            {" "}
            <img
              src={logo || default_picture}
              alt="PP"
              className={croppedLogo || croppedLogo === undefined ? "finder-card-image" : "finder-card-image-withoutCropped"}
            />{" "}
          </Link>
          <div className="finder-card-info-text">
            <div className="finder-card-info-top">
              <Link to={`/teams/${_id}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                <span className="bold">{name}</span>
              </Link>

              <span className="pill">
                {getTimeString(date, timeZone)}
                {`  ${applyBrowserTimezone(date, timeZone).format("HH:mm")}`}
                {`  ${applyBrowserTimezone(date, timeZone).zoneAbbr()}`}
              </span>
              {countDown && (
                <span className="pill warning">
                  <Icon name="clock-icon-white" iconStyle={{ marginRight: "0px", verticalAlign: "bottom" }} />
                  STARTS IN: {countDown}
                </span>
              )}
              <div style={{ display: "inline-block" }}>
                {showApplications && (item.acceptedMatchUp || isScrimUser) && (
                  <span className="pill primary">
                    {!item.acceptedMatchUp
                      ? item.matchups
                        ? `${item.matchups} Application${item.matchups != 1 ? "s" : ""}`
                        : "No Applications"
                      : "Scrim Found"}
                  </span>
                )}
              </div>
            </div>
            <div className="finder-card-info-bottom">
              <div className="customRow">
                <img
                  src={`https://flagcdn.com/w20/${nationality?.code?.toLowerCase()}.png`}
                  width={20}
                  height={13}
                  alt={nationality.name}
                />
                <span className="faded uppercase text-12" style={{ marginLeft: "5px" }}>
                  {`  ${nationality?.name}`}, Euw
                </span>
              </div>
              {/* {showApplications && (item.acceptedMatchUp || isScrimUser) && (
                <span className="pill primary">
                  {!item.acceptedMatchUp
                    ? item.matchups
                      ? `${item.matchups} Application${item.matchups != 1 ? "s" : ""}`
                      : "No Applications"
                    : "Scrim Found"}
                </span>
              )} */}
            </div>
          </div>
        </div>

        <div className="finder-card-actions">
          <Link to={button_link_to ?? "/"}>
            <button className="button bordered  ml-3 mr-3">{button_label ?? "Invite to team"}</button>
          </Link>
          {acceptedMatchUp === null &&
            (authenticated && user.state
              ? createdBy &&
                user &&
                createdBy._id !== user.state._id &&
                !isMemberOfTeam(item.team, user.state._id) && (
                  <button
                    className={`button primary`}
                    onClick={() => setTeamSelectModal(true)}
                    disabled={hasTeams ? false : true}
                    style={!hasTeams ? { backgroundColor: "#68717e" } : {}}
                  >
                    Apply
                  </button>
                )
              : null)}
          <button className="content-toggle" onClick={() => setContentShow(!contentShow)}>
            <Icon name="angle" />
          </button>
        </div>
      </header>
      {typeof scrim === "undefined" ? (
        <div className={contentShow ? "finder-card-content show" : "finder-card-content"}>
          <div className="row smaller-gutter-row">
            <div className="col-8">
              <div className="faded bold uppercase">Description</div>
              <p>
                Im a midmain and have played the game for nearly 10 years. The past 6 seasons, i have been plat or above and the past 2
                seasons i have been a diamond player jumping around the different divisions.
              </p>
              <p>
                Prefer playing control mages, but can also do assassins if it suits the comp. Im a flexible player, but still open to learn
                new champions if needed. Furthermore I got a decent amount of team experience from playing on my last team (VHE).
              </p>
              <a href="/" className="primary-link">
                View profile
              </a>
            </div>
            <div className="col-4">
              <div className="faded bold uppercase pb-2 ">Languages</div>
              <span>Danish and English</span>
              <div className="faded bold uppercase pb-2 pt-3">Age</div>
              <span>21</span>
            </div>
          </div>
        </div>
      ) : null}
      <footer>
        {game.shortName === "CSGO" ? (
          <div>
            <div className="faded bold uppercase">Division</div>
            <div className="bold">{ordinalSuffixOf(division)} division</div>
            {/* <div className="bold">{getTeamsAvgRank(members,game)}</div> */}
          </div>
        ) : (
          <div>
            <div className="faded bold uppercase">average rank</div>
            <div className="bold">{rank}</div>
            {/* <div className="bold">{getTeamsAvgRank(members,game)}</div> */}
          </div>
        )}
        <div>
          <div className="faded bold uppercase">number of matches</div>
          <div className="bold">
            {numberOfMatches} {numberOfMatches > 1 ? "Matches" : "Match"}
          </div>
        </div>
        {game.shortName === "CSGO" && (
          <div>
            <div className="faded bold uppercase">map type</div>
            <div className="bold">{map}</div>
          </div>
        )}
        <div>
          <div className="faded bold uppercase">winrate</div>
          <div className="bold">
            <div className="bold">{getTeamsWinRate(teamMatches)}</div>

            {/*  */}
          </div>
        </div>
        {game.shortName === "CSGO" ? null : (
          <div>
            <div className="faded bold uppercase">Looking for opponent</div>
            <div className="bold">
              {game.gameRanks[minRank]} - {game.gameRanks[maxRank]}
            </div>
          </div>
        )}
      </footer>
      {authenticated && user.state && (
        <JoinScrimModal
          open={teamSelectModal}
          setUserHasTeams={(isTrue) => setHasTeams(isTrue)}
          onClose={() => setTeamSelectModal(false)}
          scrimId={item._id}
        />
      )}
    </div>
  );
};

export default FinderCard;