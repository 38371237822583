import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Loading, SimpleTabs } from "@common";
import PrizesTable from "./PrizesTable";

const Prizes = ({id}) => {
  const [loading, setLoading] = useState(true);
  const [allStagePrizes, setAllStagePrizes] = useState(null);
  const [allStages, setAllStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState(null);
  const [page, setPage] = useState(0);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/prizes`).then(({ data }) => {
      const notHiddenStagePrizes = [];
      const stageNames = [];
      data.data.forEach(stage => {
        if(stage.stageType === 'league'){
          if(!stage.league.hidePrizes){
            stageNames.push(stage.name);
            notHiddenStagePrizes.push(stage);
          }
        }else{
          if(!stage.tournament.hidePrizes){
            stageNames.push(stage.name);
            notHiddenStagePrizes.push(stage);
          }
        }
      });
      notHiddenStagePrizes?.length > 0 && setSelectedStage(notHiddenStagePrizes[0]);
      setAllStages(stageNames);
      setAllStagePrizes(notHiddenStagePrizes);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if(allStagePrizes && allStagePrizes?.length > 0){
      setSelectedStage(allStagePrizes[page]);
    }
  }, [page]);

  return loading ? ( 
    <div className="text-center league-no-matches">
      <Loading width={50} height={50} />
    </div>
  ) : (
    allStagePrizes.length > 0 ?
      <div style={{marginTop:8}}>
        {allStagePrizes.length > 1 && <SimpleTabs pages={allStages} page={page} setPage={setPage} />}
        <div className="mt-5 row smaller-gutter-row player-overview-row pt-3 pb-5">
          <div className="col-3 row-title">
            <span className="ml-4 text-16 faded bold uppercase">Prizes</span>
          </div>
          <div className="col-9 row-content">
            <PrizesTable stage={selectedStage} />
          </div>
        </div>
      </div>
    :
    <div>
      <span><p>There are no prizes for this competition</p></span>
    </div>
  );
};

export default Prizes;
