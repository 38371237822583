import React from "react";
import { Item, SimplePageWithTabs } from "@common";

const Streams = () => {
  return (
    <SimplePageWithTabs title="Streams">
      <div className="under-contruction no-tabs-under">
        <div className="no-tabs">
          <div className="row smaller-gutter-row">
            <div className="col-4">
              <div className="row smaller-gutter-row">
                <Item column="12" link_to="/stream/1" />
              </div>
            </div>
            <div className="col-8">
              <div className="row smaller-gutter-row">
                <Item column="3" link_to="/stream/1" />
                <Item column="3" link_to="/stream/1" />
                <Item column="3" link_to="/stream/1" />
                <Item column="3" link_to="/stream/1" />
                <Item column="3" link_to="/stream/1" />
                <Item column="3" link_to="/stream/1" />
                <Item column="3" link_to="/stream/1" />
                <Item column="3" link_to="/stream/1" />
              </div>
            </div>

            <Item column="3" link_to="/stream/1" />
            <Item column="3" link_to="/stream/1" />
            <Item column="3" link_to="/stream/1" />
            <Item column="3" link_to="/stream/1" />
            <Item column="3" link_to="/stream/1" />
            <Item column="3" link_to="/stream/1" />
            <Item column="3" link_to="/stream/1" />
            <Item column="3" link_to="/stream/1" />
          </div>
        </div>
      </div>
    </SimplePageWithTabs>
  );
};

export default Streams;
