import React from "react";
import SlickSlider from "react-slick";
import styles from "./_arrowsBg.scss";
const Slider = ({ info, children, isArrowBg }) => {
  const settings = {
    className: `slider variable-width ${isArrowBg && "arrow-bg"}`,
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    speed: 150,
  };
  return (
    <div className="slider-container">
      <div className="slider-info">{info}</div>
      <div className="slider-items">
        <SlickSlider {...settings} style={{ overflow: "hidden" }}>
          {children}
        </SlickSlider>
      </div>
    </div>
  );
};

export default Slider;
