import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import Axios from "axios";
import moment from "moment";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { AuthModalContext } from "../../AuthModal";
import Loading from "../../../../_common/Loading/Loading";
import { countries } from "@utils";
import useTranslated from "../../../../../helpers/translationHelper";
import { Countries } from "../../../../../utils/transCountries";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const { registrationData, setRegistrationData, setStep } = useContext(AuthModalContext);
  const { register, handleSubmit, watch, errors, setError } = useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [nationalities, setNationalities] = useState([]);
  const _countries = Countries()
  let _first_n_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_0");
  let _placeholder_jhon_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_1");
  let _last_n_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_2");
  let _placeholder_doe_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_3");
  let _password_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_4");
  let _password2_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_5");
  let _c_password_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_6");
  let _c_password2_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_7");
  let _birthday_label_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_8");
  let _birthday_day_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_9");
  let _birthday_year_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_10");
  let _gender_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_11");
  let _nationality_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_12");
  let _email_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_13");
  let _email_id_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_14");
  let _continue_translation = useTranslated("id_pop-up_-_log_in_(sign_up)_15");




  const monthtranslation=useTranslated('id_utility_-_months_0')
  const Januarymonth=useTranslated('id_utility_-_months_1')
  const Februarymonth=useTranslated('id_utility_-_months_2')
  const Marchmonth=useTranslated('id_utility_-_months_3')
  const Aprilmonth=useTranslated('id_utility_-_months_4')
  const Maymonth=useTranslated('id_utility_-_months_5')
  const Junemonth=useTranslated('id_utility_-_months_6')
  const Julymonth=useTranslated('id_utility_-_months_7')
  const Augustmonth=useTranslated('id_utility_-_months_8')
  const Septembermonth=useTranslated('id_utility_-_months_9')
  const Octobermonth=useTranslated('id_utility_-_months_10')
  const Novembermonth=useTranslated('id_utility_-_months_11')
  const Decembermonth=useTranslated('id_utility_-_months_12')
  useEffect(() => {
    // Get list of nationalities from external API (europe only)
    //https://restcountries.eu/rest/v2/all
    //Axios.get("https://restcountries.eu/rest/v2/region/europe?fields=demonym;alpha2Code", { headers: false }).then(({ data }) => {
    // Axios.get("https://restcountries.eu/rest/v2/all?fields=demonym;alpha2Code", { headers: false }).then(({ data }) => {
    //   setNationalities(
    //     data
    //       .filter(({ demonym }) => demonym)
    //       .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    //       .sort((a, b) => a.demonym.localeCompare(b.demonym))
    //   );
    // });
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, []);
  const getCountryNameTrans= (code,demonym,countries)=>{
    if(countries && countries.length > 0){
      let country = [..._countries].find(el=> el.alpha2Code === code)
      if(country){
      return country && country.demonym
      }else{
        return demonym
      }
    }
    }
  const togglePswdVisibility = () => setPasswordVisible(!passwordVisible);

  const onSubmit = async (data) => {
    setLoading(true);

    // Check if email exists via API
    const { data: emailExists } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/users/emailExists/${data.email}`);
    if (emailExists) {
      setError("email", "emailExists", "Email already exists");
      return setLoading(false);
    }

    // Check if user is 10
    const birthday = moment(`${data.birthdayDay} ${data.birthdayMonth} ${data.birthdayYear}`);

    if (moment().diff(birthday, "years") < 10) {
      setError("birthday", "tooYoung", "You need to be at least 10 to sign up for Leagues");
      return setLoading(false);
    }

    setRegistrationData({ ...data, birthday: birthday.toISOString() });
    setLoading(false);
    return setStep(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row form-row sm-gutters">
        <div className="col-6">
          <label htmlFor="firstName">{_first_n_translation}</label>
          <input onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
            ref={register({ required: "Required", maxLength: { value: 200, message: "Name too long" } })}
            type="text"
            id="firstName"
            name="firstName"
            placeholder={_placeholder_jhon_translation}
            autoComplete="first-name"
            className={`form-input ${errors.firstName ? _first_n_translation : ""}`}
            defaultValue={registrationData.firstName}
          />
          {errors.firstName && <div className="form-input-error">{errors.firstName.message}</div>}
        </div>

        <div className="col-6">
          <label htmlFor="lastName">{_last_n_translation}</label>
          <input onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
            ref={register({ required: "Required", maxLength: { value: 200, message: "Name too long" } })}
            type="text"
            placeholder={_placeholder_doe_translation}
            id="lastName"
            name="lastName"
            autoComplete="last-name"
            className={`form-input ${errors.lastName ? _last_n_translation : ""}`}
            defaultValue={registrationData.lastName}
          />
          {errors.lastName && <div className="form-input-error">{errors.lastName.message}</div>}
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="password">{_password_translation}</label>
        <input
          ref={register({
            required: "Required",
            minLength: { value: 6, message: "Password must be at least 6 characters" },
            maxLength: { value: 30, message: "Password can't be more than 30 characters" },
          })}
          type={passwordVisible ? "text" : `password`}
          id="password"
          name="password"
          placeholder={_password_translation}
          maxLength={30}
          autoComplete="new-password"
          className={`form-input ${errors.password ? /* `${_password_translation}` */"invalid" : ""}`}
          defaultValue={registrationData.password}
        />
        <div onClick={togglePswdVisibility} className="toggle-pswd-visibility">
          {passwordVisible ? <RiEyeOffLine size="1.2rem" color="#666" /> : <RiEyeLine size="1.2rem" color="#666" />}
        </div>
        {errors.password && <div className="form-input-error">{errors.password.message}</div>}
      </div>

      <div className="form-row">
        <label htmlFor="confirmPassword">{_c_password_translation}</label>
        <input
          ref={register({ required: "Required", validate: (value) => value === watch('password') || "Passwords must match" })}
          type={passwordVisible ? "text" : `password`}
          id="confirmPassword"
          name="confirmPassword"
          placeholder={_c_password_translation}
          maxLength={30}
          autoComplete="confirm-new-password"
          className={`form-input ${errors.confirmPassword ? /* `${_c_password_translation}` */ "invalid" : ""}`}
          defaultValue={registrationData.confirmPassword}
        />
        <div onClick={togglePswdVisibility} className="toggle-pswd-visibility">
          {passwordVisible ? <RiEyeOffLine size="1.2rem" color="#666" /> : <RiEyeLine size="1.2rem" color="#666" />}
        </div>
        {errors.confirmPassword && <div className="form-input-error">{errors.confirmPassword.message}</div>}
      </div>

      <div className="form-row">
        <label htmlFor="birthdayDay">{_birthday_label_translation}</label>
        <div className="row form-row sm-gutters">
          <div className="col-3">
            <input
              type="number"
              ref={register({ required: "Required", min: 1, max: 31 })}
              id="birthdayDay"
              name="birthdayDay"
              className={`form-input ${errors.birthdayDay ? `${_birthday_day_translation}` : ""}`}
              defaultValue={registrationData.birthdayDay || ""}
              placeholder={_birthday_day_translation}
            />
            {errors.birthdayDay && <div className="form-input-error">{errors.birthdayDay.message}</div>}
          </div>

          <div className="col-6">
            <select
              ref={register({ required: "Required" })}
              id="birthdayMonth"
              name="birthdayMonth"
              className={`form-input ${errors.birthdayMonth ? "invalid" : ""}`}
              defaultValue={registrationData.birthdayMonth || ""}
            >
              <option value="" disabled>
                {monthtranslation}
              </option>
              {[Januarymonth, Februarymonth, Marchmonth, Aprilmonth, Maymonth, Junemonth, Julymonth, Augustmonth, Septembermonth, Octobermonth, Novembermonth, Decembermonth].map((month) => (
                <option value={month}>{month}</option>
              ))}
            </select>
            {errors.birthdayMonth && <div className="form-input-error">{errors.birthdayMonth.message}</div>}
          </div>
          <div className="col-3">
            <input
              type="number"
              ref={register({ required: "Required", min: 1900, max: moment().format("Y") })}
              id="birthdayYear"
              name="birthdayYear"
              className={`form-input ${errors.birthdayYear ? `${_birthday_year_translation}` : ""}`}
              defaultValue={registrationData.birthdayYear || ""}
              placeholder={_birthday_year_translation}
            />
            {errors.birthdayYear && <div className="form-input-error">{errors.birthdayYear.message}</div>}
          </div>
        </div>
      </div>

      <div className="row form-row sm-gutters">
        <div className="col-6">
          <label htmlFor="gender">{_gender_translation}</label>
          <select
            ref={register({ required: "Required" })}
            id="gender"
            name="gender"
            className={`form-input ${errors.gender ? `${_gender_translation}` : ""}`}
            defaultValue={registrationData.gender || ""}
          >
            <option value="" disabled>
              Choose {_gender_translation}
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
            <option value="Prefer not to say">Prefer not to say</option>
          </select>
          {errors.gender && <div className="form-input-error">{errors.gender.message}</div>}
        </div>

        <div className="col-6">
          <label htmlFor="nationality">{_nationality_translation}</label>
          <select
            ref={register({ required: "Required" })}
            id="nationality"
            name="nationality"
            className={`form-input ${errors.nationality ? `${_nationality_translation}` : ""}`}
            defaultValue={registrationData.nationality || ""}
          >
            <option value="" disabled>
              Choose {_nationality_translation}
            </option>
            {nationalities.map(({ alpha2Code, demonym }) => (
              <option key={alpha2Code} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
                {/* {demonym} */}
                {getCountryNameTrans(alpha2Code,demonym,_countries)}
              </option>
            ))}
          </select>
          {errors.nationality && <div className="form-input-error">{errors.nationality.message}</div>}
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="email">{_email_translation}</label>
        <input
          ref={register({ required: "Required" })}
          type="email"
          id="email"
          placeholder={_email_id_translation}
          name="email"
          autoComplete="email"
          className={`form-input ${errors.email ? `${_email_id_translation}` : ""}`}
          defaultValue={registrationData.email}
        />
        {errors.email && <div className="form-input-error">{errors.email.message}</div>}
      </div>

      <button type="submit" className="button primary thicc" disabled={loading}>
        {loading ? (
          <>
            <Loading width={30} height={30} /> Verifying
          </>
        ) : (
          `${_continue_translation}`
        )}
      </button>
    </form>
  );
};

export default Register;
