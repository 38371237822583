import Axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { BsLink45Deg } from "react-icons/bs";
import React, { useState, useEffect } from "react";
import default_team_logo from "@img/default-team-logo.png";
import useDebounce from "../../../../_common/Debounce/useDebounce";
import { LeaguesResultItem, List, Icon, MatchListingByDate } from "@common";
import ConnectOrganization from "../../../../_modals/ConnectOrganization/ConnectOrganization";

import useTranslated from "../../../../../helpers/translationHelper";
import LeagueParticipent from "./leagueParticipent";
const About = ({
  about,
  leaguesParticipating = [],
  leaguesParticipatingHistory = [],
  tournamentParticipatingHistory = [],
  tournamentParticipating = [],
  organisation = null,
  recentMatches,
  team,
  onReload
}) => {
  const lableCurrently=useTranslated("id_team_page_(overview)_0")
  const lableParticipation=useTranslated("id_team_page_(overview)_1")
  const lableXTeams=useTranslated("id_team_page_(overview)_2")
  const lableRecent=useTranslated("id_team_page_(overview)_3")
  const lableOrganization=useTranslated("id_team_page_(overview)_4")


	const [tm, setTm] = useState(team)
  	const [page, setPage] = useState(0);
  	const [results, setResults] = useState([])
  	const [search, setSearch] = useState(null)
  	const [org, setOrg] = useState(organisation ? organisation : team.organisationInvitationStatus === 'pending' ? team.pendingTeamOrganisationInvites.organisation : null)
  	const [loading, setLoading] = useState(false)
  	const [connect, setConnect] = useState(false)
  	const debounceSearch = useDebounce(search, 500)
  
  console.log("Organisation = ", org)  

  const toggleOrganisation = (data) => {
    Axios.post(`${process.env.REACT_APP_CORE_API}/api/${ tm.organisationInvitationStatus === 'pending' ? `teams/remove/organisation/request` : `teams/leave/organisation`}`, data).then(response => {
      setOrg(null)
      setTm(response.data.team)
      toast.success(response.data.msg)
      onReload();
    }).catch(error => {
      if (error.response) {
        toast.error(error.response.data.msg)
      }
      toast.error(error.message)
    })
  }

  const searchOrganisation = () => {
    setLoading(true)
    Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/search`, {query: debounceSearch}).then(async response => {
    	if (response.data?.length) {
        	let orgList = await response.data.map(org => ({_id: org._id, title: org.name, type: 'Organisation', img: org.logo, subtitle: false, logoCropped: org.banner}))
        	setResults(orgList)
      }
      setLoading(false)
    }).catch(error => {
    	setLoading(false)
      	if (error.response) {
        	toast.error(error.response.data.msg)
      	}
      	toast.error(error.message)
    })
  }

  const connectOrganisation = (org) => {
	  setResults([])
	  setSearch(null)
	  setConnect(false)
	Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/join/organisation`, {teamID: team?._id, organisation: org._id}).then(async response => {
		setTm(response.data.team)
		setOrg(response.data.organisation)
    	toast.success(response.data.msg)
      onReload();
    }).catch(error => {
      	if (error.response) {
        	toast.error(error.response.data.msg)
      	}
      	toast.error(error.message)
    })
  }

  useEffect(() => {
    if (debounceSearch) searchOrganisation()
  }, [debounceSearch])

  return (
    <React.Fragment>
      <div className="tabs-container mb-4">
        <div className="tabs-nav" style={{ borderBottom: "none" }}>
          <nav>
            <button className={page === 0 ? "active" : ""} onClick={() => setPage(0)}>
              {lableCurrently/* Currently participating in */}
            </button>
            <button className={page === 1 ? "active" : ""} onClick={() => setPage(1)}>
              {lableParticipation/* Participation history */}
            </button>
          </nav>
        </div>
      </div>

      {page === 0 && (
        <LeagueParticipent
          leaguesParticipating={leaguesParticipating}
          tournamentParticipating={tournamentParticipating}
          message={"This team is not currently participating in anything"}
        />
      )}

      {page === 1 && (
        <LeagueParticipent
          leaguesParticipating={leaguesParticipatingHistory}
          tournamentParticipating={tournamentParticipatingHistory}
          message={"This team has not previously participated in anything"}
        />
      )}

      <div className="row mb-3 smaller-gutter-row mb-4">
        <div className="col-12">
          <div className="mt-4 uppercase bold"> {lableRecent/* Recent Matches */}</div>
          {!recentMatches.length ? (
            <div className="league-no-matches">
              <Icon name="calendar" />
              <p>This team hasn’t played any matches yet</p>
            </div>
          ) : (
            // <List>
            //   {recentMatches.map((match) => (
            //     <LeaguesResultItem key={match._id} match={match} />
            //   ))}
            // </List>
            <MatchListingByDate
              existingMatches={recentMatches}
              isRecent={true}
              page={"RECENT_MATCHES"}
              fullScreen={true}
              id={team?._id}
            ></MatchListingByDate>
          )}
        </div>
      </div>

      <div>
        {org && (
          <div>
            <div className="mt-4 mb-4 uppercase bold  d-flex align-center justify-content-space-between" > {lableOrganization/* Organisation */}</div>
            <div
              style={{
                backgroundImage: `linear-gradient(to top, rgb(3, 6, 13) 15%, rgba(3, 6, 13, 0) 140%),url(${org.banner})`,
                height: "350px",
                objectFit: "fill",
                backgroundPostions: "center",
                backgroundSize: "cover",
				opacity: tm.pendingTeamOrganisationInvites ? .5 : 1
              }}
            >
              {tm.teamOwner && <button className="button danger float-right mt-2 mr-2" onClick={() => toggleOrganisation({teamID: tm?._id, organisation: org._id})}>{tm.organisationInvitationStatus === 'pending' ? 'Remove Request' : 'Remove Organisation' }</button>}
              <Link to={`/organisation/${org._id}`} className={"text-decoration-none"}>
                <div className="wide-page-with-tabs-heading-wrapper without_margin" style={{ padding: "5%" }}>
                  <div className="wide-page-with-tabs-heading">
                    <img
                      className={
                        org.logoCropped || org.logoCropped === undefined
                          ? "wide-page-with-tabs-logo"
                          : "wide-page-with-tabs-logo_withoutCropped"
                      }
                      alt="Team"
                      src={org.logo ?? default_team_logo}
                    />
                    <div className="wide-page-with-tabs-heading-text">
                      <h1>{org.name} {tm.organisationInvitationStatus === 'pending' ? '(Pending)' : ''}</h1>
                      <span className="text-faded uppercase text-white bold">
                        {tm.organisationInvitationStatus === 'pending' ? 'waiting for acceptance' : org.countTeams <= 1 ? `${org.countTeams} Team` : `${org.countTeams} Teams`}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
        {!org && tm.teamOwner && (tm.organisationInvitationStatus === 'default' || tm.organisationInvitationStatus === 'declined') && (
          <div>
          <div className="mt-4 mb-4 uppercase bold"> Organisation</div>
          <div
            className="test-organization" onClick={() => setConnect(true)}>
            {/* <Link to={`/organisation/`} className={"text-decoration-none"}> */}
              <div className="wide-page-with-tabs-heading-wrapper without_margin" style={{ padding: "5%" }}>
                <div className="wide-page-with-tabs-heading flx-column align-center">
                  <BsLink45Deg className="ex-link" size={50} onClick={() => setConnect(true)}/>
                  <div className="wide-page-with-tabs-heading-text ng-m-t-10 text-center">
                    <h3>CONNECT ORGANIZATION</h3>
                    <span className="text-faded uppercase text-white bold des-text">
                      Click and search to connect this team to your organisation
                    </span>
                  </div>
                </div>
              </div>
            {/* </Link> */}
          </div>
          <ConnectOrganization open={connect} onClose={() => {setSearch(null); setResults([]); setConnect(false)}} loading={loading} results={results} onChange={(search) => setSearch(search)} onClick={connectOrganisation}/>
        </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default About;
