import React from "react";
import DotPattern from "./DotPattern";
import RenderNewsItems from "./RenderNewsItems";
import RenderFeaturedNewsItem from "./RenderFeaturedNewsItem";
import { Button, SliderItem, Icon, Advert } from "@common";
import { RiCheckLine } from "react-icons/ri";
import {Link} from "react-router-dom";
import { FiPlay } from "react-icons/fi";
import { RiCloseLine } from "react-icons/ri";
const Subscription = () => {
return (
  <div className="container-fluid">
    <div className="cool-heading-container">
      <div className="icon-cross">
        <Icon name="cross" pureIcon={<RiCloseLine size={`1.5em`} />} />
      </div>
      <div className="icon-play">
      <Icon name="play" pureIcon={<FiPlay size={`1.5em`} />} />
      </div>
      <DotPattern />

      <div className="bg-transparent card-header p-4 border-0 center">
        <span className="planPrice m-4">ORGANIZE, ANALYZE AND MANAGE WITH LEAGUES PRO</span>
        <h1 className="h1 font-weight-normal text-primary mt-4 center" data-pricing-value="15">
          <span className="h6 text-muted ml-2 text-left">UPGRADE YOUR ACCOUNT</span>
        </h1>
                      
      </div>
      {/* <h1 className="center">
        
      </h1> */}
    </div>

    <div className="row smaller-gutter-row">
            
            <div className="col-6 right">
              <div className="col-container mb-5 mt-5">
                <div className="pricing floatright card-deck flex-column flex-md-row mb-3">
                  <div className="card card-pricing text-left px-3 mb-4"  style={{height:`559px`}}>
                    <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm status">FOR PLAYERS</span>
                        
                    <div className="bg-transparent card-header pt-4 border-0">
                      <h1 className="h1 font-weight-normal text-primary text-left mb-0" data-pricing-value="15"><span className="h6 text-muted ml-2 text-left">PRO USER</span></h1>
                      <span className="planPrice">$37.99 / YEAR</span>
                      <p className='price-desc'>Upgrade your player account to pro and get access to all the Leagues pro features.</p>
                    </div>
                    <div className="card-body pt-0">
                      <ul className="list-unstyled mb-4 p-0 featureListing">
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Get access to the Leagues calendar</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Get access to the analysis tool</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Get featured on the streams page</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Access to animated banners</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Add your own custom logo to your profile</li>
                      </ul>
                      <div className='text-center'>
                      <Link to="/pro-user" >
                        <Button name="cta-button" as={Link} to="/pro-user" >
                          Try for free today
                        </Button>
                      </Link>
                      </div>
                      <div className='text-center mt-4'>
                      <Link to="/pro-user" >
                        <Button name="cta-button-transparent" >
                          Read More
                        </Button>  
                      </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 right">
              <div className="col-container mb-5 mt-5">
                <div className="pricing card-deck flex-column flex-md-row mb-3">
                  <div className="card card-pricing text-left px-3 mb-4" style={{height:`618px`}}>
                  <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm status">FOR ORGANISATIONS AND TEAMS</span>
                      
                    <div className="bg-transparent card-header pt-4 border-0">
                      <h1 className="h1 font-weight-normal text-primary text-left mb-0" data-pricing-value="15"><span className="h6 text-muted ml-2 text-left">Pro ORGANISATION</span></h1>
                      <span className="planPrice">$7.99 / YEAR</span>
                      <p className='price-desc'>Organise your team activities, manage your team and analyze the enemy team with the pro organisation plan.</p>
                    </div>
                    <div className="card-body pt-0">
                      <ul className="list-unstyled mb-4 p-0 featureListing">
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Access the Leagues website editor and built your very own esport organisation page</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Team and organisation calendar</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Customizable sponsor page</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Customizable news page</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Get access to the analysis tool</li>
                        <li className=''><RiCheckLine className="featureListingSingle" size={`1.5em`} /> Manage contracts with the contract system</li>
                      </ul>
                      
                      <div className='text-center'>
                      <Link to="/org-update" >
                        <Button name="cta-button">
                          Try for free today
                        </Button>
                      </Link>
                      </div>
                      <div className='text-center mt-4'>
                      <Link to="/org-update" >
                        <Button name="cta-button-transparent" >
                          Read More
                        </Button>  
                      </Link>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <p className='trailNote center pb-5'>Terms and conditions apply. 7 day free trial not available for users who have already tried PRO.<br /><a href="">Contact Us</a> if you have questions.</p>
    </div>

  </div>
)}

export default Subscription;