import React from "react";
import "./glitch-text.scss";
const GlitchText = ({ children, className = "", style, intesity = 10, animateOnHover = false }) => {
  return (
    <div className={"wrapper " + className}>
      {[...Array(intesity)].map((_) => (
        <div className={`${animateOnHover ? "line__hover" : "line"}`} style={style}>
          {children}
        </div>
      ))}
    </div>
  );
};

export default GlitchText;
