import React from "react";
import { ReactComponent as Spinner } from "./loading-spinner.svg";

const Loading = ({ width = 35, height = 35, className=null }) => {
  return (
    <div style={{ display: "inline-block", verticalAlign: "middle" }} className={className}>
      <Spinner style={{ width, height }} />
    </div>
  );
};

export default Loading;
