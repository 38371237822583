import React, { useContext, useEffect, useState, useRef } from "react";
import { ScheduleMatchesItem, Icon, Loading, Checkbox } from "../../_common";
import { AppContext } from "../../../context/AppContext";
import { BsChevronUp, BsArrowUp, BsChevronDown } from "react-icons/bs";
import { getCurrentIsoTime, groupMatchesDate } from "@utils";
import Axios from "axios";
import { isCustomDateMatch } from "../../../utils/time-functions";
import useTranslated from "../../../helpers/translationHelper";
const MatchListingByDate = (props) => {
  let {
    page,
    id: queryId,
    fullScreen = false,
    existingMatches,
    isRecent,
    showSetPlayers,
    canSetPlayers,
    renderSetPlayers,
    isActive,
    teamSelector,
  } = props; //queryId is "id" belongs to league or tournament or team or user
  // console.log("matches : ", existingMatches);
  const { selectedGame } = useContext(AppContext);
  const [upPage, setUpPage] = useState(0);
  const [downPage, setDownPage] = useState(0);
  const [isPrev, setIsPrev] = useState(false); //if API call is for previous matches from current date
  const [matches, setMatches] = useState([]); //matches after group by date
  const [orignalMatches, setOrignalMatches] = useState(isRecent ? existingMatches : []); //matches from API
  const [dataInfoDown, setDataInfoDown] = useState();
  const [dataInfoUp, setDataInfoUp] = useState();
  const [scroll, setScroll] = useState(false);
  const [prevResultDate, setPrevResultDate] = useState();
  const [prevResultButton, setPrevResultButton] = useState();
  const [currentResultDate, setCurrentResultDate] = useState();
  const [currentMatches, setcurrentMatches] = useState([]); //current date matches so go to today button can be show accordingly
  const matchesScroll = useRef(null);
  const [showToday, setShowToday] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isTodayPressed, setIsTodayPressed] = useState(false);
  const [firstAPICall, setFirstAPICall] = useState(true);
  const [selected, setSelected] = useState([SELECTEDBRACKET.WINNER, SELECTEDBRACKET.LOSER]); //for tournament matches
  const [teams, setTeams] = useState([]); //for your-matches
  const [clickedPastResult, setClickPastResult] = useState(false);
  const [firstPrev, setFirstPrev] = useState(false);
  const [MatchDiv, setMatchDiv] = useState();
  const { user } = useContext(AppContext);
  const Januarymonth=useTranslated('id_utility_-_months_1')
  const Februarymonth=useTranslated('id_utility_-_months_2')
  const Marchmonth=useTranslated('id_utility_-_months_3')
  const Aprilmonth=useTranslated('id_utility_-_months_4')
  const Maymonth=useTranslated('id_utility_-_months_5')
  const Junemonth=useTranslated('id_utility_-_months_6')
  const Julymonth=useTranslated('id_utility_-_months_7')
  const Augustmonth=useTranslated('id_utility_-_months_8')
  const Septembermonth=useTranslated('id_utility_-_months_9')
  const Octobermonth=useTranslated('id_utility_-_months_10')
  const Novembermonth=useTranslated('id_utility_-_months_11')
  const Decembermonth=useTranslated('id_utility_-_months_12')
  
  const Monday=useTranslated('id_utility_-_week_days_0')
  const Tuesday=useTranslated('id_utility_-_week_days_1')
  const Wednesday=useTranslated('id_utility_-_week_days_2')
  const Thursday=useTranslated('id_utility_-_week_days_3')
  const Friday=useTranslated('id_utility_-_week_days_4')
  const Saturday=useTranslated('id_utility_-_week_days_5')
  const Sunday=useTranslated('id_utility_-_week_days_6')

  //lables
  const lablematch=useTranslated("id_team_page_(matches)_1")
  const lablematches=useTranslated("id_team_page_(matches)_2")

  let _today_matches_display_translation = useTranslated('id_match_display_9')
  let _no_future_matches_display_translation = useTranslated('id_match_display_11')
  let _past_results_matches_display_translation = useTranslated('id_match_display_10')





  const getMatchGroupDate = (date) => {
    const tempDate = new Date(date);
    let months = [Januarymonth, Februarymonth, Marchmonth, Aprilmonth, Maymonth, Junemonth, Julymonth, Augustmonth, Septembermonth, Octobermonth, Novembermonth, Decembermonth];
    let weekdays = [Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday];
    let groupDate = {};
    groupDate.day = weekdays[tempDate.getDay()];
    groupDate.month = months[tempDate.getMonth()];
    groupDate.date = tempDate.getDate();
    groupDate.year = tempDate.getFullYear();
  
    return groupDate;
  };
  useEffect(() => {
    if ((scroll || firstAPICall) && !isRecent) {
      setLoading(true);
      setFirstAPICall(false);
      let scrollTemp = scroll;
      setScroll(false);
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/matches/matchListing/${selectedGame._id}/${queryId}`, {
        params: {
          isPrevious: isPrev,
          page: isPrev ? upPage : downPage,
          date: getCurrentIsoTime(),
          isLeague: pages.LEAGUE === page,
          isTournament: pages.TOURNAMENT === page,
          isYourMatches: pages.YOUR_MATCHES === page,
          isTeamMatches: pages.TEAM_MATCHES === page,
        },
      }).then(({ data }) => {
        if (pages.YOUR_MATCHES === page) {
          setTeams(data.teams);
        }
        if (data?.data) {
          // console.log("data in matchListing -- ", data.data);
          if (isPrev || data.isPrevious) {
            if (data.isPrevious) {
              setIsPrev(true);
            }
            setDataInfoUp({ totalCount: data.totalCount, currentPage: data.currentPage, hasMore: data.hasMore });

            setUpPage(upPage + 1);

            let mData = data.data.reverse();
            if (scrollTemp) setOrignalMatches([...mData, ...orignalMatches]);
            else {
              setMatches([]);
              setOrignalMatches([...data.data]);
            }
          } else {
            setDataInfoDown({ totalCount: data.totalCount, currentPage: data.currentPage, hasMore: data.hasMore });
            setDownPage(downPage + 1);

            let matchesData = data.data;

            if (scrollTemp) setOrignalMatches([...orignalMatches, ...matchesData]);
            else {
              setMatches([]);
              setOrignalMatches([...matchesData]);
            }
          }
          if (data.prevMatches && data.prevMatches.length) {
            const { currentPage, totalCount, hasMore } = data.prevMatchInfo;
            setDataInfoUp({ totalCount, currentPage, hasMore });
            setUpPage(1);
            let mData = data.prevMatches.reverse();
            setOrignalMatches([...mData, ...data.data]);
          }
        }
        setLoading(false);
      });
    }
  }, [scroll]);

  const onHover = (index, groupIndex, isOwnerOfBothTeams, teamSelector) => {
    // console.log(index, groupIndex, isOwnerOfBothTeams, teamSelector)
    let group = matches[groupIndex];
    // 603f6da10f051713537b92195fd21b06080f0c79aa1e4c65
    let element;
    if (group) {
      if (index > 0) {
        let matches = Object.values(group)[0];
        let match = matches[index - 1];
        if (match && match._id && !isOwnerOfBothTeams) {
          isOwnerOfBothTeams = teams.includes(match?.t1?._id) && teams.includes(match?.t2?._id);
          element = document.getElementById(match._id + (isOwnerOfBothTeams ? match.t2?._id : match.t1?._id || match.t2?._id || ""));
        } else if (match && match._id && isOwnerOfBothTeams) {
          if (teamSelector == "t2") {
            let match = matches[index];
            element = document.getElementById(match._id + match.t1?._id);
          } else {
            isOwnerOfBothTeams = teams.includes(match?.t1?._id) && teams.includes(match?.t2?._id);
            element = document.getElementById(match._id + (isOwnerOfBothTeams ? match.t2?._id : match.t1?._id || match.t2?._id || ""));
          }
        }
      } else if (index == 0) {
        let matches = Object.values(group)[0];
        let match = matches[0];
        isOwnerOfBothTeams = teams.includes(match?.t1?._id) && teams.includes(match?.t2?._id);
        if (isOwnerOfBothTeams && teamSelector == "t2") {
          element = document.getElementById(match._id + match.t1?._id);
        } else {
          let date = Object.keys(group)[0];
          if (date) {
            element = document.getElementById(date);
          }
        }
      }
      if (element) {
        element.classList.remove("listing-item-hover");
        element.classList.add("listing-item-hover");
      }
    }
  };

  const onLeave = (index, groupIndex, isOwnerOfBothTeams, teamSelector) => {
    let group = matches[groupIndex];
    let element;
    if (group) {
      if (index > 0) {
        let matches = Object.values(group)[0];
        let match = matches[index - 1];
        if (match && match._id && !isOwnerOfBothTeams) {
          isOwnerOfBothTeams = teams.includes(match?.t1?._id) && teams.includes(match?.t2?._id);
          element = document.getElementById(match._id + (isOwnerOfBothTeams ? match.t2?._id : match.t1?._id || match.t2?._id || ""));
        } else if (match && match._id && isOwnerOfBothTeams) {
          if (teamSelector == "t2") {
            let match = matches[index];
            element = document.getElementById(match._id + match.t1?._id);
          } else {
            isOwnerOfBothTeams = teams.includes(match?.t1?._id) && teams.includes(match?.t2?._id);

            element = document.getElementById(match._id + (isOwnerOfBothTeams ? match.t2?._id : match.t1?._id || match.t2?._id || ""));
          }
        }
      } else if (index == 0) {
        let matches = Object.values(group)[0];
        let match = matches[0];
        isOwnerOfBothTeams = teams.includes(match?.t1?._id) && teams.includes(match?.t2?._id);
        if (isOwnerOfBothTeams && teamSelector == "t2") {
          element = document.getElementById(match._id + match.t1?._id);
        } else {
          let date = Object.keys(group)[0];
          if (date) {
            element = document.getElementById(date);
          }
        }
      }
      if (element) {
        element.classList.remove("listing-item-hover");
      }
    }
  };

  useEffect(() => {
    if (orignalMatches?.length) {
      let prev = {};
      let matchesList = groupMatchesDate(orignalMatches, isRecent ? false : true, user.state.timezone, page === pages.TOURNAMENT);
      // console.log("matches group by date -- ", matchesList);

      if (!matches.length) {
        let lastIndex = matchesList.length - 1;
        let newDataLatestDate = Object.keys(matchesList[0])[0];
        if (!isPrev) setPrevResultButton(newDataLatestDate);
        let matchId =
          matchesList[0][newDataLatestDate]?.[0]._id +
          (matchesList[0][newDataLatestDate]?.[0].t1?._id || matchesList[0][newDataLatestDate]?.[0].t2?._id || "");
        if (isPrev) newDataLatestDate = Object.keys(matchesList[lastIndex])[0];
        setcurrentMatches([...matchesList[isPrev ? lastIndex : 0][newDataLatestDate]]);
        setCurrentResultDate({ topDate: isPrev ? "end" : newDataLatestDate, matchId }); //storing date where need to show past result text
      } else if (isPrev || (dataInfoUp?.currentPage == 0 && (dataInfoDown?.currentPage == 0 || typeof dataInfoDown === "undefined"))) {
        let newDataLatestDate = Object.keys(matchesList[0])[0];
        let matchId =
          matchesList[0][newDataLatestDate]?.[0]._id +
          (matchesList[0][newDataLatestDate]?.[0].t1?._id || matchesList[0][newDataLatestDate]?.[0].t2?._id || "");
        prev.topDate = currentResultDate?.topDate;
        prev.matchId = currentResultDate?.matchId;

        setPrevResultDate({ ...prev });
        setCurrentResultDate({ topDate: newDataLatestDate, matchId });
      }
      setMatches([...matchesList]);
    }
  }, [orignalMatches]);

  useEffect(() => {
    if (isPrev && !(dataInfoUp?.currentPage == 0 && (dataInfoDown?.currentPage == 0 || typeof dataInfoDown === "undefined"))) {
      setTimeout(() => {
        onPrevious(prevResultDate);
      }, 120);
    } else if (dataInfoUp?.currentPage == 0 && (dataInfoDown?.currentPage == 0 || typeof dataInfoDown === "undefined")) {
      setTimeout(() => {
        onPressToday();
      }, 120);
    }
  }, [matches.length]);

  const handleScroll = () => {
    const { current } = matchesScroll;

    if (current.scrollTop === 0 && dataInfoUp && dataInfoUp?.hasMore) {
      scrollToPrev();
    }
    const scroll = current.scrollHeight - current.scrollTop - current.clientHeight; //for bottom reach
    if (scroll <= 2) {
      if (dataInfoDown?.hasMore) {
        setIsTodayPressed(false);
        setIsPrev(false);
        setScroll(true);
      }
    }
    var BreakException = {};

    try {
      //following code to check if current date matches are upwards/downwards or in visible view in div
      let count = 0;
      currentMatches.forEach(({ _id, t1, t2 }, index) => {
        var element = document.getElementById(_id + (t1?._id || t2?._id || ""));

        const eleTop = element.offsetTop;
        const eleBottom = eleTop + element.clientHeight;
        const containerTop = matchesScroll.current.scrollTop;
        const containerBottom = containerTop + matchesScroll.current.clientHeight;

        if (
          (eleTop >= containerTop && eleBottom <= containerBottom) ||
          (eleTop < containerTop && containerTop < eleBottom) ||
          (eleTop < containerBottom && containerBottom < eleBottom)
        ) {
          setShowToday(0); //between top and below
          throw BreakException;
        } else {
          count = count + 1;
          if (count == currentMatches.length) {
            if (eleTop < containerTop && containerTop > eleBottom) setShowToday(1);
            //up
            else if (eleTop > containerBottom && containerBottom < eleBottom) setShowToday(2); //down
          }
        }
      });
    } catch (e) {}
  };

  const scrollToPrev = () => {
    if (!dataInfoUp || dataInfoUp?.hasMore) {
      setIsTodayPressed(false);
      setIsPrev(true);
      setScroll(true);
    }
  };

  const onPressToday = (btnPressed = false) => {
    setIsTodayPressed(true);
    let matchDiv;
    if (btnPressed) {
      matchDiv = document.getElementById("Match_div");
      matchDiv.classList.add("smoothScroll");
    }

    const titleElement = document.getElementById(
      typeof dataInfoDown === "undefined" || currentResultDate?.topDate == "end"
        ? "end"
        : currentMatches[0]?._id + (currentMatches[0]?.t1?._id || currentMatches[0]?.t2?._id || "")
    );
    if (titleElement) {
      titleElement.parentNode.scrollTop = titleElement.offsetTop - 130;
    }
    if (btnPressed && matchDiv) matchDiv.classList.remove("smoothScroll");
  };

  const onPrevious = (date = {}) => {
    const titleElement = document.getElementById(!prevResultDate ? currentResultDate?.topDate : `${prevResultDate?.matchId}`);
    if (titleElement) {
      titleElement.parentNode.scrollTop = titleElement.offsetTop - 130;
    }
  };

  const _setSelected = (value) => {
    //this func for tournament matches winner loser checkbox
    if (selected.includes(value))
      if (selected.length === 1) return;
      else
        selected.splice(
          selected.findIndex((el) => el === value),
          1
        );
    else selected.push(value);
    setSelected([...selected]);
  };

  const checkIfAllMatchesWinnerLoser = (type, matchesParam = []) => {
    //check whether to show date if winner or loser matches hide.
    let winnerMatches = matchesParam.filter((m) => m.winner === type);
    if (winnerMatches.length === matchesParam.length) return true;

    return false;
  };
  const scrollOnPastresult = () => {
    if (dataInfoUp !== null && typeof dataInfoUp !== "undefined") {
      let matchDiv = document.getElementById("Match_div");
      matchDiv.classList.add("smoothScroll");
      matchesScroll.current.scrollTop = matchesScroll.current.scrollTop - matchesScroll.current.clientHeight;
      setClickPastResult(false);
      matchDiv.classList.remove("smoothScroll");
    }
  };
  return (
    <div className={`row d-flex ${pages.TOURNAMENT === page || pages.RECENT_MATCHES === page && "tournament_match"}`}>
      <div className="col-12" style={{ paddingLeft: "30px", paddingRight: "0" }}>
        <div className="align-items-center justify-content-between" style={{ display: "flex", flexDirection: "row" }}>
          {!isRecent && (
            <div className="text-uppercase bold" style={{ marginLeft: "10px" }}>
              {orignalMatches.length} {lablematches/* Matches */}
            </div>
          )}
          {page === pages.TOURNAMENT && (
            <div className="row p-0 m-0 align-items-center mr-4">
              <span className="bold text-grey uppercase font-13 ">Show:</span>
              <Checkbox
                checked={selected.includes(SELECTEDBRACKET.WINNER)}
                onChange={() => _setSelected(SELECTEDBRACKET.WINNER)}
                label="Winners'"
                className="check-type1"
                game={selectedGame.name}
              />
              <Checkbox
                checked={selected.includes(SELECTEDBRACKET.LOSER)}
                onChange={() => _setSelected(SELECTEDBRACKET.LOSER)}
                label="Losers'"
                className="check-type1"
                game={selectedGame.name}
              />
            </div>
          )}
        </div>

        <div
          id="Match_div"
          className={`grayboxListing graybox_yourmatches_hover ${page === pages.RECENT_MATCHES && "recentMatches"} ${
            page === pages.ACTIVE_NEXT_MATCH && "activeMatch"
          }`}
          ref={(r) => (matchesScroll.current = r)}
          onScroll={() => handleScroll()}
          style={page == "SCRIM_PAGE" ? { overflowY: "hidden" } : {}}
        >
          {loading && isPrev && (
            <div className="col-12 text-center">
              <Loading />
            </div>
          )}
          {matches.length ? (
            <>
              {matches.map((group, g_index) => {
                let date = getMatchGroupDate(Object.keys(group)[0]);
                let id = JSON.stringify(Object.keys(group)[0]);
                let showPrev = isCustomDateMatch(date, new Date(prevResultButton),getMatchGroupDate);
                let currentDate = new Date().getFullYear();
                let groupDate = new Date(Object.keys(group)[0]).getFullYear();
                let groupMatches = Object.values(group)[0];
           
                console.log(groupMatches)

                return (
                  <>
                    {!(
                      (!selected.includes(SELECTEDBRACKET.WINNER) && checkIfAllMatchesWinnerLoser(true, groupMatches)) ||
                      (!selected.includes(SELECTEDBRACKET.LOSER) && checkIfAllMatchesWinnerLoser(false, groupMatches))
                    ) && (
                      <div className="date-wrapper">
                        <div
                          className="dateListing row d-flex justify-content-between"
                          id={Object.keys(group)[0]}
                          style={{ marginLeft: page ? "2.5rem" : "1.5rem" }}
                        >
                          <span className="uppercase">
                            <b>{date.day}</b> - {date.date} {date.month} {groupDate != currentDate ? date.year : null}
                          </span>
                          {showPrev && !isRecent && (
                            <div
                              className="row d-flex text-14 bold uppercase color-white align-items-center prevListing"
                              onClick={() => {
                                setClickPastResult(true);
                                // scrollToPrev();
                                scrollOnPastresult();
                              }}
                            >
                              <span style={{ textAlign: "center" }}>{_past_results_matches_display_translation}</span>
                              <BsArrowUp
                                size={20}
                                style={{
                                  color: "var(--primary)",
                                  marginLeft: "2px",
                                  marginTop: "2px",
                                  marginRight: "15px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {groupMatches.map((match, ind) => {
                      return (
                        <>
                          {((selected.includes(SELECTEDBRACKET.WINNER) && match.winner) ||
                            (selected.includes(SELECTEDBRACKET.LOSER) && !match.winner)) && (
                            <>
                              {page === pages.YOUR_MATCHES && match.status !== "complete" ? (
                                (match.tournament
                                  ? [match?.t1, match?.t2].map((team) => {
                                      const selectedPlayers = match.tournament.teams
                                        ?.find((_team) => team?._id === _team?.team)
                                        ?.selectedPlayers?.map(({ player_id }) => player_id);
                                      return {
                                        ...team,
                                        members: team?.members?.filter(
                                          (member) => member?.role?.includes("Player") && selectedPlayers?.includes(member?.player?._id)
                                        ),
                                      };
                                    })
                                  : [match?.t1, match?.t2]
                                ).map((team, i) => {
                                  let isOwnerOfBothTeams = teams.includes(match?.t1?._id) && teams.includes(match?.t2?._id);
                                  return (
                                    // If match is from a tournament then only allow to set players selected in the roaster from admin panel

                                    teams.includes(team?._id) && (
                                      <ScheduleMatchesItem
                                        key={match?._id + team?._id}
                                        match={match}
                                        id={match?._id}
                                        date={date}
                                        fullScreen={fullScreen}
                                        page={page}
                                        queryId={queryId}
                                        team={team}
                                        showSetPlayers={showSetPlayers}
                                        onHover={onHover}
                                        onLeave={onLeave}
                                        index={ind}
                                        groupIndex={g_index}
                                        teamSelector={`t${i + 1}`}
                                        mainItemId={match?._id + (isOwnerOfBothTeams ? team?._id : match.t1?._id || match.t2?._id || "")}
                                        isOwnerOfBothTeams={isOwnerOfBothTeams}
                                      />
                                    )
                                  );
                                })
                              ) : (
                                <ScheduleMatchesItem
                                  key={match?._id + (match.t1?._id || match.t2?._id || "")}
                                  match={match}
                                  id={match?._id}
                                  date={date}
                                  fullScreen={fullScreen}
                                  page={page}
                                  queryId={queryId}
                                  userTeams={teams}
                                  showSetPlayers={showSetPlayers}
                                  canSetPlayers={canSetPlayers}
                                  onHover={onHover}
                                  onLeave={onLeave}
                                  index={ind}
                                  groupIndex={g_index}
                                  renderSetPlayers={renderSetPlayers}
                                  teamSelector={teamSelector}
                                  mainItemId={match?._id + (match.t1?._id || match.t2?._id || "")}
                                />
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                  </>
                );
              })}
              {/* <div id="end"></div> */}
            </>
          ) : null}

          {(!matches.length || (matches.length && dataInfoDown == null)) && page !== "SCRIM_PAGE" && !isRecent && (
            <>
              <div className="dateListing row d-flex justify-content-between" style={{ borderBottom: "0px" }}>
                <span></span>

                {
                  <div
                    className="row d-flex text-14 bold uppercase color-white align-items-center prev"
                    onClick={() => {
                      setClickPastResult(true);
                      // scrollToPrev();
                      scrollOnPastresult();
                    }}
                  >
                    <span style={{ textAlign: "center" }}>{_past_results_matches_display_translation}</span>
                    <BsArrowUp
                      size={20}
                      style={{
                        color: "var(--primary)",
                        marginLeft: "2px",
                        marginTop: "2px",
                        marginRight: "15px",
                      }}
                    />
                  </div>
                }
              </div>
              {loading && !isPrev && (
                <div className="col-12 text-center">
                  <Loading />
                </div>
              )}
              <span className="no-match-text-matches" id={"end"}>
                {_no_future_matches_display_translation}
              </span>
            </>
          )}
        </div>
        {loading && !isPrev && dataInfoDown !== null && typeof dataInfoDown !== "undefined" && (
          <div className="col-12 text-center">
            <Loading />
          </div>
        )}
        {showToday !== 0 && !isRecent && (
          <div className="text-14 bold uppercase color-white bottom-float prev" onClick={() => onPressToday(true)}>
            <span style={{ textAlign: "center" }}>{_today_matches_display_translation}</span>
            {showToday === 2 ? (
              <BsChevronDown style={{ color: "var(--primary)", marginLeft: "3px" }} />
            ) : (
              <BsChevronUp style={{ color: "var(--primary)", marginLeft: "3px" }} />
            )}{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchListingByDate;

const pages = {
  LEAGUE: "LEAGUE",
  TOURNAMENT: "TOURNAMENT",
  TEAM_MATCHES: "TEAM_MATCHES",
  YOUR_MATCHES: "YOUR_MATCHES",
  RECENT_MATCHES: "RECENT_MATCHES",
  ACTIVE_NEXT_MATCH: "ACTIVE_NEXT_MATCH",
};
const SELECTEDBRACKET = {
  WINNER: "WINNER",
  LOSER: "LOSER",
};