import React, { useState } from "react";
import { Icon, GameOverviewBox } from "@common";
import { useHistory } from "react-router-dom";
import { Button } from "@common";

import { Link } from "react-router-dom";
import moment from "moment";

const TournamentListItem = ({ type = "?", mapData, championIdToImg, spellIdToImg, match = {} }) => {
  const { winForPlayer, playerStats, gameCreation, gameDuration, teams, participants, participantIdentities } = mapData;
  const history = useHistory();

  const [expanded, setExpanded] = useState(false);
  let color = gameDuration < 500 ? "secondary" : winForPlayer ? "success" : "danger";

  const getTeamTotal = (ti, key) => {
    let total = 0;

    participants.forEach(({ teamId, stats }) => {
      if (teamId === ti) total += stats[key];
    });

    return total;
  };

  const getParticipantIdentity = (pId) => {
    const participant = participantIdentities.find(({ participantId }) => participantId === pId);

    if (participant) return participant.player;

    return false;
  };

  const team1 = participants ? participants.filter(({ teamId }) => teamId === 100) : [];
  const team2 = participants ? participants.filter(({ teamId }) => teamId === 200) : [];

  // Special kill
  let specialKill = false;
  if (playerStats.stats.doubleKills)
    specialKill = (
      <span className="text-8 pill bordered small special-kill-pill">
        <Icon name="double" /> Double Kill
      </span>
    );
  if (playerStats.stats.tripleKills)
    specialKill = (
      <span className="text-8 pill bordered small special-kill-pill">
        <Icon name="triple" /> Triple Kill
      </span>
    );
  if (playerStats.stats.quadraKills)
    specialKill = (
      <span className="text-8 pill bordered primary small special-kill-pill">
        <Icon name="quadra" /> Quadra Kill
      </span>
    );
  if (playerStats.stats.pentaKills)
    specialKill = (
      <span className="text-8 pill bordered warning small special-kill-pill">
        <Icon name="penta" /> Penta Kill
      </span>
    );

  return (
   
   <React.Fragment>
    
      <div className={`row tiny-gutter-row`}>
        <div className="col-12">
          <div className={`tournament-list-item bg-${color} bordered mr-0`}>
            <div className="row tiny-gutter-row">
              <div className="col-2 tournament-list-item-first-col">
                <div className="text-10 bold">{type}</div>
                <span className="text-8 uppercase bold faded mb-1">{moment(gameCreation).fromNow()}</span>
                {type !== "Ranked Solo" && match && match.t1 && match.t2 && (
                  <div className="text-10 bold mt-2 mb-3">
                    {(
                      <>
                        <img
                          src={match.t1.logo}
                          alt={match.t1.name}
                          className={match.t1.logoCropped || match.t1.logoCropped === undefined ? "imgCrop" : "imgNotCrop"}
                        />
                        {match.t1.placeHolder ?
                          <div className="text-decoration-none" style={{ marginRight: "2px" }}>
                            {match.t1.initials}</div>
                          :
                          <Link className="text-decoration-none" to={`/teams/${match.t1._id}`} style={{ marginRight: "2px" }}>
                          {match.t1.initials}
                        </Link>
                        }

                      </>
                    ) || null}
                    <small>vs</small>
                    {(
                      <>
                        {match.t2.placeHolder ?
                          <div className="text-decoration-none" style={{ marginLeft: "2px" }}>
                            {match.t2.initials}
                          </div>
                          : 
                          <Link className="text-decoration-none" to={`/teams/${match.t2._id}`} style={{ marginLeft: "2px" }}>
                            {match.t2.initials}
                          </Link>
                        }
                        <img
                          src={match.t2.logo}
                          alt={match.t2.name}
                          className={match.t2.logoCropped || match.t2.logoCropped === undefined ? "imgCrop" : "imgNotCrop"}
                          style={{ marginLeft: "5px" }}
                        />
                      </>
                    ) || null}
                  </div>
                )}
                <span className={`text-8 ml-0 pill ${color}`}>{gameDuration < 500 ? "Remake" : winForPlayer ? "Victory" : "Defeat"}</span>
                <span className="text-8 time">
                  {Math.floor(gameDuration / 60)}m {gameDuration % 60}s
                </span>
              </div>
              <div className="col-5 tournament-list-item-second-col">
                <div className="tournament-images-container">
                  <div className="round-image" style={{ overflow: "hidden" }}>
                    <img src={championIdToImg(playerStats.championId)} alt="" style={{ transform: "scale(1.1)" }} />
                  </div>
                  <div>
                    <div>
                      <img src={spellIdToImg(playerStats.spell1Id)} className="small-image" alt="" />
                      {/* <img
                        src={`https://opgg-static.akamaized.net/images/lol/perk/${playerStats.stats.perkSubStyle}.png`}
                        className="small-image"
                        alt=""
                      /> */}
                    </div>
                    <div>
                      <img src={spellIdToImg(playerStats.spell2Id)} className="small-image" alt="" />
                      {/* <img
                        src={`https://opgg-static.akamaized.net/images/lol/perk/${playerStats.stats.perkPrimaryStyle}.png`}
                        className="small-image"
                        alt=""
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="tournament-item-data data-kda">
                  <span className="text-13 bold">
                    {playerStats.stats.kills} / {playerStats.stats.deaths} / {playerStats.stats.assists}
                  </span>
                  <span className="text-10 bold">
                    {(playerStats.stats.deaths !== 0
                      ? parseFloat((playerStats.stats.kills + playerStats.stats.assists) / playerStats.stats.deaths).toFixed(2)
                      : "Perfect") + " KDA"}
                  </span>
                  {specialKill}
                </div>

                <div className="tournament-item-data">
                  <span className="text-10 bold">Level {playerStats.stats.champLevel}</span>
                  <span className="text-10 bold">
                    {playerStats.stats.totalMinionsKilled +
                      playerStats.stats.neutralMinionsKilledTeamJungle +
                      playerStats.stats.neutralMinionsKilledEnemyJungle}{" "}
                    (
                    {parseFloat(
                      (playerStats.stats.totalMinionsKilled +
                        playerStats.stats.neutralMinionsKilledTeamJungle +
                        playerStats.stats.neutralMinionsKilledEnemyJungle) /
                      (gameDuration / 60)
                    ).toFixed(1)}
                    ) CS
                  </span>
                  <span className="text-10 bold">
                    KP:{" "}
                    {Math.round(
                      ((playerStats.stats.kills + playerStats.stats.assists) /
                        (getTeamTotal(playerStats.teamId, "kills") - playerStats.stats.kills)) *
                      100
                    )}
                    %
                  </span>
                </div>
              </div>

              <div className="col-2 tournament-list-item-third-col">
                <div>
                  {[0, 1, 2, 3, 4, 5, 6].map((itemId, i) => (
                    <div
                      key={i}
                      className="tournament-list-item-item"
                      style={{
                        backgroundImage: `url(https://ddragon.leagueoflegends.com/cdn/10.20.1/img/item/${playerStats.stats[`item${itemId}`]
                          }.png)`,
                      }}
                      alt={playerStats.stats[`item${itemId}`]}
                    />
                  ))}
                </div>
                <span className="text-10">
                  Vision score: <b>{playerStats.stats.visionScore}</b>
                </span>
              </div>

              <div className="col-3 tournament-list-item-fourth-col">
                <div className="players">
                  {participantIdentities.map(
                    ({ participantId, player }, i) =>
                      player && (
                        <div className="tournament-player" key={participantId}>
                          <img src={championIdToImg(participants[i].championId)} alt={player.summonerName} />
                          <span className={`countPlayer-${i}`}>{player.summonerName}</span>
                        </div>
                      )
                  )}
                </div>
                <button className="round-toggle-button" onClick={() => setExpanded(!expanded)}>
                  <Icon name="down" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`tournament-list-item-expandable-content ${expanded && "expanded"}`}>
        {type !== "Ranked Solo" && match && match._id && (
          // <Link className="cta-button-transparent" to={`/match/${match._id}`}>
          //   GO TO MATCH
          //   <span className="top"></span>
          //   <span className="bottom"></span>
          //   <span className="left"></span>
          //   <span className="right"></span>
          //   <span className="right-corner"></span>
          //   <span className="left-corner"></span>
          // </Link>
          <div className="mb-2 mt-1">
            <Button onClick={() => history.push(`/match/${match._id}`)} name="cta-button-transparent1">
              GO TO MATCH
            </Button>
          </div>
        )}
        <div className="row tiny-gutter-row">
          <div className="col-12">
            <GameOverviewBox map={mapData} hide_bans />
          </div>
        </div>

        {[
          { team: teams[0], participants: team1 },
          { team: teams[1], participants: team2 },
        ].map(({ team, participants = [] }, i) => (
          <div className="row tiny-gutter-row" key={i}>
            <div className="col-12">
              <div className="faded-box-item">
                <div className="row tiny-gutter-row tournament-detail-header">
                  <div className="col-3 bold faded text-12">
                    {team.win === "Win" ? <span className="color-success">VICTORY</span> : <span className="color-danger">DEFEAT</span>} (
                    {type !== "Ranked Solo" &&
                      (match && match.t1 && match.t2 && i === 0
                        ? (match.t1 ? (
                          <Link className="text-decoration-none" to={`/teams/${match.t1._id}`}>
                            {match.t1.name}
                          </Link>
                        ) : (
                          "Team T1"
                        )) || "Team T1"
                        : (match.t2 ? (
                          <Link className="text-decoration-none" to={`/teams/${match.t2._id}`}>
                            {match.t2.name}
                          </Link>
                        ) : (
                          "Team T2"
                        )) || "Team T2")}
                    )
                  </div>
                  {/* <div className="col-1 bold faded text-12 center">TIER</div  > */}
                  <div className="col-2 bold faded text-12 center">KDA</div>
                  <div className="col-2 bold faded text-12 center">DAMAGE</div>
                  <div className="col-1 bold faded text-12 center">WARDS</div>
                  <div className="col-1 bold faded text-12 center">CS</div>
                  <div className="col-3 bold faded text-12 center">ITEMS</div>
                </div>
                <div className="row tiny-gutter-row">
                  {participants.map(({ participantId, teamId, championId, stats }, i) => {
                    const participantIdentity = getParticipantIdentity(participantId);

                    return (
                      <div className="col-12" key={i}>
                        <div className={stats.win ? "success-box-item" : "danger-box-item"}>
                          <div className="row tiny-gutter-row">
                            <div className="col-3 bold text-12 flex-row">
                              <img src={championIdToImg(championId)} className="player-image" alt={participantIdentity.summonerName} />
                              <span className="bold text-12">
                                <Link style={{ textDecoration: "none" }} to={`/summoner-redirect/${participantIdentity.accountId}`}>
                                  {participantIdentity.summonerName}
                                </Link>
                              </span>
                            </div>
                            {/* <div className="col-1 bold text-12 center color-orange flex-row">G2</div> */}
                            <div className="col-2 bold text-12 center flex-column center">
                              <span className="bold">
                                {stats.kills} <span className="faded">/</span> {stats.deaths} <span className="faded">/</span>{" "}
                                {stats.assists}
                              </span>
                              <span className="text-10">
                                <span className="bold">
                                  {stats.deaths === 0 ? "Perfect" : parseFloat((stats.kills + stats.assists) / stats.deaths).toFixed(2)}
                                </span>{" "}
                                KDA
                              </span>
                            </div>
                            <div className="col-2 bold text-12 center flex-column center">
                              <span>{stats.totalDamageDealt}</span>
                              <div className="damage-meter">
                                <div
                                  className="damage-bar"
                                  style={{ width: (stats.totalDamageDealt / getTeamTotal(teamId, "totalDamageDealt")) * 100 + "%" }}
                                />
                              </div>
                            </div>
                            <div className="col-1 bold text-12 center flex-row">{stats.wardsPlaced}</div>
                            <div className="col-1 bold text-12 center flex-row">
                              {stats.totalMinionsKilled + stats.neutralMinionsKilledTeamJungle + stats.neutralMinionsKilledEnemyJungle}
                            </div>
                            <div className="col-3 bold text-12 center flex-row items-row">
                              {[0, 1, 2, 3, 4, 5, 6].map((itemId, i) => (
                                <div
                                  key={i}
                                  className="tournament-list-item-item"
                                  style={{
                                    backgroundImage: `url(https://ddragon.leagueoflegends.com/cdn/10.20.1/img/item/${stats[`item${itemId}`]
                                      }.png)`,
                                  }}
                                  alt={stats[`item${itemId}`]}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default TournamentListItem;
