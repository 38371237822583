import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { List, TeamMemberListItem, MatchListingByDate } from "@common";
import SetPlayers from "../SetPlayers/SetPlayers";
import { scrimCheckinToMins } from "../../../../../../utils/time-functions";
import { AppContext } from "../../../../../../context/AppContext";
import moment from "moment";

const ActiveForNextMatch = ({ team, scrim }) => {
  const [players, setPlayers] = useState([]);
  const [match, setMatch] = useState(null);
  const setPlayersDeadline = scrimCheckinToMins(scrim.checkInPeriod);
  const { user: authUser } = useContext(AppContext);
  const { date } = scrim;
  const [teamObjectName, setTeamObjectName] = useState();

  useEffect(() => {
    let url = `${process.env.REACT_APP_CORE_API}/api/scrims/${team._id}/${scrim._id}/${
      team.game.shortName === "CSGO" ? "CSGO" : "LOL"
    }/activeForNextMatch`;
    Axios.get(url).then(({ data }) => {
      if (data.nextMatch) {
        setTeamObjectName(data.nextMatch.t1._id === team._id ? "t1" : "t2");
        setPlayers(data.activeForNextMatch);
        setMatch(data.nextMatch);
      }
    });
  }, [team._id]);

  const isWithinDeadline = moment(date).diff(moment(), "minutes") >= setPlayersDeadline && moment(date).diff(moment(), "hours") < 336;
  // Decide whether setting players is allowed...
  const showSetPlayers = () => {
    const { members: teamMembers = [], organisation } = team;
    let isOrgOwner = false;
    let isTeamMember = false;
    if (organisation?.owners?.find(({ user }) => user === authUser?.state?._id || user?._id === authUser?.state?._id)) {
      isOrgOwner = true;
    }

    if (
      teamMembers.find(
        ({ user, coach }) => (user && user._id === authUser?.state?._id) || (coach && coach?.user?._id === authUser?.state?._id)
      )
    ) {
      isTeamMember = true;
    }

    if (isWithinDeadline && (isOrgOwner || isTeamMember)) {
      return true;
    } else {
      return false;
    }
  };
  let canSetPlayers = showSetPlayers();
  return (
    <div>
      <div className="row mb-4">
        <div className="col-3">
          <span className="text-faded uppercase bold">Players</span>
        </div>
        <div className="col-9">
          <List>
            {!players.length
              ? `Players not set for next match. Check back later!`
              : players.map(({ _id, role, player }) => {
                  return (
                    <TeamMemberListItem
                      key={_id}
                      img={player && player.avatarImage}
                      title={player && player.name}
                      subtitle={player && `${player.user.firstName} ${player.user.lastName}`}
                      //position={player && getPlayerPositionData(role)}
                      role={role}
                      nationality={player && player.user.nationality}
                    />
                  );
                })}
          </List>
        </div>
        {match && (
          <div className="col-12">
            <MatchListingByDate
              existingMatches={[match]}
              isRecent={true}
              page={"SCRIM_PAGE"}
              showSetPlayers={() => showSetPlayers}
              canSetPlayers={canSetPlayers}
              fullScreen={false}
              renderSetPlayers={
                <div style={{ padding: "0px 20px" }}>
                  <SetPlayers
                    {...match}
                    setPlayersDeadline={scrimCheckinToMins(scrim.checkInPeriod)}
                    teamData={team}
                    match={match}
                    teamSelector={teamObjectName}
                    updateMatch={(uMatch) => {
                      let leagueOrTournament = uMatch.league || uMatch.tournament || uMatch.scrim;
                      let data = leagueOrTournament.game?.shortName == "CSGO" ? uMatch.csgoData : uMatch.lolData;
                      let finalData;
                      if (leagueOrTournament.game?.shortName == "CSGO") {
                        finalData = { ...match, csgoData: data };
                      } else {
                        finalData = { ...match, lolData: data };
                      }
                      setMatch(finalData);
                      setPlayers(data.players[teamObjectName]);
                    }}
                  />
                </div>
              }
            ></MatchListingByDate>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveForNextMatch;
