import React from "react";
import { Switch, Route } from "react-router-dom";
import Teams from "./Teams/Teams";
import TeamsSingle from "./TeamsSingle/TeamsSingle";
import TeamsManage from "./TeamsManage/TeamsManage";

const TeamsRouter = () => {
  return (
    <Switch>
      <Route path="/teams" exact>
        <Teams />
      </Route>
      <Route path="/teams/:id/manage">
        <TeamsManage />
      </Route>
      <Route path="/teams/:id">
        {/* TeamsProfile was used before*/}
        {/* Had problems with the TeamsProfile component*/}
        {/* So created TeamsSingle to work on the ui*/}
        <TeamsSingle />
      </Route>
    </Switch>
  );
};

export default TeamsRouter;
