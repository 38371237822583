import React, { useEffect, useState } from "react";
import { TeamStatItem } from "@common";
import Axios from "axios";
import { Loading } from "@common";
import moment from "moment";
import { toast } from "react-toastify";
import useTranslated from "../../../../../helpers/translationHelper";

const TeamStats = ({ team, showFilters = true, isScrim }) => {
  const [playerStats, setPlayerStats] = useState();
  const [mainPlayerStats, setMainPlayerStats] = useState([]);
  const [subPlayerStats, setSubPlayerStats] = useState([]);
  const [updatingData, setUpdatingData] = useState(false);
  const [statsType, setStatsType] = useState("ranked_solo");
  const [championsData, setChampionsData] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);

  //lables
  const lableMainPlayer=useTranslated("id_team_page_(team_stats)_0")
  const lableSubsitutes=useTranslated("id_team_page_(team_stats)_1")
  const lableUpdateData=useTranslated("id_team_page_(team_stats)_2")


  const updatePlayerData = async () => {
    setUpdatingData(true);

    try {
      await Axios.post(`${process.env.REACT_APP_LOL_API}/api/players/${team._id}/massUpdateTeamPlayers`);

      return window.location.reload();
    } catch (e) {
      let error = "There was a problem updating player data";
      if (e.response && e.response.data) error = e.response.data.msg;

      toast.error(error);
    }

    setUpdatingData(false);
  };

  useEffect(() => {
    setStatsLoading(true);
    Axios.get(`${process.env.REACT_APP_LOL_API}/api/teams/${team._id}/playerStats?type=${statsType}`).then(({ data }) => {
      let mainPlayersType = [
        "Player:TOP",
        "Player:JUNGLE",
        "Player:MID",
        "Player:BOT",
        "Player:SUPPORT",
        "Player:IGL",
        "Player:RIFLER",
        "Player:AWP",
        "Player:LURKER",
      ];
      let subPlayersType = ["Player:SUB"];
      const mainPlayers = data.filter((item) => mainPlayersType.includes(item.role));
      const subPlayers = data.filter((item) => item.role.includes(subPlayersType));
      setPlayerStats(data);
      setMainPlayerStats(mainPlayers);
      setSubPlayerStats(subPlayers);
      setStatsLoading(false);
      setLoading(false);
    });
    // .catch((e) => {
    //   setLoading(false);
    //   console.log("player- error", playerStats);
    // });

    Axios.get(`${process.env.REACT_APP_LOL_API}/api/riot/champions`).then(({ data }) => {
      setChampionsData(data);
    });
  }, [team._id, statsType]);

  let ordering = {},
    sortOrder = ["Player:TOP", "Player:JUNGLE", "Player:MID", "Player:BOT", "Player:SUPPORT", "Player:SUB", "Player:SUB"];
  for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;

  return (
    <div>
      {/* <div className="row">
        <div className="col-12 mb-4">
          <button class="button default mr-3">All queues</button>
          <button class="button default mr-3">Season 10</button>
          <button class="button transparent">Reset</button>
        </div>
      </div> */}
      {loading ? (
        <div className="text-center league-no-matches">
          <Loading width={50} height={50} />
        </div>
      ) : playerStats?.length === 0 || championsData?.length === 0 ? (
        "No player stats available right now!"
      ) : (
        <>
          <div className="tabs-container mb-4">
            <div className="tabs-nav" style={{ borderBottom: "none" }}>
              <nav>
                <button className={page === 0 ? "active" : ""} onClick={() => setPage(0)}>
                  {lableMainPlayer/* Main Players */}
                </button>
                <button className={page === 1 ? "active" : ""} onClick={() => setPage(1)}>
                  {lableSubsitutes/* Substitutes */}
                </button>
              </nav>

              {team.game.shortName !== "CSGO" && (
                <select
                  className="form-input ranked-solo"
                  id="ranked-solo"
                  value={statsType}
                  onChange={(e) => setStatsType(e.target.value)}
                >
                  <option value={"ranked_solo"}>Ranked Solo</option>
                  <option value={"leagues.gg"}>Leagues.gg</option>
                </select>
              )}

              {showFilters && (
                <div className="filters">
                  <div className="update-btn-container-rom">
                    {/* <div> */}
                    <span className="last-updated-text mr-1">Last updated {moment(team.lolDatalastUpdated).fromNow()}</span>
                    <button className="button primary mustAButton" disabled={updatingData} onClick={updatePlayerData}>
                      {updatingData ? <Loading width={17} height={17} /> : lableUpdateData/* `Update Data` */}
                    </button>
                  </div>
                  {/* </div> */}
                </div>
              )}
            </div>
          </div>

          {!statsLoading && championsData ? (
            <div className="row smaller-gutter-row mb-4 in-small-gutter3">
              {page === 0 &&
                mainPlayerStats
                  .sort((a, b) => ordering[a.role] - ordering[b.role])
                  .map((playerData) => (
                    <div className={`${isScrim ? "col-4" : "col-player"}  mb-3`}>
                      <TeamStatItem
                        lanes={team.game.configuration.playerTypes || []}
                        playerData={playerData}
                        championsData={championsData}
                        statsType={statsType}
                      />
                    </div>
                  ))}
              {page === 1 &&
                subPlayerStats
                  .sort((a, b) => ordering[a.role] - ordering[b.role])
                  .map((playerData) => (
                    <div className={`${isScrim ? "col-4" : "col-player mr-3"}  mb-3`}>
                      <TeamStatItem
                        lanes={team.game.configuration.playerTypes || []}
                        playerData={playerData}
                        championsData={championsData}
                        statsType={statsType}
                      />
                    </div>
                  ))}

              {/* {playerStats.sort((a, b) => ordering[a.role] - ordering[b.role]).map((playerData) => (
            <div className="col-player">
              <TeamStatItem lanes={team.game.configuration.playerTypes || []} playerData={playerData} championsData={championsData} />
            </div>
          ))} */}
            </div>
          ) : (
            <div className="text-center league-no-matches">
              <Loading width={50} height={50} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TeamStats;
