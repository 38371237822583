import React from 'react'
import "./glitch-image.scss";

const GlitchImage = ({ url, className, intensity = 5, children, style }) => {
  return (
    <div className={"glitch " + className}>
      {[...Array(intensity)].map((_) => (
        <div className="glitch__item" style={style}>
          {children}
        </div>
      ))}
    </div>
  );
} 

export default GlitchImage;
