import React, { Component } from 'react';
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";

class RepositionTabModal extends Component {
    state = {
        tabnewName : ''
      }

      savePositions = () => {
        this.props.onSubmit();
        this.props.closeModal();
      }
    render() {
        return (
            <Modal 
            position='absolute'
            style={{ overlay: {position:'unset'} }}
            isOpen={this.props.isModalOpen} 
            onRequestClose={() => this.props.closeModal()} 
            className="modal auth-modal" 
            shouldCloseOnEsc={false}>
                <div className="modal-close" onClick={() => this.props.closeModal()} >
                    <RiCloseLine />
                </div>
                

                <div >
                    <div className="modal-header">
                        
                        <h2 className="bold uppercase">Reposition Tabs</h2>
                        {/* <h3>Connect your game account to participate in tournaments, scrims etc. You can always add your game account later.</h3> */}
                    </div>
                   
                    <div className="modal-body">
                        {this.props.children}
                        <div className="row">
                            <div className="col-6">
                                <button type="submit" className="button primary" onClick={() => this.savePositions()}>
                                Save
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Modal>
            );
  }
}

export default RepositionTabModal;
