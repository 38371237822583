import Axios from "axios"

export const joinAsTeam = async (id, args, cbSuccess, cbFailure) => {
    try {
        const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}/teams/register`, args);
        cbSuccess();
    } catch (e) {
        console.log('[ERROR JOINING]', e.response);
        cbFailure(e.response?.data?.msg);
    }
}
export const editLineup = async (id, args, cbSuccess, cbFailure) => {
    try {
        const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}/teams/edit-lineup`, args);
        cbSuccess();
    } catch (e) {
        console.log('[ERROR UPDATING]', e.response);
        cbFailure(e.response?.data?.msg);
    }
}