import React, { useRef, useState } from 'react';
import { Seed, SeedItem, SeedTeam, SingleLineSeed } from 'react-brackets';
import moment from 'moment';
import TimeCounter from './TimeCounter';
import { Link } from 'react-router-dom';
import useTranslated from '../../../../../../helpers/translationHelper';
const CustomSeed = ({
    seed,
    breakpoint,
    roundIndex,
    hilighted,
    setHilighted,
    singleLine,
    losingTrack,
    hovered,
    setHovered,
    matchIndex,
    handleMatchClick
}) => {
    let _best_of_match_display = useTranslated("id_match_display_5");

    if (!seed) return (
        <SingleLineSeed
            mobileBreakpoint={breakpoint}
            style={{
                fontSize: 15,
                minWidth: 350,
            }}>
            <div style={{ height: 1, backgroundColor: "#707070", width: "100%", bottom: -1 }} />
        </SingleLineSeed>
    )

    if (seed.hidden) return (
        <div style={{
            fontSize: 15,
            minWidth: 350,
            padding: "1em 1.5em",
            visibility: 'hidden'
        }} />
    )

    const Wrapper = singleLine ? SingleLineSeed : Seed;
    const {
        matchData,
        teams,
        index,
        forceHilight
    } = seed;

   

    return (
        <>
            <Wrapper
                borderColor={hilighted ? "#747474" : "#FFFFFF"}
                mobileBreakpoint={breakpoint}
                style={{
                    fontSize: 15,
                    minWidth: 350,
                }}>
                <Status datetime={matchData?.datetime} status={matchData?.status} />
                <SeedItem className="bg-transparent seed-container" onClick={teams[0]?.team && teams[1]?.team ? ()=> handleMatchClick(matchData):null}>
                    <div>
                        <CustomSeedTeam
                            showScore={matchData?.status !== "upcoming"}
                            roundIndex={roundIndex}
                            matchIndex={matchIndex}
                            forceHilight={forceHilight || hovered && (roundIndex === hovered.roundIndex && matchIndex === hovered.matchIndex && hovered.place === "upper")}
                            upper
                            hilighted={hilighted}
                            status={matchData?.status}
                            setHilighted={setHilighted}
                            hovered={hovered}
                            setHovered={setHovered}
                            winner={teams[0] && teams[1] ? teams[0].score > teams[1].score : teams[0]}
                            // seedTeam={teams[0] ? teams[0] : { team: {} }}
                            seedTeam={setSeedTeam(teams,1)}

                             />
                        <CustomSeedTeam
                            showScore={matchData?.status !== "upcoming"}
                            roundIndex={roundIndex}
                            matchIndex={matchIndex}
                            forceHilight={forceHilight || hovered && (roundIndex === hovered.roundIndex && matchIndex === hovered.matchIndex && hovered.place === "lower")}
                            lower
                            hilighted={hilighted}
                            status={matchData?.status}
                            setHilighted={setHilighted}
                            hovered={hovered}
                            setHovered={setHovered}
                            winner={teams[0] && teams[1] ? teams[1].score > teams[0].score : teams[1]}
                            seedTeam={setSeedTeam(teams,2)} />
                    </div>
                </SeedItem>
                <div className="w-100 row p-0 pt-2 m-0 justify-content-between margin-bottom-7 text-grey">
                    <span>
                        Match {index} - {_best_of_match_display}  {matchData?.bestOf}
                    </span>
                    {matchData?.status !== "upcoming" ?
                        <Link className="text-decoration-none" to={"/match/" + matchData?._id}>
                            View details
                        </Link>
                        : null}
                </div>
            </Wrapper>
        </>
    );
}

export default CustomSeed;


//set which team to show up and down 
const setSeedTeam = (teams,teamPlace)=>{
let team = null;
 if(teamPlace === 1 && teams[0] && teams[1]){
    teams.sort((a, b) => a.seedOrder - b.seedOrder);
    team = teams[0];
 }
 else if(teamPlace === 1 && (teams[0]=== null || teams[1]=== null)){
    team = teams[0] ? teams[0]:teams[1]?teams[1]:{team:{}};
 }
 else if(teamPlace === 2 && teams[0] && teams[1]){
    teams.sort((a, b) => a.seedOrder - b.seedOrder);
    team = teams[1];
 }
 else if(teamPlace === 2 && (teams[0] === null || teams[1] === null)){
    team = {team:{}};
 }
 
 return team;
}

const CustomSeedTeam = ({ hilighted, setHilighted, seedTeam, upper, lower, showScore, winner, status, roundIndex, matchIndex, setHovered, forceHilight }) => {
    const [show, setShow] = useState(false);
    const {
        _id,
        team,
        score
    } = seedTeam;
    const onMouseEnter = () => {
        setHilighted(_id);
        setShow(true);
        if (!_id && status === "upcoming") {
            setHovered({
                roundIndex,
                matchIndex,
                place: upper ? "upper" : "lower"
            })
        }
    }
    const onMouseLeave = () => {
        setHilighted("");
        setShow(false);
        setHovered(undefined);
    }
    const target = useRef();

    return (
        <SeedTeam
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={_id && hilighted === _id ?
                "seed-item bold bg-hilighted border-0 " + (status === "complete" && !winner ? "bg-hilighted-lost" : "")
                :
                forceHilight ?
                    "seed-item bold bg-secondary border-0 bg-force-hilight"
                    :
                    "seed-item bold bg-secondary border-0 " + (status === "complete" && !winner ? "bg-lost" : "")}
                    // className={_id && hilighted === _id ?
                    //     "seed-item bold bg-hilighted border-0 " + (status === "complete" && !winner ? "bg-hilighted-lost" : "")
                    //     :
                    //     forceHilight ?
                    //         "seed-item bold bg-secondary border-0 " + (status !== "complete" ? "bg-force-hilight" : "")
                    //         :
                    //         "seed-item bold bg-secondary border-0 " + (status === "complete" && !winner ? "bg-lost" : "")}        
            style={{
                borderTopLeftRadius: upper ? 10 : 0,
                borderTopRightRadius: upper ? 10 : 0,
                borderBottomLeftRadius: lower ? 10 : 0,
                borderBottomRightRadius: lower ? 10 : 0,
                borderBottom: upper ? "0.1px solid #444" : undefined,
            }}>
            <div ref={target} className="row w-100 p-0 m-0 align-items-center">
                {team?.logo ?
                    <img
                        className="avatar"
                        style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "40px",
                            margin: "5px",
                            verticalAlign: "middle"
                        }}
                        src={team?.logo} />
                    :
                    null}
                <div className="flex-fill text-left">
                    {team?.name}
                </div>
                {score !== undefined && showScore ?
                    <div className="p-1">
                        {score}
                    </div>
                    : null}
            </div>
        </SeedTeam>
    );
}

const Status = ({ lower, status = "upcoming", datetime }) => (
    <div className="w-100 row align-items-center p-0 pb-2 m-0 justify-content-between">
        <span className={`pill ${statuses[status].class} m-0 ${lower ? "visibility-hidden" : ""}`}>
            {statuses[status].text}
        </span>
        {status === "inProgress" ?
            <TimeCounter datetime={datetime} />
            : null}
        {status !== "inProgress" ?
            <small>
                {moment(datetime).format("DD MMM, YYYY - HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr()}
            </small>
            : null}
    </div>
)

const statuses = {
    upcoming: { text: "Upcoming", class: "warning" },
    inProgress: { text: "Playing", class: "primary" },
    complete: { text: "Completed", class: "success" },
}
