import React, { useEffect, useState } from "react";
import default_profile_pic from "@img/unranked.png";
import Axios from "axios";
import Loading from "@common/Loading/Loading";
import { Link } from "react-router-dom";
const RankData = ({ playerId }) => {
  const [dataLoading, setDataLoading] = useState(true);
  const [league, setLeague] = useState(null);
  const [team, setTeam] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    setDataLoading(true);

    Axios.get(`${process.env.REACT_APP_CSGO_API}/api/players/rankData/${playerId}`).then(({ data }) => {
      let leastLeague = null;
      let filteredTeam = null;
      let role = null;

      if (data.assocLeagues.length) {
        data.assocLeagues.sort(function (a, b) {
          return a.leagueDivision - b.leagueDivision;
        });

        leastLeague = data.assocLeagues[0];
        // setLeague(data.assocLeagues[0]);
      }

      if (leastLeague) {
        leastLeague.teams.filter((team) => {
          if (filteredTeam === null) {
            team.team.members.filter((member) => {
              if (member.player) {
                if (member.player === playerId || member.user === playerId || member.coach === playerId) {
                  filteredTeam = team.team;
                }
              }
            });
          }
        });
      }

      //to get role in the team
      // if(filteredTeam){
      //   filteredTeam.members.filter((mem) => {
      //     if(mem.player === playerId || mem.user === playerId || mem.coach === playerId){
      //       filteredTeam = team.team;
      //     }
      //   })
      // }

      // setRole(role);
      setLeague(leastLeague);
      setTeam(filteredTeam);

      setDataLoading(false);
    });
  }, [playerId]);

  // console.log('roma-league-',league);
  // console.log('roma-team-',team);
  {
    /* <Link className="btn text-secondary" to={`/adverts/${row._id}/manage`}>
              <FontAwesomeIcon icon={faAd} />
            </Link> */
  }
  return (
    <>
      <div className="col-2">
        {!dataLoading ? (
          league ? (
            <Link className="results-detail-team" to={`/league/${league._id}`}>
              <img src={league.logoImage || default_profile_pic} alt={league.name} className="game-profile-lg-image" />
              <div className="flex-column">
                <div className="text-12 bold">{league.name}</div>
                <div className="text-12 faded pt-1 pb-1">{league.leagueDivisionName}</div>
              </div>
            </Link>
          ) : (
            <>
              <img src={default_profile_pic} alt={"No Division"} className="game-profile-lg-image" />
              <div className="flex-column">
                {/* <div className="text-12 bold">{}</div> */}
                <div className="text-12 faded pt-1 pb-1">No Division</div>
              </div>
            </>
          )
        ) : (
          <div className="col-12 text-center">
            <Loading />
          </div>
        )}
      </div>

      <div className="col-3">
        {!dataLoading ? (
          team ? (
            <Link
              className="results-detail-team"
              to={`/teams/${team._id}`}
            // style={{ justifyContent:`flex-end`}}
            >
              <img
                src={team.logo || default_profile_pic}
                alt={team.name}
                className={`${team.logoCropped || team.logoCropped === undefined ? "game-profile-lg-image" : "game-profile-lg-image-team"}`}
              />
              <div className="flex-column">
                <div className="text-12 bold">{team.name}</div>
              </div>
            </Link>
          ) : (
            <>
              <img src={default_profile_pic} alt={`No Team`} className="game-profile-lg-image" />
              <div className="flex-column">
                <div className="text-12 bold">No team</div>
              </div>
            </>
          )
        ) : (
          <div className="col-12 text-center">
            <Loading />
          </div>
        )}
      </div>
    </>
  );
};

export default RankData;
