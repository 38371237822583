import React, { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { RiCheckLine } from "react-icons/ri";

const NotificationSuccess = ({ _id, title = "", body = "", createdAt = "" }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 2000);
  }, [_id]);

  return (
    <div className={`notification-item completed ${!show && "hide"}`}>
      <div className="completed-text">
        <RiCheckLine /> Success
      </div>
      <div className="notification-item-inner">
        <h3>{title}</h3>
        <p>{body}</p>
        <small>{moment(createdAt).fromNow()}</small>
      </div>
    </div>
  );
};

export default NotificationSuccess;
