export const getTracks = (brackets) => {
    const winnigIds = [];
    brackets[0].forEach(round => round.forEach(({ matchData }) => {

        if (matchData != undefined) {
            const { t1Score, t2Score, t1, t2, status } = matchData;
            if (!t1 && !t2) return;
            if ((!t1Score && !t2Score) || status !== "complete") {
                t1 && winnigIds.push(t1);
                t2 && winnigIds.push(t2);
                return;
            }
            if (t1Score > t2Score) {
                if (winnigIds.includes(t2)) winnigIds.splice(winnigIds.findIndex(el => el === t2), 1);
                if (winnigIds.includes(t1)) {
                    return;
                }
                winnigIds.push(t1);
            }
            if (t1Score < t2Score) {
                if (winnigIds.includes(t1)) winnigIds.splice(winnigIds.findIndex(el => el === t1), 1);
                if (winnigIds.includes(t2)) {
                    return;
                }
                winnigIds.push(t2);
            }
        }
    }));
    const losingIds = [];
    brackets[1].forEach(round => round.forEach(({ matchData }) => {
        if (matchData != undefined) {
            const { t1Score, t2Score, t1, t2, status } = matchData;
            if (!t1 && !t2) return;
            if ((!t1Score && !t2Score) || status !== "complete") {
                t1 && losingIds.push(t1);
                t2 && losingIds.push(t2);
                return;
            }
            if (t1Score > t2Score) {
                if (losingIds.includes(t2)) losingIds.splice(losingIds.findIndex(el => el === t2), 1);
                if (losingIds.includes(t1)) {
                    return;
                }
                losingIds.push(t1);
            }
            if (t1Score < t2Score) {
                if (losingIds.includes(t1)) losingIds.splice(losingIds.findIndex(el => el === t1), 1);
                if (losingIds.includes(t2)) {
                    return;
                }
                losingIds.push(t2);
            }
        }
    }));
    winnigIds.forEach(_id => {
        const foundIndex = losingIds.findIndex(el => el === _id);
        if (foundIndex !== -1) losingIds.splice(foundIndex, 1);
    });
    return {
        winnigIds,
        losingIds
    }
}