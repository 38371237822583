import React, { useState, useEffect, useContext } from "react";
import DotPattern from "./DotPattern";
import { Button, SliderItem, Icon, Advert, AdvertByGame } from "@common";
import {Link} from "react-router-dom";
import { FiPlay } from "react-icons/fi";
import { RiCloseLine } from "react-icons/ri";
const Header = ({data}) => {
console.log('dasdasdasdsad-',data)
    const [backText,setBackText] = useState("Loading...");
    const [blueText,setBlueText] = useState("Loading...");
    const [whiteText,setWhiteText] = useState("Loading...");
    const [link,setLink] = useState("#");
    
    useEffect(() => {
        if(data.BackgroundText){
            setBackText(data.BackgroundText);
        }
        if(data.BlueText){
            setBlueText(data.BlueText);
        }
        if(data.WhiteText){
            setWhiteText(data.WhiteText);
        }
        if(data.Link){
            setLink(data.Link);
        }
       
      },[data]);

    return (
        
        <div className="container-fluid">
        <div className="cool-heading-container">
            
            <div className="icon-cross">
                <Icon name="cross" pureIcon={<RiCloseLine size={`1.5em`} />} />
            </div>
            
            <div className="icon-play">
                <Icon name="play" pureIcon={<FiPlay size={`1.5em`} />} />
            </div>
            
            <DotPattern />
            
            <h1 className="center">
                <span className="news-heading-background">{backText}</span>
                <span className="news-heading-foreground">
                <Link to={link} style={{textDecoration:'none'}}><span className="outline">{blueText}</span> {whiteText}</Link>
                </span>
            </h1>
        </div>      
    </div>
    )
}

export default Header;