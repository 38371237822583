import Axios from "axios";

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export default async (existingSubscriptions = []) => {
  if (!("serviceWorker" in navigator)) return false;

  try {
    const register = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`, { scope: "/" });
    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
    });

    // If this device is not already subscribed, store it in the users push subscriptions
    if (!existingSubscriptions.includes(JSON.stringify(subscription)))
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/notifications/subscribe`, { subscription });
  } catch (e) {
    return false;
  }
};
