import React, { useEffect } from "react";
import objective_1_primary from "@img/objective-1-primary.svg";
import objective_2_primary from "@img/objective-2-primary.svg";
import objective_3_primary from "@img/objective-3-primary.svg";
import objective_1_danger from "@img/objective-1-danger.svg";
import objective_2_danger from "@img/objective-2-danger.svg";
import objective_3_danger from "@img/objective-3-danger.svg";
import useTranslated from "../../../helpers/translationHelper";

const GameOverviewBox = ({ map, champions, hide_bans }) => {
  const championIdToImg = (championId) => {
    const championData = champions[championId];
    const championVers = championData.version;
    const championImage = championData.image.full;

    return `https://ddragon.leagueoflegends.com/cdn/${championVers}/img/champion/${championImage}`;
  };

  const calculateTotal = (teamId, statName) => {
    const teamParticipants = map.participants.filter(({ teamId: id }) => id === teamId);

    let total = 0;
    teamParticipants.forEach(({ stats }) => {
      total += stats ? stats[statName] : 0;
    });

    return total;
  };
  const bansTrans = useTranslated('id_match_page_(games)_[lol]_0')
  const objectTrans = useTranslated('id_match_page_(games)_[lol]_1')
  const killTrans = useTranslated('id_match_page_(games)_[lol]_2')
  const goldTrans = useTranslated('id_match_page_(games)_[lol]_3')

  return (
    <div className="faded-box-item">
      <div className="row tiny-gutter-row">
        {/* TEAM 1 */}
        <div className={hide_bans ? "col-3" : "col-4"}>
          <div className="row tiny-gutter-row">
            {!hide_bans && (
              <div className="col-5">
                <span className="games-overview-col-header text-12 uppercase bold">{/* Bans */}{bansTrans}</span>

                <div className="games-overview-col-inner">
                  {map.teams[0].bans.map(({ championId }) => (
                    <img src={championIdToImg(championId)} alt={championId} className="games-overview-ban" />
                  ))}
                </div>
              </div>
            )}
            <div className={hide_bans ? "col-8" : "col-5"}>
              <span className="games-overview-col-header text-12 uppercase bold">{/* Objectives */}{objectTrans}</span>

              <div className="games-overview-col-inner objectives">
                <img src={objective_1_primary} alt="Tower Kills" className="objective-image" />
                <span className="text-12 bold">{map.teams[0].towerKills}</span>
                <img src={objective_2_primary} alt="Baron Kills" className="objective-image" />
                <span className="text-12 bold">{map.teams[0].baronKills}</span>
                <img src={objective_3_primary} alt="Dragon Kills" className="objective-image" />
                <span className="text-12 bold">{map.teams[0].dragonKills}</span>
              </div>
            </div>
            <div className={hide_bans ? "col-4" : "col-2"}>
              <span className="games-overview-col-header text-12 uppercase bold">{/* Kills */}{killTrans}</span>
              <span className="text-12 uppercase bold">{calculateTotal(100, "kills")}</span>
            </div>
          </div>
        </div>

        {/* TEAM 2 */}
        <div className={hide_bans ? "col-6 center" : "col-4 center"}>
          <span className="games-overview-col-header text-12 uppercase bold">{/* Gold */}{goldTrans}</span>
          <div className="gold-ratio-bar-wrapper">
            <span className="text-12 bold">{calculateTotal(100, "goldEarned")}</span>
            <div className="gold-ratio-bar">
              <div
                className="primary"
                style={{
                  width:
                    (calculateTotal(100, "goldEarned") / (calculateTotal(100, "goldEarned") + calculateTotal(200, "goldEarned"))) * 100 +
                    "%",
                }}
              ></div>
              <div
                className="danger"
                style={{
                  width:
                    (calculateTotal(200, "goldEarned") / (calculateTotal(100, "goldEarned") + calculateTotal(200, "goldEarned"))) * 100 +
                    "%",
                }}
              ></div>
            </div>
            <span className="text-12 bold">{calculateTotal(200, "goldEarned")}</span>
          </div>
        </div>
        <div className={hide_bans ? "col-3" : "col-4"}>
          <div className="row tiny-gutter-row">
            <div className={hide_bans ? "col-4" : "col-2"}>
              <span className="games-overview-col-header text-12 uppercase bold">{/* Kills */}{killTrans}</span>
              <span className="text-12 uppercase bold">{calculateTotal(200, "kills")}</span>
            </div>
            <div className={hide_bans ? "col-8" : "col-5"}>
              <span className="games-overview-col-header text-12 uppercase bold">{/* Objectives */}{objectTrans}</span>

              <div className="games-overview-col-inner objectives">
                <img src={objective_1_danger} alt="Tower Kills" className="objective-image" />
                <span className="text-12 bold">{map.teams[1].towerKills}</span>
                <img src={objective_2_danger} alt="Baron Kills" className="objective-image" />
                <span className="text-12 bold">{map.teams[1].baronKills}</span>
                <img src={objective_3_danger} alt="Dragon Kills" className="objective-image" />
                <span className="text-12 bold">{map.teams[1].dragonKills}</span>
              </div>
            </div>
            {!hide_bans && (
              <div className="col-5">
                <span className="games-overview-col-header text-12 uppercase bold">{/* Bans */}{bansTrans}</span>
                <div className="games-overview-col-inner">
                  {map.teams[1].bans.map(({ championId }) => (
                    <img src={championIdToImg(championId)} alt={championId} className="games-overview-ban" />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameOverviewBox;
