import React, { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import Loading from "../../../_common/Loading/Loading";
import { toast } from "react-toastify";
import Axios from "axios";
import moment from "moment";
import { countries } from "@utils";

const JoinPlayerFinder = ({ open, onClose, existingPlayer, selectedGame, getMyStatus, birthday, getPlayers }) => {
  const [nationalities, setNationalities] = useState([]);
  const [description, setDescription] = useState('');
  const [language,setLanguage] = useState(false);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    // Axios.get("https://restcountries.eu/rest/v2/all?fields=demonym;alpha2Code", { headers: false }).then(({ data }) => {
    //   setNationalities(
    //     data
    //       .filter(({ demonym }) => demonym)
    //       .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    //       .sort((a, b) => a.demonym.localeCompare(b.demonym))
    //   );
    // });
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, []);

  
  const onSubmit = async (e) => {
    setLoading(true);
    if(existingPlayer.length === 0){
      toast.error("You don't have any active Player in your account.");
      setLoading(false);
    }else{
      try {
        const { data: finder } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/finder`, {
          player: existingPlayer[0]._id,
          playerDescription: description,
          language:language.value,
          minor:(moment().diff(birthday, "years") <= 18),
          game: selectedGame._id,
        });
        if (finder) {
          toast.success("Successfully joined finder!");
          getPlayers()
          
          setLoading(false);
          onClose();
          getMyStatus(existingPlayer);
        }
      } catch (e) {
        // console.log('e-',e)
        const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem joining finder";
        toast.error(msg);
        getMyStatus(existingPlayer);
        setLoading(false);
      }
    }
  };

 
 
  const handleRegionFilter = (e) => {
    // setLanguage({ ...language, [event.target.name]: event.target.value });
    // console.log('event-',event.target)
    // setLanguage({value: event.option});
    var options = e.target.options;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
              if (options[i].selected) {
                value.push(options[i].value);
              }
            }
            setLanguage({value: value});
  };

  const handleNewMessageChange = (event) => {
    setDescription(event.target.value);
  };
// console.log('language-',language)
  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" contentLabel="Example Modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>Join Player Finder</h2>
        {/* <h3>Connect your game account to participate in tournaments, scrims etc. You can only create one player profile per game.</h3> */}
      </div>
      <div className="modal-body">
     
      <div className="form-row">
        <label htmlFor="summoner-name">Description</label>
        <textarea
              type="text"
              max={2000}
              rows={4}
              id="description"
              name="description"
              autoComplete="description"
              placeholder="Write something about player"
              className={`form-input`}
              value={description}
              onChange={handleNewMessageChange}
            />
      </div>

    

      <div className="form-row">
        <label htmlFor="secondaryRole">Language</label>
        <select
          className="select" multiple={true} name="language" value={language.value || '_DEFAULT_'} onChange={handleRegionFilter}
        >
          <option value="_DEFAULT" disabled>
            Choose Language
          </option>
          {nationalities.map(({ alpha2Code, demonym }) => (
              <option key={alpha2Code} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
                {demonym}
              </option>
            ))}
        </select>
      </div>

      <button onClick={() => onSubmit()} className="button primary thicc" >
        {loading ? (
          <>
            <Loading width={30} height={30} /> Loading
          </>
        ) : (
          "Submit"
        )}
      </button>
  
      </div>
    </Modal>
  );
};

export default JoinPlayerFinder;
