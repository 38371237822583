import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import { useForm, useFieldArray } from "react-hook-form";
import cross from "@img/cross-black.svg";
import { RichEditor } from "../../_common/RichEditor/RichEditiorAppointment";
import { CheckboxSlider, Checkbox } from "@common";
import { FaQuestion, FaPlus } from "react-icons/fa";
import DynamicSearchAppointment from "@common/DynamicSearchAppointment/DynamicSearchAppointment";
import Axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { appointmentWeekColor } from "../../../utils/calendarHelper";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { AiOutlineDelete } from "react-icons/ai";

const selectedGameSelected = localStorage.getItem(["selectedGame"]);
let game = "";
if (selectedGameSelected && JSON.parse(selectedGameSelected).mainColour) {
  game = JSON.parse(selectedGameSelected).shortName;
}

const options = [
  {
    value: "TRAINING",
    label: (
      <>
        <div className="typeDot" style={{ borderRight: "7px solid #3788d8", borderLeft: "7px solid #3788d8", background: '#3788d8' }}></div>TRAINING
      </>
    ),
  },
  {
    value: "MATCH",
    label: (
      <>
        <div className="typeDot" style={{ borderRight: "7px solid #d5901e", borderLeft: "7px solid #d5901e", background: '#d5901e' }}></div>MATCHES
      </>
    ),
  },
  {
    value: "EVENT",
    label: (
      <>
        <div className="typeDot" style={{ borderRight: "7px solid #46db60", borderLeft: "7px solid #46db60", background: '#46db60' }}></div>EVENTS
      </>
    ),
  },
];

const CreateAppointmentModal = ({ open, onClose, team, teamOrg, appointment, setEvents, setEventCopy, events, edit, eventsCopy }) => {
  const timeout = useRef();
  const cet = moment.tz.guess();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [appointmentData, setAppointmentData] = useState(appointment);
  const [addGuest, setAddGuest] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [membersRoles, setMembersRoles] = useState([]);
  const [startTime, setStartTime] = useState("15:00");
  const [endTime, setEndTime] = useState("16:00");
  const [appointType, setAppointType] = useState(options[2]);
  const [visibility, setVisibilty] = useState({
    organisation: false,
    team: false,
    participants: true,
    public: false,
  });
  const [teamOrganisationvisibility, setTeamOrganisationvisibility] = useState({
    members: false,
    staff: false,
    coaches: false,
    players: false,
  });

  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      notifications: appointmentData?.notifications || [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "notifications",
  });

  useEffect(() => {
    if (appointmentData && Object.keys(appointmentData).length > 2) {
      setAppointType(options.find((el) => el.value === appointmentData.appointment_type));
      setDescription(appointmentData.description);
      setStartTime(moment(appointmentData.start).format("HH:mm"));
      setEndTime(moment(appointmentData.end).format("HH:mm"));
      setTeamOrganisationvisibility(appointmentData.team_org_visibility);
      setVisibilty(appointmentData.appointment_visibility);
      setSelectedMembers(
        appointmentData.guests.map((guest) => {
          if (guest.user) {
            return { ...guest.user, title: `${guest.user.firstName} ${guest.user.lastName}`, type: "User", img: guest.user.avatarImage };
          }
          if (guest.coach) {
            return { ...guest.coach, title: guest.coach.name, type: "Coach", img: guest.coach.avatarImage };
          }
          if (guest.player) {
            return { ...guest.player, title: guest.player.name, type: "Player", img: guest.player.avatarImage };
          }
        })
      );
    }
    if (appointmentData && Object.keys(appointmentData).length === 2) {
      setStartTime(moment(appointmentData.start).format("HH:mm"));
      setEndTime(moment(appointmentData.end).format("HH:mm"));
    }
  }, [appointmentData]);

  const removeAppointment = async () => {
    try {
      Swal.fire({
        text: "Do you want to delete this appointment",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#28a745",
        cancelButtonText: "No",
        width: "20%",
        customClass: "custom_swal",
        imageHeight: "20px",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/appointment/${appointmentData._id}`, {});
          setEvents(events.filter((event) => event.extendedProps._id !== appointmentData._id));
          setEventCopy(eventsCopy.filter((event) => event.extendedProps._id !== appointmentData._id));
          onReset();
          toast.success(`Successfully deleted appointment`);
        }
      });
    } catch (e) {
      const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem deleting Appointment";
      toast.error(msg);
    }
  };

  const onSubmit = async (formData) => {
    const selectedGuest = [];
    {
      selectedMembers.map(({ _id, type }) => {
        switch (type) {
          case "User": {
            selectedGuest.push({ user: _id });
            break;
          }
          case "Coach": {
            selectedGuest.push({ coach: _id });
            break;
          }
          case "Player": {
            selectedGuest.push({ player: _id });
            break;
          }
        }
      });
    }

    const newAppointment = {
      title: formData.title,
      start: new Date(`${formData.startDate} ${formData.startTime}`),
      end: new Date(`${formData.startDate} ${formData.endTime}`),
      description: description,
      appointment_type: appointType.value,
      rrule_type: formData.rrule_type,
      team: formData.inviteParticipants,
      organisation: null,
      appointment_visibility: visibility,
      team_org_visibility: teamOrganisationvisibility,
      guests: selectedGuest,
      notifications: formData.notifications,
    };

    try {
      setLoading(true);

      let updatedData = null;
      if (team) {
        if (edit) {
          updatedData = await Axios.put(
            `${process.env.REACT_APP_CORE_API}/api/appointment/${appointmentData._id}?teamId=${team.team._id}`,
            newAppointment
          );
        } else {
          updatedData = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/appointment?teamId=${team.team._id}`, newAppointment);
        }
      }
      if (!team) {
        let teamGuest = "";
        let orgGuest = "";
        teamOrg.teams.forEach((el) => {
          if (el._id === formData.inviteParticipants) {
            teamGuest = el._id;
          }
        });
        teamOrg.organisations.forEach((el) => {
          if (el._id === formData.inviteParticipants) {
            orgGuest = el._id;
          }
        });

        newAppointment.team = teamGuest;
        newAppointment.organisation = orgGuest;

        if (teamGuest === "") newAppointment.team = null;
        if (orgGuest === "") newAppointment.organisation = null;

        if (edit) {
          updatedData = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/appointment/${appointmentData._id}`, newAppointment);
        } else {
          updatedData = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/appointment`, newAppointment);
        }
      }

      const { data } = updatedData;

      if (!edit) {
        let appointmentData = [];
        if (data.appointment.rrule_type === "noRepeating") {
          appointmentData.push({
            title: data.appointment.title,
            start: data.appointment.start,
            end: data.appointment.end,
            backgroundColor: appointmentWeekColor(data.appointment.start, data.appointment.appointment_type),
            extendedProps: {
              ...data.appointment,
            },
          });
        } else {
          appointmentData.push({
            title: data.appointment.title,
            duration: data.appointment.duration,
            rrule: data.appointment.rrule,
            backgroundColor: appointmentWeekColor(data.appointment.start, data.appointment.appointment_type),
            extendedProps: {
              ...data.appointment,
            },
          });
        }
        setEvents([...events, ...appointmentData]);
        setEventCopy([...eventsCopy, ...appointmentData]);
      } else {
        let appointmentData = events.map((event) => {
          if (event.extendedProps._id === appointment._id) {
            if (data.appointment.rrule_type === "noRepeating") {
              return {
                title: data.appointment.title,
                start: data.appointment.start,
                end: data.appointment.end,
                backgroundColor: appointmentWeekColor(data.appointment.start, data.appointment.appointment_type),
                extendedProps: {
                  ...data.appointment,
                },
              };
            } else {
              return {
                title: data.appointment.title,
                duration: data.appointment.duration,
                rrule: data.appointment.rrule,
                backgroundColor: appointmentWeekColor(data.appointment.start, data.appointment.appointment_type),
                extendedProps: {
                  ...data.appointment,
                },
              };
            }
          }
          return event;
        });
        setEvents(appointmentData);
        setEventCopy(appointmentData);
      }
      toast.success("Successfully created Appointment!");
      setLoading(false);
      onReset();
    } catch (e) {
      const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem creating your Appointment";
      setLoading(false);
      toast.error(msg);
    }
  };

  const onSearch = async (query) => {
    clearTimeout(timeout.current);
    setResults([]);
    if (!query.length) return setLoading(false);
    setLoading(true);

    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch`, {
        query,
        include: "users, player, coach",
        selectedGameId: JSON.parse(selectedGameSelected)._id,
      });

      // Map data to required output, and remove already selected players
      const searchResults = data
        .filter(({ _id }) => !selectedMembers.map(({ _id }) => _id).includes(_id))
        .map(({ _id, img, name, user, type }) => ({
          _id,
          type,
          img,
          title: name,
          subtitle: user ? `${user.firstName} ${user.lastName}` : false,
        }));

      setResults(searchResults);
      setLoading(false);
    }, 250);
  };

  const addMember = (player) => {
    setResults(false);
    // let playerAdvData = getPlayerData(player._id)
    setSelectedMembers([...selectedMembers, { ...player, role: "Player" }]);
  };

  const removeMember = (idToRemove) => {
    let selectedPlayer = selectedMembers.find((player) => player._id === idToRemove);
    setMembersRoles(
      membersRoles.filter(
        (player) =>
          player._id !== selectedPlayer.player?._id && player._id !== selectedPlayer.coach?._id && player._id !== selectedPlayer.user?._id
      )
    );
    setSelectedMembers(selectedMembers.filter(({ _id }) => _id !== idToRemove));
  };

  const onReset = () => {
    setAppointType(options[2]);
    setDescription("");
    setStartTime("15:00");
    setEndTime("16:00");
    setTeamOrganisationvisibility({
      members: false,
      staff: false,
      coaches: false,
      players: false,
    });
    setVisibilty({
      organisation: false,
      team: false,
      participants: true,
      public: false,
    });
    setSelectedMembers([]);
    onClose();
  };

  return (
    <Modal isOpen={open} onRequestClose={onReset} className="appointmentModal" contentLabel="Example Modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onReset}>
        <RiCloseLine />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-scrollable">
          <div className="modal-header">
            <div className="row">
              <div className="col-5 side-bar-calender">
                <div className="form-row">
                  <textarea
                    ref={register({ maxLength: { value: 20, message: "Title too long" }, required: "Required" })}
                    type="text"
                    max={20}
                    defaultValue={appointmentData ? appointmentData.title : ""}
                    id="title"
                    name="title"
                    autoComplete="title"
                    placeholder="ADD A TITLE"
                    className="title"
                  />
                  {errors.title && <div className="form-input-error">{errors.title.message}</div>}
                </div>
              </div>
              <div className="col-7 side-bar-calender">
                <div className="form-row">
                  <button type="submit" className="appointmentButton">
                    {edit ? "SAVE" : "CREATE APPOINTMENT"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-8 side-bar-calender">
                <div className="form-row">
                  <label className="label" htmlFor="when">
                    WHEN ({cet})
                  </label>
                  <input
                    ref={register({
                      required: "Required",
                    })}
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    max={moment().add(2, "years").format("YYYY-MM-DD")}
                    id="startDate"
                    name="startDate"
                    defaultValue={
                      appointmentData?.start
                        ? moment(appointmentData?.start).format("YYYY-MM-DD")
                        : moment().add(1, "days").format("YYYY-MM-DD")
                    }
                    className={`form-input appointmentDate ${errors.startDate ? "invalidDate" : ""}`}
                  />
                  <input
                    ref={register({
                      validate: (val) => moment(val, "HH:mm").isBefore(moment(endTime, "HH:mm")),
                    })}
                    onBlur={(e) => {
                      setStartTime(e.target.value);
                    }}
                    type="time"
                    id="startTime"
                    name="startTime"
                    placeholder="00:00"
                    min={moment()}
                    defaultValue={startTime}
                    className={`form-input time ${errors.startTime ? "invalidTime" : ""}`}
                  />
                  <span className="bold" style={{ fontSize: "13px" }}>
                    TO
                  </span>
                  <input
                    ref={register({
                      validate: (val) => moment(startTime, "HH:mm").isBefore(moment(val, "HH:mm")),
                    })}
                    onBlur={(e) => {
                      setEndTime(e.target.value);
                    }}
                    type="time"
                    id="endTime"
                    name="endTime"
                    placeholder="00:00"
                    defaultValue={endTime}
                    min={moment()}
                    className={`form-input time ${errors.startTime ? "invalidTime" : ""}`}
                  />
                </div>
                <div className="form-row">
                  <select
                    id="rrule_type"
                    name="rrule_type"
                    className="form-input whenDropdown"
                    defaultValue={appointmentData ? appointmentData.rrule_type : "noRepeating"}
                    ref={register({
                      required: "Required",
                    })}
                  >
                    <option value="noRepeating">NOT REPEATED</option>
                    <option value="daily">EVERY DAY</option>
                    <option value="weekly">EVERY WEEK</option>
                    <option value="monthly">EVERY MONTH</option>
                    <option value="workingDays">All Working Days (Mon - Fri)</option>
                  </select>
                  {errors.rrule_type && <div className="form-input-error">{errors.rrule_type.message}</div>}
                </div>

                <div className="form-row">
                  <label className="label" htmlFor="notification">
                    NOTIFICATION
                  </label>

                  {fields.map((field, index) => {
                    return (
                      <div className="form-row" key={field.id}>
                        <select
                          name={`notifications[${index}].notification_medium`}
                          defaultValue={`${field.notification_medium}`} // make sure to set up defaultValue
                          ref={register({
                            required: "Required",
                          })}
                          id="notification_medium"
                          className="form-input notificationDropdown"
                        >
                          <option value="WEBSITE">WEBSITE</option>
                          <option value="EMAIL">EMAIL</option>
                          <option value="SMS">SMS</option>
                        </select>

                        <input
                          name={`notifications[${index}].time_value`}
                          defaultValue={`${field.time_value}`}
                          ref={register({
                            required: "Required",
                          })}
                          type="number"
                          id="time_value"
                          className={`form-input notificationInput ${errors.time_value ? "notificationInputInvalid" : ""}`}
                          ref={register({ min: 0, max: 100 })}
                        />

                        <select
                          name={`notifications[${index}].time_unit`}
                          defaultValue={`${field.time_unit}`}
                          ref={register({
                            required: "Required",
                          })}
                          id="time_unit"
                          className="form-input notificationDropdown"
                        >
                          <option value="MINUTES">MINUTES</option>
                          <option value="HOURS">HOURS</option>
                          <option value="DAYS">DAYS</option>
                        </select>

                        <img
                          type="button"
                          className="deleteImage"
                          src={cross}
                          onClick={() => {
                            remove(index);
                          }}
                        />

                        {errors.time_value && <div className="form-input-error">Value Less Than 100</div>}
                      </div>
                    );
                  })}

                  <button
                    type="button"
                    className={game === "CSGO" ? "notificationButtonCsgo" : "notificationButton"}
                    onClick={() => {
                      append({ notification_medium: "WEBSITE", time_value: "30", time_unit: "MINUTES" });
                    }}
                    disabled={fields.length === 5 ? true : false}
                  >
                    <span style={{ marginRight: 10 }}>
                      <FaPlus size={8} />
                    </span>
                    ADD A NOTIFICATION
                  </button>
                </div>
                <div className="form-row">
                  <label className="label" htmlFor="appointment_type">
                    APPOINTMENT TYPE
                  </label>
                  <Select
                    id="appointment_type"
                    name="appointment_type"
                    value={appointType}
                    onChange={(selectedOption) => {
                      setAppointType(selectedOption);
                    }}
                    isSearchable={false}
                    options={options}
                  />
                </div>

                <div className="form-row">
                  <label className="label" htmlFor="description">
                    DESCRIPTION
                  </label>
                  <RichEditor placeholder="Add a description." defaultValue={description} onChange={setDescription} />
                </div>
              </div>
              <div className="col-4 side-bar-calender">
                <div className="form-row">
                  <label className="label" htmlFor="appointment_visibility">
                    CHOOSE VISIBILITY
                  </label>
                  <CheckboxSlider
                    checked={visibility.organisation}
                    label_style="small bold visibilty-label-setting"
                    slider={"checkbox-slider-custom"}
                    label={"ORGANISATION"}
                    icon={<FaQuestion size={9} />}
                    tooltipText="The appointment will be visible to everyone from the organization."
                    onChange={() => {
                      setVisibilty({ ...visibility, organisation: !visibility.organisation });
                    }}
                  />
                  <CheckboxSlider
                    checked={visibility.team}
                    label_style="small bold visibilty-label-setting"
                    slider={"checkbox-slider-custom"}
                    label={"TEAM"}
                    icon={<FaQuestion size={9} />}
                    tooltipText="The appointment will be visible to everyone from the team."
                    onChange={() => {
                      setVisibilty({ ...visibility, team: !visibility.team });
                    }}
                  />
                  <CheckboxSlider
                    checked={visibility.participants}
                    label_style="small bold visibilty-label-setting"
                    slider={"checkbox-slider-custom"}
                    label={"PARTICIPANT"}
                    icon={<FaQuestion size={9} />}
                    tooltipText="The appointment will be visible to all participants from the organization."
                    onChange={() => {
                      setVisibilty({ ...visibility, participants: !visibility.participants });
                    }}
                  />
                  <CheckboxSlider
                    checked={visibility.public}
                    label_style="small bold visibilty-label-setting"
                    slider={"checkbox-slider-custom"}
                    label={"PUBLIC"}
                    icon={<FaQuestion size={9} />}
                    tooltipText="The appointment will be visible to everyone watching the team or organization calendar."
                    onChange={() => {
                      setVisibilty({ ...visibility, public: !visibility.public });
                    }}
                  />
                </div>

                <label className="label" htmlFor="inviteParticipants">
                  INVITE PARTICIPANTS
                </label>
                {!!team ? (
                  <select
                    id="inviteParticipants"
                    name="inviteParticipants"
                    className="form-input inviteDropdown"
                    checked="absolved"
                    defaultValue={team?.team?._id}
                    ref={register({
                      required: "Required",
                    })}
                  >
                    <option value={team?.team?._id}>TEAM: {team?.team?.name}</option>
                  </select>
                ) : (
                  <select
                    id="inviteParticipants"
                    name="inviteParticipants"
                    className="form-input inviteDropdown"
                    checked="absolved"
                    defaultValue={appointmentData && appointmentData?.team ? appointmentData.team?._id : appointmentData?.organisation?._id}
                    ref={register({
                      required: "Required",
                    })}
                  >
                    {Object.keys(teamOrg).length && teamOrg.organisations.map((el) => <option value={el._id}>ORG: {el.name}</option>)}
                    {Object.keys(teamOrg).length && teamOrg.teams.map((el) => <option value={el._id}>TEAM: {el.name}</option>)}
                  </select>
                )}

                <Checkbox
                  onChange={() => {
                    setTeamOrganisationvisibility({ ...teamOrganisationvisibility, members: !teamOrganisationvisibility.members });
                  }}
                  checked={teamOrganisationvisibility.members}
                  label={"ALL Members"}
                  checkBoxClass={`checkbox-container-permission ${teamOrganisationvisibility.members ? "bgColor" : ""}`}
                  customClass="permission"
                  icon={<FaQuestion size={9} />}
                  tooltipText="All members will be participating and be notified."
                />
                <Checkbox
                  onChange={() => {
                    setTeamOrganisationvisibility({ ...teamOrganisationvisibility, staff: !teamOrganisationvisibility.staff });
                  }}
                  checked={teamOrganisationvisibility.staff}
                  label={"ALL Staff"}
                  checkBoxClass={`checkbox-container-permission ${teamOrganisationvisibility.staff ? "bgColor" : ""}`}
                  customClass="permission"
                  icon={<FaQuestion size={9} />}
                  tooltipText="All owners and managers will be participating and be notified."
                />
                <Checkbox
                  onChange={() => {
                    setTeamOrganisationvisibility({ ...teamOrganisationvisibility, coaches: !teamOrganisationvisibility.coaches });
                  }}
                  checked={teamOrganisationvisibility.coaches}
                  label={"ALL Coaches"}
                  checkBoxClass={`checkbox-container-permission ${teamOrganisationvisibility.coaches ? "bgColor" : ""}`}
                  customClass="permission"
                  icon={<FaQuestion size={9} />}
                  tooltipText="All coaches from a team will be participating and be notified"
                />
                <Checkbox
                  onChange={() => {
                    setTeamOrganisationvisibility({ ...teamOrganisationvisibility, players: !teamOrganisationvisibility.players });
                  }}
                  checked={teamOrganisationvisibility.players}
                  label={"ALL Players"}
                  checkBoxClass={`checkbox-container-permission ${teamOrganisationvisibility.players ? "bgColor" : ""}`}
                  customClass="permission"
                  icon={<FaQuestion size={9} />}
                  tooltipText="All players from a team will be participating and be notified."
                />

                <button
                  type="button"
                  className={game === "CSGO" ? "notificationButtonCsgo" : "notificationButton"}
                  onClick={() => setAddGuest(true)}
                >
                  <span style={{ marginRight: 10 }}>
                    <FaPlus size={8} />
                  </span>
                  ADD GUEST MANUALLY
                </button>
                {addGuest && (
                  <DynamicSearchAppointment
                    placeholder="Search Guests"
                    onSearch={onSearch}
                    onSelectResult={addMember}
                    loadingResults={loading}
                    results={results}
                    mixedResults={true}
                  />
                )}
                {selectedMembers.length ? (
                  <div className="dynamic-search-selected-items">
                    {selectedMembers.map(({ _id, img, title, subtitle, role, roles, type }) => (
                      <div key={_id} className="selected-item">
                        <div className="item-details">
                          {img ? (
                            <div className="item-img" style={{ backgroundImage: `url(${img})` }} />
                          ) : (
                            <div className="profile-img-with-inital-1-letter-modal">{title.charAt(0)}</div>
                          )}

                          <div className="item-name-multi">
                            <p>{title}</p>
                          </div>
                        </div>
                        <div className="type">{type}</div>
                        <button onClick={() => removeMember(_id)}>
                          <RiCloseLine size={27} />
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {edit && (
              <>
                {
                  <div class="appoinment-del">
                    <div className="headerDelIcon">
                      <button
                        type="button"
                        class="appoinment-del-btn"
                        onClick={() => {
                          removeAppointment();
                        }}
                      >
                        <AiOutlineDelete size={18} />
                      </button>
                    </div>
                  </div>
                }
              </>
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreateAppointmentModal;
