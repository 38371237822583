import React from "react";
import { Switch, Route } from "react-router-dom";
import PlayerProfile from "./PlayerProfile/PlayerProfile";

const PlayersRouter = () => {
  return (
    <Switch>
      <Route path="/players/:id">
        <PlayerProfile />
      </Route>
    </Switch>
  );
};

export default PlayersRouter;
 