import React from "react";
import vars from "@styles/_variables.scss";
import default_image from "@img/image.jpg";
import { Item } from "@common";

const Tag = (props) => {
  if (props.tag === null) {
    return null;
  }

  const tag_color = props.tag.accent_color ?? vars.primary;

  return <span style={{ backgroundColor: tag_color }}>{props.tag.name}</span>;
};

const NewsItem = (props) => {
  let classes = "news-item";
  let col = props.column ?? "4";

  if (typeof props.large !== "undefined") {
    col = props.column ?? "8";
    classes += " col-" + col + " large";
  } else {
    classes += " col-" + col;
  }

  if (typeof props.loading !== "undefined") {
    classes += " loading";
  }

  const title = props.title ?? "Lorem ipsum";
  const excerpt = props.excerpt ?? "Lorem ipsum";
  const image = props.image ?? default_image;
  const published = props.published;
  const slug = props.slug ?? "not-found";
  const tag = props.tag ?? null;

  /*
  return (
    <div className={classes}>
      <Link to={`/news/${slug}`} style={{ textDecoration: "none" }}>
        <div className="news-item-image" style={{ backgroundImage: `url(${image})` }}>
          <div className="image-cover"></div>
          <Tag tag={tag} />
        </div>
        <div className="news-item-info">
          <span>{timeAgo}</span>
        </div>
        <div className="news-item-title">
          <h3>{title}</h3>
        </div>
        <div className="news-item-excerpt">{excerpt}</div>
      </Link>
    </div>
  );
  */

  return (
    <Item
      title={title}
      excerpt={excerpt}
      image={image}
      classes={classes}
      link_to={`/news/${slug}`}
      item_image_inner={
        <React.Fragment>
          <div className="image-cover"></div>
          <Tag tag={tag} />
        </React.Fragment>
      }
      published={published}
      backgroundSize={"cover"}
    />
  );
};

export default NewsItem;
