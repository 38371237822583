import React, { useState } from "react";

import ActiveForNextMatch from "./includes/ActiveForNextMatch";
import AllMembers from "./includes/AllMembers";

const Members = ({ team, isOwner, isManager, scrim }) => {
  const [page, setPage] = useState(0);

  return (
    <React.Fragment>
      <div className="tabs-container mb-4">
        <div className="tabs-nav" style={{ borderBottom: "none" }}>
          <nav>
            <button className={page === 0 ? "active" : ""} onClick={() => setPage(0)}>
              All
            </button>
            <button className={page === 1 ? "active" : ""} onClick={() => setPage(1)}>
              Active for scrim
            </button>
          </nav>
        </div>
      </div>

      {page === 0 && <AllMembers team={team} isOwner={isOwner} isManager={isManager} />}
      {page === 1 && <ActiveForNextMatch team={team} scrim={scrim} />}
    </React.Fragment>
  );
};

export default Members;
