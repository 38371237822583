import React, { useContext, useState, useEffect } from "react";
import NewsItem from "@components/News/NewsItem/NewsItem";
import { AppContext } from "@context/AppContext";

const News = () => {
	let context = useContext(AppContext);
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		context.ghost.posts
			.browse({ limit: 9, include: "tags" })
			.then((content) => {
				setPosts(content);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [context.ghost.posts]);

	if (loading) {
		return (
			<div className="less-narrow-content teams-content" style={{ paddingTop: "20px", paddingBottom: "50px" }}>
				<div className="row smaller-gutter-row">
					<NewsItem large column="12" loading />
					<NewsItem column="6" loading />
					<NewsItem column="6" loading />
					<NewsItem column="6" loading />
					<NewsItem column="6" loading />
					<NewsItem column="6" loading />
					<NewsItem column="6" loading />
					<NewsItem column="6" loading />
					<NewsItem column="6" loading />
				</div>
			</div>
		);
	}

	return (
		<div className="less-narrow-content teams-content" style={{ paddingTop: "20px", paddingBottom: "50px" }}>
			<div className="row smaller-gutter-row">
				{posts.map((item, index) => {
					let props = {
						column: "6",
						key: item.id,
						title: item.title,
						excerpt: item.excerpt,
						image: item.feature_image,
						published: item.published_at,
						slug: item.slug,
						tag: item.primary_tag,
					};

					if (index === 0) {
						props.large = true;
						props.column = "12";
					}

					return <NewsItem {...props} />;
				})}
			</div>
		</div>
	);
};

export default News;
