import React, {useState, useEffect} from 'react'
import './_steps.scss'
import { LightenDarkenColor } from 'lighten-darken-color'; 


const iconStyle = { width: 30 };
const MapStats = ({ team, gameData, maps, stats}) => {

    const [winRate, setWinRate] = useState(0)
    const [kd, setKd] = useState(0)
    useEffect(() => {
        if(stats && stats.teamStats) {
            if (stats.teamStats.lastTwenty) {
                setWinRate(parseFloat(((stats.teamStats.lastTwenty.wins / stats.teamStats.lastTwenty.played) * 100).toFixed(2)))
            }
            if(stats.teamStats.teamKDA) {
                setKd(parseFloat(((stats.teamStats.teamKDA.kills / stats.teamStats.teamKDA.deaths)).toFixed(2)))
            }
        }        
     }, [stats])
        
    return (
        <div className="player-stats d-flex flex-column text-white">
            <div className="row stat m-0 mt-1 py-2">
                <div className="col-2 d-flex flex-column justify-content-center align-items-center m-0 p-0">
                    <img src={team.logo} style={iconStyle} />
                </div>
                <div className="col-4 p-0 m-0">
                    <p className="bold py-1 m-0 overflow-ellipsis">{team.name}</p>
                    {/* <p className="bold default-text py-1 m-0">4.2 <span className="faded">HLTV R.</span></p> */}
                </div>
                <div className="col-2 p-0 m-0">
                    <div className="mini-pie-chart" style={{
                            background: `conic-gradient(${LightenDarkenColor(gameData.mainColour, -180)} 0, ${LightenDarkenColor(gameData.mainColour, -180)} ${Math.round(
                              100 - winRate
                            )}%, ${gameData.mainColour} 0, ${gameData.mainColour} 100%)`,
                          }}
                    ></div>
                </div>
                <div className="col-4 p-0 m-0">
                    <div className="flex-column">
                        <div className={`text-10 bold ${winRate == 50 ? 'color-warning' : winRate > 50 ? 'color-success' : 'color-danger'}`}>{`${winRate}% WR` }</div>
                        <div className="text-10 bold faded">Last 20 games</div>
                        <div className="text-10 bold faded">
                            <span className="default-text">{`${kd}KD`}</span>
                            {/* <span className="text-white bold ml-1">4.2/5.4</span> */}
                        </div>

                    </div>
                </div>
            </div>
            {maps && maps.map(mapData => {
                let winRate = 0, played = 0, wins = 0
                if (stats && stats.teamStats && stats.teamStats.mapsStats) {
                    let mapStats = stats.teamStats.mapsStats.find(data => data.map == mapData.key)
                    if (mapStats && mapStats.count) {
                        winRate = parseFloat(((mapStats.wins/mapStats.count) * 100).toFixed(2))
                        played = mapStats.count
                        wins = mapStats.wins
                    }
                }
               
                return (
                    <div className="row stat m-0 mt-2 py-2">
                        <div className="col-4 d-flex flex-column justify-content-center align-items-center m-0 p-0 ">
                            <img className="map-img" src={mapData.img} />
                        </div>
                        <div className="col-3 p-0 m-0 d-flex flex-column ml-1 align-items-center">
                           {mapData.name}
                            
                        </div>
                        <div className="col-4 d-flex flex-column justify-content-lg-start align-items-center">
                            <div className={`text-12 bold ${played > 0 ? winRate == 50 ? 'color-warning' : winRate > 50 ? 'color-success' : 'color-danger' : ''}`}>{`${winRate}%`}</div>
                            <div className="text-10 faded bold">{`WR - ${wins}/${played}`}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default MapStats
