import React from "react";

const MirrorText = ({ text, is_static }) => {
  let classes = "mirror-text";
  if (typeof is_static !== "undefined") {
    classes += " static";
  }

  return (
    <div className={classes}>
      {text.map((line, i) => (
        <span key={i}>{line}</span>
      ))}
    </div>
  );
};

export default MirrorText;
