import React, { useEffect, useState } from "react";
import { Button, Icon } from "@common";
import moment from "moment";
import { makeCountdownString } from '@utils';
const PageWithTabsHeader = ({ danger, warning, button_text, date, onButtonClick, hideDate, disabledButton,registerationDeadline,teamRegistered }) => {
  const [time, setTime] = useState(0);
  const isRegDateAfterToday = moment(moment(registerationDeadline)).isAfter(moment());
  let pill_classes = "pill round-edge default";

  if (danger) {
    pill_classes += " danger";
  } else if (warning) {
    pill_classes += " warning";
  }

  useEffect(() => {
    setTime(moment(isRegDateAfterToday ? registerationDeadline: date).diff(moment(), "seconds"));
  }, []);

  useEffect(() => {
    if (time < 1) return;

    const countdown = setInterval(() => {
      setTime(time - 1);
    }, 1000);

    return () => clearInterval(countdown);
  }, [time]);

  const timeLeft = () => {
    return makeCountdownString(time * 1000);
  };

  return (
    <div className="pwt-single-header">
      <div className="pwt-single-header-inner">
        <div style={{ marginBottom: "20px" }}>
          {hideDate ?
            null
            :
            <span className={pill_classes + ' mr-4'}>
              <Icon  name={isRegDateAfterToday ? "clock" : "white-pencil" } />
            STARTS IN: {timeLeft()}
            </span>
          }
        </div>
        <div className="mr-4">
          <Button disabled={disabledButton} onClick={onButtonClick} name={disabledButton ? "lockAnimation disabled-opo cta-button" : "cta-button"}>{button_text}</Button>
        </div>
        
        {/* <div className="text-12 faded mt-3 center">Join as a team or a party</div> */}
      </div>
    </div>
  );
};

export default PageWithTabsHeader;
