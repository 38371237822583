import React, { useState, useEffect, useContext } from "react";
import Slider from "../Slider/Slider";
import { useHistory, useParams } from "react-router-dom";
import { Button, SliderItem, Icon, Advert, AdvertByGame } from "@common";
import { AppContext } from "../../../context/AppContext";
import Axios from "axios";

const LeaguesSeasons = ({ data }) => {
  // console.log('Leagues_SeasonSG--datya-0-',data)
  const { selectedGame, setSelectedGame } = useContext(AppContext);
  const history = useHistory();
  const [loadingSeasons, setLoadingSeasons] = useState(true);
  const [seasons, setSeasons] = useState([]);
  const [blueText, setBlueText] = useState("Loading...");
  const [whiteText, setWhiteText] = useState("Loading...");
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [includeAllWithSameName, setIncludeAllWithSameName] = useState(false);

  useEffect(() => {
    // console.log("getFeaturedSeasons useEffect Data : ", data);
    if (data.BlueText) {
      setBlueText(data.BlueText);
    }
    if (data.WhiteText) {
      setWhiteText(data.WhiteText);
    }
    if (data.season && data.season.length) {
      setSelectedSeason(data.season.map(({ _id }) => _id).toString());
    }
    if (data.includeAllWithSameName) {
      setIncludeAllWithSameName(data.includeAllWithSameName);
    }
  }, [data]);

  // console.log('seasons-',seasons)

  const getFeaturedSeasons = (selectedSeason) => {
    if (includeAllWithSameName) {
      console.log("sadasd iff");
      // console.log("getFeaturedSeasons just before API IF ", selectedSeason);

      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/getSeasonsWithSameNameYear/${selectedSeason}`, {
        params: { name: "leagues" },
      }).then(({ data }) => {
        let dataleagues = [];
        data &&
          data.map((sesSing) => {
            dataleagues.push(sesSing.leagues);
          });
        // console.log("dataleagues-", dataleagues[0]);
        // console.log("getFeaturedSeasons IF : ", dataleagues[0]);

        setSeasons(dataleagues[0]);
        setLoadingSeasons(false);
      });
    } else {
      console.log("sadasd else");
      // console.log("getFeaturedSeasons just before API ELSE");

      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/selectedSeason/${selectedSeason}`).then(({ data }) => {
        // console.log("getFeaturedSeasons ELSE : ", data);

        setSeasons(data.leagues);
        setLoadingSeasons(false);
      });
    }
  };

  useEffect(() => {
    // featured seasons
    console.log("getFeaturedSeasons useEffect selectedSeason : ", selectedSeason);

    selectedSeason && getFeaturedSeasons(selectedSeason);
  }, [selectedGame, selectedSeason]);

  return (
    <>
      <Slider
        isArrowBg
        key={`${blueText}-slide-${blueText}`}
        info={
          <div>
            <h1>{whiteText}</h1>
            <h1 className="outline">{blueText}</h1>
            <Button onClick={() => history.push("/leagues")} name="cta-button-transparent">
              All Leagues
            </Button>
          </div>
        }
      >
        {/* {seasons.length &&
          [1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(() => {
            console.log("getFeaturedSeasons abc");
            return seasons.map(({ _id, name, thumbnailImage, leagueDivisionName, country = {}, teams = [] }) => (
              // <span>{name}</span>
              <SliderItem
                key={_id}
                title={name}
                second_line={
                  <React.Fragment>
                    <img src={`https://www.countryflags.io/${country.code}/flat/24.png`} alt="Danish flag" />
                    {country.name}
                  </React.Fragment>
                }
                third_line={`${teams.length} teams participating`}
                tag={leagueDivisionName}
                image={thumbnailImage}
                link_to={`/league/${_id}`}
              />
            ));
          })} */}
        {seasons?.length &&
          seasons.map(({ _id, name, thumbnailImage, leagueDivisionName, country = {}, teams = [] }) => (
            <SliderItem
              key={_id}
              title={name}
              second_line={
                <React.Fragment>
                  <img src={`https://flagcdn.com/w20/${country.code?.toLowerCase()}.png`} alt="Danish flag" />
                  {country.name}
                </React.Fragment>
              }
              third_line={`${teams.length || teams} teams participating`}
              tag={leagueDivisionName}
              image={thumbnailImage}
              link_to={`/league/${_id}`}
            />
          ))}
      </Slider>
    </>
  );
};
export default LeaguesSeasons;
