import React from "react";
const DotPattern = () => {
    const num_dots = 18 * 12;
    let dots = [];
  
    for (let i = 0; i < num_dots; i++) {
      dots.push(<span key={`${i}-nova`}></span>);
    }
  
    return <div className="dot-pattern">{dots}</div>;
  };
  
  export default DotPattern;