import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Axios from "axios";
import MyPlayersCoaches from "../../Settings/SettingsTabs/MyPlayersCoaches/MyPlayersCoaches";
import { Tabs, Button, Icon } from "@common";
import { About, Teams, GameProfiles } from "./PlayerProfileTabs";
import { AdvertByGame } from "../../_common";
import vars from "@styles/_variables.scss";
import default_team_cover from "@img/teams-single-bg.png";
import PlayersCoaches from "./PlayerProfileTabs/GameProfiles/PlayersCoaches";
import { AppContext } from "../../../context/AppContext";
import { Loading } from "@common";

import useTranslated from "../../../helpers/translationHelper";

const PlayerProfile = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [assocGames, setAssocGames] = useState([]);

  const lableAbout=useTranslated("id_user_page_0")
  const lableGameProfile=useTranslated("id_user_page_1")
  const lableTeam=useTranslated("id_user_page_2")

  useEffect(() => {
    let currentGames = assocGames;
    if (user && Object.keys(user).length) {
      [...user.assocPlayers, ...user.assocCoaches].map(({ game }) => {
        if (game != null) {
          let ifGameExist = currentGames.filter((cgame) => cgame._id === game._id);
          if (ifGameExist.length === 0) {
            currentGames.push(game);
          }
        } 
      });
    }
    setAssocGames(currentGames);
  }, [user]);

  const selectedGameSelected = localStorage.getItem(["selectedGame"]);
  let game = "";
  if (selectedGameSelected && JSON.parse(selectedGameSelected).mainColour) {
    game = JSON.parse(selectedGameSelected).shortName;
  }

  const { user: userState, selectedGame } = useContext(AppContext);
  const [advertGameId, setAdvertGameId] = useState(selectedGame ? selectedGame._id : null);
  const adsComponent = (
    <div className="col-3">
      <div className="info-placeholder-wrapper">
        <div className="info-placeholder small">
          <AdvertByGame
            advertForGame={advertGameId}
            width="100%"
            height={250}
            refName="PROFILE_SIDEBAR_420_250_SM"
            style={{ borderRadius: 2 }}
          />
        </div>
        <div className="info-placeholder">
          <AdvertByGame
            advertForGame={advertGameId}
            width="100%"
            height={500}
            refName="PROFILE_SIDEBAR_420_500_LG"
            style={{ borderRadius: 2 }}
          />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/users/${id}`)
      .then(({ data }) => {
        setUser(data);
        setLoading(false);
      })
      .catch((e) => {
        history.push("/error");
      });
  }, [id, history]);

  let pages = {
    /* About */[lableAbout]: <About user={user} />,
    /* "Game Profiles" */[lableGameProfile]: (
      <>{id === userState.state._id ? <MyPlayersCoaches /> : <GameProfiles players={user.assocPlayers} coaches={user.assocCoaches} />}</>
    ),
    // "Game Profiles": <PlayersCoaches user={user._id} coaches={user.assocCoaches} players={user.assocPlayers} />,
    /* Teams */[lableTeam]: <Teams teams={user.assocTeams} player_id={id} />,
  };

  return loading ? (
   
   <div className="col-12 text-center">
      <Loading />
    </div>
  ) : (
    <div className=" page-with-tabs" style={{ position: "relative" }}>
      <div
        className="page-with-tabs-hero-bg"
        style={{
          background: `linear-gradient(to top, ${vars.content_bg_color} 10%, rgba(3, 6, 13, 0) 120%), url(${user.headerImage || default_team_cover
            })`,
        }}
      />
      <div className="row smaller-gutter-row pt-4">
        <div className="col-12 p-0">
          <div className="row page-with-tabs-header pl-30">
            <React.Fragment>
              <div className="page-with-tabs-image-container">
                {user.avatarImage ? (
                  <img src={user.avatarImage} alt={user.firstName} className={"image-cropped"}/>
                ) : (
                  <div className={game === "CSGO" ? "player-avatar-csgo" : "player-avatar"}>
                    {user.firstName.charAt(0) + user.lastName.charAt(0)}
                  </div>
                )}
              </div>

              <div className="page-with-tabs-info">
                {user.nickname ? (
                  <>
                    <span className="text-14 faded">
                      {user.firstName} {user.lastName}
                    </span>
                    <h1 className="text-32">{user.nickname}</h1>
                  </>
                ) : (
                  <h1 className="text-32">
                    {user.firstName} {user.lastName}
                  </h1>
                )}

                <div className="row ml-1">
                  {assocGames.map((game) => (
                    <img src={game.whiteIcon} className="players-game-icon" alt={game.name} />
                  ))}
                </div>
              </div>
            </React.Fragment>
          </div>
        
          <Tabs ads={adsComponent} pages={pages} base_route={`/players/${id}`} custom_filter={true} />
        </div>
      </div>
    </div>
  );
};

export default PlayerProfile;
