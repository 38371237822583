import React from "react";
import { Link } from "react-router-dom";
import default_profile_pic from "@img/default-team-member.png";
import RankData from "./includes/RankData";
import RankDataCSGO from "./includes/RankDataCSGO";

const GameProfileListCoach = ({ gameIcon, gameName, coaches = [] }) => {
  return (
    <div className="game-profile-list">
      {/* <div className="title text-16 uppercase bold">
        <img src={gameIcon} alt={gameName} /> {gameName}
      </div> */}
      <div className="game-profile-list-th">
        <div className="row tiny-gutter-row">
          <div className="col-2">
            <span className="text-12 faded bold uppercase">Name</span>
          </div>
          <div className="col-2">
            <span className="text-12 faded bold uppercase">Game</span>
          </div>
          <div className="col-2">
            <span className="text-12 faded bold uppercase">{gameName !== "League of Legends" ? `Division` : `Ranked solo`}</span>
          </div>
          <div className="col-3">
            <span className="text-12 faded bold uppercase">{gameName !== "League of Legends" ? `Team` : `Ranked flex`}</span>
          </div>
          <div className="col-2">
            <span className="text-12 faded bold uppercase">Role</span>
          </div>
        </div>
      </div>

      {coaches.map(({ _id, name, avatarImage, game }) => {
        const getLaneIcon = (role = "") => {
          const data = game.configuration.playerTypes.find(({ name }) => role.includes(name));

          return data ? data.icon : false;
        };

        return (
          <div className="game-profile-list-item">
            <div className="row tiny-gutter-row">
              <div className="col-2">
                <img src={avatarImage || default_profile_pic} className="game-profile-lg-image" alt="" />
                <span className="text-12 bold">{name}</span>
              </div>

              <div className="col-2">
                <div className="game-profile-sm-image" style={{ backgroundImage: `url(${gameIcon})` }} />
                <span className="text-12 bold">{gameName}</span>
              </div>
              <div className="col-2">N/A</div>
              <div className="col-3">N/A</div>
              <div className="col-2">Coach</div>

              <div className="col-1 align-right">
                <Link to={`/coaches/${_id}`} className="text-12 primary-link">
                  View Profile
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GameProfileListCoach;
