import React from "react";
import { Link } from "react-router-dom";
import logo_header from "@img/logo-header.svg";

const PageNotFound = () => {
  return (
    <div className="global-pnf">
      <div className="container-fluid">
          <div className="area-logo">
            <Link to="/" className="logo-text">
              <img src={logo_header} alt="Leagues logo" />
              <strong className="uppercase">Page not found</strong>
            </Link>
           
          </div>

          <div className="area-logo">
            <Link to="/" className="log">
              <strong className="uppercase">Go Home</strong>
            </Link>
          </div>

      </div>
    </div>
  );
};

export default PageNotFound;
