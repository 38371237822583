import React, { Component } from 'react';
import {
  Canvas,
  state,
  Preview,
  registerPaletteElements
} from 'react-page-maker';

import { elements } from './const';
import DraggableHeader from './elements/ModuleHeader';
import DraggableSocialFollow from './elements/ModuleSocialFollow';
import DraggableVideo from './elements/ModuleVideo';
import DraggableImage from './elements/ModuleImage';
import DraggablePartnerLogo from './elements/ModulePartnerLogo';
import ElementsModal from './modals/elementsModal';
import { v4 as uuidv4 } from 'uuid';

class App extends Component {
  
  constructor(props) {
    super(props);

    // register all palette elements
    registerPaletteElements([
    {
      type: elements.HEADER,
      component: DraggableHeader
    },
    {
      type: elements.SOCIALFOLLOW,
      component: DraggableSocialFollow
    },
    {
      type: elements.VIDEO,
      component: DraggableVideo
    },
    {
      type: elements.IMAGE,
      component: DraggableImage
    },
    {
      type: elements.PARTNERLOGO,
      component: DraggablePartnerLogo
    }
  ]);

    // state.clearState() triggers this event
    state.addEventListener('flush', (e) => {
      // console.log('addEventListener flush', e);
    });

    // state.removeElement() triggers this event
    state.addEventListener('removeElement', (e) => {
      // console.log('addEventListener removeElement', e);
    });

    // state.updateElement() triggers this event
    state.addEventListener('updateElement', (e) => {
      // console.log('addEventListener updateElement', e);
    });
  }

  state = {
    activeTab: '1',
    currentState: [],
    showElementsModal: false
  }

  componentWillMount() {
    this.setState({ currentState: this.props.initialContent});
    state.addEventListener('change', this._stateChange);
  }

  componentWillUnmount() {
    state.removeEventListener('change', this._stateChange);
  }

  _stateChange = (s) => {
    const newState = state.getStorableState();
    
    this.setState({ currentState: newState}, () => {
      this.props.saveContent(newState);
      // localStorage.setItem('initialElements', JSON.stringify(newState));
    });
  }

  // re-hydrate canvas state
  initialElements = this.state.currentState; //JSON.parse(localStorage.getItem('initialElements'))

  // define all palette elements that you want to show
  paletteItemsToBeRendered = [
  {
    type: elements.HEADER,
    name: 'TEXT',
    id: 'h1',
    payload: { // initial data
          heading: '#WEARELEGION',
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        }
  },
  {
    type: elements.SOCIALFOLLOW,
    name: 'SOCIAL FOLLOW',
    id: 'h2',
    payload: { // initial data
        twitch:'#',
        facebook:'#',
        twitter:'#',
        instagram:'#',
        youtube:'#'
      }
  },
  {
    type: elements.VIDEO,
    name: 'VIDEO',
    id: 'h3',
    payload: { // initial data
        url:'https://www.youtube.com/watch?v=dgwXgi2BVXs'
      }
  },
  {
    type: elements.IMAGE,
    name: 'IMAGE',
    id: 'h4',
    payload: { // initial data
        url:''
      }
  },
  {
    type: elements.PARTNERLOGO,
    name: 'PARTNERLOGO',
    id: 'h5',
    payload: { // initial data
      logo_0: '',
      logo_1: '',
      logo_2: '',
    }
  }
]

  _onDrop = (data, cb) => {
    // no need to ask id and name again
    if (data.payload && data.payload.dropped) {
      return cb(data);
    }

    let name = data.name;

    const id = uuidv4(); 
    this.toggleShowElementsModal();
    cb({
      ...data,
      name,
      id,
      payload: { ...data.payload, dropped: true }
    });
  }

  _toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  _clearState = () => {
    state.clearState();
  }

  toggleShowElementsModal = () => {
        this.setState({
            showElementsModal: !this.state.showElementsModal
        });
  }


  render() {
      const {showElementsModal,currentState} = this.state;
      const {showPreview} = this.props;
      
     
    return (
      <div className="App contasiner">
         <ElementsModal paletteElements={this.paletteItemsToBeRendered}  isModalOpen={showElementsModal} closeModal={() => this.toggleShowElementsModal()} />
            
        {/* {showPreview &&
            <div className="main-canvas">
              <div className="dropzone">
              
               <Preview >
                  {
                    ({ children }) => (
                      <>
                        {children}
                      </>
                    )
                  }
                </Preview>
              </div>
            </div>
        } */}
      <div style={{display:showPreview ? 'none' : 'block'}} >
            <button className="button wide-primary" onClick={() => this.toggleShowElementsModal()} >
                Add content
            </button> 
            <Canvas onDrop={this._onDrop} initialElements={currentState || []} placeholder="Module Drop Here" />
            
         </div>
      </div>
    );
  }
}

export default App;
