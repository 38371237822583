import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import default_image from "@img/image.jpg";
import useTranslated from "../../../../../helpers/translationHelper";
const GamesOverviewTeamMembersList = ({ PlayersOnTeam={}, lanes = [],teamdetail = null, teamId, map, champions, basicOrAdvType=0 }) => {
  
  const kdaTrans = useTranslated('id_match_page_(games)_[cs:go]_3')
  const plusminusTrans = useTranslated('id_match_page_(games)_[cs:go]_4')
  const assTrans = useTranslated('id_match_page_(games)_[cs:go]_5')
  const hsTrans = useTranslated('id_match_page_(games)_[cs:go]_6')
  return (
    <div className="games-overview-team-members-list">
      
      <div className="row smaller-gutter-row">
        




      <div className="col-12">
                <div className="faded-box-item matchMatricsHeadings m-0">
                  <div className="row smaller-gutter-row">
                    <div className="col-4 flex-row">
                      <img src={teamdetail && teamdetail.logo || default_image} alt={teamdetail && teamdetail.name || "No Team"} className={`team-member-image ${teamdetail?.logoCropped || teamdetail?.logoCropped === undefined ? "border-radius-100" : ""}`} />
                      <span className="text-12 bold">
                        {teamdetail ? <Link style={{ textDecoration: "none" }} to={`/teams/${teamdetail._id}`}>
                          {teamdetail && teamdetail.name || '??'}
                          </Link>
                          : <>{teamdetail && teamdetail.name || '??'}</>
                        }
                      </span>
                    </div>
                    {basicOrAdvType === 0 ? <div className="col-8 multi-flex-column">

                      {/* <span className="text-12 uppercase bold">
                        <img src={default_image} alt={`${_.name}`} width={20} />
                      </span> */}
                      <span className="text-12 uppercase bold headtitles">
                      {/* KD */}{kdaTrans}
                      </span>
                      <span className="text-12 uppercase bold headtitles">
                      {/* +/- */}{plusminusTrans}
                      </span>
                      <span className="text-12 uppercase bold headtitles">
                      {/* ASS. */}{assTrans}
                      </span>
                      <span className="text-12 uppercase bold headtitles">
                      {/* HS */}{hsTrans}
                      </span>
                    </div> : <div className="col-8 multi-flex-column">
                      {/* <span className="text-12 uppercase bold">
                        <img src={default_image} alt={`${_.name}`} width={20} />
                      </span> */}
                      <span className="text-12 uppercase bold headtitles">
                      ADR
                      </span>
                      <span className="text-12 uppercase bold headtitles">
                      FK
                      </span>
                      <span className="text-12 uppercase bold headtitles">
                      HS%
                      </span>
                     
                    </div>}
                    
                  </div>
                </div>
              </div>
            



        {!Object.values(PlayersOnTeam).length ? (
          <div className="game-no-set-players">
            <RiErrorWarningLine />
            <p>No data available</p>
          </div>
        ) : (
          Object.values(PlayersOnTeam).map((_) => {
            return (
              <div className="col-12">
                <div className="faded-box-item m-0 darkLine">
                  <div className="row smaller-gutter-row">
                    {_.processedData.data ? <div className="col-4 flex-row">
                      <img src={ _.processedData.data.avatarImage || default_image} alt={_.processedData.data.name} className="team-member-image image-40 border-radius-100" />
                      <span className="text-12 bold">
                        <Link style={{ textDecoration: "none" }} to={`/game-profile/${_.processedData.data._id}`}>
                          {_.processedData.data.name || "??"}
                        </Link>
                        {_.processedData.data.primaryRole && 
                        <>
                          <br />
                          <span className="text-12 faded bold headtitles">{_.processedData.data.primaryRole}
                          </span>
                        </>}
                      </span>
                    </div>
                     :
                     <div className="col-4 flex-row" title="This player is not exist in Leagues.gg database.">
                       <div className="game-profile-lg-image uppercase profile-image-with-initials">{_.name ? _.name.charAt(0) : "??"}</div>
                      {/* <img src={default_image} alt={_.name} className="team-member-image image-40 border-radius-100" /> */}
                      <span className="text-12 bold">
                          {_.name || "??"}
                      </span>
                    </div>
                    }
                    {basicOrAdvType === 0 ? <div className="col-8 multi-flex-column">

                      {/* <span className="text-12 uppercase bold">
                        <img src={default_image} alt={`${_.name}`} width={20} />
                      </span> */}
                      <span className="text-12 uppercase bold">
                      {_.processedData.KD || "??"}
                      </span>
                      <span className="text-12 uppercase bold">
                      {_.processedData.PlusMinus || "??"}
                      </span>
                      <span className="text-12 uppercase bold">
                      {_.assists || "??"}
                      </span>
                      <span className="text-12 uppercase bold">
                      {_.enemyHSs || "??"}
                      </span>
                    </div> : <div className="col-8 multi-flex-column">
                      {/* <span className="text-12 uppercase bold">
                        <img src={default_image} alt={`${_.name}`} width={20} />
                      </span> */}
                      <span className="text-12 uppercase bold">
                      {_.processedData.ADR || "??"}
                      </span>
                      <span className="text-12 uppercase bold">
                      {_.firstKs || "??"}
                      </span>
                      <span className="text-12 uppercase bold">
                      {_.processedData.HSPercentage || "??"}
                      </span>
                     
                    </div>}
                    
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default GamesOverviewTeamMembersList;
