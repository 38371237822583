import React from "react";
import { LeagueItem, TournamentItem } from "@common";
import moment from "moment";
import useTranslated from "../../../../../helpers/translationHelper";

const LeagueParticipent = ({ leaguesParticipating, tournamentParticipating, message }) => {
  const lableTeamParticipating=useTranslated("id_team_page_(overview)_2")
  return (
    <>
      {leaguesParticipating.length || tournamentParticipating.length ? (
        <div className="row smaller-gutter-row participation-card-main">
          {leaguesParticipating.map(({ _id, logoImage, name, leagueFinished, country, teams, leagueDivisionName, thumbnailImage }) => (
            <LeagueItem
              key={_id}
              title={name}
              second_line={
                <React.Fragment>
                  <img src={`https://flagcdn.com/w20/${country?.code?.toLowerCase()}.png`} alt="Danish flag" />
                  {country?.name}
                </React.Fragment>
              }
              third_line={`${teams.length} ${lableTeamParticipating} `/* teams participating */}
              tag={leagueDivisionName}
              column="3"
              image={thumbnailImage}
              link_to={`/league/${_id}`}
              leagueFinished={leagueFinished}
            />
          ))}
          {tournamentParticipating.map(
            ({ thumbnailImage, name, gameServer = "EUW", startDate, _id, type = -1, prizes, registerationDeadline, teamSize }) => {
              let subtitle = "";
              if (gameServer) subtitle += gameServer;
              if (teamSize) subtitle += getTeamSize(teamSize);
              if (prizes && prizes.length !== 0) subtitle += "- " + prizes[0];
              if (type !== -1) subtitle += " - " + getGameType(type);

              const diff = moment(startDate).diff(moment());
              const diffDuration = moment.duration(diff);
              const _date = moment(startDate);
              let string = _date.format("DD MMM YYYY");
              if (diffDuration.days() === -1) string = "Yesterday";
              if (diffDuration.days() === 1) string = "Tomorrow";
              if (diffDuration.days() === 0) string = "Today";
              const date = string + " at " + _date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr();
              let reg_date = undefined;
              if (registerationDeadline) {
                const rDiff = moment(registerationDeadline).diff(moment());
                const rDiffDuration = moment.duration(rDiff);
                const _rDate = moment(registerationDeadline);
                let rString = _rDate.format("DD MMM YYYY");
                if (rDiffDuration.days() === -1) rString = "Yesterday";
                if (rDiffDuration.days() === 1) rString = "Tomorrow";
                if (rDiffDuration.days() === 0) rString = "Today";
                reg_date = rString + " at " + _rDate.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr();
              }
              return (
                <TournamentItem
                  key={_id}
                  link_to={`/tournament/${_id}?name=${name}`}
                  image={thumbnailImage}
                  title={name}
                  subtitle={subtitle}
                  color="red"
                  date={"Starting: " + date}
                  reg_date={reg_date ? "Registration: " + reg_date : undefined}
                  startTime={startDate}
                  registerationDeadline={registerationDeadline}
                />
              );
            }
          )}
        </div>
      ) : (
        <div className="league-no-matches">
          <p>{message}</p>
        </div>
      )}
    </>
  );
};

const getTeamSize = (size) => {
  switch (size) {
    case 1:
      return " - 1V1 ";
    case 3:
      return " - 3V3 ";
    case 5:
      return " - 5V5 ";
  }
};

const getGameType = (type) => {
  switch (type) {
    case -1:
      return "";
    case 1:
      return "Single elimination";
    case 2:
      return "Double elimination";
    case 3:
      return "King of the hill";
  }
};

export default LeagueParticipent;
