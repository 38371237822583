import React, { useEffect, useState } from "react";
import default_profile_pic from "@img/unranked.png";
import Loading from "@common/Loading/Loading";
import Axios from "axios";

const RankData = ({ playerId }) => {
  const [globalData, setGlobalData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [rankedflexsr, setRankedflexsr] = useState([]);
  const [rankedsolo, setRankedsolo] = useState([]);

  const [icon, setIcon] = useState("");
  const [title, setTitle] = useState("No Rank");
  const [wins, setWins] = useState("");
  const [losses, setLosses] = useState("");
  const [leaguePoints, setLeaguePoints] = useState("0");

  const [iconSolo, setIconSolo] = useState("");
  const [titleSolo, setTitleSolo] = useState("");
  const [winsSolo, setWinsSolo] = useState("");
  const [lossesSolo, setLossesSolo] = useState("");
  const [leaguePointsSolo, setLeaguePointsSolo] = useState("0");

  useEffect(() => {
    setDataLoading(true);

    Axios.get(`${process.env.REACT_APP_LOL_API}/api/players/${playerId}/aggregratedStats`, { params: { type: "ranked" } }).then(
      ({ data }) => {

        let rankedflexsrData = data?.playerData?.lolData?.leagueEntries?.filter((leagueEntry) => leagueEntry.queueType === "RANKED_FLEX_SR");
        setRankedflexsr(rankedflexsrData && rankedflexsrData[0]);
        // let { icon, title, subtitle, wins, losses, leaguePoints } = rankedflexsrData[0];

        // If the entry data is from riot we need to format it
        if (rankedflexsrData && rankedflexsrData[0] && rankedflexsrData[0].leagueId) {
          const tierSentenceCase = rankedflexsrData[0].tier.charAt(0).toUpperCase() + rankedflexsrData[0].tier.substr(1).toLowerCase();
          let icon = `https://leagues-static.s3.eu-west-2.amazonaws.com/riot_imgs/ranked-emblems/Emblem_${tierSentenceCase}.png`;
          setIcon(icon);
          let title = `${tierSentenceCase} ${rankedflexsrData[0].rank}`;
          title.length && setTitle(title);
          let wins = rankedflexsrData[0].wins;
          setWins(wins);
          let losses = rankedflexsrData[0].losses;
          setLosses(losses);
          let leaguePoints = rankedflexsrData[0].leaguePoints;
          setLeaguePoints(leaguePoints);
        }

        let rankedsoloData = data?.playerData?.lolData?.leagueEntries?.filter((leagueEntry) => leagueEntry.queueType === "RANKED_SOLO_5x5");
        setRankedsolo(rankedsoloData && rankedsoloData);

        // let { icon, title, subtitle, wins, losses, leaguePoints } = rankedsoloData[0];
        if (rankedsoloData && rankedsoloData[0] && rankedsoloData[0].leagueId) {
          const tierSentenceCase = rankedsoloData[0].tier.charAt(0).toUpperCase() + rankedsoloData[0].tier.substr(1).toLowerCase();
          let iconSolo = `https://leagues-static.s3.eu-west-2.amazonaws.com/riot_imgs/ranked-emblems/Emblem_${tierSentenceCase}.png`;
          setIconSolo(iconSolo);
          let titleSolo = `${tierSentenceCase} ${rankedsoloData[0].rank}`;
          setTitleSolo(titleSolo);
          // let subtitleSolo = rankedsoloData[0].queueType;
          // setSubtitleSolo(subtitleSolo);
          let winsSolo = rankedsoloData[0].wins;
          setWinsSolo(winsSolo);
          let lossesSolo = rankedsoloData[0].losses;
          setLossesSolo(lossesSolo);
          let leaguePointsSolo = rankedsoloData[0].leaguePoints;
          setLeaguePointsSolo(leaguePointsSolo);
        }

        setGlobalData(data);
        setDataLoading(false);
      }
    );
  }, [playerId]);

  return (
    <>
      <div className="col-2">
        {!dataLoading ? (
          <>
            <img src={icon || default_profile_pic} className="game-profile-lg-image" alt="" />
            <div>
              <div>
                <span className="text-12 bold color-success">{title}</span>
                <span className="text-12 faded"> / </span>
                <span className="text-12 bold">{leaguePoints} LP</span>
              </div>
              <div>
                <span className="text-12 bold">{Math.round((wins / (wins + losses)) * 100) || 0}% WR</span>{" "}
                <span className="text-12 faded">{wins + losses || 0} games</span>
              </div>
            </div>
          </>
        ) : (
          <div className="col-12 text-center">
            <Loading />
          </div>
        )}
      </div>

      <div className="col-3">
        {!dataLoading ? (
          <>
            <img src={iconSolo || default_profile_pic} className="game-profile-lg-image" alt="" />
            <div>
              <div>
                <span className="text-12 bold color-success">{titleSolo}</span>
                <span className="text-12 faded"> / </span>
                <span className="text-12 bold">{leaguePointsSolo} LP</span>
              </div>
              <div>
                <span className="text-12 bold">{Math.round((winsSolo / (winsSolo + lossesSolo)) * 100) || 0}% WR</span>{" "}
                <span className="text-12 faded">{winsSolo + lossesSolo || 0} games</span>
              </div>
            </div>
          </>
        ) : (
          <div className="col-12 text-center">
            <Loading />
          </div>
        )}
      </div>
      {/*         
        <div className="col-2" >
            <img src={default_profile_pic} className="game-profile-lg-image" alt="" />
            <span className="bold text-12">HAVssOC</span>
        </div> */}
    </>
  );
};

export default RankData;
