import React, { useState, useEffect, useContext } from "react";
import { MirrorText, SimplePageWithTabs } from "@common";
import MultiStageLeaguesTab from "./MultiStageLeaguesTab/MultiStageLeaguesTab";
import Axios from "axios";
import { AppContext } from "../../context/AppContext";
import leaguesGuide from "../../img/leagues-guide.jpg";
import { useParams } from "react-router-dom";

const MultiStageLeague = () => {
  const { id } = useParams();
  const { selectedGame } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
// {console.log("GET PARAMS ID =>",id);}
//   useEffect(() => {
//     Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${selectedGame._id}`).then(({ data }) => {
//       setGroups(data.groups);
//       setLoading(false);
//     });
//   }, [selectedGame._id]);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${selectedGame._id}`).then(({ data }) => {
      setGroups(data.groups);
      setLoading(false);
    });
  }, [selectedGame._id]);

  const tabs = {
    All: <MultiStageLeaguesTab all />,
  };

  groups.forEach(({ _id, name }) => {
    tabs[name] = <MultiStageLeaguesTab groupId={_id} groupName={name} />;
  });

  return (
    !loading && <SimplePageWithTabs
          title="Competitions"
          tabs={tabs}
          top_right={
            <React.Fragment>
              <MirrorText text={["CH4LL3NG3R D14M0ND", "PL4T1N1UM"]} is_static />
            </React.Fragment>
          }
        />
  );
};

export default MultiStageLeague;
