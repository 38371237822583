import React, {useContext,useEffect, useState} from "react";
import { AppContext } from "../../../context/AppContext";
import vars from "@styles/_variables.scss";
import { Item } from "@common";
// import lol_icon from "@img/lol-icon.svg";

const TeamsItem = (props) => {
  const { team, game } = props;
  let { classes, ...props_parent } = { ...props };
  const { user } = useContext(AppContext);

  classes += " teams-item";
  return team ? (
    <Item
      {...props_parent}
      link_to={`/teams/${team?._id}`}
      openInNewWindow={true}
      title={`${team.name} (${team.initials})`}
      excerpt={
        game && (
          <>
            <img src={game.whiteIcon} alt="Icon" /> {game.name} - {team?.members.length} {team?.members.length > 1 ? "Members" : "Member"}
          </>
        )
      }
      backgroundSize={"cover"}
      default_image={team.banner}
      classes={classes}
      item_image_inner={
        <React.Fragment>
          <div className="image-cover"></div>
        </React.Fragment>
      }
    />
  ) : (
    ""
  );
};

export default TeamsItem;
