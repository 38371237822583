import React, { useState, createContext, useEffect } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import CreateCoach from "./CreateCoach/CreateCoach";
import AddCoachSocials from "./AddCoachSocials/AddCoachSocials";

export const CreateCoachModalContext = createContext();

const CreateCoachModal = ({ open, onClose, afterCreate = () => false }) => {
  const [step, setStep] = useState(0);
  const [coachData, setCoachData] = useState({});

  useEffect(() => {
    // reset modal when it changes visibility
    setStep(0);
    setCoachData({});
  }, [open]);

  const steps = [<CreateCoach />, <AddCoachSocials />];

  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      {step > 0 ? (
        <div className="modal-step-progress">
          <span className="text-12 uppercase bold color-primary">step {step + 1}/3</span>
        </div>
      ) : null}
      <CreateCoachModalContext.Provider value={{ setStep, onClose, coachData, setCoachData, afterCreate }}>
        {steps.map((component, i) => i === step && <div key={i}>{component}</div>)}
      </CreateCoachModalContext.Provider>
    </Modal>
  );
};

export default CreateCoachModal;
