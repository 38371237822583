import React, { useContext, useEffect, useState } from "react";
import { CheckboxSlider, SearchInput } from "@common";
import FinderCard from "../../../_common/FinderCard/PlayerFinderCard";
import Loading from "../../../_common/Loading/Loading";
import Axios from "axios";
import { AppContext } from "../../../../context/AppContext";
import { toast } from "react-toastify";
import JoinPlayerFinder from "./../Modals/JoinPlayerFinder";
import { countries } from "@utils";

const PlayerFinder = () => {
  const {
    authenticated,
    user,
    user: {
      state: { _id },
    },
    selectedGame,
  } = useContext(AppContext);
  const [showJoinPlayerFinder, setShowJoinPlayerFinder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [existingPlayer, setExistingPlayer] = useState([]);
  const [loggedInUserBirthday,setLoggedInUserBirthday] = useState(null);
  const [finderId, setFinderId] = useState("");
  const [all, setAll] = useState(false);
  const [minor, setMinor] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [nationalities, setNationalities] = useState([]);
  const [playerRoles, setPlayerRoles] = useState([]);
  const [teamFormat, setTeamFormat] = useState([]);
  const [tags, setTags] = useState([]);
  const [language,setLanguage] = useState(false);
  const [teamRanks, setTeamRanks] = useState({
    minRank: -1,
    maxRank: -1,
  });


  const handleRegionFilter = (e) => {
    setLanguage(e.target.value);
   
  };


  useEffect(() => {
    // Axios.get("https://restcountries.eu/rest/v2/all?fields=demonym;alpha2Code", { headers: false }).then(({ data }) => {
    //   setNationalities(
    //     data
    //       .filter(({ demonym }) => demonym)
    //       .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    //       .sort((a, b) => a.demonym.localeCompare(b.demonym))
    //   );
    // });
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, []);

  const generateResultArray = (array, value) => {
    return array.includes(value) ? array.filter((val) => val !== value) : [...array, value];
  };

  const handlePlayerRoleFilter = (id) => {
    let sizes = generateResultArray(playerRoles, id.toUpperCase());
    setPlayerRoles(sizes);
  };

  const generateTags = () => {
    // if(playerRoles.length){
    //   setTags({...tags,playerRoles})
    // }
  }

  const removeTag = (remThisTag, remFrom) => {
    // console.log('remThisTag, remFrom-',remThisTag, remFrom)
    
    if(remFrom === 'fromplayerRoles'){
      let sizes = generateResultArray(playerRoles, remThisTag);
      
      setPlayerRoles(sizes);
     
    }
    
    }
    // console.log('test if remove',playerRoles)
  const generateFilters = () => {
    let filters = "?getFindersOf=player&";
    if (!all) {
      filters += "gameId=" + selectedGame._id + "&";
      filters += "minor=" + minor + "&";
    }
    if (searchString) filters += "search=" + searchString + "&";
    if (playerRoles.length) filters += "playerRoles=" + playerRoles.join(",") + "&";
    if (language) filters += "language=" + language + "&";
    if (teamRanks.minRank > -1) filters += "minRank=" + teamRanks.minRank + "&";
    if (teamRanks.maxRank > -1) filters += "maxRank=" + teamRanks.maxRank + "&";
    return filters.length > 1 ? filters : "";
  };

  const getMyStatus = (existingPlayerData = null) => {
    existingPlayerData && Axios.post(`${process.env.REACT_APP_CORE_API}/api/finder/my-status`, {
      playerCoachId: existingPlayerData[0]._id,
      criteria: 'player',
      game: selectedGame._id,
    }).then(({ data }) => {
      // console.log('data-',data);
      setFinderId(data._id ? data._id : "");
    });
  }

  const getPlayers = () =>
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/finder${generateFilters()}`).then(({ data }) => {
      // console.log('data---',data);
      setPlayers(data);
      setLoading(false);
    });


  useEffect(() => {
    // console.log('user.state.players-',user.state.players)
    var existingPlayerData = user.state.players && user.state.players.filter(({ game }) => game._id === selectedGame._id);
    setExistingPlayer(existingPlayerData);
    setLoggedInUserBirthday(user.state.birthday);
    existingPlayerData && existingPlayerData.length > 0 && getMyStatus(existingPlayerData);
  },[user]);

  useEffect(() => {
    getPlayers();
    generateTags();
  }, [all, finderId, selectedGame, minor, searchString, playerRoles, teamRanks, language]);

  const handleJoinExitFinder = async () => {
    setLoading(true);
    if (finderId) {
      try {
        const { data: finder } = await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/finder/${finderId}`);
        if (finder) {
          toast.success(finder.msg);
          setFinderId("");
        }
      } catch (e) {
        const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem joining finder";
        toast.error(msg);
      }
    } else {
      if(existingPlayer.length === 0){
        toast.error("You don't have any active Player in your account.");
      }else{
  
        // try {
        //   const { data: finder } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/finder`, {
        //     player: existingPlayer[0]._id,
        //     playerDescription: 'Test Finder Description',
        //     language:new Array("Saab", "Volvo", "BMW"),
        //     game: selectedGame._id,
        //   });
        //   if (finder) {
        //     toast.success("Successfully joined finder!");
        //     getMyStatus(existingPlayer);
        //   }
        // } catch (e) {
        //   const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem joining finder";
        //   toast.error(msg);
        //   getMyStatus(existingPlayer);
        // }
      }
    }
    setLoading(false);
  };

  const handleSearchChange = (event) => {
    setSearchString(event.target.value);
  };

const handleSetMinor = (minorVal) => {
  setTags({...tags,minor:[minorVal ? 'Show 18+ users' : 'Hide 18+ users']})
  setMinor(minorVal)
}

  const handleRankChange = (name,value) => {
    setTeamRanks({ ...teamRanks, [name]: parseInt(value) });
  };

  // console.log('playerRoles-',playerRoles);
  const resetFilters = () => {
    setTeamFormat([]);
    setPlayerRoles([]);
    setTeamRanks({
      minRank: -1,
      maxRank: -1,
    });
    setAll(false);
    setLanguage(false);
    setMinor(false);
  };
// console.log('language-',typeof JSON.parse(language), JSON.parse(language).name)
  return (<>
    <JoinPlayerFinder
      open={showJoinPlayerFinder}
      onClose={() => setShowJoinPlayerFinder(false)}
      // afterCreate={(player) => onCreate(player, "players")}
      existingPlayer={existingPlayer}
      selectedGame={selectedGame}
      getMyStatus={(existPl) => getMyStatus(existPl)}
      getPlayers={() => getPlayers()}
      birthday={loggedInUserBirthday}
    />
    <div className="row smaller-gutter-row">
      <div className="col-3 border-right">
        {authenticated && (
          finderId ? <button className={`mb-4 button ${finderId ? "danger" : "primary"} thicc`}  onClick={handleJoinExitFinder}>
            {"Exit Finder"}
          </button> : <button className={`mb-4 button ${finderId ? "danger" : "primary"} thicc`}  onClick={() => setShowJoinPlayerFinder(true)} >
            {"Join finder"}
          </button>
        )}
        

        <div className="faded bold uppercase mb-2">Roles</div>
        
        <CheckboxSlider checked={playerRoles.includes("TOP")} onChange={(event) => handlePlayerRoleFilter(event.target.id)} label="TOP" />
        <CheckboxSlider checked={playerRoles.includes("MID")} onChange={(event) => handlePlayerRoleFilter(event.target.id)} label="MID" />
        <CheckboxSlider checked={playerRoles.includes("JUNGLE")} onChange={(event) => handlePlayerRoleFilter(event.target.id)} label="JUNGLE" />
        <CheckboxSlider checked={playerRoles.includes("BOT")} onChange={(event) => handlePlayerRoleFilter(event.target.id)} label="BOT" />
        <CheckboxSlider checked={playerRoles.includes("SUPPORT")} onChange={(event) => handlePlayerRoleFilter(event.target.id)} label="SUPPORT" />

        <div className="faded bold uppercase mt-4 mb-2">Min rank</div>
        <select className="select" value={teamRanks.minRank} name="minRank" onChange={(e) => handleRankChange(e.target.name,e.target.value)}>
          <option value={-1} disabled>
            Select Rank
          </option>
          {selectedGame.gameRanks?.map((rank, index) => (
            <option value={index} key={index}>
              {rank}
            </option>
          ))}
        </select>

        <div className="faded bold uppercase mt-4 mb-2">Max rank</div>
        <select className="select" value={teamRanks.maxRank} name="maxRank" onChange={(e) => handleRankChange(e.target.name,e.target.value)}>
          <option value={-1} disabled>
            Select Rank
          </option>
          {selectedGame.gameRanks?.map((rank, index) => (
            <option value={index} key={index}>
              {rank}
            </option>
          ))}
        </select>

        <div className="faded bold uppercase mt-4 mb-2">Age</div>

        <CheckboxSlider label="Show 18+ users only" label_style="" value={minor} onChange={() => handleSetMinor(!minor)} />

        <div className="faded bold uppercase mt-4 mb-2">Language</div>

        <select
          className="select" name="language"  onChange={handleRegionFilter}
        >
          <option value="_DEFAULT" selected={!language} disabled>
            Choose Language
          </option>
          {nationalities.map(({ alpha2Code, demonym }) => (
              <option key={alpha2Code} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
                {demonym}
              </option>
            ))}
        </select>

        {/* <div className="faded bold uppercase mt-4 mb-2">Country</div>

        <select className="select">
          <option>Select country</option>
          <option>No limit</option>
          <option>No limit</option>
        </select> */}

        <div className="faded bold uppercase mt-4 mb-2">Display all players</div>

        <CheckboxSlider label="Show all users" label_style="" value={all} onChange={() => setAll(!all)} />

        <p className="faded pb-2 mb-0">
          <small>This includes leagues.gg players who are not looking for teams.</small>
        </p>

        <button className="button default thicc mt-4" onClick={resetFilters}>
          Reset all filters
        </button>
      </div>
      <div className="col-9">
        <SearchInput onChange={handleSearchChange} value={searchString} />
        <br />
      
      {
        playerRoles.map((tgname) => <span key={tgname+'pill'} class='pill'>
          {tgname} {' '} <i key={tgname+'pill-i'} onClick={() => handlePlayerRoleFilter(tgname)} class="fa fa-times"></i>
        </span>)
      }
      {
        teamRanks.maxRank >= 0 && <span key={'maxRank-pill'} class='pill'>
        {selectedGame.gameRanks[teamRanks.maxRank]} {' '} <i key={'maxrank-pill-i'} onClick={() => handleRankChange('maxRank',-1)} class="fa fa-times"></i>
      </span>
      }
      {
        teamRanks.minRank >= 0 && <span key={'minRank-pill'} class='pill'>
        {selectedGame.gameRanks[teamRanks.minRank]} {' '} <i key={'minRank-pill-i'} onClick={() => handleRankChange('minRank',-1)} class="fa fa-times"></i>
      </span>
      }

      {minor && <span key={'minor-pill'} class='pill'>
        {"18+ Users Only"} {' '} <i key={'minor-pill-i'} onClick={() => handleSetMinor(false)} class="fa fa-times"></i>
      </span>}

      {language && <span key={'language-pill'} class='pill'>
        {JSON.parse(language).name} {' '} <i key={'language-pill-i'} onClick={() => setLanguage(false)} class="fa fa-times"></i>
      </span>}
        

        {loading && (
          <div className="center mt-4">
            <Loading />
          </div>
        )}

        {!loading && !players.length && (
          <div className="center mt-4">
            <p>Currently! No players available.</p>
          </div>
        )}

        {!loading &&
          players.map((player) => (
            player && player.player && <FinderCard
              color="primary"
              item={{
                finderCardFor: 'player',
                rank: "",
                playerFinderData:player,
                player: player.player,
                selectedGame,
                team: {
                  // initials: player.firstName,
                  name: player.player.name,
                  logo: player.player.avatarImage,
                  nationality: player.player.nationality,
                },
              }}
            />
          ))}
      </div>
    </div>
    </>
  );
};

export default PlayerFinder;
