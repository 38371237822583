import React, { useState, useEffect, useContext } from "react";
import { MirrorText, SimplePageWithTabs } from "@common";
import LeaguesTab from "./LeaguesTab/LeaguesTab";
import Axios from "axios";
import { AppContext } from "../../context/AppContext";
import leaguesGuide from "../../img/leagues-guide.jpg";

const Leagues = () => {
  const { selectedGame } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${selectedGame._id}`).then(({ data }) => {
      setGroups(data.groups);
      setLoading(false);
    });
  }, [selectedGame._id]);

  const tabs = {
    All: <LeaguesTab all />,
  };

  groups.forEach(({ _id, name }) => {
    tabs[name] = <LeaguesTab groupId={_id} groupName={name} />;
  });

  return (
    !loading && 
    <div>
       <SimplePageWithTabs
    
          title="Leagues"
          tabs={tabs}
          top_right={
            <React.Fragment>
              <MirrorText text={["CH4LL3NG3R D14M0ND", "PL4T1N1UM"]} is_static />
            </React.Fragment>
          }
        />
    </div>
   
  );
};

export default Leagues;
