import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SimpleTabs, SortableList, SortableListItem, Loading } from "@common";
import Axios from "axios";

const TopPlayers = ({ leagueId }) => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [topPlayers, setTopPlayers] = useState([]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_LOL_API}/api/leagues/${leagueId}/topPlayers`).then(({ data }) => {
      if (data?.playerStats) {
        setTopPlayers(
          data?.playerStats?.map((player) => {
            player.kda = player.deaths === 0 ? Infinity : parseFloat((player.kills + player.assists) / player.deaths).toFixed(2);
            player.csPerMin = parseFloat(player.cs / (player.totalTimePlayed / 60)).toFixed(1);

            return player;
          })
        );
      }
      setLoading(false);
    });
  }, [leagueId]);
  useEffect(() => {
    console.log("top players : ", topPlayers);
  }, [topPlayers]);
  let pages = ["KDA", "CS PR MIN", "KILLS", "ASSISTS", "VISION SCORE", "KILL PARTICIPATION"];
  let prop_names = ["kda", "csPerMin", "kills", "assists", "visionScore", "killParticipation"];

  return loading ? (
    <div className="text-center league-no-matches">
      <Loading width={50} height={50} />
    </div>
  ) : (
    <div>
      <SimpleTabs pages={pages} page={page} setPage={setPage} />

      <SortableList
        config={[
          { col: 1, title: "#", prop_name: "number", is_sortable: true, alignment: "left" },
          { col: 3, title: "Player", prop_name: "player", alignment: "left" },
          { col: 3, title: "Team", prop_name: "team", alignment: "left" },
          { col: 1, title: "Position", prop_name: "position", is_sortable: true, alignment: "left", style: { marginLeft: "55px" } },
          { col: 1, title: "Maps Played", prop_name: "mapsPlayed", is_sortable: true, alignment: "right" },
          { col: 1, title: pages[page], prop_name: prop_names[page], is_sortable: true, alignment: "right" },
          {
            col: 1,
            title: "Action",
            prop_name: "action",
            alignment: "center",
            style: { marginLeft: "40px" },
          },
        ]}
      >
        {topPlayers.length !== 0
          ? topPlayers
              .sort((a, b) => b[prop_names[page]] - a[prop_names[page]])
              .map((player, i) => {
                const playerInnerData = player.player;

                return (
                  <SortableListItem
                    number={i + 1}
                    team={
                      <>
                        {player?.team ? (
                          <React.Fragment>
                            <img
                              src={player.team?.logo}
                              alt={player.team?.name}
                              className={
                                player.team?.logoCropped || player.team?.logoCropped === undefined
                                  ? "image-40 border-radius-100"
                                  : "image-40"
                              }
                            />
                            {player.team?.name}
                          </React.Fragment>
                        ) : (
                          "[Deleted]"
                        )}
                      </>
                    }
                    player={
                      playerInnerData ? (
                        <React.Fragment>
                          <img src={playerInnerData.avatarImage} alt={playerInnerData.name} className="image-40 border-radius-100" />
                          {playerInnerData.name}
                        </React.Fragment>
                      ) : (
                        "[Deleted]"
                      )
                    }
                    position={player.role}
                    // position_number={i % 3}
                    kda={player.kda === Infinity ? "Perfect" : player.kda}
                    csPerMin={player.csPerMin}
                    kills={player.kills}
                    assists={player.assists}
                    killParticipation={
                      // (((player.kills + player.assists) / Math.max(1, player.teamKills - player.kills)) * 100).toFixed(2) + "%"
                      (((player.kills + player.assists) / Math.max(1, player.teamKills)) * 100).toFixed(2) + "%"
                    }
                    visionScore={player.visionScore}
                    mapsPlayed={player.mapsPlayed}
                    action={
                      playerInnerData && (
                        <React.Fragment>
                          <Link to={`/game-profile/${playerInnerData._id}`} className="text-12 primary-link">
                            Go to player
                          </Link>
                        </React.Fragment>
                      )
                    }
                  />
                );
              })
          : null}
      </SortableList>
    </div>
  );
};

export default TopPlayers;
