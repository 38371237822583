import React, { useState, useContext } from "react";
import { AppContext } from "../../../../../context/AppContext";
import { AuthModalContext } from "../../AuthModal";
import Axios from "axios";
import { toast } from "react-toastify";
import { RiEyeOffLine, RiEyeLine } from "react-icons/ri";
import Loading from "../../../../_common/Loading/Loading";
import ForgotPassword from "./ForgotPassword";
import useTranslated from "../../../../../helpers/translationHelper";
const Login = () => {
  const { setAuthenticated, setShowAuth } = useContext(AppContext);
  const { setRegistrationData, setStep } = useContext(AuthModalContext);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const _login = useTranslated("id_pop-up_-_log_in_(log_in)_5");
  const _email = useTranslated("id_pop-up_-_log_in_(log_in)_0");
  const _oassword = useTranslated("id_pop-up_-_log_in_(log_in)_2");
  const _rememberme = useTranslated("id_pop-up_-_log_in_(log_in)_4");
  const _frogetpassword = useTranslated("id_pop-up_-_log_in_(log_in)_6");
  const _defaultemail = useTranslated("id_pop-up_-_log_in_(log_in)_1");
  const _defaulpassword = useTranslated("id_pop-up_-_log_in_(log_in)_3");

  const togglePswdVisibility = () => setPasswordVisible(!passwordVisible);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/login`, {
        email,
        password,
        rememberMe,
      });

      setLoading(false);
      localStorage.setItem("sessionToken", data);
      setShowAuth(false);
      setAuthenticated(true);
    } catch (e) {
      setLoading(false);

      if (e.response && e.response.data && e.response.data.invalidCode) {
        setRegistrationData({ email, password, rememberMe });
        setStep(2);
      } else {
        const msg = e.response && e.response.data ? e.response.data.msg : "Fatal error";
        toast.error(msg);
      }
    }
  };

  return forgotPassword ? (
    <ForgotPassword afterReset={() => setForgotPassword(false)} />
  ) : (
    <form onSubmit={login}>
      <div className="form-row">
        <label htmlFor="email">{_email}</label>
        <input
          type="email"
          id="email"
          placeholder={`${_defaultemail}`}
          autoComplete="email"
          className="form-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="form-row">
        <label htmlFor="password">{_oassword}</label>
        <input
          type={passwordVisible ? "text" : "password"}
          id="password"
          autoComplete="password"
          placeholder={`${_defaulpassword}`}
          className="form-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <div onClick={togglePswdVisibility} className="toggle-pswd-visibility">
          {passwordVisible ? <RiEyeOffLine size="1.2rem" color="#666" /> : <RiEyeLine size="1.2rem" color="#666" />}
        </div>
      </div>

      <div className="form-row inline">
        <input
          type="checkbox"
          className="form-input"
          id="remember-me"
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
        />
        <label htmlFor="remember-me">{_rememberme}</label>
      </div>
      <button type="submit" className="button primary thicc" disabled={loading}>
        {loading ? (
          <>
            <Loading width={30} height={30} /> Logging you in
          </>
        ) : (
          `${_login}`
        )}
      </button>

      <button onClick={() => setForgotPassword(true)} className="button-link forgot-password">
        {_frogetpassword}
      </button>
    </form>
  );
};

export default Login;
