import React, { useState,useEffect } from 'react';
import { Icon,Loading,TeamMemberListItemType2 } from "@common";
import Top from "@img/top.svg";
import Mid from "@img/mid.svg";
import Bot from "@img/bot.svg";
import Jungle from "@img/jungle.svg";
import Support from "@img/support.svg";
import Sub1 from "@img/substitude-1.svg";
import Sub2 from "@img/substitude-2.svg";
import Collapsible from 'react-collapsible';

const Lineup = ({matchData}) => {
  const [teamOnePlayers, setTeamOnePlayers] = useState([]); 
  const [teamTwoPlayers, setTeamTwoPlayers] = useState([]);
  // console.log('matchData=',matchData)
  // matchData.tournament.teams[0].selectedPlayers  *** matchData.t1._id

  useEffect(() => {
    let t1Id = matchData?.t1?._id;
    let t2Id = matchData?.t2?._id;
    let team1_selectedPlayers = [];
    let team2_selectedPlayers = [];
    
    let data = matchData?.tournament?.teams.filter((team) => {
      if(team.team === t1Id){
        team.selectedPlayers.map((sinPlay) => {
          team1_selectedPlayers.push({
            ...sinPlay,
            player:matchData.t1.members.filter((singMem) => singMem.player && singMem.player._id === sinPlay.player_id)[0]?.player
          })
        });

        team.subPlayers.map((sinPlay) => {
          team1_selectedPlayers.push({
            ...sinPlay,
            player:matchData.t1.members.filter((singMem) => singMem.player && singMem.player._id === sinPlay.player_id)[0]?.player
          })
        });
      }
      if(team.team === t2Id){
        team.selectedPlayers.map((sinPlay) => {
          team2_selectedPlayers.push({
            ...sinPlay,
            // player:matchData.t2.members.filter(({_id}) => _id === sinPlay.player_id)})
            player:matchData.t2.members.filter((singMem) => singMem.player && singMem.player._id === sinPlay.player_id)[0]?.player
        })
      });

      team.subPlayers.map((sinPlay) => {
        team2_selectedPlayers.push({
          ...sinPlay,
          player:matchData.t2.members.filter((singMem) => singMem.player && singMem.player._id === sinPlay.player_id)[0]?.player
        })
      });
    }
  });
    setTeamOnePlayers(team1_selectedPlayers)
    setTeamTwoPlayers(team2_selectedPlayers)
  }, [])

  // const playersOfTeamOne = () => {
  //   let teamSele =  matchData.tournament.teams.filter((team) => team._id === matchData.t1._id);
  //   console.log('teamSele=',teamSele)
  //   return teamSele.length ? teamSele[0].selectedPlayers : [];
  // }

  // const playersOfTeamTwo = () => {
  //   let teamSele =  matchData.tournament.teams.filter((team) => team._id === matchData.t2._id);
  //   console.log('teamSele=',teamSele)
  //   return teamSele.length ? teamSele[0].selectedPlayers : [];
  // }

  return ( 
    <div>
      {matchData ? (
        <>
        <div>{team(matchData.t1,teamOnePlayers)}</div>
        <div>{team(matchData.t2,teamTwoPlayers)}</div>
        </>
      ):null}
    </div>
   );
}

const positionData = (role)=>{
  const position={
    name:role
  };
  if(role==="TOP") position.icon=Top;
  else if(role==="MID") position.icon=Mid;
  else if(role==="BOT") position.icon=Bot;
  else if(role==="SUPPORT") position.icon=Support;
  else if(role==="JUNGLE") position.icon=Jungle;
  else if(role==="SUB 1") position.icon=Sub1;
  else if(role==="SUB 2") position.icon=Sub2;
  return position
}

const handleGotoTeamPage = (teamId,e)=>{
  e.preventDefault();
  // history.push(`/teams/${teamId}`);

  const win = window.open(`/teams/${teamId}`, "_blank");
win.focus();

  e.stopPropagation();
}
const team = (_team,players)=>{
  // console.log("playersplayers=",players);
  return (
    <div className="lineup-team">
        {/* team info start  */}
        <div className="row justify-content-between align-items-center">
        <div className="col-md-5">
          <div className="par-team-info">
            <div className="team-logo" style={{backgroundImage:`url(${_team.logo})`}}></div>
            <div className="team-name">{_team.name}</div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="par-goto-team text-right">
            <a href="#" onClick={(e)=>handleGotoTeamPage(_team._id,e)}>GO TO TEAM PAGE  <Icon name="external-primary" /></a>
          </div>
        </div>
        </div>
      {/* team info end  */}
      {players && players.length>0 && players.filter((pl)=>(pl.player)).map((_player)=>(
        <TeamMemberListItemType2 key={_player._id}
        img={_player?.player?.avatarImage}
        title={
          _player?.player?.name
        }
    //   subtitle={player.player_id ? `${player?.user?.firstName}${player?.user?.nickname ? ` "${player?.user?.nickname}" ` : " "}${
    //     player?.user?.lastName
    // }`:""}
      position={positionData(_player.role)}
      role={_player.role}
      nationality={_player?.player?.nationality}
      editable={false}
      availableNewRoles={[]}
        
      actions={[
        <a
          href={_player?.player?._id ? `/game-profile/${_player?.player?._id}`:"#"}
          className="text-14 primary-link">
          View profile
        </a>
      ]} />
      ))}

    </div>
  )
}
 

export default Lineup;