import React, { Component } from 'react';
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";

class AddNewTabModal extends Component {
    state = {
        tabnewName : ''
      }

      saveNewName = () => {
        this.props.addNewPage(this.state.tabnewName);
        this.props.closeModal();
      }
    render() {
        return (
            <Modal 
            position='absolute'
            style={{ overlay: {position:'unset'} }}
            isOpen={this.props.isModalOpen} 
            onRequestClose={() => this.props.closeModal()} 
            className="modal auth-modal" 
            shouldCloseOnEsc={false}>
                <div className="modal-close" onClick={() => this.props.closeModal()} >
                    <RiCloseLine />
                </div>
                

                <div >
                    <div className="modal-header">
                        
                        <h2 className="bold uppercase">Add New Tab</h2>
                        {/* <h3>Connect your game account to participate in tournaments, scrims etc. You can always add your game account later.</h3> */}
                    </div>
                   
                    <div className="modal-body">
                        <div className="form-row">
                            <label htmlFor="name">Name</label>
                            <input onBlur={(e) => {
                                e.target.value = e.target.value.trim();
                                }}
                                type="text"
                                id="name"
                                name="name"
                                autoComplete="off"
                                maxLength={8}
                                className={`form-input`}
                                
                                placeholder="Name"
                                onChange={(target) => this.setState({tabnewName:target.target.value})}
                            />
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <button type="submit" className="button primary" onClick={() => this.saveNewName()}>
                                Save
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Modal>
            );
  }
}

export default AddNewTabModal;
