import React, { useEffect, useState } from 'react';
import { Bracket } from 'react-brackets';
import CustomSeed from './CustomSeed';
import { hilightTracks, unhilightTracks } from './hilight-tracks';
 
const BracketsMap = ({ data, losingTrack, hilighted, setHilighted, isShort,selectedLoser,handleMatchClick,upperBracketData,highlightUpperBracket }) => {
  const [hovered, setHovered] = useState();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    unhilightTracks(data);
    if (upperBracketData) {
      unhilightTracks(upperBracketData);
    }
    if (hovered) {
      hilightTracks(hovered, data, upperBracketData);
      // its a loser bracket
      //  if(upperBracketData){
      //     highlightUpperBracket(hovered)
      //  }
    }
    setRefresh(!refresh);
  }, [hovered]);

  if (data.length === 0) return <div className="col-12 p-5 text-center text-grey"></div>;
  // console.log("Data--->",data)
  return (
    <Bracket
      bracketClassName="single-bracket"
      mobileBreakpoint={0}
      rounds={data}
      roundTitleComponent={(title, roundIndex) => {
        let _title =
          roundIndex === data.length - 1
            ? "Final"
            : roundIndex === data.length - 2
            ? "Semifinal"
            : roundIndex === data.length - 3
            ? "Quarterfinal"
            : title;
        if (losingTrack) {
          if (roundIndex === data.length - 1 && !isShort) _title = "Third place decider";
          // else _title = " "
        }
        // if seeds are not hidden show title only
        if (
          (data && roundIndex !== undefined && data[roundIndex] && !data[roundIndex].seeds.every((seed) => seed && seed.hidden)) ||
          selectedLoser
        ) {
          return (
            <div className="text-white bold text-center" style={{ minHeight: 30 }}>
              {_title}
            </div>
          );
        }
      }}
      renderSeedComponent={({ seed, breakpoint, roundIndex }) => {
        let singleLine = false;
        if (roundIndex !== data.length - 1)
          if (data[roundIndex].seeds?.length === data[roundIndex + 1].seeds?.length) {
            singleLine = true;
          }
        const matchIndex =
          seed && !seed.hidden ? data[roundIndex].seeds.findIndex((el) => el?.matchData?._id === seed.matchData?._id) : undefined;
        // if all seeds are hidden dont show the brackets and if not loser bracket selected
        if ((data && data[roundIndex] && !data[roundIndex].seeds.every((s) => s && s.hidden)) || selectedLoser) {
          return CustomSeed({
            seed,
            breakpoint,
            roundIndex,
            hilighted,
            setHilighted,
            singleLine,
            losingTrack,
            hovered,
            setHovered,
            matchIndex,
            handleMatchClick,
          });
        }
      }}
    />
  );
}

export default BracketsMap;
