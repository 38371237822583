import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { About, Blank, Calendar, Contact, News, Teams } from "./OrganisationsSingleTabs";
import { WidePageWithTabs } from "@common";
import Axios from "axios";
import { AppContext } from "@context/AppContext";
import { toast } from "react-toastify";
import useTranslated from "../../../helpers/translationHelper";
const OrganisationsSingle = () => {
  const { id } = useParams();
  const history = useHistory();
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [organisation, setOrganisation] = useState({});
  const [organisationTabs, setOrganisationtabs] = useState({});
  const [showPreview, setShowPreviewToggle] = useState(true);
const orgabout=useTranslated('id_organisation_page_0')
const teamsabout=useTranslated('id_organisation_page_1')
const calenderabout=useTranslated('id_organisation_page_2')
const newsabout=useTranslated('id_organisation_page_3')
const contactabout=useTranslated('id_organisation_page_4')
const orgmanag=useTranslated('id_organisation_page_5')

  // { pages, cover_image, logo, title, label, base_route }

  useEffect(() => {
    fetchOrgData();
  }, [id]);
  

  const fetchOrgData = () => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/organisations/${id}`).then(({ data }) => {
      let tabs = data.profileConfig.tabs;
      // swapping the teams and calendar tab.
      const temp = tabs[1];
      tabs[1] = tabs[2];
      tabs[2] = temp;
      setOrganisation({ ...data, tabs });
      setOrganisationtabs(tabs);
      setLoading(false);
    });
  };
  var getOrgData = async () => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/organisations/${id}`).then(({ data }) => {
      setOrganisation(data);
      setOrganisationtabs(data.profileConfig.tabs);
      setLoading(false);
    });
  };

  var showPreviewToggle = () => {
    setShowPreviewToggle(!showPreview);
  };
  var saveContent = async (e, dataForTab) => {
    organisationTabs.filter((tabData) => {
      if (tabData.name === dataForTab) {
        tabData.content = e;
      }
    });
    setOrganisationtabs(organisationTabs);
  };

  var addNewPage = async (newTabName) => {
    let newTabNameExist = false;
    let newTabObject = { name: newTabName, hide: false, content: [] };
    organisationTabs.filter((tabData) => {
      if (tabData.name === newTabName) {
        newTabNameExist = true;
      }
    });
    if (newTabNameExist) {
      toast.success("Tab with same name already exist.");
    } else {
      organisationTabs.push(newTabObject);
      setOrganisationtabs(organisationTabs);

      submitContent(true);
      setTimeout(async () => {
        getOrgData();
      }, 200);
    }
    // setOrganisationtabs(organisationTabs);
  };

  var editTabName = async (newName, oldName) => {
    organisationTabs.filter((tabData) => {
      if (tabData.name === oldName) {
        tabData.name = newName;
      }
    });
    setOrganisationtabs(organisationTabs);

    submitContent(true);
    setTimeout(async () => {
      getOrgData();
    }, 200);
  };

  var removeTab = async (tabName) => {
    organisationTabs.filter((tabData) => {
      if (tabData.name === tabName) {
        tabData.hide = true;
      }
    });
    setOrganisationtabs(organisationTabs);
    submitContent(true);
    setTimeout(async () => {
      getOrgData();
    }, 200);
  };

  var submitContent = async (builderRemainActive = false) => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${id}/profileConfig`, { tabs: organisationTabs });
      if (!builderRemainActive) {
        showPreviewToggle();
      }
      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }
  };

  const isOwner = !loading && (organisation.owners.find(({ user: id }) => id && user.state._id === id._id) || user.state.admin);

  // TABS CONFIGURATION
  // pages starts empty, and only if the tabs are set as not hidden we will add pages, and their page content to the pages object
  if (!loading) {
    const pages = {};
    const contents = {};
    const pageContent = {
      [orgabout]: (
        <About
          logo={organisation.logo}
          originalLogo={organisation.originalLogo}
          showLogo={!organisation.profileConfig.logoInBanner}
          title={organisation.name}
          about={organisation.about}
          croppedLogo={organisation.logoCropped}
          tagline={organisation.tagline}
          orgId={id}
          canBeModify={true}
          canBeRemove={false}
          tabObject={organisation.profileConfig.tabs.filter((tabData) => tabData.name === "About")}
          showPreview={showPreview}
          saveContent={(e) => saveContent(e, "About")}
          editTabName={(e) => editTabName(e, "About")}
          removeTab={(tabName) => removeTab(tabName)}
        />
      ),
      [calenderabout]: (
        <Calendar
          canBeModify={false}
          canBeRemove={false}
          editTabName={(e) => alert("Tab modification/remove not allowed.")}
          removeTab={(tabName) => alert("Tab modification/remove not allowed.")}
        />
      ),
      [teamsabout]: (
        <Teams
          canBeModify={false}
          canBeRemove={false}
          teams={organisation.assocTeams}
          editTabName={(e) => alert("Tab modification/remove not allowed.")}
          removeTab={(tabName) => alert("Tab modification/remove not allowed.")}
          categories={organisation.categories}
          organisationOwners={organisation.owners}
          organisationName={organisation.name}
          organisationId={id}
          afterUpdate={fetchOrgData}
        />
      ),
      [newsabout]: (
        <News
          canBeModify={false}
          canBeRemove={false}
          editTabName={(e) => alert("Tab modification/remove not allowed.")}
          removeTab={(tabName) => alert("Tab modification/remove not allowed.")}
        />
      ),
      [contactabout]: (
        <Contact
          canBeModify={false}
          canBeRemove={false}
          {...organisation}
          editTabName={(e) => alert("Tab modification/remove not allowed.")}
          removeTab={(tabName) => alert("Tab modification/remove not allowed.")}
        />
      ),
    };

    organisation.profileConfig.tabs.forEach(({ name, hide, content }) => {
      console.log({ name, hide, content });
      let _name = name
      if (!hide) {

        if (_name==="About"){
          _name= `${orgabout}`
        }if (_name==="Teams"){
          _name=`${teamsabout}`
        }if (_name==="Calendar"){
          _name=`${calenderabout}`
        }if (_name==="News"){
          _name=`${newsabout}`
        }if (_name==="Contact"){
          _name=`${contactabout}`
        }
        // debugger
        pages[_name] = pageContent[_name] || (
          <Blank
            orgId={id}
            canBeModify={true}
            canBeRemove={true}
            tabObject={organisation.profileConfig.tabs.filter((tabData) => tabData.name === name)}
            showPreview={showPreview}
            saveContent={(e) => saveContent(e, name)}
            editTabName={(e) => editTabName(e, name)}
            removeTab={(tabName) => removeTab(tabName)}
          />
        );
        contents[name] = content ? content : [];
      }
    });

    const actions = [isOwner ? { title: `${orgmanag}`, action: () => history.push(`/organisation/${id}/manage`) } : false];
    return (
      <WidePageWithTabs
        key={orgabout}
        pages={pages}
        isActivatePageBuilder={isOwner}
        base_route={`/organisation/${id}`}
        showLogo={organisation.profileConfig.logoInBanner}
        title={organisation.name}
        label={organisation.tagline}
        actions={actions}
        cover_image={organisation.banner}
        logo={organisation.logo}
        type="organisations"
        contents={contents}
        showPreviewToggle={() => showPreviewToggle()}
        showPreview={showPreview}
        submitContent={() => submitContent()}
        addNewPage={(newTabName) => addNewPage(newTabName)}
        profileConfig={organisation.profileConfig}
        id={id}
        isPageBuilderEnabled={true}
      />
    );
  } else {
    return null;
  }
};

export default OrganisationsSingle;
