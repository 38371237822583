import React, { useState, createContext, useEffect } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import CreateOrg from "./CreateOrg/CreateOrg";
import InvitePlayers from "./InvitePlayers/InvitePlayers";
import AddOrgImages from "./AddOrgImages/AddOrgImages";
import AddOrgSocials from "./AddOrgSocials/AddOrgSocials";
import { useTransition } from "react";
import useTranslated from "../../../helpers/translationHelper";

export const CreateOrgModalContext = createContext();

const CreateOrgModal = ({ open, onClose, afterCreate }) => {
  const [step, setStep] = useState(0);
  const [orgData, setOrgData] = useState({});

  const lableStep2=useTranslated("id_pop-up_-_create_organization_13")/* step ({step + 1})/4 */
  const lableStep3=useTranslated("id_pop-up_-_create_organization_20")/* step ({step + 1})/4 */
  const lableStep4=useTranslated("id_pop-up_-_create_organization_27")/* step ({step + 1})/4 */

  
  
  useEffect(() => {
    // reset modal when it changes visibility
    setStep(0);
    setOrgData({});
  }, [open]);

  const steps = [<CreateOrg />, <InvitePlayers />, <AddOrgImages />, <AddOrgSocials />];

  const PrintingSteps = (key) => {
    switch (key+1) {
      
      case 2:
        return <span className="text-12 uppercase bold color-primary">{lableStep2/* step ({step + 1})/4 */}</span>
        break;
      case 3:
        return <span className="text-12 uppercase bold color-primary">{lableStep3/* step ({step + 1})/4 */}</span>
        break;
      case 4:
        return <span className="text-12 uppercase bold color-primary">{lableStep4/* step ({step + 1})/4 */}</span>
        break;
      default:
        break;
    }
  };

  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal" shouldCloseOnEsc={false}>
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      {step > 0 ? (
        <div className="modal-step-progress">
          {/* <span className="text-12 uppercase bold color-primary">step ({step + 1})/4</span> */}
          {/* Due to different translation of steps i created a function down to print above line} */}
          {PrintingSteps(step)}
          
         
        </div>
      ) : null}
      <CreateOrgModalContext.Provider value={{ setStep, onClose, orgData, setOrgData, afterCreate }}>
        {steps.map((component, i) => i === step && <div key={i}>{component}</div>)}
      </CreateOrgModalContext.Provider>
    </Modal>
  );
};

export default CreateOrgModal;
