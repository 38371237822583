import React, { useContext, useEffect, useState } from "react";
import { Card, List, ListItem, Icon } from "@common";
import game_settings_image from "@img/image.png";
import { TwitchPlayer } from "react-twitch-embed";
import { parseUrl } from "query-string";
import { TournamentItem, Loading } from "@common";
import { numberSuffix } from "@utils";
import { Link } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { AppContext } from "../../../../../context/AppContext";
import Tippy from "@tippyjs/react";
import { getGameBackground } from "../../../../../utils/additional-helpers";
const aboutPlaceholder = `The best 8 LoL teams in Denmark competing to win the Telia Esport Series Denmark.
<br />
<br />
The series will be Bo1 - with teams competing for 7 weeks, for 2 potential spots in the official Northern League of Legends
Championship.
<br />
<br />
Learn more about the new format here!`;

const poweredByPlaceholder = `Im a midmain and have played the game for nearly 10 years. The past 6 seasons, i have been plat or above and the past 2 seasons
i have been a diamond player jumping around the different divisions. Prefer playing control mages, but can also do assassins if
it suits the comp. Im a flexible player, but still open to learn new champions if needed. Furthermore i got a decent amount of
team experience from playing on my last team (VHE). Highest peek ever: <a target="_blank" href="https://euw.op.gg/summoner/userName=Legenden%20Lalle">D1 My op.gg</a>`;

const Overview = ({
  about,
  game,
  country,
  teamSize,
  prizes,
  teams,
  teamSlots,
  teamsToShow = 3,
  poweredBy,
  liveStreamLink,
  setPlayersDeadline = 30,
  id,
  numberOfSubs,
  discord,
  type = -1,
  tournamentFormate,
  prizeText,
  selectedGame
}) => {
  const availableSlots = teamSlots ? teamSlots - teams.length : 0;
  let channelId;
  if (liveStreamLink) {
    const { query } = parseUrl(liveStreamLink);
    if (query?.channel) channelId = query?.channel;
  }
  const [relatedLoader, setRelatedLoader] = useState(true);
  const [relatedTournaments, setRelatedTournaments] = useState([]);
  const { remountDom } = useContext(AppContext);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}/relatedTournaments`)
      .then(({ data }) => {
        // console.log("[DATA FOR REALTED TOURNAMENTS]", data);
        setRelatedTournaments(data);
      })
      .finally(() => setRelatedLoader(false));
  }, []);

  const setPlayersDeadlineTitle = () => {
    let hours =
      parseInt(setPlayersDeadline / 60) > 0
        ? parseInt(setPlayersDeadline / 60) + (parseInt(setPlayersDeadline / 60) > 1 ? " Hours " : " Hour ")
        : "";
    let mins =
      setPlayersDeadline % 60 > 0
        ? (setPlayersDeadline % 60) + (setPlayersDeadline % 60 > 1 ? " minutes" : " minute") + " before start"
        : "";
    let beforeStart = mins == "" ? " before start" : "";
    return hours + mins + beforeStart;
  };
  return (
    <div>
      <div className="row smaller-gutter-row">
        <div className="col-8 row-title">
          {channelId ? (
            <div className="col-12 p-0 m-0 mb-3">
              <TwitchPlayer height="480px" width="100%" id="twitch-player-id" channel={channelId} />
            </div>
          ) : null}
          <span className="faded bold uppercase">format</span>
          <div className="row smaller-gutter-row pb-2">
            <div className="col-4 pt-3 pb-1">
              <Card icon={<Icon name="globe-icon" />} title={`${game?.shortName}, ${country?.name ?? ""}`} subtitle="Game & Region" />
            </div>

            <div className="col-4 pt-3 pb-1">
              <Card icon={<Icon name="game-icon" />} title={tournamentFormate} subtitle="Tournament Format" />
            </div>

            <div className="col-4 pt-3 pb-1">
              <Card icon={<Icon name="trophy-icon" />} title={prizeText ? prizeText : "Not set"} subtitle="Prize pool" />
            </div>

            <div className="col-4 pt-3 pb-1">
              <Card
                icon={<Icon name="trophy-icon" />}
                title={`${teamSize}v${teamSize} ${
                  numberOfSubs ? "+ " + numberOfSubs + (numberOfSubs > 1 ? " substitutes" : " substitute") : ""
                }`}
                subtitle="Team size"
              />
            </div>

            <div className="col-4 pt-3 pb-1">
              <Card icon={<Icon name="cash-icon" />} title="Free to enter" subtitle="Entry fee" />
            </div>

            <div className="col-4 pt-3 pb-1">
              <Card icon={<Icon name="clock-icon" />} title={setPlayersDeadlineTitle()} subtitle="Check-in period" />
            </div>
          </div>

          <div className="row smaller-gutter-row pb-2 pt-2">
            <div className="col-12 flex-row">
              {teams.length !== 0 ? (
                <>
                  {teams.map((el, i) =>
                    i < teamsToShow ? (
                      <div key={i} className="round-img-item">
                        <a data-tip data-for={`tip_${i}`}>
                          <img className="img" src={el?.team?.logo} />
                        </a>
                        <ReactTooltip id={`tip_${i}`}>
                          <span>{el?.team?.name}</span>
                        </ReactTooltip>
                      </div>
                    ) : null
                  )}
                  {teams.length > teamsToShow ? (
                    <>
                      <div data-tip data-for="other_teams" className="round-img-item primary">
                        +{teams.length - teamsToShow}
                      </div>
                      <ReactTooltip id="other_teams">
                        <ul className="team-list-tooltip">
                          {teams.map((team, index) =>
                            index >= teamsToShow ? (
                              <li>
                                {" "}
                                <span>{team?.team?.name}</span>
                              </li>
                            ) : null
                          )}
                        </ul>
                      </ReactTooltip>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>

          <div className="row smaller-gutter-row pt-1">
            <div className="col-4">
              <div className="faded text-14 pb-1">Registered teams</div>
              <div className="text-14">{teams.length ?? "No teams registered"}</div>
            </div>

            <div className="col-4">
              <div className="faded text-14 pb-1">Available slots</div>
              <div className="text-14">{availableSlots}</div>
            </div>
          </div>

          {about ? (
            <>
              <div className="faded bold uppercase pt-5">Information</div>

              <p dangerouslySetInnerHTML={{ __html: about }} className="text-14"></p>
            </>
          ) : null}

          {poweredBy ? (
            <>
              <div className="faded bold uppercase pt-5">Powered by</div>
              <p dangerouslySetInnerHTML={{ __html: poweredBy }} className="text-14"></p>
            </>
          ) : null}
        </div>
        <div className="col-4">
          {prizes.length !== 0 ? (
            <>
              <span className="faded bold uppercase">Prizes</span>
              <List>
                {prizes.map((prize, i) =>
                  i < 3 ? (
                    <ListItem key={i}>
                      <div className="row smaller-gutter-row">
                        <div className="col-6 flex-row">
                          <Icon
                            name={
                              i === 0 ? "trophy-icon-gold" : i === 1 ? "trophy-icon-silver" : i === 2 ? "trophy-icon-bronze" : "trophy-icon"
                            }
                          />
                          <div className="bold pl-3 text-14">
                            {i + 1} {numberSuffix(i + 1)} prize
                          </div>
                        </div>

                        <div className="col-6 right">
                          <span className="bold text-14">{prize}</span>
                        </div>
                      </div>
                    </ListItem>
                  ) : null
                )}
              </List>
            </>
          ) : null}

          <Link
            to={`/tournament/${id}/prizes`}
            className="primary-link text-14"
            style={{ display: "block", width: "100%", textAlign: "center" }}
          >
            See all prizes
          </Link>

          {discord ? (
            <div className="row smaller-gutter-row mt-2">
              <div className="col-12">
                <a
                  href={discord}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="primary-bg-link"
                  style={{ backgroundColor: getGameBackground() }}
                >
                  <span>
                    <Icon name={game.name === "CS:GO" ? "discord-icon-csgo" : "discord-icon"} />
                    {/* Discord server: */}
                    <span className="ml-2 link">{discord}</span>
                  </span>
                  <Icon name={game.name === "CS:GO" ? "external-primary-csgo" : "external-primary"} />
                </a>
              </div>
            </div>
          ) : null}

          {liveStreamLink ? (
            <div className="row smaller-gutter-row mt-2">
              <div className="col-12">
                <a
                  href={liveStreamLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="primary-bg-link"
                  style={{ background: "#110c24", color: "#883ef0", marginTop: 10 /* twitch brand colours */ }}
                >
                  <span>
                    <Icon name="twitch-coloured" />
                    {/* Discord server: */}
                    <span className="ml-2 link">{liveStreamLink}</span>
                  </span>
                  <Icon name="external-primary" />
                </a>
              </div>
            </div>
          ) : null}
          {selectedGame.shortName !== 'VL' &&
            <>
              <div className="faded bold uppercase mt-5">Game Settings</div>

              <img src={game_settings_image} className="block-image mt-2 mb-2" alt="" />

              <div className="text-14 text-faded mt-3 mb-1">Map</div>
              <div className="text-14">Summoners Rift</div>
              <div className="text-14 text-faded mt-3 mb-1">Pick mode</div>
              <div className="text-14">Draft Pick</div>
              <div className="text-14 text-faded mt-3 mb-1">Game mode</div>
              <div className="text-14">Destroy Nexus</div>
            </>
          }
        </div>

        {relatedTournaments && relatedTournaments.length !== 0 ? (
          <div className="col-12">
            <hr className="mt-5" />

            <h3 className="text-faded uppercase">Other Tournaments</h3>

            <div className="row smaller-gutter-row">
              {relatedLoader ? (
                <div className="col-12 text-center">
                  <Loading />
                </div>
              ) : relatedTournaments && relatedTournaments.length !== 0 ? (
                relatedTournaments.map(
                  ({ thumbnailImage, name, gameServer = "EUW", startDate, _id, type = -1, prizes, registerationDeadline }) => {
                    let subtitle = "";
                    if (gameServer) subtitle += gameServer + " - 5V5 ";
                    if (prizes && prizes.length !== 0) subtitle += "- " + prizes[0];
                    if (type !== -1) subtitle += " - " + getGameType(type);

                    const _date = moment(startDate);
                    const date =
                      _date.format("DD/MM/YYYY") !== moment().format("DD/MM/YYYY")
                        ? _date.format("DD MMM YYYY") + " at " + _date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr()
                        : "Today at " + _date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr();
                    const _reg_date = registerationDeadline ? moment(registerationDeadline) : undefined;
                    const reg_date = registerationDeadline
                      ? _reg_date.format("DD/MM/YYYY") !== moment().format("DD/MM/YYYY")
                        ? _reg_date.format("DD MMM YYYY") +
                          " at " +
                          _reg_date.format("HH:mm") +
                          " " +
                          moment.tz(moment.tz.guess()).zoneAbbr()
                        : "Today at " + _reg_date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr()
                      : undefined;
                    return (
                      <TournamentItem
                        key={_id}
                        onClick={remountDom}
                        link_to={`/tournament/${_id}?name=${name}`}
                        image={thumbnailImage}
                        title={name}
                        subtitle={subtitle}
                        color="red"
                        date={"Starting: " + date}
                        reg_date={reg_date ? "Registration: " + reg_date : undefined}
                        startTime={startDate}
                      />
                    );
                  }
                )
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Overview;

const getGameType = (type) => {
  switch (type) {
    case -1:
      return "Not set";
    case 1:
      return "Single elimination";
    case 2:
      return "Double elimination";
    case 3:
      return "King of the hill";
  }
};
