import React from "react";
import MultistageMatchListingByDate from "../../../../_common/MultistageMatchListingByDate/MultistageMatchListingByDate";

const Matches = ({ league, id }) => {

  return (
    <React.Fragment>
      <MultistageMatchListingByDate id={id} page={"LEAGUE"} fullScreen={true} />
    </React.Fragment>
  );
};

export default Matches;