import React, { useState, useEffect, useRef }  from "react";
import { SearchInput } from "@common";
import PlayerItem from './PlayerItem';
import DynamicSearch from "@common/DynamicSearch/DynamicSearch";
import { RiCloseLine } from "react-icons/ri";
import Axios from "axios";
import { toast } from "react-toastify";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const Overview = () => {
  const timeout = useRef();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [championsData, setChampionsData] = useState([]);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedMembers((items) => arrayMove(items, oldIndex, newIndex));
  };

  const onSearch = async (query) => {
    if (!query.length) return setResults([]);
    setLoading(true);

    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch`, {
        query,
        include: "players",
      });
      
      // Map data to required output
      // Remove
      //  - already selected players
      //  - already added members & already invited members
      const searchResults = data
        .filter(({ _id }) => !selectedMembers.map(({ _id }) => _id).includes(_id))
        .map(({ _id, avatarImage, confidenceScore, img, name, user, type }) => ({
          _id,
          type,
          img,
          title: name,
          subtitle: user ? `${user.firstName} ${user.lastName}` : false,
          avatarImage, 
          confidenceScore,
          user
        }));
      setResults(searchResults);
      setLoading(false);
    }, 500);
  };
// console.log('selectedMembers-',selectedMembers)
  const addMember = (player) => {
    // console.log('player',player);
    setResults(false);
    // let playerAdvData = getPlayerData(player._id)
    setSelectedMembers([...selectedMembers, { ...player, role: 'Player' }]);
  };

  const removeSelectedPlayer = (playerIndex) => {
    setSelectedMembers(selectedMembers.filter((_, i) => i !== playerIndex));
}


const SortableItem = SortableElement(({ i, title }) => (
  <span style={{'cursor': 'move'}} className={`reorder-item`} key={i+'pill'} class='pill'>
          {title} {' '} <i key={i+'pill-i'} onClick={() => removeSelectedPlayer(i)} class="fa fa-times"></i>
        </span>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <div className="reorder-list">
      {selectedMembers.map((item, i) => (
        <SortableItem key={`item-${item._id}`} index={i} i={i} {...item} />
      ))}
    </div>
  );
});


  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_LOL_API}/api/riot/champions`).then(({ data }) => {
      setChampionsData(data);
    });
  }, []);

  return (
    <div>
      <DynamicSearch
            placeholder="Search for team members"
            onSearch={onSearch}
            onSelectResult={addMember}
            loadingResults={loading}
            results={results}
            mixedResults={true}
          /><br />

      <SortableList items={selectedMembers} onSortEnd={onSortEnd} axis='xy' />
   
      <div className="row smaller-gutter-row pt-3">
        <div className="col-12 pb-3">
          <button className="button default mr-3">All queues</button>
          <button className="button default mr-3">Season 10</button>
          <button className="button transparent mr-3">Reset</button>
        </div>
        {selectedMembers.length > 0 ? selectedMembers.map((item) => (
          <div className="col-3" key={item._id}>
            <PlayerItem key={item._id} item={item} playerId={item._id}  />
          </div>
        )) : <div className="center mt-4">
        <p>Currently! No players selected.</p>
      </div>}
      </div>
    </div>
  );
};

export default Overview;
