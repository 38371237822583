import React, {useState, useLayoutEffect} from "react";
import vars from "./../../../styles/_variables.scss";
import default_team_cover from "@img/teams-single-bg.png";
import useTranslated from "../../../helpers/translationHelper";
import { Tabs } from "@common";

const WidePageWithTabs = ({
  type,
  pages,
  cover_image,
  logo,
  fullLogo,
  showLogo = true,
  title,
  label,
  base_route,
  tab_link,
  actions = [],
  contents,
  showPreviewToggle,
  isActivatePageBuilder,
  showPreview,
  submitContent,
  addNewPage,
  profileConfig,
  id,
  isPageBuilderEnabled,
  game = null,
  croppedLogo,
  nationality = null,
}) => {
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 1280) {
        setHeight(window.innerWidth/6)
      } else {
        setHeight(213);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  console.log("logoCrop-", croppedLogo);
  const Activemanag=useTranslated('id_organisation_page_6')

  return (
    <div className={"container-fluid wide-page-with-tabs " + type + " uni-space"}>
      <div className="row n-ml-mr" style={type === "teams" ? {marginBottom: (height - 233)} : { marginBottom: (height - 150)}}>
        <div className={"top-image-wrapper " + `top-image-wrapper-for-${type}`}>
          <div
            className={"top-image " + `top-image-for-${type}`}
            style={{
              height: `${height}px`,
              transitionProperty: height, transitionDuration: .5, transitionDelay: .2,
              backgroundImage: `linear-gradient(to top, ${vars.content_bg_color} ${showLogo ? "15%" : "0%"}, rgba(3, 6, 13, 0) 140%), url(${
                cover_image ?? default_team_cover
              })`,
            }}
          >
            {(showPreview || !isPageBuilderEnabled) &&
              actions.map(
                ({ title, action }, i) =>
                  title && (
                    <button key={i} className="button primary" onClick={action}>
                      {title}
                    </button>
                  )
              )}
            {isPageBuilderEnabled && showPreview && isActivatePageBuilder && actions.length > 0 && (
              <button key={"q221-21312"} className="button primary" onClick={() => showPreviewToggle()}>
                {Activemanag}
              </button>
            )}

            {!showPreview && isPageBuilderEnabled && actions.length > 0 && (
              <>
                <button key={"q221-34252314"} className="button primary" onClick={() => submitContent()}>
                  Save Changes
                </button>
                <button key={"q221-4352345"} className="button primary" onClick={() => window.location.reload()}>
                  Cancle Changes
                </button>
              </>
            )}
          </div>
        </div>

        <div className={"col-12 wide-page-with-tabs-heading-wrapper " + type}>
          <div className="wide-page-with-tabs-heading">
            {showLogo && type === "teams" ? (
              <>
                <a href={fullLogo ?? logo} target="_blank">
                  <img
                    target="_blank"
                    className={
                      croppedLogo
                        ? "wide-page-with-tabs-logo"
                        : croppedLogo === false
                        ? "wide-page-with-tabs-logo_withoutCropped"
                        : "wide-page-with-tabs-logo"
                    }
                    alt="Team"
                    src={logo}
                  />
                </a>
                <div className="wide-page-with-tabs-heading-text">
                  <h1>{title}</h1>
                  <span className="text-faded uppercase">
                    <React.Fragment>
                      <img className="game-icon" alt="Game" src={game?.whiteIcon || game?.blackIcon} />{" "}
                      {`${game?.shortName} - ${nationality?.name}`}
                    </React.Fragment>
                  </span>
                  {label && <span className="text-faded uppercase labelForTagline">{label}</span>}
                </div>
              </>
            ) : (
              <div /*style={{ height: 100 }}*/></div>
            )}
            <div /*style={{ height: 100 }}*/></div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 tabs_fix">
          <Tabs
            isPageBuilderEnabled={isPageBuilderEnabled}
            addNewPage={addNewPage}
            pages={pages}
            base_route={base_route}
            show_filter={typeof tab_link !== "undefined"}
            custom_filter={tab_link}
            contents={contents}
            showPreview={showPreview}
            profileConfig={profileConfig}
            id={id}
          />
        </div>
      </div>
    </div>
  );
};

export default WidePageWithTabs;
