import React, { useEffect, useState } from "react";
import './_steps.scss'

const StepTabs = ({pages, current}) => {
  let defaultPage = pages[0].number;

  const [currentPage, setCurrentPage] = useState(current ? current : defaultPage);

  useEffect(() => {
    setCurrentPage(current)
  }, [current])

const pageData = current >= pages.length ? pages[current - 1] : pages.find(page => page.number == currentPage)

  return pages && pages.length ? (
    <div className="tabs-container">
      <div className="tabs-nav veto-steps">
        <nav className="_nav">
          {pages.map(
            (page) =>
              !page.final && (
                <button
                  className={`veto-step-tab px-0 ${currentPage === page.number ? "current" : currentPage < page.number ? "next" : "past"}`}
                  disabled={true}
                  style={{ pointerEvents: "none" }}
                >
                  {page.title}
                </button>
              )
          )}
        </nav>
      </div>
      {pageData && (
        <div key={pageData.number} className="tab-content">
          {pageData.content}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default StepTabs;
