import React, { useContext, useState } from "react";
import { Card, Icon } from "@common";
import TeamsItemScrimSingle from "@components/TeamsRouter/TeamsItem/TeamsItemScrimSingle";
import { AppContext } from "@context/AppContext";
import { EditScrimModal } from "../../../../_modals/EditScrimModal/EditScrimModal";
import { getTeamsAvgRank, pickModeOptions } from "@utils";
import { ordinalSuffixOf } from "../../../../../utils/additional-helpers";

const Overview = ({ data, scrimDetails }) => {
  const { authenticated, user, selectedGame } = useContext(AppContext);
  const [editScrim, setEditScrim] = useState(false);
  const { size, team, checkInPeriod, createdBy, game, map, pickMode, numberOfMatches, deleteScrim, division } = data;
  const isAllowedToEdit = () => {
    const {
      members,
      organisation: { owners },
    } = scrimDetails.team;
    let userId = user.state._id;
    let coachOrManager = members.filter((m) => (m.user && m.user._id === userId) || (m.coach && m.coach.user._id === userId));
    let owner = owners.filter((o) => o.user && o.user._id === userId);
    if (createdBy._id === userId || coachOrManager.length > 0 || owner.length > 0) return true;
    return false;
  };

  return (
    <div>
      <div className="row smaller-gutter-row">
        <div className="col-8 row-title">
          <span className="faded bold uppercase">format</span>
          <div className="row smaller-gutter-row">
            <div className="col-4 pt-3 pb-3">
              <Card
                icon={<Icon name="globe-icon" />}
                title={`${selectedGame.shortName === "CSGO" ? "CS:GO" : "LOL, EUW"}`}
                subtitle={selectedGame.shortName === "CSGO" ? "Game" : "Game & Region"}
              />
            </div>

            <div className="col-4 pt-3 pb-3">
              <Card
                icon={<Icon name="game-icon" />}
                title={`${numberOfMatches} ${numberOfMatches > 1 ? "Matches" : "Match"}`}
                subtitle="Number of matches"
              />
            </div>

            <div className="col-4 pt-3 pb-3">
              <Card icon={<Icon name="trophy-icon" />} title={size} subtitle="Team size" />
            </div>

            <div className="col-4 pt-3 pb-3">
              <Card icon={<Icon name="game-icon" />} title={checkInPeriod} subtitle="Check-in period" />
            </div>

            <div className="col-4 pt-3 pb-3">
              {selectedGame.shortName === "CSGO" ? (
                <Card icon={<Icon name="game-icon" />} title={ordinalSuffixOf(division) + " division"} subtitle="Division" />
              ) : (
                <Card icon={<Icon name="game-icon" />} title={getTeamsAvgRank(team.members, game)} subtitle="Team rank" />
              )}
            </div>

            <div className="col-4 pt-3 pb-3">
              <Card icon={<Icon name="game-icon" />} title={`${createdBy?.firstName} ${createdBy?.lastName}`} subtitle="Team captain" />
            </div>
          </div>

          {authenticated && user.state && (isAllowedToEdit() || user.state.admin) && (
            <>
              <div className="faded bold uppercase pt-3 pb-3">Actions</div>
              {!scrimDetails.acceptedMatchUp ? (
                <button className="button primary" onClick={() => setEditScrim(true)}>
                  Edit Scrim
                </button>
              ) : null}
              <button className={`${!scrimDetails.acceptedMatchUp ? "ml-3" : ""}  button danger`} onClick={() => deleteScrim()}>
                Delete Scrim
              </button>
            </>
          )}
          <EditScrimModal scrim={scrimDetails} open={editScrim && authenticated} onClose={() => setEditScrim(false)} />
        </div>
        <div className="col-4">
          <div className="faded bold uppercase mb-2">Scrim team</div>
          <div className="row pt-2">
            <TeamsItemScrimSingle team={team} game={game} column="12" />
          </div>

          <div className="faded bold uppercase pt-3 pb-2">Game Settings</div>

          <img src={require(`@img/${map}.png`)} className="block-image mt-2 mb-2" alt="" />

          <div className="text-14 text-faded mt-3 mb-1">Map</div>
          <div className="text-14 ">{map}</div>
          {selectedGame.shortName === "LoL" && (
            <>
              <div className="text-14 text-faded mt-3 mb-1">Pick mode</div>
              <div className="text-14 ">{pickModeOptions?.find((mode) => mode.value === pickMode)?.name}</div>
              <div className="text-14 text-faded mt-3 mb-1">Game mode</div>
              <div className="text-14 ">Destroy Nexus</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;
