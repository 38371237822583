// This custom hook get translation data form redux state and then find the translation with the help of user selected language

// WE Need to pass the unique id_local to get the tranlation
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useTranslated = (id) => {
  const [data, setData] = useState(null);
  const language = useSelector((state)=> state.LANGUAGE.Selected?.name)
  const translations = useSelector((state)=>{
    // debugger
    let lang = state.LANGUAGE.Selected._id
    let trans = state.TRANSLATION?.Translation.find(el=> el._id === lang)
    if(!trans){
      trans = state.TRANSLATION?.Translation.find(el=> el.language === 'English')
    }
    return trans?.translation
  })
  useEffect(() => {
     const found = translations?.find(el=> el.id_local === id)
     setData(found)
    }, [translations,language]);
  return data && (data.translated && data.translated !== '' ? data.translated : data.source)
};

export default useTranslated;
