import React, { useState } from "react";
import { RiCheckLine, RiFileCopyLine } from "react-icons/ri";

const UpcomingGame = ({ team1, team2, tournamentCodes = [] }) => {
  const [expanded, setExpanded] = useState(false);
  const [copied, setCopied] = useState(false);

  const copyMapCode = (code, keyNum) => {
    navigator.clipboard.writeText(code);
    setCopied(keyNum);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="game-keys-upcoming-game">
      <div className="row smaller-gutter-row team-names">
        <div className="col-6">
          <small className="text-faded">Blue side</small>
          <p className="team-name">
            <strong>{team1}</strong>
          </p>
        </div>
        <div className="col-6">
          <small className="text-faded">Red side</small>
          <p className="team-name">
            <strong>{team2}</strong>
          </p>
        </div>
      </div>

      {!expanded ? (
        <div className="center">
          <button className="button primary cta-button-transparent" onClick={() => setExpanded(true)}>
            + Show game keys
          </button>
        </div>
      ) : (
        tournamentCodes.map((code, i) => (
          <div className="row smaller-gutter-row map-keys">
            <div className="col-9">
              <small className="text-faded">Map {i + 1}</small>
              <p className="map-code">{code}</p>
            </div>
            <div className="col-3 right">
              <button className="button primary small copy-button" onClick={() => copyMapCode(code, i)}>
                {copied === i ? (
                  <>
                    <RiCheckLine /> Copied
                  </>
                ) : (
                  <>
                    <RiFileCopyLine /> Copy
                  </>
                )}
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default UpcomingGame;
