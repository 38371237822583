import io from 'socket.io-client';
import moment from "moment";

const socket = {
    _socket: undefined
}

export const connectSocket = (id, callback, onDisconnect) => {
    socket._socket = io(process.env.REACT_APP_CSGO_API);
    socket._socket.on("connect", () => {
        socket._socket.on(`veto-update-${id}`, (e) => {
            callback(e);
        });
    });
    socket._socket.on('connect_error', err => onDisconnect());
    socket._socket.on('connect_failed', err => onDisconnect());
    socket._socket.on('disconnect', err => onDisconnect());
}
export const computeRemainingTime = (matchData, vetoState, vetoData, user) => {
    const {veto,t1,t2} = matchData
    const data = {state: vetoState, timeLeft: 0 }
    let isUserinT1 = veto.userTeam == 't1'
    let isUserinT2 = (!isUserinT1 && veto.userTeam == 't2')
    const { t1ReadyTime, t2ReadyTime, t1User, t2User, teamA, teamB } = vetoData
    if((t1ReadyTime && t1User) || (t2ReadyTime && t2User)) {
        let {state, time, t1State, t2State} = parseVetoData(matchData, vetoData, user, vetoState)
        data.state = state
        data.time = time
        data.t1State = t1State
        data.t2State = t2State
    } else {
        let time = vetoState === "waiting" ?
            new Date((new Date(matchData.datetime).getTime()) - (veto.vetoStartTime * 1000)).toISOString()
            :
            (vetoState === "picking" || vetoState == "waitforopponent") ? (vetoData && vetoData.steps.length && vetoData.steps[0].startedTime) ? vetoData.steps[0].startedTime : new Date().toISOString()
            :
            matchData.datetime
        let timeDiff = moment(time).diff(moment(), "seconds")
        if (timeDiff <= 0 && vetoState === "waiting") {
            data.state = "open"
            time = matchData.datetime
        }
        data.time = time
    }
    if (data.state == "open" || data.state == "opponent") {
        if (!isUserinT1 && !isUserinT2) {
            data.state = "admin"
        }
    }
    if (matchData.scrim && vetoData.completed) {
        data.state = "steps"
    }
    return data
                    
} 
export const isTeamPlayer = (teamMembers, user) => {
    let bool = false
    teamMembers.map(member => {
        let memberUserId = member.player?.user?._id || member.coach?.user?._id
        if ((user.state._id == memberUserId)) {
            bool = true
        }
    })
    return bool

}
export const parseVetoData = (matchData, vetoData, user, vetoState, vetoStarted = false) => {
    const {veto, t1, t2} = matchData
    let isUserinT1 = veto.userTeam == 't1'
    let isUserinT2 = (!isUserinT1 && veto.userTeam == 't2')
    const { t1ReadyTime, t2ReadyTime, t1User, t2User, teamA, teamB } = vetoData
    const data = {t1State: 'waiting', t2State: 'waiting', state: vetoState, userVeto: false, time: null}
    let isUserT1Selector = (t1User && `${user.state._id}` == `${t1User}`) ? true : false
    let isUserT2Selector = (t2User && `${user.state._id}` == `${t2User}`) ? true : false
    let isT1Ready = t1ReadyTime && t1User
    let isT2Ready = t2ReadyTime && t2User 
        if (!teamA && !teamB) {
            data.t1State = isT1Ready ? "Ready for veto" : "Awaiting Acceptance"
            data.t2State = isT2Ready ? "Ready for veto" : "Awaiting Acceptance"
            if (isT1Ready && isT2Ready) {
                let readyTimeDiff = new Date(t1ReadyTime) - new Date(t2ReadyTime)
                if (moment().diff((moment(readyTimeDiff > 0 ? t1ReadyTime : t2ReadyTime)), "seconds") >= 60) {
                    data.state = isUserinT1 ? "picking" : "waitforopponent"
                    data.t1State = "Choosing Priority"
                    data.time = new Date(new Date(readyTimeDiff > 0 ? t1ReadyTime : t2ReadyTime).getTime() + (90 * 1000)).toISOString()
                    if (moment().diff(moment(data.time), "seconds") >= 0) {
                        data.state = 'steps'
                        data.t1State = "Ready for veto"
                        data.t2State = "Ready for veto"
                    }
                } else {
                    data.state = "loading"
                    data.time = new Date(new Date(readyTimeDiff > 0 ? t1ReadyTime : t2ReadyTime).getTime() + (60 * 1000)).toISOString()

                }
            } else if ((isUserinT1 && isT1Ready && !isT2Ready) || (isUserinT2 && isT2Ready && !isT1Ready)) {
                data.state = "opponent"
                data.time = matchData.datetime
            } else if ((isUserinT1 && isT2Ready && !isT1Ready) || (isUserinT2 && isT1Ready && !isT2Ready)) {
                data.state = "open"
                data.time = matchData.datetime
            }
            
        } else {
            data.state = "steps"
            data.t1State = "Ready for veto"
            data.t2State = "Ready for veto"
        }
        if (!t1ReadyTime && !t2ReadyTime && !t1User && !t2User && !teamA && !teamB) {
            let startTime =  new Date((new Date(matchData.datetime).getTime()) - (vetoData.vetoStartTime * 1000)).toISOString()
            if (moment(startTime).diff(moment(), "seconds") < 0) {
                data.state = "open"
            } else {
                data.state = "waiting"
            }
        }
        data.userVeto = isUserinT1 ? isUserT1Selector : isUserinT2 ? isUserT2Selector : false
        return data
    
}
export const disconnectSocket = () => {
    if (socket._socket)
        socket._socket.disconnect();
}
