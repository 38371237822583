import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import DynamicSearch from "@common/DynamicSearch/DynamicSearch";
import Axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import useTranslated from "../../../../../helpers/translationHelper";
const AddOwnerModal = ({ open, onClose, owners, setOwners }) => {
  const { id } = useParams();
  const timeout = useRef();
  const [loading, setLoading] = useState(false);
  const [userResults, setUserResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);


  const addMember = (player) => {
    let roles = [];
    switch (player.type) {
      case "User":
        roles = ["Manager", "Owner"];
        break;
      case "Coach":
        roles = ["Coach"];
        break;
      default:
    }
    // setSelectedUser({ ...data, role: "Owner" })}
    setSelectedMembers([...selectedMembers, { ...player, role: "Owner" }]);
  };

  const setSelectedUsersRole = (index, role) => {
    let memberData = selectedMembers;
    memberData[index].role = role;
    setSelectedMembers(memberData);
  }

  useEffect(() => {
    setUserResults([]);
    setSelectedUser(false);
  }, [open]);

  const onUsersSearch = async (query) => {
    if (!query.length) return setUserResults([]);
    setLoading(true);

    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/users/search`, { query });

      // Map data to required output, and remove current owners
      const players = data
        .filter(({ _id }) => (owners.find(({ user: currentOwner }) => currentOwner && currentOwner._id === _id) ? false : true))
        .map(({ _id, avatarImage, email, firstName, lastName, name }) => ({
          _id,
          img: avatarImage,
          title: name,
          // subtitle: email,
        }));

      setUserResults(players);
      setLoading(false);
    }, 500);
  };

  const onAddOwner = async () => {
    if (!selectedMembers) return false;

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/invites`, {
        members: selectedMembers,
        organisation: id,
      });
      toast.success("Successfully invited user");
      onClose();
    } catch (e) {
      toast.error("There was a problem inviting selected user");
    }
  };

  return (
    <Modal isOpen={open} onRequestClose={onClose} className="modal">
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>{useTranslated('id_pop-up_-_add_owner_or_manager_0')}</h2>
        <h3>{useTranslated('id_pop-up_-_add_owner_or_manager_1')}</h3>
      </div>
      <div className="modal-body">
        <DynamicSearch
          placeholder={useTranslated('id_pop-up_-_add_owner_or_manager_2')}
          onSearch={onUsersSearch}
          onSelectResult={addMember}
          // onSelectResult={(data) => setSelectedUser({ ...data, role: "Owner" })}
          loadingResults={loading}
          results={userResults}
        />

        <div className="dynamic-search-selected-items" style={{ overflow: "hidden" }}>
          {/* {selectedUser && ( */}
          {selectedMembers && selectedMembers.map((selectedUser, i) => (
            <div className="selected-item">
              <div className="item-details">
                <div className="item-img" style={{ backgroundImage: `url(${selectedUser.img})` }} />
                <p className="item-name">{selectedUser.title}</p>
              </div>
              <select
                name="role"
                id="role"
                className="form-input"
                defaultValue="_EMPTY_"
                // value={selectedUser.role}
                onChange={(e) => setSelectedUsersRole(i, e.target.value)}
              >
                <option value="_EMPTY_" disabled>Select Role</option>
                {["Manager", "Owner"].map((role) => (
                  <option value={role}>{role}</option>
                ))}
              </select>
              <button onClick={() => setSelectedMembers(selectedMembers.filter((val, key) => key !== i))}>
                <RiCloseLine size={27} />
              </button>
            </div>
          ))
          }
        </div>

        <button className="button primary thicc" onClick={onAddOwner} disabled={!selectedMembers.length}>
         {useTranslated('id_pop-up_-_add_owner_or_manager_3')}
        </button>
      </div>
    </Modal>
  );
};

export default AddOwnerModal;
