import React from "react";
import { SliderItemFront } from "@common";

const SliderItem = ({ title, second_line, third_line, tag, gameStyle, ...rest }) => {
  return (
    <SliderItemFront
      hide_time_ago={true}
      classes="slider-item frontend"
      title={title}
      above_excerpt={second_line}
      excerpt={third_line}
      tag={tag}
      gameStyle={gameStyle}
      {...rest}
    />
  );
};

export default SliderItem;
