import React, { useState, useContext, useEffect } from "react";
import useTranslated from "../helpers/translationHelper";
export const Countries =  () => {
    let _afghan_country_translation = useTranslated("id_dropdown_-_nationality_1");
    let _Ålandish_country_translation = useTranslated("id_dropdown_-_nationality_2");
    let _Albanian_country_translation = useTranslated("id_dropdown_-_nationality_3");
    let _Algerian_country_translation = useTranslated("id_dropdown_-_nationality_4");
    let _American_country_translation = useTranslated("id_dropdown_-_nationality_5");
    let _American_Samoan_country_translation = useTranslated("id_dropdown_-_nationality_6");
    let _Adorran_country_translation = useTranslated("id_dropdown_-_nationality_7");
    let _Angolan_country_translation = useTranslated("id_dropdown_-_nationality_8");
    let _Anguillian_country_translation = useTranslated("id_dropdown_-_nationality_9");
    let _Antiguan_Barbudan_country_translation = useTranslated("id_dropdown_-_nationality_10");
    let _Argentinian_country_translation = useTranslated("id_dropdown_-_nationality_11");
    let _Armenian_country_translation = useTranslated("id_dropdown_-_nationality_12");
    let _Aruban_country_translation = useTranslated("id_dropdown_-_nationality_13");
    let _Australian_country_translation = useTranslated("id_dropdown_-_nationality_14");
    let _Austrian_country_translation = useTranslated("id_dropdown_-_nationality_15");
    let _Azerbaijani_country_translation = useTranslated("id_dropdown_-_nationality_16");
    let _Bahamian_country_translation = useTranslated("id_dropdown_-_nationality_17");
    let _Bahraini_country_translation = useTranslated("id_dropdown_-_nationality_18");
    let _Bangladeshi_country_translation = useTranslated("id_dropdown_-_nationality_19");
    let _Barbadian_country_translation = useTranslated("id_dropdown_-_nationality_20");
    let _Belarusian_country_translation = useTranslated("id_dropdown_-_nationality_21");
    let _Belgian_country_translation = useTranslated("id_dropdown_-_nationality_22");
    let _Belizian_country_translation = useTranslated("id_dropdown_-_nationality_23");
    let _Beninese_country_translation = useTranslated("id_dropdown_-_nationality_24");
    let _Bermudian_country_translation = useTranslated("id_dropdown_-_nationality_25");
    let _Bhutanese_country_translation = useTranslated("id_dropdown_-_nationality_26");
    let _Bolivian_country_translation = useTranslated("id_dropdown_-_nationality_27");
    let _Bonaire_Saint_Eustatius_and_Saba_country_translation = useTranslated("id_dropdown_-_nationality_28");
    let _Bosnian_Herzegovinian_country_translation = useTranslated("id_dropdown_-_nationality_29");
    let _Brazilian_country_translation = useTranslated("id_dropdown_-_nationality_30");
    let _British_country_translation = useTranslated("id_dropdown_-_nationality_31");
    let _Bruneian_country_translation = useTranslated("id_dropdown_-_nationality_32");
    let _Bulgarian_country_translation = useTranslated("id_dropdown_-_nationality_33");
    let _Burkinabe_country_translation = useTranslated("id_dropdown_-_nationality_34");
    let _Burmese_country_translation = useTranslated("id_dropdown_-_nationality_35");
    let _Burundian_country_translation = useTranslated("id_dropdown_-_nationality_36");
    let _Cambodian_country_translation = useTranslated("id_dropdown_-_nationality_37");
    let _Cameroonian_country_translation = useTranslated("id_dropdown_-_nationality_38");
    let _Canadian_country_translation = useTranslated("id_dropdown_-_nationality_39");
    let _Cape_Verdian_country_translation = useTranslated("id_dropdown_-_nationality_40");
    let _Caymanian_country_translation = useTranslated("id_dropdown_-_nationality_41");
    let _Central_African_country_translation = useTranslated("id_dropdown_-_nationality_42");
    let _Chadian_country_translation = useTranslated("id_dropdown_-_nationality_43");
    let _Channel_Islander_country_translation = useTranslated("id_dropdown_-_nationality_44");
    let _Chilean_country_translation = useTranslated("id_dropdown_-_nationality_45");
    let _Chinese_country_translation = useTranslated("id_dropdown_-_nationality_46");
    let _Christmas_Island_country_translation = useTranslated("id_dropdown_-_nationality_47");
    let _Cocos_Islander_country_translation = useTranslated("id_dropdown_-_nationality_48");
    let _Colombian_country_translation = useTranslated("id_dropdown_-_nationality_49");
    let _Comoran_country_translation = useTranslated("id_dropdown_-_nationality_50");
    let _Congoese_country_translation = useTranslated("id_dropdown_-_nationality_51");
    let _Cook_Islander_country_translation = useTranslated("id_dropdown_-_nationality_52");
    let _Costa_Rican_country_translation = useTranslated("id_dropdown_-_nationality_53");
    let _Croatian_country_translation = useTranslated("id_dropdown_-_nationality_54");
    let _Cuban_country_translation = useTranslated("id_dropdown_-_nationality_55");
    let _Curacao_country_translation = useTranslated("id_dropdown_-_nationality_56");
    let _Cypriot_country_translation = useTranslated("id_dropdown_-_nationality_57");
    let _Czech_country_translation = useTranslated("id_dropdown_-_nationality_58");
    let _Danish_country_translation = useTranslated("id_dropdown_-_nationality_59");
    let _Djibouti_country_translation = useTranslated("id_dropdown_-_nationality_60");
    let _Dominican_country_translation = useTranslated("id_dropdown_-_nationality_61");
    let _Dutch_country_translation = useTranslated("id_dropdown_-_nationality_62");
    let _East_Timorese_country_translation = useTranslated("id_dropdown_-_nationality_63");
    let _Ecuadorean_country_translation = useTranslated("id_dropdown_-_nationality_64");
    let _Egyptian_country_translation = useTranslated("id_dropdown_-_nationality_65");
    let _Emirati_country_translation = useTranslated("id_dropdown_-_nationality_66");
    let _Equatorial_Guinean_country_translation = useTranslated("id_dropdown_-_nationality_67");
    let _Eritrean_country_translation = useTranslated("id_dropdown_-_nationality_68");
    let _Estonian_country_translation = useTranslated("id_dropdown_-_nationality_69");
    let _Ethiopian_country_translation = useTranslated("id_dropdown_-_nationality_70");
    let _Falkland_Islander_country_translation = useTranslated("id_dropdown_-_nationality_71");
    let _Faroese_country_translation = useTranslated("id_dropdown_-_nationality_72");
    let _Fijian_country_translation = useTranslated("id_dropdown_-_nationality_73");
    let _Filipino_country_translation = useTranslated("id_dropdown_-_nationality_74");
    let _Finnish_country_translation = useTranslated("id_dropdown_-_nationality_75");
    let _French_country_translation = useTranslated("id_dropdown_-_nationality_76");
    let _French_Polynesian_country_translation = useTranslated("id_dropdown_-_nationality_77");
    let _Gabonese_country_translation = useTranslated("id_dropdown_-_nationality_78");
    let _Gambian_country_translation = useTranslated("id_dropdown_-_nationality_79");
    let _Georgian_country_translation = useTranslated("id_dropdown_-_nationality_80");
    let _German_country_translation = useTranslated("id_dropdown_-_nationality_81");
    let _Ghanian_country_translation = useTranslated("id_dropdown_-_nationality_82");
    let _Gibraltar_country_translation = useTranslated("id_dropdown_-_nationality_83");
    let _Greek_country_translation = useTranslated("id_dropdown_-_nationality_84");
    let _Greenlandic_country_translation = useTranslated("id_dropdown_-_nationality_85");
    let _Grenadian_country_translation = useTranslated("id_dropdown_-_nationality_86");
    let _Guadeloupian_country_translation = useTranslated("id_dropdown_-_nationality_87");
    let _Guamanian_country_translation = useTranslated("id_dropdown_-_nationality_88");
    let _Guatemalan_country_translation = useTranslated("id_dropdown_-_nationality_89");
    let _Guinea_Bissauan_country_translation = useTranslated("id_dropdown_-_nationality_90");
    let _Guinean_country_translation = useTranslated("id_dropdown_-_nationality_91");
    let _Guyanese_country_translation = useTranslated("id_dropdown_-_nationality_92");
    let _Haitian_country_translation = useTranslated("id_dropdown_-_nationality_93");
    let _Heard_and_McDonald_Islander_country_translation = useTranslated("id_dropdown_-_nationality_94");
    let _Honduran_country_translation = useTranslated("id_dropdown_-_nationality_95");
    let _Hungarian_country_translation = useTranslated("id_dropdown_-_nationality_96");
    let _I_Kiribati_country_translation = useTranslated("id_dropdown_-_nationality_97");
    let _Icelander_country_translation = useTranslated("id_dropdown_-_nationality_98");
    let _Indian_country_translation = useTranslated("id_dropdown_-_nationality_99");
    let _Indonesian_country_translation = useTranslated("id_dropdown_-_nationality_100");
    let _Iranian_country_translation = useTranslated("id_dropdown_-_nationality_101");
    let _Iraqi_country_translation = useTranslated("id_dropdown_-_nationality_102");
    let _Irish_country_translation = useTranslated("id_dropdown_-_nationality_103");
    let _Israeli_country_translation = useTranslated("id_dropdown_-_nationality_104");
    let _Italian_country_translation = useTranslated("id_dropdown_-_nationality_105");
    let _Ivorian_country_translation = useTranslated("id_dropdown_-_nationality_106");
    let _Jamaican_country_translation = useTranslated("id_dropdown_-_nationality_107");
    let _Japanese_country_translation = useTranslated("id_dropdown_-_nationality_108");
    let _Jordanian_country_translation = useTranslated("id_dropdown_-_nationality_109");
    let _Kazakhstani_country_translation = useTranslated("id_dropdown_-_nationality_110");
    let _Kenyan_country_translation = useTranslated("id_dropdown_-_nationality_111");
    let _Kirghiz_country_translation = useTranslated("id_dropdown_-_nationality_112");
    let _Kittian_and_Nevisian_country_translation = useTranslated("id_dropdown_-_nationality_113");
    let _Kosovar_country_translation = useTranslated("id_dropdown_-_nationality_114");
    let _Kuwaiti_country_translation = useTranslated("id_dropdown_-_nationality_115");
    let _Laotian_country_translation = useTranslated("id_dropdown_-_nationality_116");
    let _Latvian_country_translation = useTranslated("id_dropdown_-_nationality_117");
    let _Lebanese_country_translation = useTranslated("id_dropdown_-_nationality_118");
    let _Liberian_country_translation = useTranslated("id_dropdown_-_nationality_119");
    let _Libyan_country_translation = useTranslated("id_dropdown_-_nationality_120");
    let _Liechtensteiner_country_translation = useTranslated("id_dropdown_-_nationality_121");
    let _Lithuanian_country_translation = useTranslated("id_dropdown_-_nationality_122");
    let _Luxembourger_country_translation = useTranslated("id_dropdown_-_nationality_123");
    let _Macedonian_country_translation = useTranslated("id_dropdown_-_nationality_124");
    let _Malagasy_country_translation = useTranslated("id_dropdown_-_nationality_125");
    let _Malawian_country_translation = useTranslated("id_dropdown_-_nationality_126");
    let _Malaysian_country_translation = useTranslated("id_dropdown_-_nationality_127");
    let _Maldivan_country_translation = useTranslated("id_dropdown_-_nationality_128");
    let _Malian_country_translation = useTranslated("id_dropdown_-_nationality_129");
    let _Maltese_country_translation = useTranslated("id_dropdown_-_nationality_130");
    let _Manx_country_translation = useTranslated("id_dropdown_-_nationality_131");
    let _Marshallese_country_translation = useTranslated("id_dropdown_-_nationality_132");
    let _Mauritanian_country_translation = useTranslated("id_dropdown_-_nationality_133");
    let _Mauritian_country_translation = useTranslated("id_dropdown_-_nationality_134");
    let _Mexican_country_translation = useTranslated("id_dropdown_-_nationality_135");
    let _Micronesian_country_translation = useTranslated("id_dropdown_-_nationality_136");
    let _Moldovan_country_translation = useTranslated("id_dropdown_-_nationality_137");
    let _Monegasque_country_translation = useTranslated("id_dropdown_-_nationality_138");
    let _Mongolian_country_translation = useTranslated("id_dropdown_-_nationality_139");
    let _Montenegrin_country_translation = useTranslated("id_dropdown_-_nationality_140");
    let _Montserratian_country_translation = useTranslated("id_dropdown_-_nationality_141");
    let _Moroccan_country_translation = useTranslated("id_dropdown_-_nationality_142");
    let _Mosotho_country_translation = useTranslated("id_dropdown_-_nationality_143");
    let _Motswana_country_translation = useTranslated("id_dropdown_-_nationality_144");
    let _Mozambican_country_translation = useTranslated("id_dropdown_-_nationality_145");
    let _Namibian_country_translation = useTranslated("id_dropdown_-_nationality_146");
    let _Nauruan_country_translation = useTranslated("id_dropdown_-_nationality_147");
    let _Nepalese_country_translation = useTranslated("id_dropdown_-_nationality_148");
    let _New_Caledonian_country_translation = useTranslated("id_dropdown_-_nationality_149");
    let _New_Zealander_country_translation = useTranslated("id_dropdown_-_nationality_150");
    let _Ni_Vanuatu_country_translation = useTranslated("id_dropdown_-_nationality_151");
    let _Nicaraguan_country_translation = useTranslated("id_dropdown_-_nationality_152");
    let _Nigerian_country_translation = useTranslated("id_dropdown_-_nationality_153");
    let _Nigerien_country_translation = useTranslated("id_dropdown_-_nationality_154");
    let _Niuean_country_translation = useTranslated("id_dropdown_-_nationality_155");
    let _Norfolk_Islander_country_translation = useTranslated("id_dropdown_-_nationality_156");
    let _North_Korean_country_translation = useTranslated("id_dropdown_-_nationality_157");
    let _Norwegian_country_translation = useTranslated("id_dropdown_-_nationality_158");
    let _Omani_country_translation = useTranslated("id_dropdown_-_nationality_159");
    let _Pakistani_country_translation = useTranslated("id_dropdown_-_nationality_160");
    let _Palauan_country_translation = useTranslated("id_dropdown_-_nationality_161");
    let _Palestinian_country_translation = useTranslated("id_dropdown_-_nationality_162");
    let _Panamanian_country_translation = useTranslated("id_dropdown_-_nationality_163");
    let _Papua_New_Guinean_country_translation = useTranslated("id_dropdown_-_nationality_164");
    let _Paraguayan_country_translation = useTranslated("id_dropdown_-_nationality_165");
    let _Peruvian_country_translation = useTranslated("id_dropdown_-_nationality_166");
    let _Pitcairn_Islander_country_translation = useTranslated("id_dropdown_-_nationality_167");
    let _Polish_country_translation = useTranslated("id_dropdown_-_nationality_168");
    let _Portuguese_country_translation = useTranslated("id_dropdown_-_nationality_169");
    let _Puerto_Rican_country_translation = useTranslated("id_dropdown_-_nationality_170");
    let _Qatari_country_translation = useTranslated("id_dropdown_-_nationality_171");
    let _Romanian_country_translation = useTranslated("id_dropdown_-_nationality_172");
    let _Russian_country_translation = useTranslated("id_dropdown_-_nationality_173");
    let _Rwandan_country_translation = useTranslated("id_dropdown_-_nationality_174");
    let _Sahrawi_country_translation = useTranslated("id_dropdown_-_nationality_175");
    let _Sant_Barthélemy_Islander_country_translation = useTranslated("id_dropdown_-_nationality_176");
    let _Saint_Helenian_country_translation = useTranslated("id_dropdown_-_nationality_177");
    let _Saint_Lucian_country_translation = useTranslated("id_dropdown_-_nationality_178");
    let _Saint_Martin_Islander_country_translation = useTranslated("id_dropdown_-_nationality_179");
    let _Saint_Vincentian_country_translation = useTranslated("id_dropdown_-_nationality_180");
    let _Salvadoran_country_translation = useTranslated("id_dropdown_-_nationality_181");
    let _Sammarinese_country_translation = useTranslated("id_dropdown_-_nationality_182");
    let _Samoan_country_translation = useTranslated("id_dropdown_-_nationality_183");
    let _Sao_Tomean_country_translation = useTranslated("id_dropdown_-_nationality_184");
    let _Saudi_Arabian_country_translation = useTranslated("id_dropdown_-_nationality_185");
    let _Scotland_country_translation = useTranslated("id_dropdown_-_nationality_186");
    let _Senegalese_country_translation = useTranslated("id_dropdown_-_nationality_187");
    let _Serbian_country_translation = useTranslated("id_dropdown_-_nationality_188");
    let _Seychellois_country_translation = useTranslated("id_dropdown_-_nationality_189");
    let _Sierra_Leonian_country_translation = useTranslated("id_dropdown_-_nationality_190");
    let _Singaporean_country_translation = useTranslated("id_dropdown_-_nationality_191");
    let _Sint_Maarten_country_translation = useTranslated("id_dropdown_-_nationality_192");
    let _Slovak_country_translation = useTranslated("id_dropdown_-_nationality_193");
    let _Slovene_country_translation = useTranslated("id_dropdown_-_nationality_194");
    let _Solomon_Islander_country_translation = useTranslated("id_dropdown_-_nationality_195");
    let _Somali_country_translation = useTranslated("id_dropdown_-_nationality_196");
    let _South_African_country_translation = useTranslated("id_dropdown_-_nationality_197");
    let _South_Georgia_and_the_South_Sandwich_Islander_country_translation = useTranslated("id_dropdown_-_nationality_198");
    let _South_Korean_country_translation = useTranslated("id_dropdown_-_nationality_199");
    let _South_Sudanese_country_translation = useTranslated("id_dropdown_-_nationality_200");
    let _Spanish_country_translation = useTranslated("id_dropdown_-_nationality_201");
    let _Sri_Lankan_country_translation = useTranslated("id_dropdown_-_nationality_202");
    let _Sudanese_country_translation = useTranslated("id_dropdown_-_nationality_203");
    let _Surinamer_country_translation = useTranslated("id_dropdown_-_nationality_204");
    let _Swazi_country_translation = useTranslated("id_dropdown_-_nationality_205");
    let _Swedish_country_translation = useTranslated("id_dropdown_-_nationality_206");
    let _Swiss_country_translation = useTranslated("id_dropdown_-_nationality_207");
    let _Syrian_country_translation = useTranslated("id_dropdown_-_nationality_208");
    let _Tadzhik_country_translation = useTranslated("id_dropdown_-_nationality_209");
    let _Taiwanese_country_translation = useTranslated("id_dropdown_-_nationality_210");
    let _Tanzanian_country_translation = useTranslated("id_dropdown_-_nationality_211");
    let _Thai_country_translation = useTranslated("id_dropdown_-_nationality_212");
    let _Togolese_country_translation = useTranslated("id_dropdown_-_nationality_213");
    let _Tokelauan_country_translation = useTranslated("id_dropdown_-_nationality_214");
    let _Tongan_country_translation = useTranslated("id_dropdown_-_nationality_215");
    let _Tinidadian_country_translation = useTranslated("id_dropdown_-_nationality_216");
    let _Tunisian_country_translation = useTranslated("id_dropdown_-_nationality_217");
    let _Turkish_country_translation = useTranslated("id_dropdown_-_nationality_218");
    let _Turkmen_country_translation = useTranslated("id_dropdown_-_nationality_219");
    let _Turks_and_Caicos_Islander_country_translation = useTranslated("id_dropdown_-_nationality_220");
    let _Tuvaluan_country_translation = useTranslated("id_dropdown_-_nationality_221");
    let _Ugandan_country_translation = useTranslated("id_dropdown_-_nationality_222");
    let _Ukrainian_country_translation = useTranslated("id_dropdown_-_nationality_223");
    let _Uruguayan_country_translation = useTranslated("id_dropdown_-_nationality_224");
    let _Uzbekistani_country_translation = useTranslated("id_dropdown_-_nationality_225");
    let _Venezuelan_country_translation = useTranslated("id_dropdown_-_nationality_226");
    let _Vietnamese_country_translation = useTranslated("id_dropdown_-_nationality_227");
    let _Virgin_Islander_country_translation = useTranslated("id_dropdown_-_nationality_228");
    let _Wales_country_translation = useTranslated("id_dropdown_-_nationality_229");
    let _Wallis_and_Futuna_Islander_country_translation = useTranslated("id_dropdown_-_nationality_230");
    let _Yemeni_country_translation = useTranslated("id_dropdown_-_nationality_231");
    let _Zambian_country_translation = useTranslated("id_dropdown_-_nationality_232");
    let _Zimbabwean_country_translation = useTranslated("id_dropdown_-_nationality_233");
    if(_Zimbabwean_country_translation !== null){

      return [
        {
      alpha2Code: "AF",
      demonym: `${_afghan_country_translation}`,
    },
    {
      alpha2Code: "AX",
      demonym: `${_Ålandish_country_translation}`,
    },
    {
      alpha2Code: "AL",
      demonym: `${_Albanian_country_translation}`,
    },
    {
      alpha2Code: "DZ",
      demonym: `${_Algerian_country_translation}`,
    },
    {
      alpha2Code: "AS",
      demonym: `${_American_country_translation}`,
    },
    {
      alpha2Code: "AD",
      demonym: `${_American_Samoan_country_translation}`,
    },
    {
      alpha2Code: "AO",
      demonym: `${_Adorran_country_translation}`,
    },
    {
      alpha2Code: "AI",
      demonym: `${_Angolan_country_translation}`,
    },
    {
      alpha2Code: "AQ",
      demonym: `${_Anguillian_country_translation}`,
    },
    {
      alpha2Code: "AG",
      demonym: `${_Antiguan_Barbudan_country_translation}`,
    },
    {
      alpha2Code: "AR",
      demonym: `${_Argentinian_country_translation}`,
    },
    {
      alpha2Code: "AM",
      demonym: `${_Armenian_country_translation}`,
    },
    {
      alpha2Code: "AW",
      demonym: `${_Aruban_country_translation}`,
    },
    {
      alpha2Code: "AU",
      demonym: `${_Australian_country_translation}`,
    },
    {
      alpha2Code: "AT",
      demonym: `${_Austrian_country_translation}`,
    },
    {
      alpha2Code: "AZ",
      demonym: `${_Azerbaijani_country_translation}`,
    },
    {
      alpha2Code: "BS",
      demonym: `${_Bahamian_country_translation}`,
    },
    {
      alpha2Code: "BH",
      demonym: `${_Bahraini_country_translation}`,
    },
    {
      alpha2Code: "BD",
      demonym: `${_Bangladeshi_country_translation}`,
    },
    {
      alpha2Code: "BB",
      demonym: `${_Barbadian_country_translation}`,
    },
    {
      alpha2Code: "BY",
      demonym: `${_Belarusian_country_translation}`,
    },
    {
      alpha2Code: "BE",
      demonym: `${_Belgian_country_translation}`,
    },
    {
      alpha2Code: "BZ",
      demonym: `${_Belizian_country_translation}`,
    },
    {
      alpha2Code: "BJ",
      demonym: `${_Beninese_country_translation}`,
    },
    {
      alpha2Code: "BM",
      demonym: `${_Bermudian_country_translation}`,
    },
    {
      alpha2Code: "BT",
      demonym: `${_Bhutanese_country_translation}`,
    },
    {
      alpha2Code: "BO",
      demonym: `${_Bolivian_country_translation}`,
    },
    {
      alpha2Code: "BQ",
      demonym: `${_Bonaire_Saint_Eustatius_and_Saba_country_translation}`,
    },
    {
      alpha2Code: "BA",
      demonym: `${_Bosnian_Herzegovinian_country_translation}`,
    },
    {
      alpha2Code: "BW",
      demonym: `${_Brazilian_country_translation}`,
    },
    {
      alpha2Code: "BV",
      demonym: `${_British_country_translation}`,
    },
    {
      alpha2Code: "BR",
      demonym: `${_Bruneian_country_translation}`,
    },
    {
      alpha2Code: "IO",
      demonym: `${_Bulgarian_country_translation}`,
    },
    {
      alpha2Code: "UM",
      demonym: `${_Burkinabe_country_translation}`,
    },
    {
      alpha2Code: "VG",
      demonym: `${_Burmese_country_translation}`,
    },
    {
      alpha2Code: "VI",
      demonym: `${_Burundian_country_translation}`,
    },
    {
      alpha2Code: "BN",
      demonym: `${_Cambodian_country_translation}`,
    },
    {
      alpha2Code: "BG",
      demonym: `${_Cameroonian_country_translation}`,
    },
    {
      alpha2Code: "BF",
      demonym: `${_Canadian_country_translation}`,
    },
    {
      alpha2Code: "BI",
      demonym: `${_Cape_Verdian_country_translation}`,
    },
    {
      alpha2Code: "KH",
      demonym: `${_Caymanian_country_translation}`,
    },
    {
      alpha2Code: "CM",
      demonym: `${_Central_African_country_translation}`,
    },
    {
      alpha2Code: "CA",
      demonym: `${_Chadian_country_translation}`,
    },
    {
      alpha2Code: "CV",
      demonym: `${_Channel_Islander_country_translation}`,
    },
    {
      alpha2Code: "KY",
      demonym: `${_Chilean_country_translation}`,
    },
    {
      alpha2Code: "CF",
      demonym: `${_Chinese_country_translation}`,
    },
    {
      alpha2Code: "TD",
      demonym: `${_Christmas_Island_country_translation}`,
    },
    {
      alpha2Code: "CL",
      demonym: `${_Cocos_Islander_country_translation}`,
    },
    {
      alpha2Code: "CN",
      demonym: `${_Colombian_country_translation}`,
    },
    {
      alpha2Code: "CX",
      demonym: `${_Comoran_country_translation}`,
    },
    {
      alpha2Code: "CC",
      demonym: `${_Congoese_country_translation}`,
    },
    {
      alpha2Code: "CO",
      demonym: `${_Cook_Islander_country_translation}`,
    },
    {
      alpha2Code: "KM",
      demonym: `${_Costa_Rican_country_translation}`,
    },
    {
      alpha2Code: "CG",
      demonym: `${_Croatian_country_translation}`,
    },
    {
      alpha2Code: "CD",
      demonym: `${_Cuban_country_translation}`,
    },
    {
      alpha2Code: "CK",
      demonym: `${_Curacao_country_translation}`,
    },
    {
      alpha2Code: "CR",
      demonym: `${_Cypriot_country_translation}`,
    },
    {
      alpha2Code: "HR",
      demonym: `${_Czech_country_translation}`,
    },
    {
      alpha2Code: "CU",
      demonym: `${_Danish_country_translation}`,
    },
    {
      alpha2Code: "CW",
      demonym: `${_Djibouti_country_translation}`,
    },
    {
      alpha2Code: "CY",
      demonym: `${_Dominican_country_translation}`,
    },
    {
      alpha2Code: "CZ",
      demonym: `${_Dutch_country_translation}`,
    },
    {
      alpha2Code: "DK",
      demonym: `${_East_Timorese_country_translation}`,
    },
    {
      alpha2Code: "DJ",
      demonym: `${_Ecuadorean_country_translation}`,
    },
    {
      alpha2Code: "DM",
      demonym: `${_Egyptian_country_translation}`,
    },
    {
      alpha2Code: "DO",
      demonym: `${_Emirati_country_translation}`,
    },
    {
      alpha2Code: "EC",
      demonym: `${_Equatorial_Guinean_country_translation}`,
    },
    {
      alpha2Code: "EG",
      demonym: `${_Eritrean_country_translation}`,
    },
    {
      alpha2Code: "SV",
      demonym: `${_Estonian_country_translation}`,
    },
    {
      alpha2Code: "GQ",
      demonym: `${_Ethiopian_country_translation}`,
    },
    {
      alpha2Code: "ER",
      demonym: `${_Falkland_Islander_country_translation}`,
    },
    {
      alpha2Code: "EE",
      demonym: `${_Faroese_country_translation}`,
    },
    {
      alpha2Code: "ET",
      demonym: `${_Fijian_country_translation}`,
    },
    {
      alpha2Code: "FK",
      demonym: `${_Filipino_country_translation}`,
    },
    {
      alpha2Code: "FO",
      demonym: `${_Finnish_country_translation}`,
    },
    {
      alpha2Code: "FJ",
      demonym: `${_French_country_translation}`,
    },
    {
      alpha2Code: "FI",
      demonym: `${_French_Polynesian_country_translation}`,
    },
    {
      alpha2Code: "FR",
      demonym: `${_Gabonese_country_translation}`,
    },
    {
      alpha2Code: "GF",
      demonym: `${_Gambian_country_translation}`,
    },
    {
      alpha2Code: "PF",
      demonym: `${_Georgian_country_translation}`,
    },
    {
      alpha2Code: "TF",
      demonym: `${_German_country_translation}`,
    },
    {
      alpha2Code: "GA",
      demonym: `${_Ghanian_country_translation}`,
    },
    {
      alpha2Code: "GM",
      demonym: `${_Gibraltar_country_translation}`,
    },
    {
      alpha2Code: "GE",
      demonym: `${_Greek_country_translation}`,
    },
    {
      alpha2Code: "DE",
      demonym: `${_Greenlandic_country_translation}`,
    },
    {
      alpha2Code: "GH",
      demonym: `${_Grenadian_country_translation}`,
    },
    {
      alpha2Code: "GI",
      demonym: `${_Guadeloupian_country_translation}`,
    },
    {
      alpha2Code: "GR",
      demonym: `${_Guamanian_country_translation}`,
    },
    {
      alpha2Code: "GL",
      demonym: `${_Guatemalan_country_translation}`,
    },
    {
      alpha2Code: "GD",
      demonym: `${_Guinea_Bissauan_country_translation}`,
    },
    {
      alpha2Code: "GP",
      demonym: `${_Guinean_country_translation}`,
    },
    {
      alpha2Code: "GU",
      demonym: `${_Guyanese_country_translation}`,
    },
    {
      alpha2Code: "GT",
      demonym: `${_Haitian_country_translation}`,
    },
    {
      alpha2Code: "GG",
      demonym: `${_Heard_and_McDonald_Islander_country_translation}`,
    },
    {
      alpha2Code: "GN",
      demonym: `${_Honduran_country_translation}`,
    },
    {
      alpha2Code: "GW",
      demonym: `${_Hungarian_country_translation}`,
    },
    {
      alpha2Code: "SCT",
      demonym: `${_I_Kiribati_country_translation}`,
    },
    {
      alpha2Code: "WAL",
      demonym: `${_Icelander_country_translation}`,
    },
    {
      alpha2Code: "GY",
      demonym: `${_Indian_country_translation}`,
    },
    {
      alpha2Code: "HT",
      demonym: `${_Indonesian_country_translation}`,
    },
    {
      alpha2Code: "HM",
      demonym: `${_Iranian_country_translation}`,
    },
    {
      alpha2Code: "VA",
      demonym: `${_Iraqi_country_translation}`,
    },
    {
      alpha2Code: "HN",
      demonym: `${_Irish_country_translation}`,
    },
    {
      alpha2Code: "HK",
      demonym: `${_Israeli_country_translation}`,
    },
    {
      alpha2Code: "HU",
      demonym: `${_Italian_country_translation}`,
    },
    {
      alpha2Code: "IS",
      demonym: `${_Ivorian_country_translation}`,
    },
    {
      alpha2Code: "IN",
      demonym: `${_Jamaican_country_translation}`,
    },
    {
      alpha2Code: "ID",
      demonym: `${_Japanese_country_translation}`,
    },
    {
      alpha2Code: "CI",
      demonym: `${_Jordanian_country_translation}`,
    },
    {
      alpha2Code: "IR",
      demonym: `${_Kazakhstani_country_translation}`,
    },
    {
      alpha2Code: "IQ",
      demony: `${_Kenyan_country_translation}`,
    },
    {
      alpha2Code: "IE",
      demony: `${_Kirghiz_country_translation}`,
    },
    {
      alpha2Code: "IM",
      demon: `${_Kittian_and_Nevisian_country_translation}`,
    },
    {
      alpha2Code: "IL",
      demonym: `${_Kosovar_country_translation}`,
    },
    {
      alpha2Code: "IT",
      demonym: `${_Kuwaiti_country_translation}`,
    },
    {
      alpha2Code: "JM",
      demonym: `${_Laotian_country_translation}`,
    },
    {
      alpha2Code: "JP",
      demonym: `${_Latvian_country_translation}`,
    },
    {
      alpha2Code: "JE",
      demonym: `${_Lebanese_country_translation}`,
    },
    {
      alpha2Code: "JO",
      demonym: `${_Liberian_country_translation}`,
    },
    {
      alpha2Code: "KZ",
      demonym: `${_Libyan_country_translation}`,
    },
    {
      alpha2Code: "KE",
      demonym: `${_Liechtensteiner_country_translation}`,
    },
    {
      alpha2Code: "KI",
      demonym: `${_Lithuanian_country_translation}`,
    },
    {
      alpha2Code: "KW",
      demonym: `${_Luxembourger_country_translation}`,
    },
    {
      alpha2Code: "KG",
      demonym: `${_Macedonian_country_translation}`,
    },
    {
      alpha2Code: "LA",
      demonym: `${_Malagasy_country_translation}`,
    },
    {
      alpha2Code: "LV",
      demonym: `${_Malawian_country_translation}`,
    },
    {
      alpha2Code: "LB",
      demonym: `${_Malaysian_country_translation}`,
    },
    {
      alpha2Code: "LS",
      demonym: `${_Maldivan_country_translation}`,
    },
    {
      alpha2Code: "LR",
      demonym: `${_Malian_country_translation}`,
    },
    {
      alpha2Code: "LY",
      demonym: `${_Maltese_country_translation}`,
    },
    {
      alpha2Code: "LI",
      demonym: `${_Manx_country_translation}`,
    },
    {
      alpha2Code: "LT",
      demonym: `${_Marshallese_country_translation}`,
    },
    {
      alpha2Code: "LU",
      demonym: `${_Mauritanian_country_translation}`,
    },
    {
      alpha2Code: "MO",
      demonym: `${_Mauritian_country_translation}`,
    },
    {
      alpha2Code: "MK",
      demonym: `${_Mexican_country_translation}`,
    },
    {
      alpha2Code: "MG",
      demonym: `${_Micronesian_country_translation}`,
    },
    {
      alpha2Code: "MW",
      demonym: `${_Moldovan_country_translation}`,
    },
    {
      alpha2Code: "MY",
      demonym: `${_Monegasque_country_translation}`,
    },
    {
      alpha2Code: "MV",
      demonym: `${_Mongolian_country_translation}`,
    },
    {
      alpha2Code: "ML",
      demonym: `${_Montenegrin_country_translation}`,
    },
    {
      alpha2Code: "MT",
      demonym: `${_Montserratian_country_translation}`,
    },
    {
      alpha2Code: "MH",
      demonym: `${_Moroccan_country_translation}`,
    },
    {
      alpha2Code: "MQ",
      demonym: `${_Mosotho_country_translation}`,
    },
    {
      alpha2Code: "MR",
      demonym: `${_Motswana_country_translation}`,
    },
    {
      alpha2Code: "MU",
      demonym: `${_Mozambican_country_translation}`,
    },
    {
      alpha2Code: "YT",
      demonym: `${_Namibian_country_translation}`,
    },
    {
      alpha2Code: "MX",
      demonym: `${_Nauruan_country_translation}`,
    },
    {
      alpha2Code: "FM",
      demonym: `${_Nepalese_country_translation}`,
    },
    {
      alpha2Code: "MD",
      demonym: `${_New_Caledonian_country_translation}`,
    },
    {
      alpha2Code: "MC",
      demonym: `${_New_Zealander_country_translation}`,
    },
    {
      alpha2Code: "MN",
      demonym: `${_Ni_Vanuatu_country_translation}`,
    },
    {
      alpha2Code: "ME",
      demonym: `${_Nicaraguan_country_translation}`,
    },
    {
      alpha2Code: "MS",
      demonym: `${_Nigerian_country_translation}`,
    },
    {
      alpha2Code: "MA",
      demonym: `${_Nigerien_country_translation}`,
    },
    {
      alpha2Code: "MZ",
      demonym: `${_Niuean_country_translation}`,
    },
    {
      alpha2Code: "MM",
      demonym: `${_Norfolk_Islander_country_translation}`,
    },
    {
      alpha2Code: "NA",
      demonym: `${_North_Korean_country_translation}`,
    },
    {
      alpha2Code: "NR",
      demonym: `${_Norwegian_country_translation}`,
    },
    {
      alpha2Code: "NP",
      demonym: `${_Omani_country_translation}`,
    },
    {
      alpha2Code: "NL",
      demonym: `${_Pakistani_country_translation}`,
    },
    {
      alpha2Code: "NC",
      demonym: `${_Palauan_country_translation}`,
    },
    {
      alpha2Code: "NZ",
      demonym: `${_Palestinian_country_translation}`,
    },
    {
      alpha2Code: "NI",
      demonym: `${_Panamanian_country_translation}`,
    },
    {
      alpha2Code: "NE",
      demonym: `${_Papua_New_Guinean_country_translation}`,
    },
    {
      alpha2Code: "NG",
      demonym: `${_Paraguayan_country_translation}`,
    },
    {
      alpha2Code: "NU",
      demonym: `${_Peruvian_country_translation}`,
    },
    {
      alpha2Code: "NF",
      demonym: `${_Pitcairn_Islander_country_translation}`,
    },
    {
      alpha2Code: "KP",
      demonym: `${_Polish_country_translation}`,
    },
    {
      alpha2Code: "MP",
      demonym: `${_Portuguese_country_translation}`,
    },
    {
      alpha2Code: "NO",
      demonym: `${_Puerto_Rican_country_translation}`,
    },
    {
      alpha2Code: "OM",
      demonym: `${_Qatari_country_translation}`,
    },
    {
      alpha2Code: "PK",
      demonym: `${_Romanian_country_translation}`,
    },
    {
      alpha2Code: "PW",
      demonym: `${_Russian_country_translation}`,
    },
    {
      alpha2Code: "PS",
      demonym: `${_Rwandan_country_translation}`,
    },
    {
      alpha2Code: "PA",
      demonym: `${_Sahrawi_country_translation}`,
    },
    {
      alpha2Code: "PG",
      demonym: `${_Sant_Barthélemy_Islander_country_translation}`,
    },
    {
      alpha2Code: "PY",
      demonym: `${_Saint_Helenian_country_translation}`,
    },
    {
      alpha2Code: "PE",
      demonym: `${_Saint_Lucian_country_translation}`,
    },
    {
      alpha2Code: "PH",
      demonym: `${_Saint_Martin_Islander_country_translation}`,
    },
    {
      alpha2Code: "PN",
      demonym: `${_Saint_Vincentian_country_translation}`,
    },
    {
      alpha2Code: "PL",
      demonym: `${_Salvadoran_country_translation}`,
    },
    {
      alpha2Code: "PT",
      demonym: `${_Sammarinese_country_translation}`,
    },
    {
      alpha2Code: "PR",
      demonym: `${_Samoan_country_translation}`,
    },
    {
      alpha2Code: "QA",
      demonym: `${_Sao_Tomean_country_translation}`,
    },
    {
      alpha2Code: "XK",
      demonym: `${_Saudi_Arabian_country_translation}`,
    },
    {
      alpha2Code: "RE",
      demonym: `${_Scotland_country_translation}`,
    },
    {
      alpha2Code: "RO",
      demonym: `${_Senegalese_country_translation}`,
    },
    {
      alpha2Code: "RU",
      demonym: `${_Serbian_country_translation}`,
    },
    {
      alpha2Code: "RW",
      demonym: `${_Seychellois_country_translation}`,
    },
    {
      alpha2Code: "BL",
      demonym: `${_Sierra_Leonian_country_translation}`,
    },
    {
      alpha2Code: "SH",
      demonym: `${_Singaporean_country_translation}`,
    },
    {
      alpha2Code: "KN",
      demonym: `${_Sint_Maarten_country_translation}`,
    },
    {
      alpha2Code: "LC",
      demonym: `${_Slovak_country_translation}`,
    },
    {
      alpha2Code: "MF",
      demonym: `${_Slovene_country_translation}`,
    },
    {
      alpha2Code: "PM",
      demonym: `${_Solomon_Islander_country_translation}`,
    },
    {
      alpha2Code: "VC",
      demonym: `${_Somali_country_translation}`,
    },
    {
      alpha2Code: "WS",
      demonym: `${_South_African_country_translation}`,
    },
    {
      alpha2Code: "SM",
      demonym: `${_South_Georgia_and_the_South_Sandwich_Islander_country_translation}`,
    },
    {
      alpha2Code: "ST",
      demonym: `${_South_Korean_country_translation}`,
    },
    {
      alpha2Code: "SA",
      demonym: `${_South_Sudanese_country_translation}`,
    },
    {
      alpha2Code: "SN",
      demonym: `${_Spanish_country_translation}`,
    },
    {
      alpha2Code: "RS",
      demonym: `${_Sri_Lankan_country_translation}`,
    },
    {
      alpha2Code: "SC",
      demonym: `${_Sudanese_country_translation}`,
    },
    {
      alpha2Code: "SL",
      demonym: `${_Surinamer_country_translation}`,
    },
    {
      alpha2Code: "SG",
      demonym: `${_Swazi_country_translation}`,
    },
    {
      alpha2Code: "SX",
      demonym: `${_Swedish_country_translation}`,
    },
    {
      alpha2Code: "SK",
      demonym: `${_Swiss_country_translation}`,
    },
    {
      alpha2Code: "SI",
      demonym: `${_Syrian_country_translation}`,
    },
    {
      alpha2Code: "SB",
      demonym: `${_Tadzhik_country_translation}`,
    },
    {
      alpha2Code: "SO",
      demonym: `${_Taiwanese_country_translation}`,
    },
    {
      alpha2Code: "ZA",
      demonym: `${_Tanzanian_country_translation}`,
    },
    {
      alpha2Code: "GS",
      demonym: `${_Thai_country_translation}`,
    },
    {
      alpha2Code: "KR",
      demonym: `${_Togolese_country_translation}`,
    },
    {
      alpha2Code: "SS",
      demonym: `${_Tokelauan_country_translation}`,
    },
    {
      alpha2Code: "ES",
      demonym: `${_Tongan_country_translation}`,
    },
    {
      alpha2Code: "LK",
      demonym: `${_Tinidadian_country_translation}`,
    },
    {
      alpha2Code: "SD",
      demonym: `${_Tunisian_country_translation}`,
    },
    {
      alpha2Code: "SR",
      demonym: `${_Turkish_country_translation}`,
    },
    {
      alpha2Code: "SJ",
      demonym: `${_Turkmen_country_translation}`,
    },
    {
      alpha2Code: "SZ",
      demonym: `${_Turks_and_Caicos_Islander_country_translation}`,
    },
    {
      alpha2Code: "SE",
      demonym: `${_Tuvaluan_country_translation}`,
    },
    {
      alpha2Code: "CH",
      demonym: `${_Ugandan_country_translation}`,
    },
    {
      alpha2Code: "SY",
      demonym: `${_Ukrainian_country_translation}`,
    },
    {
      alpha2Code: "TW",
      demonym: `${_Uruguayan_country_translation}`,
    },
    {
      alpha2Code: "TJ",
      demonym: `${_Uzbekistani_country_translation}`,
    },
    {
      alpha2Code: "TZ",
      demonym: `${_Venezuelan_country_translation}`,
    },
    {
      alpha2Code: "TH",
      demonym: `${_Vietnamese_country_translation}`,
    },
    {
      alpha2Code: "TL",
      demonym: `${_Virgin_Islander_country_translation}`,
    },
    {
      alpha2Code: "TG",
      demonym: `${_Wales_country_translation}`,
    },
    {
      alpha2Code: "TK",
      demonym: `${_Wallis_and_Futuna_Islander_country_translation}`,
    },
    {
      alpha2Code: "TO",
      demonym: `${_Yemeni_country_translation}`,
    },
    {
      alpha2Code: "TT",
      demonym: `${_Zambian_country_translation}`,
    },
    {
      alpha2Code: "TN",
      demonym: `${_Zimbabwean_country_translation}`,
    },
    //   {
    //     alpha2Code: "TR",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "TM",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "TC",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "TV",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "UG",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "UA",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "AE",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "GB",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
      //     alpha2Code: "US",
      //     demonym: `${_afghan_country_translation}`,
      //   },
    //   {
    //     alpha2Code: "UY",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
      //     alpha2Code: "UZ",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
      //     alpha2Code: "VU",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "VE",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
      //     alpha2Code: "VN",
      //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
      //     alpha2Code: "WF",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "EH",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "YE",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "ZM",
    //     demonym: `${_afghan_country_translation}`,
    //   },
    //   {
    //     alpha2Code: "ZW",
    //     demonym: `${_afghan_country_translation}`,
    //   },
  ];
}
};
