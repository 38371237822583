import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { applyBrowserTimezone } from "../../../utils/time-functions";
import useTranslated from "../../../helpers/translationHelper";

const Upcoming = ({ t1 = {}, t2 = {}, bestOf, datetime, isTournament, isVeto, t1VetoState, t2VetoState }) => {
  const [countdown, setCountdown] = useState(moment.duration(moment(applyBrowserTimezone(datetime)).diff(moment(), "milliseconds")));
  let _best_of_match_display = useTranslated("id_match_display_5");

  let intervalRef = useRef(null);
  useEffect(() => {
    if (moment(applyBrowserTimezone(datetime)).diff(moment(), "days") < 1) {
      intervalRef.current = setInterval(() => {
        setCountdown(moment.duration(moment(applyBrowserTimezone(datetime)).diff(moment(), "milliseconds")));
      }, 1000);
    }
  }, [datetime]);
  useEffect(() => {
    if (countdown._milliseconds <= 0) clearInterval(intervalRef.current);
  }, [countdown]);
  const pad2 = (n) => {
    return n < 10 ? "0" + n : n;
  };

  return (
    <>
      <div className="matches-header-inner">
        {t1 ? (
          t1.placeHolder ?
            <div className="matches-header-left-team">
              <div>
                <h2 className="text-22 uppercase bold">{t1.name}</h2>
                {isVeto && t1VetoState && (
                  <span
                    style={{ minWidth: "max-content" }}
                    className={`pill ml-0 ${t1VetoState.toLowerCase().includes("ready") ? "success" : ""}`}
                  >
                    {t1VetoState}
                  </span>
                )}
              </div>
              <img src={t1.logo} alt={t1.name} className={t1.logoCropped || t1.logoCropped === undefined ? "imgCropped" : "imgNotCropped"} />
            </div> :
          <Link to={`/teams/${t1._id}`} className="matches-header-left-team">
            <div>
              <h2 className="text-22 uppercase bold">{t1.name}</h2>
              {isVeto && t1VetoState && (
                <span
                  style={{ minWidth: "max-content" }}
                  className={`pill ml-0 ${t1VetoState.toLowerCase().includes("ready") ? "success" : ""}`}
                >
                  {t1VetoState}
                </span>
              )}
            </div>
            <img src={t1.logo} alt={t1.name} className={t1.logoCropped || t1.logoCropped === undefined ? "imgCropped" : "imgNotCropped"} />
          </Link>
        ) : null}

        <div className="matches-header-info-wrapper">
          <span className="text-14">{_best_of_match_display} {bestOf}</span>

          <span class="pill primary large">
            {moment(applyBrowserTimezone(datetime)).diff(moment(), "days") < 1
              ? `${pad2(countdown.hours())}:${pad2(countdown.minutes())}:${pad2(countdown.seconds())}`
              : moment(applyBrowserTimezone(datetime)).fromNow()}
          </span>

          <span className="pill warning text-12 ml-0">{applyBrowserTimezone(datetime).format("Do MMM YYYY, HH:mm z")}</span>
        </div>

        {t2 ? (
          t2.placeHolder ?
            <div className="matches-header-right-team">
              <img src={t2.logo} alt={t2.name} className={t2.logoCropped || t2.logoCropped === undefined ? "imgCropped" : "imgNotCropped"} />
              <div>
                <h2 className="text-22 uppercase bold">{t2.name}</h2>
                {isVeto && t2VetoState && (
                  <span
                    style={{ minWidth: "max-content" }}
                    className={`pill ml-0 ${t2VetoState.toLowerCase().includes("ready") ? "success" : ""}`}
                  >
                    {t2VetoState}
                  </span>
                )}
              </div>
            </div> :
            <Link to={`/teams/${t2._id}`} className="matches-header-right-team">
            <img src={t2.logo} alt={t2.name} className={t2.logoCropped || t2.logoCropped === undefined ? "imgCropped" : "imgNotCropped"} />
            <div>
              <h2 className="text-22 uppercase bold">{t2.name}</h2>
              {isVeto && t2VetoState && (
                <span
                  style={{ minWidth: "max-content" }}
                  className={`pill ml-0 ${t2VetoState.toLowerCase().includes("ready") ? "success" : ""}`}
                >
                  {t2VetoState}
                </span>
              )}
            </div>
          </Link>


        ) : null}
      </div>
    </>
  );
};

export default Upcoming;
