import io from 'socket.io-client';

const socket = {
    _socket: undefined
}

export const connectSocket = (id, callback, onDisconnect, name, api) => {
    socket._socket = io(api);
    socket._socket.on("connect", () => {
        socket._socket.on(`${name}-${id}`, (e) => {
            callback(e);
        });
    });
    socket._socket.on('connect_error', err => onDisconnect());
    socket._socket.on('connect_failed', err => onDisconnect());
    socket._socket.on('disconnect', err => onDisconnect());
}
export const disconnectSocket = () => {
    if (socket._socket)
        socket._socket.disconnect();
}