import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { Loading } from "@common";
import { toast } from "react-toastify";

const TempInvites = () => {
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/auth/invites`).then(({ data }) => {
      setInvites(data);
      setLoading(false);
    });
  }, []);

  const onInviteAction = async (inviteId, action) => {
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/invites/${inviteId}/${action === "accept" ? "accept" : "decline"}`);
      setInvites(invites.filter(({ _id }) => _id !== inviteId));
      toast.success(`Successfully ${action === "accept" ? "accepted" : "declined"} invite`);
    } catch (e) {
      toast.error("There was a problem with this invite");
    }
  };

  return loading ? (
    <div className="col-12 text-center p-5">
      <Loading />
    </div>
  ) : (
    <div>
      <h1>Invites</h1>

      {!invites.length
        ? "No new invites"
        : invites.map(({ _id, player, team, createdAt }) => (
          <p key={_id}>
            <Link to={`/teams/${team._id}`}>{team.name}</Link> invited your player {player.name} {moment(createdAt).fromNow()}
            <button onClick={() => onInviteAction(_id, "accept")}>Accept</button>
            <button onClick={() => onInviteAction(_id, "decline")}>Decline</button>
          </p>
        ))}
    </div>
  );
};

export default TempInvites;
