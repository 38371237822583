import React, { useContext, Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageWithTabs, PageWithTabsHeader } from "@common";
import { Overview, Applications, ChatRoom, Members } from "./ScrimsSingleTabs";
import Axios from "axios";
import moment from "moment";
import Loading from "../../_common/Loading/Loading";
import { JoinScrimModal } from "../../_modals/JoinScrimModal/JoinScrimModal";
import { AppContext } from "../../../context/AppContext";
import DeleteScrimModal from "./DeleteScrimModal/DeleteScrimModal";
import TeamStats from "../../TeamsRouter/TeamsSingle/TeamsSingleTabs/TeamStats/TeamStats";
import { isMemberOfTeam } from "../../../utils/additional-helpers";
import { applyBrowserTimezone } from "../../../utils/time-functions";

const ScrimsSingle = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [scrimDetails, setScrimDetails] = useState({});
  const [teamSelectModal, setTeamSelectModal] = useState(false);
  const [hasTeams, setHasTeams] = useState(false);
  const [showDeleteScrim, setShowDeleteScrim] = useState(false);

  const [count, setCount] = useState("");
  const { authenticated, user, selectedGame, setSelectedGame, setShowAuth } = useContext(AppContext);
  const {
    date,
    description,
    size,
    scrimFormat,
    team,
    game,
    rank,
    checkInPeriod,
    createdBy,
    map,
    pickMode,
    acceptedMatchUp = null,
    numberOfMatches,
    matchups = [],
    minRank,
    maxRank,
    division,
    timeZone,
  } = scrimDetails;
  let rolesCanChat = [
    "Owner",
    "Manager",
    "Coach",
    "Player:TOP",
    "Player:JUNGLE",
    "Player:MID",
    "Player:BOT",
    "Player:SUPPORT",
    "Player:SUB",
    "Player:IGL",
    "Player:RIFLER",
    "Player:AWP",
    "Player:LURKER",
  ];

  const isOwnerOfTeam =
    authenticated &&
    user &&
    acceptedMatchUp &&
    ((scrimDetails.team &&
      ((scrimDetails.team.organisation &&
        scrimDetails.team.organisation.owners.find(({ user: userId }) => user.state._id === userId._id)) ||
        scrimDetails.team.members.find(
          ({ user: userId, role, player, coach }) =>
            (userId && user.state._id === userId._id && rolesCanChat.includes(role)) ||
            (player && user.state._id === player.user._id && rolesCanChat.includes(role)) ||
            (coach && user.state._id === coach.user._id && rolesCanChat.includes(role))
        ))) ||
      (acceptedMatchUp.team &&
        ((acceptedMatchUp.team.organisation &&
          acceptedMatchUp.team.organisation.owners.find(({ user: userId }) => user.state._id === userId)) ||
          acceptedMatchUp.team.members.find(({ user: userId, role, player, coach }) => {
            return (
              (userId && user.state._id === userId._id && rolesCanChat.includes(role)) ||
              (player && user.state._id === player.user && rolesCanChat.includes(role)) ||
              (coach && user.state._id === coach.user && rolesCanChat.includes(role))
            );
          }))));

  console.log("isOwnerOfTeam-", isOwnerOfTeam);
  // console.log("user.state._id-", user.state._id);
  acceptedMatchUp && console.log("acceptedMatchUp.team-", acceptedMatchUp.team.members);

  acceptedMatchUp && console.log("acceptedMatchUp.org-", acceptedMatchUp.organisation);

  const deleteScrim = () => {
    setShowDeleteScrim(true);
  };

  useEffect(() => {
    setCount(matchups.length);
  }, [matchups]);

  let pages = {
    Overview: (
      <Overview
        scrimDetails={scrimDetails}
        data={{
          description,
          size,
          scrimFormat,
          team,
          rank,
          checkInPeriod,
          createdBy,
          game,
          map,
          pickMode,
          numberOfMatches,
          deleteScrim,
          minRank,
          maxRank,
          division,
        }}
      />
    ),
    "Scrim Team": <Members team={team} scrim={scrimDetails} />,
    "Scrim Team Stats": <TeamStats team={team} isScrim />,

    ...(authenticated &&
      matchups.length > 0 &&
      acceptedMatchUp === null &&
      createdBy &&
      createdBy._id === user.state._id && {
        Applications: (
          <Applications
            count={count}
            setPageNameWithCount={(num) => setCount(num)}
            matchups={matchups}
            game={game}
            acceptedMatchUp={acceptedMatchUp}
            team1={team}
            numberOfMatches={numberOfMatches}
            date={date}
          />
        ),
      }),

    ...(authenticated &&
      acceptedMatchUp !== null &&
      (isOwnerOfTeam || (createdBy && createdBy._id === user.state._id)) && { Chat: <ChatRoom id={id} scrim={scrimDetails} /> }),
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/scrims/${id}`).then(({ data }) => {
      if (data?.game?.shortName !== selectedGame.shortName) {
        setSelectedGame(data.game);
        return window.location.reload();
      }
      if (data) {
        data.size = "5 Players";
        setScrimDetails(data);
        setLoading(false);
      }
    });
  }, []);

  const handleJoin = () => {
    setTeamSelectModal(true);
  };

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <PageWithTabs
          showAdsInTabs
          title={team?.name}
          croppedLogo={team.logoCropped}
          above_title="SCRIM"
          pages={pages}
          cover_image={team?.banner}
          image={team?.logo}
          below_title={
            <React.Fragment>
              <span className="pill warning">
                {moment.tz(date, timeZone || moment.tz.guess()).format("DD MMM, YYYY - HH:mm")}
                {`  ${applyBrowserTimezone(date, timeZone).zoneAbbr()}`}
              </span>
              {acceptedMatchUp === null ? (
                <span className="pill primary">OPEN FOR APPLICATIONS</span>
              ) : (
                <span className="pill">CLOSED</span>
              )}
            </React.Fragment>
          }
          base_route={`/scrim/${id}`}
          tab_link={
            <a target="_blank" href={`/teams/${team?._id}`}>
              GO TO {team?.name} TEAM PAGE <img src={require("@img/arrow-up-right-white.svg")}></img>
            </a>
          }
          append_to_header={
            acceptedMatchUp === null &&
            (authenticated && user.state ? (
              createdBy &&
              user &&
              createdBy._id !== user.state._id &&
              !isMemberOfTeam(team, user.state._id) && (
                <PageWithTabsHeader
                  warning
                  disabledButton={hasTeams ? false : true}
                  button_text={`APPLY AS OPPONENT TEAM`}
                  date={date}
                  onButtonClick={handleJoin}
                />
              )
            ) : (
              <PageWithTabsHeader
                warning
                button_text="LOGIN TO APPLY AS OPPONENT TEAM"
                date={date}
                onButtonClick={() => setShowAuth("login")}
              />
            ))
          }
        />
      )}
      {authenticated && user.state && (
        <JoinScrimModal
          open={teamSelectModal}
          setUserHasTeams={(isTrue) => setHasTeams(isTrue)}
          onClose={() => setTeamSelectModal(false)}
          scrimId={scrimDetails._id}
        />
      )}
      <DeleteScrimModal isOpen={showDeleteScrim} onClose={() => setShowDeleteScrim(false)} scrim={scrimDetails} />
    </Fragment>
  );
};

export default ScrimsSingle;
