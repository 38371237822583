import React, { useContext } from "react";
import Login from "./tabs/Login";
import Register from "./tabs/Register";
import { AppContext } from "../../../../context/AppContext";
import logo_header from "@img/logo-header.svg";
import useTranslated from "../../../../helpers/translationHelper";
const LoginRegister = () => {
  const { showAuth: tab, setShowAuth } = useContext(AppContext);
  
  const _mainheader=useTranslated('id_pop-up_-_log_in_0')
  const _mainlogin=useTranslated('id_pop-up_-_log_in_1')
  const _mainsignup=useTranslated('id_pop-up_-_log_in_2')

  // Add tabs and the component the tab should render here
  // (title is the display title, name is a reference for it)
  const tabs = [
    { name: "login", title: `${_mainlogin}`, component: <Login /> },
    { name: "register", title: `${_mainsignup}`, component: <Register /> },
  ];

  return (
    <div className="login-register-modal">
      <div className="auth-modal-header">
        <img src={logo_header} alt="Leagues.gg" />
        <h2 className="bold uppercase">{`${_mainheader}`}</h2>
      </div>
      <div className="auth-modal-tabs">
        {tabs.map(({ name, title }) => (
          <div key={name} className={`tab ${tab === name && "active"}`} onClick={() => setShowAuth(name)}>
            {title}
          </div>
        ))}
      </div>
      <div className="modal-body">{tabs.map(({ name, component }) => tab === name && <div key={name}>{component}</div>)}</div>
    </div>
  );
};

export default LoginRegister;
