import React from "react";
import { TournamentItem, MirrorText, SimplePageWithTabs, Loading } from "@common";
import { useState, useContext, useEffect } from "react";
import moment from "moment";
import Axios from "axios";
import { AppContext } from "../../context/AppContext";

const Tournaments = () => {
  const { selectedGame } = useContext(AppContext);
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons`, { params: { game: selectedGame._id, withTournaments: true } }).then(
      ({ data }) => {
        // console.log("[DATA FOR TOURNAMENTS]", data);
        setSeasons(data);
        setLoading(false);
      }
    );
  }, [selectedGame._id]);

  const tabs = {
    Active: (
      <div className="row smaller-gutter-row">
        {loading && seasons.length === 0 ? (
          <div className="col-12 text-center p-5">
            <Loading />
          </div>
        ) : (
          <>
            {seasons.map(
              ({ name, year, tournaments = [] }) =>
                !!tournaments.length && (
                  <>
                    {tournaments.filter(
                      ({ startDate, matches, tournamentFinished = false }) =>
                        matches.length == 0 || (matches.some((match) => moment(match.datetime) >= moment()) && !tournamentFinished)
                    ).length !== 0 && (
                      <div className="col-12 pb-4">
                        <span className="text-22 uppercase bold">
                          {name}&nbsp;
                          <span className="color-primary outline">{year}</span>
                        </span>
                      </div>
                    )}

                    {tournaments
                      .sort((a, b) => {
                        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
                      })
                      // .reverse()
                      .filter(
                        ({ startDate, matches, tournamentFinished }) =>
                          matches.length == 0 || (matches.some((match) => moment(match.datetime) >= moment()) && !tournamentFinished)
                      )
                      .map(
                        ({
                          thumbnailImage,
                          name,
                          gameServer = "EUW",
                          startDate,
                          _id,
                          type = -1,
                          prizes,
                          registerationDeadline,
                          teamSize,
                        }) => {
                          let subtitle = "";
                          if (gameServer) subtitle += gameServer;
                          if (teamSize) subtitle += getTeamSize(teamSize);
                          if (prizes && prizes.length !== 0) subtitle += "- " + prizes[0];
                          if (type !== -1) subtitle += " - " + getGameType(type);

                          const diff = moment(startDate).diff(moment());
                          const diffDuration = moment.duration(diff);
                          const _date = moment(startDate);
                          let string = _date.format("DD MMM YYYY");
                          if (diffDuration.days() === -1) string = "Yesterday";
                          if (diffDuration.days() === 1) string = "Tomorrow";
                          if (diffDuration.days() === 0) string = "Today";
                          const date = string + " at " + _date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr();
                          // const date = _date.format("DD MMM YYYY") + " at " + _date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr();
                          // const date = _date.format("DD/MM/YYYY") !== moment().format("DD/MM/YYYY") ?
                          //   _date.format("DD MMM YYYY") + " at " + _date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr()
                          //   :
                          //   "Today at " + _date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr();
                          let reg_date = undefined;
                          if (registerationDeadline) {
                            const rDiff = moment(registerationDeadline).diff(moment());
                            const rDiffDuration = moment.duration(rDiff);
                            const _rDate = moment(registerationDeadline);
                            let rString = _rDate.format("DD MMM YYYY");
                            if (rDiffDuration.days() === -1) rString = "Yesterday";
                            if (rDiffDuration.days() === 1) rString = "Tomorrow";
                            if (rDiffDuration.days() === 0) rString = "Today";
                            reg_date = rString + " at " + _rDate.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr();
                          }
                          // const _reg_date = registerationDeadline ? moment(registerationDeadline) : undefined;
                          // const reg_date = registerationDeadline ? _reg_date.format("DD/MM/YYYY") !== moment().format("DD/MM/YYYY") ?
                          //   _reg_date.format("DD MMM YYYY") + " at " + _reg_date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr()
                          //   :
                          //   "Today at " + _reg_date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr() : undefined
                          return (
                            <TournamentItem
                              key={_id}
                              link_to={`/tournament/${_id}`}
                              image={thumbnailImage}
                              title={name}
                              subtitle={subtitle}
                              color="red"
                              date={"Starting: " + date}
                              reg_date={reg_date ? "Registration: " + reg_date : undefined}
                              startTime={startDate}
                              registerationDeadline={registerationDeadline}
                            />
                          );
                        }
                      )}
                  </>
                )
            )}
          </>
        )}
      </div>
    ),
    Played: (
      <div className="row smaller-gutter-row">
        {loading && !seasons.length === 0 ? (
          <div className="col-12 text-center p-5">
            <Loading />
          </div>
        ) : (
          <>
            {seasons.map(
              ({ name, year, tournaments = [] }) =>
                !!tournaments.length && (
                  <>
                    {tournaments.filter(
                      ({ startDate, matches, tournamentFinished = false }) =>
                        (matches.length > 0 && matches.every((match) => moment(match.datetime) < moment())) || tournamentFinished
                    ).length !== 0 && (
                      <div className="col-12 pb-4">
                        <span className="text-22 uppercase bold">
                          {name}&nbsp;
                          <span className="color-primary outline">{year}</span>
                        </span>
                      </div>
                    )}

                    {tournaments
                      .sort((a, b) => {
                        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
                      })
                      .reverse()
                      .filter(
                        ({ startDate, matches, tournamentFinished = false }) =>
                          (matches.length > 0 && matches.every((match) => moment(match.datetime) < moment())) || tournamentFinished
                      )
                      .map(
                        ({ thumbnailImage, name, gameServer = "EUW", startDate, _id, type = "Single Elimination", prizes, teamSize }) => {
                          let subtitle = "";
                          if (gameServer) subtitle += gameServer;
                          if (teamSize) subtitle += getTeamSize(teamSize);
                          if (prizes && prizes.length !== 0) subtitle += "- " + prizes[0];
                          if (type !== -1) subtitle += " - " + getGameType(type);
                          const diff = moment(startDate).diff(moment());
                          const diffDuration = moment.duration(diff);
                          const _date = moment(startDate);
                          let string = _date.format("DD MMM YYYY");
                          if (diffDuration.days() === -1) string = "Yesterday";
                          if (diffDuration.days() === 1) string = "Tomorrow";
                          if (diffDuration.days() === 0) string = "Today";
                          const date = string + " at " + _date.format("HH:mm") + " " + moment.tz(moment.tz.guess()).zoneAbbr();
                          return (
                            <TournamentItem
                              key={_id}
                              link_to={`/tournament/${_id}`}
                              image={thumbnailImage}
                              title={name}
                              subtitle={subtitle}
                              color="visibility-hidden"
                              date={date}
                              startTime={startDate}
                            />
                          );
                        }
                      )}
                  </>
                )
            )}
          </>
        )}
      </div>
    ),
  };

  return (
    <SimplePageWithTabs
      title="Tournaments"
      tabs={tabs}
      top_right={
        <React.Fragment>
          <MirrorText text={["le4gu3s.gg T0rn4m3nts", "Gl 4nd hf"]} is_static />
        </React.Fragment>
      }
      tabs_props={{ base_route: "/tournaments" }}
      // type="under-contruction"
    />
  );
};

export default Tournaments;

const getGameType = (type) => {
  switch (type) {
    case -1:
      return "";
    case 1:
      return "Single elimination";
    case 2:
      return "Double elimination";
    case 3:
      return "King of the hill";
  }
};

const getTeamSize = (size) => {
  switch (size) {
    case 1:
      return " - 1V1 ";
    case 3:
      return " - 3V3 ";
    case 5:
      return " - 5V5 ";
  }
};
