import React, { useContext } from "react";
import { AppContext } from "@context/AppContext";
import { Button, Icon } from "@common";
import GlitchClip from "react-glitch-effect/core/Clip";

const HomeHero = ({
  backgroundType='video', // image or video
  backgroundMedia='https://leagues-static.s3.eu-west-2.amazonaws.com/img/frontpage-video.mp4', // File URL
  subheading='Loading...', // Small Top Text
  heading='Loading...', //Big Heading
  showButton=true, 
  buttonText="Loading...",
  buttonLink="#"
}) => {
  const { authenticated, setShowAuth } = useContext(AppContext);

  return (
    <div className="home-hero">
      <div className="home-hero-background"></div>

      {backgroundType === 'video' && <video className="home-hero-video" autoPlay={true} loop={true} muted={true} playsInline={true}>
        <source src={backgroundMedia} type="video/mp4" />
      </video>
      }

      {
      backgroundType === 'image' && <img className="home-hero-video" src={backgroundMedia} />
      }

      <span className="faded">{subheading}</span>
      <GlitchClip duration="10s">
        <h1>{heading}</h1>
      </GlitchClip>

      {/* <div className="row">
        <div className="col-4">
          <Icon name="teams-active" />

          <h2>Team up</h2>
          <p>Find other teams, players and coaches and unite your skills</p>
        </div>
        <div className="col-4">
          <Icon name="scrims" />

          <h2>Play esports</h2>
          <p>Play with other gamers in leagues, tournaments and scrims</p>
        </div>
        <div className="col-4">
          <Icon name="logo" style={{ height: 50, marginTop: -7 }} />

          <h2>Track stats</h2>
          <p>Track your game stats as you climb the ranks</p>
        </div>
      </div> */}
{
  showButton && <Button name="cta-button" href={buttonLink}>
  {buttonText}
</Button>
}
      {/* {!authenticated && (
        <Button name="cta-button" onClick={() => setShowAuth("register")}>
          Sign up for free
        </Button>
      )} */}
    </div>
  );
};

export default HomeHero;
