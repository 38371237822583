import React from "react";
import NewsItem from "@components/News/NewsItem/NewsItem";
import { Loading } from "@common";

const RenderNewsItems = ({ posts, featured, hide }) => {
  if (posts === []) {
    return (
      <div className="col-12 text-center">
        <Loading />
      </div>
    );
  }

  // For testing purpose only
  // const getTags = (tags) => {
  //   console.log('ccc-tags-',tags)
  //   let tagname = null;
  //    tags.map(({name}) => {
  //     if(tagname === null){
  //       tagname = name
  //     }else{
  //       tagname = tagname + ',' +name
  //     }

  //   })

  //   return tagname;
  // }
  return posts.map((post, i) => {
    if (hide && featured.length && i == 0) {
      return (
        <NewsItem
          key={featured[0].slug}
          slug={featured[0].slug}
          tag={featured[0].primary_tag}
          title={featured[0].title}
          image={featured[0].feature_image}
          excerpt={featured[0].excerpt}
          published={featured[0].published_at}
        />
      );
    }
    if (post?.id == featured[0]?.id) return;
    if (i == 3 && posts[0]?.id !== featured[0]?.id) return;
    return (
      <NewsItem
        key={post.slug}
        slug={post.slug}
        tag={post.primary_tag}
        title={post.title}
        image={post.feature_image}
        excerpt={post.excerpt}
        published={post.published_at}
      />
    );
  });
};

export default RenderNewsItems;
