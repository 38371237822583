import React from "react";
import { Item } from "@common";

const LeagueItem = ({ title, second_line, third_line, tag, leagueFinished, ...rest }) => {
  return (
    <Item
      hide_time_ago={true}
      classes={`slider-item ${leagueFinished} `}
      style={{ opacity: leagueFinished ? "0.5" : "1" }}
      backgroundSize={"cover"}
      title={title}
      above_excerpt={second_line}
      excerpt={third_line}
      tag={tag}
      {...rest}
    />
  );
};

export default LeagueItem;
