import React from 'react';
import { Draggable, state } from 'react-page-maker';
import { RiTwitchLine,RiCloseLine,RiFacebookLine,RiInstagramLine,RiTwitterLine,RiYoutubeLine } from 'react-icons/ri';
import './style.scss';
class DraggableSocialFollow extends React.Component {
  state = {
    showColorPicker: false,
    background: '',
    description: 'descriptiondescription',
    toggleEdit:false,

      twitch:'#',
      facebook:'#',
      twitter:'#',
      instagram:'#',
      youtube:'#'
    
  };

  toggleEditField = (fieldName) => {
    this.setState({toggleEdit: fieldName});
  }
  

  handleChangeComplete = (color) => {
    const { id, dropzoneID, parentID }= this.props;
    this.setState({ background: color.hex }, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: { background: color.hex }
      });
    });
  };

  changePayload = (payLoadKey,value) => {
    const { id, dropzoneID, parentID,payload }= this.props;
    this.setState({ [payLoadKey]: value }, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: { ...payload, [payLoadKey]: value }
      });
    });
    // this.setState({toggleEdit: ''});
  }

  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }

  
  render() {
    // console.log('this.props-on MOduleHeader-',this.props.payload)
    const {
      id, showBasicContent, showPreview,
      dropzoneID, parentID, 
      // name, 
      payload
    } = this.props;


    const {toggleEdit} =  this.state;
    
    // const heading =  payload && payload.heading || this.state.heading;
    const twitch = payload.twitch || this.state.twitch;
    const facebook = payload.facebook || this.state.facebook;
    const twitter = payload.twitter || this.state.twitter;
    const instagram = payload.instagram || this.state.instagram;
    const youtube = payload.youtube || this.state.youtube;
    

    if (showBasicContent) {
      // console.log('this.propsthis.props',this.props)
      const {name} = this.props;
      return (
        <>
        <Draggable
          { ...this.props } 
      >{name}</Draggable></>
      )
    }

    if (showPreview) {
      return (
        <>
        <header >
          <h2 className="pb_ele_header_heading">FOLLOW US</h2>
            <div className='socialIconBox twitch' onClick={() => window.open('https://www.twitch.tv/'+twitch, '_blank')}><RiTwitchLine className='socialIcon' /></div>
            <div className='socialIconBox facebook' onClick={() => window.open('https://www.facebook.com/'+facebook, '_blank')}><RiFacebookLine className='socialIcon' /></div>
            <div className='socialIconBox twitter' onClick={() => window.open('https://www.twitter.com/'+twitter, '_blank')}><RiTwitterLine className='socialIcon' /></div>
            <div className='socialIconBox instagram' onClick={() => window.open('https://www.instagram.com/'+instagram, '_blank')}><RiInstagramLine className='socialIcon' /></div>
            <div className='socialIconBox youtube' onClick={() => window.open('https://www.youtube.com/'+youtube, '_blank')}><RiYoutubeLine className='socialIcon' /></div>
        </header>
        <br />
        </>
      );
    }

    return (
      <Draggable { ...this.props } >
        <div className="actions">
            <span className="action_type" title="Remove element"><RiCloseLine onClick={() => state.removeElement(id, dropzoneID, parentID)} color='white' /></span>
          </div>
        <header >
          
          <h2 className="pb_ele_header_heading">FOLLOW US</h2>
          <p className="text-faded pb_ele_header_description">Click on the social media icon to update username.</p>
          <div onClick={() => {this.toggleEditField('twitch')}} className='socialIconBox twitch'><RiTwitchLine className='socialIcon' /></div>
          <div onClick={() => {this.toggleEditField('facebook')}} className='socialIconBox facebook'><RiFacebookLine className='socialIcon' /></div>
          <div onClick={() => {this.toggleEditField('twitter')}}className='socialIconBox twitter'><RiTwitterLine className='socialIcon' /></div>
          <div onClick={() => {this.toggleEditField('instagram')}}className='socialIconBox instagram'><RiInstagramLine className='socialIcon' /></div>
          <div onClick={() => {this.toggleEditField('youtube')}}className='socialIconBox youtube'><RiYoutubeLine className='socialIcon' /></div>
          <br/>
          <div className='editContainer'>{toggleEdit !== false && <p>Enter {toggleEdit} username</p>}</div>
          {toggleEdit === 'twitch' && <input placeholder='Ex. johndoe' className="form-input" type="text" value={twitch} onChange={(value) => this.changePayload('twitch',value.target.value)}/>}
          {toggleEdit === 'facebook' && <input placeholder='Ex. johndoe' className="form-input" type="text" value={facebook} onChange={(value) => this.changePayload('facebook',value.target.value)}/>}
          {toggleEdit === 'twitter' && <input placeholder='Ex. johndoe' className="form-input" type="text" value={twitter} onChange={(value) => this.changePayload('twitter',value.target.value)}/>}
          {toggleEdit === 'instagram' && <input placeholder='Ex. johndoe' className="form-input" type="text" value={instagram} onChange={(value) => this.changePayload('instagram',value.target.value)}/>}
          {toggleEdit === 'youtube' && <input placeholder='Ex. johndoe' className="form-input" type="text" value={youtube} onChange={(value) => this.changePayload('youtube',value.target.value)}/>}
          {toggleEdit !== false &&  <button className="button wide-primary socialIconSave" onClick={() => this.toggleEditField(false)} >
                Save {toggleEdit} username
            </button> }
       
        </header>
      </Draggable>
    )
  }
};

export default DraggableSocialFollow;
