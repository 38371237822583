import React, { useState } from "react";
import { RiEdit2Line } from "react-icons/ri";
import RenameInGameNameModal from "@modals/CreatePlayerModal/RenameInGameNameModal";
import useTranslated from "../../../../../helpers/translationHelper";

const EditCSGOInGameName = ({ inGameName, onUpdateName, type, game,
    currentavatarImage,
    currentabout,
    currentheaderImage
}) => {
    const editTrans = useTranslated('id_settings_(my_players_and_coaches)_9')
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    }

    return (<>
        {/* <button className="text-12 primary-link color-danger" onClick={() => toggleModal()}>
            Edit
            </button> */}
        <div className="edit-profile">
            <RiEdit2Line size={`0.8em`} title={`Edit`} className="primary-link" style={{ marginBottom: "10px" }} onClick={() => toggleModal()} />
            <text className="edit-text" onClick={() => toggleModal()}>{/* Edit */}{editTrans}</text>
        </div>
        {/* <RiEdit2Line onClick={() => toggleModal()} /> */}
        {showModal && <RenameInGameNameModal
            currentavatarImage={currentavatarImage}
            currentabout={currentabout}
            currentheaderImage={currentheaderImage}
            game={game} type={type} onUpdateName={onUpdateName} currentName={inGameName} open={showModal} onClose={() => setShowModal(false)} />}
    </>
    )
}

export default EditCSGOInGameName;