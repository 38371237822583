import React from "react";
import icon_unranked from "@img/unranked.png";

const RankData = ({ leagueEntries = [] }) => {
  const ranks = {
    RANKED_SOLO_5x5: "Ranked Solo",
    RANKED_FLEX_SR: "Ranked Flex",
  };
  return (
    <div className="faded-box-item no-padding mt-2 mb-2 league-entries">
      {leagueEntries.length ? (
        leagueEntries
          .filter((item) => item.tier && item.tier.length !== 1)
          .map((entry, i) => {
            let { icon, title, subtitle, divisionName, wins, losses } = entry;

            // If the entry data is from riot we need to format it
            if (entry.leagueId) {
              const tierSentenceCase = entry.tier.charAt(0).toUpperCase() + entry.tier.substr(1).toLowerCase();
              let queueType =
                entry.queueType === "RANKED_FLEX_SR"
                  ? "Ranked Flex"
                  : entry.queueType === "RANKED_SOLO_5x5"
                  ? "Ranked Solo"
                  : entry.queueType;

              icon = `https://leagues-static.s3.eu-west-2.amazonaws.com/riot_imgs/ranked-emblems/Emblem_${tierSentenceCase}.png`;
              title = `${tierSentenceCase} ${entry.rank} / ${entry.leaguePoints} LP`;
              subtitle = queueType;
              wins = entry.wins;
              losses = entry.losses;
            }

            return (
              <>
                <div className="inner flex-row" style={{ padding: "11px" }}>
                  <img src={icon} alt={title} className="profile-images mr-3" />
                  <div className="flex-column">
                    <div className="text-12 bold">{title}</div>
                    <div className="text-12 faded pt-1 pb-1">{divisionName}</div>
                    <span className="faded text-10 mb-1">{ranks[entry.queueType]}</span>

                    <div className="text-10">
                      <span className="bold">{Math.round((wins / (wins + losses)) * 100)}% WR</span>{" "}
                      <span className="faded">{wins + losses} games</span>
                    </div>
                  </div>
                </div>

                {i < leagueEntries.length - 1 && <hr className="m-0" />}
              </>
            );
          })
          .sort(function ({ queueType }) {
            if (queueType === ranks.RANKED_SOLO_5x5) return 1;
            return -1;
          })
      ) : (
        <>
          <div className="inner flex-row" style={{ padding: "11px" }}>
            <img src={icon_unranked} alt={"Unranked"} className="profile-images mr-3" />
            <div className="flex-column">
              <div className="text-12 bold">Unranked</div>
              <div className="text-12 faded pt-1 pb-1">{"Ranked Solo"}</div>
              <div className="text-10">
                <span className="bold">0% WR</span> <span className="faded">0 games</span>
              </div>
            </div>
          </div>
          <div className="inner flex-row" style={{ padding: "11px" }}>
            <img src={icon_unranked} alt={"Unranked"} className="profile-images mr-3" />
            <div className="flex-column">
              <div className="text-12 bold">Unranked</div>
              <div className="text-12 faded pt-1 pb-1">{"Ranked Flex"}</div>
              <div className="text-10">
                <span className="bold">0% WR</span> <span className="faded">0 games</span>
              </div>
            </div>
          </div>
        </>
      )}
      {leagueEntries.length === 1 && leagueEntries[0].queueType === "RANKED_FLEX_SR" && (
        <div className="inner flex-row" style={{ padding: "11px" }}>
          <img src={icon_unranked} alt={"Unranked"} className="profile-images mr-3" />
          <div className="flex-column">
            <div className="text-12 bold">Unranked</div>
            <div className="text-12 faded pt-1 pb-1">{"Ranked Solo"}</div>
            <div className="text-10">
              <span className="bold">0% WR</span> <span className="faded">0 games</span>
            </div>
          </div>
        </div>
      )}
      {leagueEntries.length === 1 && leagueEntries[0].queueType === "RANKED_SOLO_5x5" && (
        <div className="inner flex-row" style={{ padding: "11px" }}>
          <img src={icon_unranked} alt={"Unranked"} className="profile-images mr-3" />
          <div className="flex-column">
            <div className="text-12 bold">Unranked</div>
            <div className="text-12 faded pt-1 pb-1">{"Ranked Flex"}</div>
            <div className="text-10">
              <span className="bold">0% WR</span> <span className="faded">0 games</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RankData;
