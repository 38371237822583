import React from "react";
import { Item, SimplePageWithTabs } from "@common";
import profile_picture from "@img/christian-picture.png";

const StaffList = ({ title, staffs }) => {
	return (
		<div>
			<h1>{title}</h1>
			<div className="row smaller-gutter-row">
				{staffs.map((staff) => (
					<Item
						column="3"
						image={staff.image}
						link_to="/league/1"
						hide_time_ago={true}
						excerpt={staff.position}
						title={staff.name}
						item_image_inner={<div />}
					/>
				))}
			</div>
		</div>
	);
};

const Staff = () => {
	const casters = [{
		"name":"Kristian Møller Ringgaard",
		"email":"Casterpalle@hotmail.com",
		"position":"LCD - CASTER",
		"twitter":"https://twitter.com/CasterPalle",
		"image":"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Kristian+M%C3%B8ller+Ringgaard.png",
		"facebook":"",
		"linkedin":""
	},
	{
		name:"Mikkel Freno",
		email:"Mikkel_Freno@hotmail.com",
		position:"LCD - CASTER",
		twitter:"https://twitter.com/RevoEUW",
		image:"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Mikkel+Freno.jpeg",
		facebook:"",
		linkedin:""
	},
	{
		name:"Theodor D. Kirk",
		email:"",
		position:"LCD - CASTER",
		twitter:"https://twitter.com/TheodorKirk",
		image:"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Theodor+D.+Kirk.jpeg",
		facebook:"",
		linkedin:""
	},
	{
		name:"Niels Køhler - Jacobsen",
		email:"",
		position:"LCD - CASTER",
		twitter:"Niels Køhler - Jacobsen",
		image:"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Niels+K%C3%B8hler+-+Jacobsen+.jpeg",
		facebook:"",
		linkedin:""
	},
	{
		name:"Nico Paidar",
		email:"",
		position:"LCD - CASTER",
		twitter:"https://twitter.com/nicoistrashi",
		image:"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Nico+Paidar.jpeg",
		facebook:"",
		linkedin:""
	},
	{
		name:"Mathias Ringheim",
		email:"",
		position:"LCD - CASTER",
		twitter:"https://twitter.com/HAHAXDeuw?lang=en",
		image:"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Mathias+Ringheim.jpeg",
		facebook:"",
		linkedin:""
	},
	{
		name:"Andreas Gents",
		email:"",
		position:"LCD - CASTER",
		twitter:"https://twitter.com/ADuckling__?lang=en",
		image:"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Andreas+Gents.jpeg",
		facebook:"",
		linkedin:""
	},
	{
		name:"Benjamin Kvist Tornbo",
		email:"",
		position:"PRODUCER",
		twitter:"",
		image:"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Benjamin+Kvist+Tornbo.jpeg",
		facebook:"",
		linkedin:""
	}];
	const managers = [
		{
			name:"Christian Henriksen",
			email:"info@loldk.org",
			position:"CFO",
			twitter:"",
			image:"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Frederik+Hockauf+.jpeg",
			facebook:"https://www.facebook.com/christian.henriksen.71",
			linkedin:"https://www.linkedin.com/in/christian-henriksen-29983265/"
		},
		{
			name:"Mathias Anker Høyer Horn",
			email:"info@loldk.org",
			position:"PRODUCTION MANAGER",
			twitter:"",
			image:"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Mathias+Anker+H%C3%B8yer+Horn.jpeg",
			facebook:"",
			linkedin:"https://www.linkedin.com/in/mathias-horn-622791170/"
		},
		{
			name:"Frederik Hockauf",
			email:"",
			position:"ADMIN",
			twitter:"",
			image:"https://leagues-static.s3.eu-west-2.amazonaws.com/img/Frederik+Hockauf+.jpeg",
			facebook:"",
			linkedin:""
		}];

	const tabs = {
		"All staff members (11)": (
			<div>
				<StaffList title="LCD - casters" staffs={casters} />
				<StaffList title="Managers" staffs={managers} />
			</div>
		),
		"Casters (7)": <StaffList title="LCD - casters" staffs={casters} />,
		"Managers (4)": <StaffList title="Managers" staffs={managers} />,
	};

	return <SimplePageWithTabs title="Behind Leagues.gg" tabs={tabs} />;
};

export default Staff;
