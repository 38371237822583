import React, { useState,useEffect } from 'react'
import Axios from "axios";
import Modal from "react-modal";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import Lineup from './Lineup';
import { toast } from "react-toastify";
import Matches from './Matches';
import TeamStatsTab from './TeamStatsTab';  
import { RiCloseLine } from "react-icons/ri";
import {Loading} from '@common';
import teamLogo from '@img/default-team-logo.png';
import Results from "../../../MatchPage/MatchPageHeaders/Results";
import Upcoming from "../../../MatchPage/MatchPageHeaders/Upcoming";
import './_match-modal.scss';

const MatchModal = ({ showModal, closeModal, id,selctedMatchId}) => {
    const [selectedTab, setSelectedTab] = useState("lineup");
    const [loading, setLoading] = useState(true);
    const [matchData,SetMatchData] = useState(null);
    const tabs = [
        { name: "lineup", title: "LINEUP", component: <Lineup  id={id} matchData={matchData} /> },
        // { name: "matches", title: "MATCHES", component: <Matches id={id} selctedMatchId={selctedMatchId}/> },
        { name: "teamStats", title: "TEAM STATS", component: <TeamStatsTab id={id} matchData={matchData}/> },
    ]

    
    
    const getmatchData = (selctedMatchId)=>{
        if(!selctedMatchId){
            return false;
        }
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/matches/single/${selctedMatchId}`)
        .then(({ data }) => {
            // console.log('dara-',data.tournament.coverImage)
           SetMatchData(data);
        }).catch(error => {
            closeModal();
            toast.error('Error while fetching match data.');
        })
        .finally(() => setLoading(false));
    }

    useEffect(() => {
        getmatchData(selctedMatchId)
    }, [selctedMatchId]);

   
    // console.log("Selected Tab",selectedTab);
    // console.log("selctedMatchId",selctedMatchId);
    return (
    <Modal isOpen={showModal} onRequestClose={closeModal} className="modal match-modal customModal"  shouldCloseOnEsc={false}>
            <div className="modal-close" onClick={closeModal}>
                <RiCloseLine />
            </div>
            {loading ? (
                <Loading/>
            ):(
                <div className="login-register-modal">
                {/* match modal header  */}
                <div className="match-modal-header" 
                style={{ backgroundImage: `linear-gradient(to top, rgb(18, 20, 26) 10%, rgba(18, 20, 26, 0) 150%), url(${matchData?.tournament?.coverImage})` }}>
               
                {(moment(matchData?.datetime).diff(moment(), "seconds") < 0)?(
                    <Results {...matchData} complete={matchData.status === "complete"} />
                ):(
                    <Upcoming {...matchData} />
                ) }

                <div className="auth-modal-tabs">
                    {tabs.map(({ name, title }) => (
                        <div key={name} className={`tab ${selectedTab === name ? "active" : ""}`} onClick={() => setSelectedTab(name)}>
                            {title}
                        </div>
                    ))}
                </div>    
                </div>
                {/* match modal header end  */}

                <div className="modal-tabs-content">
                    {tabs.map(({ name, component }) => selectedTab === name && <div key={name}>
                        {component}
                    </div>)}
                </div>
            </div>
            )}
            
        </Modal>
    )
}

export default MatchModal
