import React, { useEffect, useState } from "react";
import { List, Loading, Checkbox, LeaguesResultItem } from "@common";
import Axios from "axios";
import { connectSocket, disconnectSocket, formatData } from "../Helpers/interceptors";
import moment from "moment";
import { MatchListingByDate } from "../../../../_common";

const Matches = ({ id, teams, game, name }) => {
  // const [page, setPage] = useState(PAGES.ONGOING);
  // const [selected, setSelected] = useState([SELECTEDBRACKET.WINNER, SELECTEDBRACKET.LOSER]);
  // const [loading, setLoading] = useState(true);
  // const [bracketsData, setBracketsData] = useState([]);

  // useEffect(() => {
  //   Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}/bracket_data`)
  //     .then(({ data }) => {
  //       console.log("[MATCHES] before format: ", data);
  //       setBracketsData(formatData(data));
  //       console.log("[MATCHES] after format: ", formatData(data));
  //       connectSocket(id, (_data) => setBracketsData(formatData(_data)));
  //     })
  //     .finally(() => setLoading(false));
  //   return () => disconnectSocket();
  // }, []);

  // const _setSelected = (value) => {
  //   if (selected.includes(value))
  //     if (selected.length === 1) return;
  //     else
  //       selected.splice(
  //         selected.findIndex((el) => el === value),
  //         1
  //       );
  //   else selected.push(value);
  //   setSelected([...selected]);
  // };

  // const noMatch = <div className="col-12 p-5 text-center text-grey">There is no data in this section</div>;
  // const renderMatches = (matches, i, isWinnerTrack) => {
  //   const _matches = matches.filter((match) =>
  //     page === PAGES.RESULTS ? match.matchData?.status === "complete" : match.matchData?.status !== "complete"
  //   );

  //   if (page === PAGES.RESULTS && _matches.length === 0) return null;
  //   return (
  //     <div key={i} className="row smaller-gutter-row mb-4 in-small-gutter4">
  //       {_matches.length !== 0 ? (
  //         <div className="col-12">
  //           <div className="mt-4 uppercase bold">
  //             Round {i + 1} - {isWinnerTrack ? "Winner bracket" : "Loser bracket"}
  //           </div>

  //           <List>
  //             {_matches.map((match) => (
  //               <LeaguesResultItem
  //                 name={name}
  //                 key={match?.matchData?._id}
  //                 forceShow
  //                 hidePlacementText
  //                 upcoming={match.matchData?.status === "upcoming"}
  //                 t1Score={match.teams[0]?.t1Score}
  //                 t2Score={match.teams[1]?.t2Score}
  //                 match={{
  //                   ...match.matchData,
  //                   t1: match.teams[0]?.team,
  //                   t2: match.teams[1]?.team,
  //                   league: {
  //                     teams: teams.map((el) => ({ ...el, team: el.team ? el.team._id : null })),
  //                     game,
  //                   },
  //                 }}
  //               />
  //             ))}
  //           </List>
  //         </div>
  //       ) : null}
  //     </div>
  //   );
  // };

  return (
    <React.Fragment>
      <MatchListingByDate id={id} page={"TOURNAMENT"} />

      {/* <div className="tabs-container">
        <div className="tabs-nav" style={{ borderBottom: "none" }}>
          <nav>
            <button className={page === PAGES.ONGOING ? "active" : ""} onClick={() => setPage(PAGES.ONGOING)}>
              ONGOING & UPCOMING
            </button>
            <button className={page === PAGES.RESULTS ? "active" : ""} onClick={() => setPage(PAGES.RESULTS)}>
              RESULTS
            </button>
          </nav>
          <div className="row p-0 m-0 align-items-center">
            <span className="bold text-grey uppercase font-13">Show:</span>
            <Checkbox
              checked={selected.includes(SELECTEDBRACKET.WINNER)}
              onChange={() => _setSelected(SELECTEDBRACKET.WINNER)}
              label="Winners'"
              className="check-type1"
            />
            <Checkbox
              checked={selected.includes(SELECTEDBRACKET.LOSER)}
              onChange={() => _setSelected(SELECTEDBRACKET.LOSER)}
              label="Losers'"
              className="check-type1"
            />
          </div>
        </div>
      </div>

      {loading || !bracketsData || !teams ? (
        <div className="col-12 p-5 text-center">
          <Loading />
        </div>
      ) : bracketsData.length === 0 ? (
        <div className="col-12 p-5 text-grey text-center">Nothing to show here!</div>
      ) : (
        <>
          {selected.includes(SELECTEDBRACKET.WINNER)
            ? bracketsData[0].length !== 0
              ? bracketsData[0].map((el, i) => renderMatches(el, i, true))
              : noMatch
            : null}
          {selected.includes(SELECTEDBRACKET.LOSER)
            ? bracketsData[1].length !== 0
              ? bracketsData[1].map((el, i) => renderMatches(el, i, false))
              : noMatch
            : null}
        </>
      )} */}
    </React.Fragment>
  );
};

export default Matches;

const PAGES = {
  ONGOING: "ONGOING",
  RESULTS: "RESULTS"
}

const SELECTEDBRACKET = {
  WINNER: "WINNER",
  LOSER: "LOSER"
}