import React, { useState, useRef } from "react";
import Modal from "react-modal";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import Axios from "axios";
import { toast } from "react-toastify";
import { RiCloseLine, RiArrowGoBackLine, RiArrowGoForwardLine } from "react-icons/ri";
import { useEffect } from "react";
import Loading from "../../_common/Loading/Loading";
import { useTransition } from "react";
import useTranslated from "../../../helpers/translationHelper";

const EditImageModal = ({ name, width, height, crop = true, rotate = true, borderRadius, open, onClose, afterImageUpload }) => {
  const editor = useRef();
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);

  const lableDragAndDrop=useTranslated("id_pop-up_-_upload_image_6")
  const lableRestart=useTranslated("id_pop-up_-_upload_image_5")

  const handleDrop = (dropped) => {
    // Get image from drop
    const image = dropped[0];
    const imageObj = new Image();
    imageObj.src = window.URL.createObjectURL(image);

    imageObj.onload = () => {
      const imageSize = image && image.size;
      const imageWidth = imageObj.width;
      const imageHeight = imageObj.height;

      // Is image less than 8MB?
      if (imageSize > 8000000) return toast.error("File must be smaller than 8MB");

      // Is image the minimum dimensions?
      if (imageWidth < width || imageHeight < height) return toast.error(`File must be at least ${width}px x ${height}px in size`);

      setImage(dropped[0]);
    };
  };

  const clearImage = () => {
    setImage(false);
    setUploading(false);
  };

  const handleScale = (e) => setScale(parseFloat(e.target.value));
  const rotateLeft = () => setRotation(rotation - 90);
  const rotateRight = () => setRotation(rotation + 90);

  useEffect(() => {
    clearImage();
    setUploading(false);
  }, [open]);

  useEffect(() => {
    setRotation(0);
    setScale(1);
  }, [image]);

  const onClickSave = () => {
    setUploading(true);

    const canvas = !crop ? editor.current.getImage() : editor.current.getImageScaledToCanvas();

    canvas.toBlob((blob) => {
      const file = new File([blob], `${name}-${width}-${height}.png`, {
        type: "image/png",
      });

      const formData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      formData.append("image", file);

      Axios.post(`${process.env.REACT_APP_CORE_API}/api/upload`, formData, config)
        .then(({ data }) => {
          afterImageUpload(data.file);
        })
        .catch((err) => {
          const msg = err.response.data ? err.response.data.msg : "There was a problem uploading your file";
          toast.error(msg);
          setUploading(false);
        });
    });
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={{ content: { width: width + 50 } }}
      className="modal edit-image-modal"
      contentLabel="Example Modal"
    >
      <div className="modal-close" onClick={onClose}>
        <RiCloseLine />
      </div>
      <div className="modal-header">
        <h2>{/* Upload  */}{name}</h2>
      </div>
      <div className="edit-image-body">
        {image ? (
          <div>
            <AvatarEditor
              ref={editor}
              width={width}
              height={height}
              image={image}
              scale={scale}
              rotate={rotation}
              borderRadius={borderRadius}
            />

            <div className="edit-image-controls">
              {rotate && (
                <button onClick={rotateLeft}>
                  <RiArrowGoBackLine />
                </button>
              )}
              <input
                name="scale"
                type="range"
                className="form-input"
                value={scale}
                onChange={handleScale}
                min="1"
                max="2"
                step="0.01"
                defaultValue="1"
              />
              {rotate && (
                <button onClick={rotateRight}>
                  <RiArrowGoForwardLine />
                </button>
              )}
            </div>

            <div className="save-buttons">
              <button className="button transparent" onClick={clearImage}>
                {lableRestart/* Restart */}
              </button>
              <button className="button primary" onClick={onClickSave} disabled={uploading}>
                {uploading ? <Loading /> : "Upload"}
              </button>
            </div>
          </div>
        ) : (
          <Dropzone multiple={false} accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]} onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div className="image-dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>{lableDragAndDrop/* Drag and drop or click to upload image */}</p>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    </Modal>
  );
};

// accessibility
Modal.setAppElement("#root");

export default EditImageModal;
