import React, { useState, useEffect } from "react";

import Axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../../../_common/Loading/Loading";
import { useHistory } from "react-router-dom";

const ConnectLOL = ({ gameData, afterConnect, selectedGame }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [summonerName, setSummonerName] = useState("");
  const [primaryRole, setPrimaryRole] = useState("");
  const [secondaryRole, setSecondaryRole] = useState("");
  const [code, setCode] = useState("");
  const [session, setSession] = useState("");
  // let session= localStorage.getItem('sessionToken')
  useEffect(() => {
    let sessionStorage = localStorage.getItem("sessionToken");
    setSession(sessionStorage);
  }, []);

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   try {
  //     const { data } = await Axios.post(`${process.env.REACT_APP_LOL_API}/api/connect`, {
  //       game: gameData._id,
  //       name: summonerName,
  //       primaryRole: primaryRole,
  //       secondaryRole: secondaryRole,
  //       code,
  //     });
  //     toast.success("Successfully created LoL player!");
  //     setLoading(false);

  //     afterConnect(data.player);

  //     history.push(`/game-profile/${data.player._id}`);
  //   } catch (e) {
  //     const msg = e.response && e.response.data ? e.response.data.msg : "There was a problem creating player";
  //     toast.error(msg);
  //     setLoading(false);
  //   }
  // };

  const roles = gameData.configuration.playerTypes.filter(({ name }) => !name.includes("SUB")) || [];

  if (selectedGame.shortName == "LoL") {
    return (
      <>
        <a
          // href={`${process.env.REACT_APP_CSGO_API}/authenticate`}
          // https://auth.riotgames.com/authorize?redirect_uri=https://dev-api-valorant.leagues.gg/api/auth/oauth2-callback&client_id=leaguesgg&response_type=code&scope=openid
          href={`https://auth.riotgames.com/authorize?redirect_uri=${process.env.REACT_APP_LOL_API}/api/auth/oauth2-callback&client_id=leaguesgg&response_type=code&scope=openid`}
          class="riotsbutton"
        >
          <svg class="f--icon-base" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">
            <path
              d="M13.056262,3.9137866L3.2820513,8.4319582l2.4359715,9.2688208l1.8598294-0.2324772L7.062356,11.6462278
     l0.6165743-0.2729092l1.0410995,5.953475l3.1738386-0.3840961l-0.5660353-6.4285393l0.5963583-0.2628012l1.1623945,6.5498323
     l3.2041626-0.3942032l-0.6165752-7.0552206l0.6064663-0.2628021l1.2634716,7.1562986l3.1738377-0.3840952V5.8241549
     L13.056262,3.9137866z M13.2887402,17.9635811l0.1617241,0.9097004l7.2674847,1.2129326v-3.0323315l-7.4191008,0.9096985H13.2887402
     z"
            ></path>
          </svg>
          <span>Login with Riot Games</span>
        </a>
      </>
    );
  } else {
    return (
      <>
        {/* <a href={`${process.env.REACT_APP_CSGO_API}/authenticate`} class="steambutton"> */}
        <div className="create-text">
          {" "}
          <span> Right now you are not on this Game </span>
        </div>
        <a
          // class="button primary thicc"
          href={`${process.env.REACT_APP_VALORANT_URL}?lst=${session}&shortName=VL&type=account&number=3`}
        >
          {/* Get Me There */}
          <button className="button primary thicc vl-button">Get Me There</button>
        </a>
      </>
    );
    // <div className="create-text">  <span> Right now you are not on this Game <a href={`${process.env.REACT_APP_VALORANT_URL}?lst=${session}`} > click here for this game </a> </span></div>
  }
};

export default ConnectLOL;
