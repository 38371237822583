import React, { useEffect, useState } from "react";
import { SimpleTabs, GameOverviewBoxCSGO, Loading, PageWithTabs } from "@common";
import Axios from "axios";
import { Link } from "react-router-dom";
import GamesOverviewTeamMembersListCSGO from "./GamesOverviewTeamMembersList/GamesOverviewTeamMembersListCSGO";
import MissingData from "../MissingData/MissingData";
import default_image from "@img/image.jpg";
import useTranslated from "../../../../helpers/translationHelper";
const Games = ({ match, setPageMap }) => {
  // console.log('match----',match)
  const [page, setPage] = useState(0);
  const [basicOrAdvType, setBasicOrAdvType] = useState(0);
  const [loading, setLoading] = useState(true);
  const [championsData, setChampionsData] = useState();
  const [noMapDataFound, setNoMapDataFound] = useState(true);
  const [bestOf, setBestOf] = useState(null);
  const [winnerTeam, setWinnerTeam] = useState(null);
  const [loserTeam, setLoserTeam] = useState(null);
  // const [winnerTeamKey,setWinnerTeamKey] = useState(null);
  // const [loserTeamKey,setLoserTeamKey] = useState(null);
  const mapTab = useTranslated('id_match_page_(games)_1')
  const victoryTag = useTranslated('id_match_page_(games)_2')
  const defeatTag = useTranslated('id_match_page_(games)_3')
  useEffect(() => {
    //   Axios.get(`${process.env.REACT_APP_LOL_API}/api/riot/champions`).then(({ data }) => {
    //     setChampionsData(data);
    setLoading(false);
    //   });
  }, []);

  useEffect(() => {
    setBestOf(match.bestOf);
    let map = match.csgoData.mapData[page];
    console.log("asddsadsad-dsad", map);
    if (String(map) !== "null") {
      setNoMapDataFound(false);
      map &&
        Object.keys(map.processedteamData).filter((teamOneTwo) => {
          if (map.processedteamData[teamOneTwo].winner === true) {
            let winteam = [match.t1, match.t2].filter((team) => team && team._id === map.processedteamData[teamOneTwo].teamDbId);
            setWinnerTeam(winteam[0]);
          }
          if (map.processedteamData[teamOneTwo].winner === false) {
            let loseteam = [match.t1, match.t2].filter((team) => team && team._id === map.processedteamData[teamOneTwo].teamDbId);
            setLoserTeam(loseteam[0]);
          }
        });
    }
  }, [match, page]);

  const pad2 = (n) => {
    return n < 10 ? "0" + n : n;
  };

  const lanes = match.league && match.league.game.configuration.playerTypes;

  if (!lanes) {
    return null;
  }

  const getMapName = (map, i, matchBestOf) => {
    let mapName = "Not Found";
    var mapArrayName = map.map.split("_");
    if (matchBestOf > 1) {
      if (map.map === "Total Maps") {
        mapName = map.map;
      } else {
        mapName = `${mapTab} ${i} - ${mapArrayName[mapArrayName.length - 1]}`;
      }
      // var mapArrayName = map.name.split('_');
      // return mapArrayName[mapArrayName.length - 1] || 'Map Name Not Found';
    } else {
      mapName = `${mapTab} ${i + 1} - ${mapArrayName[mapArrayName.length - 1]}`;
      // var mapArrayName = mapp.split('_');
      // return mapArrayName[mapArrayName.length - 1] || 'Map Name Not Found';
    }
    return mapName;
  };

  const setPageMapName = (page) => {
    setPage(page);
    match.csgoData.mapData[page] && setPageMap(match.csgoData.mapData[page].map || null);
  };

  let rome = match.csgoData.mapData; //.filter((mapppp) => String(mapppp) !== "null");
  let pages = [];
  // if(bestOf > 1){
  //  pages.push("All Maps");
  match.csgoData.mapData.map((_, i) => {
    pages.push(getMapName(_, i, match.bestOf));
    // String(_) !== "null" ? pages.push(`Map ${i + 1} - ${getMapName(_.map)}`) : pages.push(`Map ${i + 1} - No DATA`)
  });
  // }else{
  //   pages = rome.map((_, i) => `Map ${i + 1} - ${getMapName(_.map)}`);
  // }
  console.log("page=", pages[page]);
  let tabs = ["Basic Stats", "Advanced"];

  const getTeamSection = (teamOneOrTwo, map) => {
    const isWinner = map.processedteamData[teamOneOrTwo].winner;

    if (isWinner) {
      return (
        winnerTeam && (
          <>
            <Link style={{ textDecoration: "none" }} to={`/teams/${winnerTeam._id}`}>
              <img
                src={winnerTeam.logo || default_image}
                alt={winnerTeam.name}
                className={winnerTeam?.logoCropped || winnerTeam?.logoCropped === undefined ? "border-radius-100" : ""}
              />
            </Link>
            <span className="text-14 bold uppercase">{winnerTeam.name}</span>

            <span className="pill success">{/* VICTORY */}{victoryTag}</span>
          </>
        )
      );
    } else {
      return (
        loserTeam && (
          <>
            <Link style={{ textDecoration: "none" }} to={`/teams/${loserTeam._id}`}>
              <img
                src={loserTeam.logo || default_image}
                alt={loserTeam.name}
                className={loserTeam?.logoCropped || loserTeam?.logoCropped === undefined ? "border-radius-100" : ""}
              />
            </Link>
            <span className="text-14 bold uppercase">{loserTeam.name}</span>
            <span className="pill danger">{/* DEFEAT */}{defeatTag}</span>{" "}
          </>
        )
      );
    }
  };

  const getTeamNameWithLogo = (teamOneOrTwo, map) => {
    const isWinner = map.processedteamData[teamOneOrTwo].winner;

    if (isWinner) {
      return winnerTeam;
    } else {
      return loserTeam;
    }
  };

  const getColor = (xt, startSide) => {
    if (startSide === "T") {
      if (xt === "x" || xt === "t") {
        return "matricClr_orange";
      } else {
        return "matricClr_blue";
      }
    } else {
      if (xt === "y" || xt === "z") {
        return "matricClr_blue";
      } else {
        return "matricClr_orange";
      }
    }
  };

  return loading ? (
    <div className="text-center">
      <Loading />
    </div>
  ) : !match.csgoData.mapData.length ? (
    <MissingData
      text={
        match.scoreReason && (
          <span>
            Reason: <em>{match.scoreReason}</em>
          </span>
        )
      }
    />
  ) : (
    <div className="your-matches-games">
      <div className="simple-tabs-wrapper">
        <SimpleTabs pages={pages} page={page} setPage={setPageMapName} /> {/* Maps Tabs */}
      </div>

      {rome.map((map, i) => {
        console.log("map-", String(map));
        if (i === page) {
          if (String(map) === "null" || noMapDataFound) {
            return (
              <MissingData
                text={
                  <span>
                    Reason: <em>Map Data Not Found</em>
                  </span>
                }
              />
            );
          } else {
            return (
              <div>
                {/* {pages[page] !== "Map 1 - Total Maps" &&  */}
                <div className="row smaller-gutter-row">
                  <div className="col-5 center games-header">{getTeamSection("team1", map)}</div>
                  <div className="col-2 center games-header matrics">
                    <span className="mainMatrics ml-0">
                      {map.processedteamData.team1.totalRoundScore}:{pad2(map.processedteamData.team2.totalRoundScore)}
                    </span>
                    <span className="pill underMatrics ml-0">
                      <span className={getColor("x", map.processedteamData.team1.startingSide)}>{map.FirstHalfScore.team1}</span>:
                      <span className={getColor("y", map.processedteamData.team2.startingSide)}>{map.FirstHalfScore.team2}</span>
                      <span className="matricClr_grey">; </span>
                      <span className={getColor("z", map.processedteamData.team1.startingSide)}>{map.SecondHalfScore.team1}</span>:
                      <span className={getColor("t", map.processedteamData.team2.startingSide)}>{map.SecondHalfScore.team2}</span>
                      {map.OvertimeScore && map.OvertimeScore.team1 && map.OvertimeScore.team2 && (
                        <>
                          <span className="matricClr_grey">; </span>
                          <span className="">{map.OvertimeScore.team1}</span>:<span className="">{map.OvertimeScore.team2}</span>
                        </>
                      )}
                    </span>
                  </div>
                  <div className="col-5 center games-header">{getTeamSection("team2", map)}</div>
                </div>
                {/* } */}

                <div className="row smaller-gutter-row pt-3 pb-3">
                  <div className="col-12">
                    <GameOverviewBoxCSGO map={map} champions={championsData} />
                  </div>
                </div>
                <SimpleTabs pages={tabs} page={basicOrAdvType} setPage={setBasicOrAdvType} />
                <div className="row smaller-gutter-row">
                  <div className="col-6">
                    {map.processedteamData.team1 && (
                      <GamesOverviewTeamMembersListCSGO
                        basicOrAdvType={basicOrAdvType}
                        teamdetail={getTeamNameWithLogo("team1", map)}
                        PlayersOnTeam={map.PlayersOnTeam1}
                        lanes={lanes}
                        teamId={100}
                        map={map}
                        champions={championsData}
                      />
                    )}
                  </div>
                  <div className="col-6">
                    {map.processedteamData.team2 && (
                      <GamesOverviewTeamMembersListCSGO
                        basicOrAdvType={basicOrAdvType}
                        teamdetail={getTeamNameWithLogo("team2", map)}
                        PlayersOnTeam={map.PlayersOnTeam2}
                        lanes={lanes}
                        teamId={200}
                        map={map}
                        champions={championsData}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          }
        }
      })}
    </div>
  );
};

export default Games;
