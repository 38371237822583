import React, { useEffect, useState } from 'react'
import './_steps.scss'
import { LightenDarkenColor } from 'lighten-darken-color';


const iconStyle = { width: 30 };
const TeamStats = ({ team, gameData, vetoData, teamName, playerData, stats }) => {
    const [winRate, setWinRate] = useState(0)
    const [kd, setKd] = useState(0)
    const [playerStatsData, setPlayerStatsData] = useState([])
    useEffect(() => {
        if (stats && stats.teamStats) {
            if (stats.teamStats.lastTwenty) {
                setWinRate(parseFloat(((stats.teamStats.lastTwenty.wins / stats.teamStats.lastTwenty.played) * 100).toFixed(2)))
            }
            if (stats.teamStats.teamKDA) {
                setKd(parseFloat(((stats.teamStats.teamKDA.kills / stats.teamStats.teamKDA.deaths)).toFixed(2)))
            }
        }
        if (stats && stats.playerStats) {
            let playerKDS = stats.playerStats.map(player => {
                let playerKD = parseFloat((player.kills / player.deaths).toFixed(2))
                let playerWinRate = parseFloat(((player.wins / player.played) * 100).toFixed(1))
                let assistsAverage = parseFloat((player.assists / player.played).toFixed(1))
                return { id: player._id, kd: playerKD, winRate: playerWinRate, assistsAverage }
            })
            setPlayerStatsData(playerKDS)
        }

    }, [stats])

    return (
        <div className="player-stats d-flex flex-column text-white">
            <div className="row stat m-0 mt-1 py-2">
                <div className="col-2 d-flex flex-column justify-content-center align-items-center m-0 p-0">
                    <img src={team.logo} style={iconStyle} />
                </div>
                <div className="col-4 p-0 m-0">
                    <p className="bold py-1 m-0 overflow-ellipsis">{team.name}</p>
                    {/* <p className="bold default-text py-1 m-0">4.2 <span className="faded">HLTV R.</span></p> */}
                </div>
                <div className="col-2 p-0 m-0">
                    <div className="mini-pie-chart" style={{
                        background: `conic-gradient(${LightenDarkenColor(gameData.mainColour, -180)} 0, ${LightenDarkenColor(gameData.mainColour, -180)} ${Math.round(
                            100 - winRate
                        )}%, ${gameData.mainColour} 0, ${gameData.mainColour} 100%)`,
                    }}
                    ></div>
                </div>
                <div className="col-4 p-0 m-0">
                    <div className="flex-column">
                        <div className={`text-10 bold ${winRate == 50 ? 'color-warning' : winRate > 50 ? 'color-success' : 'color-danger'}`}>{`${winRate}% WR`}</div>
                        <div className="text-10 bold faded">Last 20 games</div>
                        <div className="text-10 bold faded">
                            <span className="default-text">{`${kd || 0}KD`}</span>
                            {/* <span className="text-white bold ml-1">4.2/5.4</span> */}
                        </div>

                    </div>
                </div>


            </div>
            <div className="d-flex flex-column">
                {team.members && team.members.map(member => {
                    let role = member.role && member.role.split(":").length ? member.role.split(":")[1] : ''
                    const { player } = member
                    const position = gameData.configuration.playerTypes.find(({ name }) => name === role);
                    if (player && playerData.find(gamePlayer => gamePlayer?.player?._id == player._id)) {
                        const vetoPlayerID = teamName == 't1' ? vetoData.t1Player : vetoData.t2Player
                        const isVetoPlayer = (player._id == vetoPlayerID)
                        const stats = playerStatsData.find(data => data.id == player._id)
                        let kd = stats && stats.kd ? stats.kd : 0
                        let wr = stats && stats.winRate ? stats.winRate : 0

                        return (
                            <div className={`row stat m-0 mt-2 py-2 ${isVetoPlayer ? "order-0" : 'order-1'}`}>
                                <div className="col-2 d-flex flex-column justify-content-center align-items-center m-0 p-0">
                                    <img className="player-img" src={player.avatarImage} style={iconStyle} />
                                </div>
                                <div className="col-4 p-0 m-0 d-flex flex-column">
                                    <p className={"text-12 bold py-1 m-0 overflow-ellipsis"} >{isVetoPlayer ? <span className="fa fa-crown mr-1 veto-icon"></span> : ''}{player.name}</p>
                                    {position &&
                                        <div className="bold p-0 m-0 faded row">
                                            <div> <img className="col-8 m-0 p-0 pos-icon" src={position.icon} alt={position.name} style={{ width: "25px" }} /> </div>
                                            <div className="col-4 m-0 p-0 text-10 uppercase bold">{position.name}</div>
                                        </div>
                                    }
                                </div>
                                <div className="col-2 p-0 m-0 d-flex flex-column text-center">
                                    <div className="text-12 bold text-white">{kd}</div>
                                    <div className="text-10 faded bold">K/D</div>
                                </div>
                                <div className="col-2 p-0 m-0 d-flex flex-column text-center">
                                    <div className={`text-12 bold ${wr == 50 ? 'color-warning' : wr > 50 ? 'color-success' : 'color-danger'}`}>{`${wr}%`}</div>
                                    <div className="text-10 faded bold">WR</div>
                                </div>
                                {/* <div className="col-2 p-0 m-0 d-flex flex-column">
                                    <div className="text-12 bold default-text">4.2</div>
                                    <div className="text-10 faded bold">HLTV R.</div>
                                </div> */}
                                <div className="col-2 p-0 m-0 d-flex flex-column text-center">
                                    <div className="text-12 bold">{stats && stats.assistsAverage ? stats.assistsAverage : 0}</div>
                                    <div className="text-10 faded bold">ASSISTS</div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default TeamStats
