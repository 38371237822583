import React, { Component } from 'react';
import Modal from "react-modal";
import { RiCloseLine } from "react-icons/ri";
import {
    Palette
  } from 'react-page-maker';
class ElementsModal extends Component {
    
    render() {
        return (
            <Modal 
            position='absolute'
            style={{ overlay: {position:'unset'} }}
            isOpen={this.props.isModalOpen} onRequestClose={() => this.props.closeModal()} className="modal auth-modal" shouldCloseOnEsc={false}>
                <div className="modal-close" onClick={() => this.props.closeModal()} >
                    <RiCloseLine />
                </div>
                

                <div >
                    <div className="modal-header">
                        
                        <h2 className="bold uppercase">Add Content</h2>
                        <h3>Connect your game account to participate in tournaments, scrims etc. You can always add your game account later.</h3>
                    </div>
                    <div className="auth-modal-tabs">
                        
                        <div className={`tab active`}>
                            Add module
                        </div>
                        <div className={`tab disabledTab`} title={`Sorry! This feature is not available yet.`}>
                            Premade templates
                        </div>
                      
                    </div>
                    <div className="modal-body">
                        <Palette paletteElements={this.props.paletteElements} />
                        
                    </div>
                </div>
            </Modal>
            );
  }
}

export default ElementsModal;
