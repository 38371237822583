import React, { useEffect, useState } from "react";
import Axios from "axios";
import { GameItem, Button, Item, SliderItemFrontEnd, MirrorText, SimplePageWithTabs } from "@common";
import { Link } from "react-router-dom";
import default_image from "@img/image.jpg";
import GlitchClip from "react-glitch-effect/core/Clip";
import SliderFrontend from "../Slider/SliderFrontend";
import LeagueBoxForMatches from "./includes/LeagueBoxForMatches";
import { allCountries } from "../../../utils/countriesWithCode";

export const ItemSingle = ({ needstatus, leagues = [], filterContry = null, results }) => {
  const [leaguesSorted, setLeaguesSorted] = useState([]);
  useEffect(() => {
    if (filterContry === null) {
      setLeaguesSorted(leagues);
    } else {
      setLeaguesSorted(leagues.filter(({ country }) => allCountries[country.code] === filterContry));
    }
  }, [leagues, filterContry]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="tabs-container">
          <div className="row smaller-gutter-row">
            {leaguesSorted.map((league) => (
              <LeagueBoxForMatches needmstatus={needstatus} league={league} results={results} manual />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const UpcomingMatchesManual = ({ data }) => {
  const [contries, setContries] = useState([]);
  const [filterContry, setFilterContry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [leagues, setLeagues] = useState([]);
  const [selectedLeagues, setSelectedLeagues] = useState(null);
  const [blueText, setBlueText] = useState("Loading...");
  const [whiteText, setWhiteText] = useState("Loading...");

  const tabs = {
    "ongoing & upcoming": <ItemSingle needstatus="upcoming" leagues={leagues} filterContry={filterContry} />,
    Results: <ItemSingle needstatus="complete" leagues={leagues} filterContry={filterContry} results />,
  };

  useEffect(() => {
    if (data.BlueText) {
      setBlueText(data.BlueText);
    }
    if (data.WhiteText) {
      setWhiteText(data.WhiteText);
    }
    if (data && data.leagues && data.leagues.length) {
      setSelectedLeagues(data.leagues.map(({ _id }) => _id).toString());
    }
  }, [data]);

  useEffect(() => {
    selectedLeagues &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/upcomingMatches/`, {
        params: { ids: selectedLeagues, withMatches: true },
      }).then(({ data }) => {
        let dataleagueCountries = [];

        data.map(({ country }) => {
          if (!dataleagueCountries.includes(allCountries[country.code])) {
            dataleagueCountries.push(allCountries[country.code]);
          }
        });

        setContries(dataleagueCountries);
        setLeagues(data);
        setLoading(false);
      });
  }, [selectedLeagues]);

  return (
    <div className="upcomingMatchesManual">
      {!loading && (
        <SimplePageWithTabs
          title={
            <div className="maintitle">
              <h1>
                <span className="outline">{blueText}</span> {whiteText}
              </h1>
            </div>
          }
          tabs={tabs}
          tabs_props={{
            show_filter: true,
            custom_filter: (
              <>
                <select onChange={(e) => setFilterContry(e.target.value === "ALL" ? null : e.target.value)} className="form-input matchesDropdown">
                  <option selected hidden>
                    FILTER
                  </option>
                  <option disabled>FILTER</option>
                  {/* <option disabled selected>
                    FILTER
                  </option> */}
                  <option value={null}>ALL</option>
                  {contries &&
                    contries.length &&
                    contries.map((country) => {
                      return <option selected={filterContry === country}>{country}</option>;
                    })}
                </select>
              </>
            ),
          }}
          frontpage
        />
      )}
    </div>
  );
};

export default UpcomingMatchesManual;
