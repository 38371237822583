import React from "react";
import icon_unranked from "@img/unranked.png";
import _ from 'lodash';

const RankDataLeagueStats = ({ leagueData = [] }) => {
  let { icon, division, wins, losses, games } = leagueData;

  const nth = (n) => { return ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th" }

  return (
    !_.isEmpty(leagueData) ?
      <div className="faded-box-item no-padding mt-2 mb-2 league-entries">
        <div className="inner flex-row" style={{ padding: "11px" }}>
          <img src={icon} alt={'league logo'} className="profile-images mr-3" />
          <div className="flex-column">
            <div className="text-12 bold" style={{ marginBottom: "5px" }}>{`${division}${nth(division)} Division`}</div>
            <div className="text-10">
              <span className="bold">{Math.round((wins / (wins + losses)) * 100)}% WR</span>{" "}
              <span className="faded">{games} games</span>
            </div>
          </div>
        </div>
      </div>
      :
      <div className="faded-box-item no-padding mt-2 mb-2 league-entries">
        <div className="inner flex-row" style={{ padding: "11px" }}>
          <img src={icon_unranked} alt={'league logo'} className="profile-images mr-3" />
          <div className="flex-column">
            <div className="text-12 bold" style={{ marginBottom: "5px" }}>{'No Division'}</div>
            <div className="text-10">
              <span className="bold">0% WR</span>{" "}
              <span className="faded">0 games</span>
            </div>
          </div>
        </div>
      </div>
  )
};

export default RankDataLeagueStats;
