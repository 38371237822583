import React, { useEffect, useState } from "react";
import MatchRowForLeagueBox from "./MatchRowForLeagueBox";
import { GameItem, Button, Item, SliderItemFrontEnd, MirrorText, SimplePageWithTabs } from "@common";
import { Link } from "react-router-dom";
import moment from 'moment';

const LeagueBoxForMatches = ({ needmstatus, league = {}, results, manual }) => {
  console.log("status status-", needmstatus);
  console.log("league-", league);
  const [leaguematches, setLeaguematches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [leagueName, setLeagueName] = useState("Loading...");
  const [leagueDivisionName, setLeagueDivisionName] = useState("Loading...");

  useEffect(() => {
    console.log("league-", league);
    let matches = results ? league.completedMatches : league.upcomingMatches;
    if (league.name) {
      setLeagueName(league.name);
      setLeagueDivisionName(league.leagueDivisionName);
    }
    if (needmstatus && matches) {
      let leagueMatchess = matches.sort((a, b) => moment(a.datetime) - moment(b.datetime));
      if (results) leagueMatchess = leagueMatchess.reverse();
      setLeaguematches(leagueMatchess.filter((match) => match.status === needmstatus));

      setLoading(false);
    }
  }, [league, needmstatus]);

  return (
    <div className={`slider-item mb-4 col-3`}>
      <div className={`item-title`}>
        <Link className="btn text-secondary" to={`/league/${league._id}`}>
          <Button name="cta-button-transparent notLikeButton">{leagueName}</Button>
        </Link>
      </div>

      {!loading ? (
        <div className="leaguesMatches  mt-4">
          {leaguematches.length ? (
            leaguematches.map((match) => (
              <MatchRowForLeagueBox
                match={match}
                needmstatuss={needmstatus}
                leagueDivisionName={leagueDivisionName}
                leagueCountry={league.country}
                league={league}
              />
            ))
          ) : (
            <div className="nomatchfound pt-3">{needmstatus === "complete" ? "no results yet" : "No Upcoming Matches"}</div>
          )}
        </div>
      ) : (
        "Loading Match.."
      )}
    </div>
  );
};

export default LeagueBoxForMatches;
