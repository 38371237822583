import React, { useState, useEffect, useContext } from "react";
import "./_steps.scss"
import './TeamStats'
import { TimerProgressBar } from '@common';
import TeamStats from "./TeamStats";
import MapStats from "./MapStats";
import { Tabs, Button } from "@common";
import { isTeamPlayer } from "../side-effecrs";
import { AppContext } from "@context/AppContext";
import { RiEyeLine, RiEyeOffLine, RiFileCopyLine, RiFileCopyFill } from "react-icons/ri";
import moment from "moment";
import { toast } from "react-toastify";
import copy from 'copy-to-clipboard';

const Step = ({ stepData, maps, onMapAction,  setLastStepCompletionTime, title, matchData, gameData, goToNextStep, vetoData, setTeamVetoState, vetoEnded}) => {
    const [statsComponent, setStatsComponent] = useState("Player Stats")
    const [isTurn, setIsTurn] = useState(false)
    const [canUserSelect, setCanUserSelect] = useState(false)
    const { user } = useContext(AppContext);
    const [type, setType] = useState(stepData && stepData.action ? stepData.action.toLowerCase() : '')
    const [linkVisible, setLinkVisible] = useState(false)
    const [showServerData, setShowServerData] = useState(true)
    const [vetoServerData, setVetoServerData] = useState(vetoData.server)
    const [isScrim, setIsScrim] = useState(!!matchData.scrim && matchData.scrim.map.toLowerCase() != 'veto')
    console.log("vetoServerData :", vetoServerData)
    useEffect(() => {
        if (!vetoEnded) {
            _setTurn()
            setType(stepData && stepData.action ? stepData.action.toLowerCase() : '')
        }
    }, [stepData, matchData])

    const toggleLinkVisibility = () => {
        setLinkVisible(!linkVisible)
    }
    useEffect(() => {
        setServerData()
        if (vetoData.server) {
            setVetoServerData(vetoData.server)
        }
        setIsScrim((!!matchData.scrim) && (matchData.scrim.map.toLowerCase() != 'veto'))
    }, [vetoData, matchData, vetoEnded])
    useEffect(() => {
        !vetoEnded && _setTurn()
    }, [])

    const setServerData = () => {
        let setPlayers =  matchData.csgoData.players.t1.concat(matchData.csgoData.players.t2)
        let isUserSet = false
        let coaches = (matchData.t1.members.filter(member => member.role == 'Coach')).concat(matchData.t2.members.filter(member => member.role == 'Coach'))
        setPlayers.map(player => {
            if (user.state.players && !!user.state.players.find(userPlayer => player.player && `${player.player._id}` == `${userPlayer._id}`)) {
                isUserSet = true
            }
        })
        coaches.map(coach => {
            if (user.state.coaches && !!user.state.coaches.find(userCoach => coach.coach && `${coach.coach._id}` == `${userCoach._id}`)) {
                isUserSet = true
            }
        })
        if ((matchData.status && matchData.status == "complete") || !isUserSet) {
            setShowServerData(false)
        }
    }
    const _setTurn = () => {
        let isT1User = matchData.veto.userTeam == 't1'
        let isT2User = !isT1User && matchData.veto.userTeam == 't2'
        const label = type == "ban" ? "banning map" : type == "pick" ? "picking map" : type == "side_pick" ? "picking side" : ''
        let team = isT1User ? matchData.t1 : isT2User ? matchData.t2 : null
        if (team) {
            setIsTurn((stepData.selectionPower == team._id))
            setCanUserSelect((`${user.state._id}` == `${stepData.selectedBy}`))
        }
        let isT1Turn = stepData.selectionPower == matchData.t1._id
        let isT2Turn = stepData.selectionPower == matchData.t2._id
        if (label && (isT1Turn || isT2Turn)) {
            setTeamVetoState(isT2Turn ? "t2" : "t1", label)
            setTeamVetoState(isT2Turn ? "t1" : "t2", "Watching")
        }
    }

    const tabs = ["Player Stats", "Map Stats"]
    const sides = [{ label: "Counter-Terrorist", value: "ct" }, { label: "Terrorist", value: "t" }]
    let heading = `${!isTurn ? "Waiting for opponent to" : ''} ${type == "side_pick" ? `Pick Starting Side for ${stepData.map.name}` : title}`
    const consoleString = (vetoServerData && vetoServerData.ip && vetoServerData.password) ? `${vetoServerData.ip}; password ${vetoServerData.password}` : ''
    
    return (
        <div className="d-flex flex-column">
            {(showServerData || !vetoEnded) && <TimerProgressBar key={vetoData.currentStep} classname={!vetoEnded ? isTurn ? "turn" : "waiting" : "ended"} time={!vetoEnded ? stepData.startedTime : isScrim ? moment().format() : ((vetoData.steps[vetoData.steps.length - 1]).finishedAt)} totalTime={!vetoEnded ? stepData.stepTime : isScrim ? moment(matchData.datetime).diff(moment(), "seconds") : (10 * 60)} onTimeComplete={setLastStepCompletionTime} />}
            <div className="pt-5 veto-step-tabs">
                <div className="tabs-container">
                    <div className="tabs-nav veto-steps">
                        <nav>
                            {tabs.map((tab) => (
                                <button className={`veto-stats-tab ${statsComponent == tab ? "active" : ''}`} onClick={() => setStatsComponent(tab)}>
                                    {tab}
                                </button>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            {(showServerData || !vetoEnded) && <div className="m-0 py-1 step-heading">
                <h3 className={`p-0 m-0 ${vetoEnded ? "color-success" : isTurn ? "turn-heading" : "wait-heading"}`}> {title ? heading : "TIME TO CONNECT!"} </h3>
            </div>}
            <div className="maps-list row m-0">
                <div className="col-4 p-2 m-0">
                    {matchData && (statsComponent == "Map Stats" ? <MapStats gameData={gameData} maps={maps} team={matchData.t1} stats={matchData?.stats?.t1Stats || {}} /> : <TeamStats gameData={gameData} stats={matchData?.stats?.t1Stats || {}} team={matchData.t1} playerData={matchData.csgoData?.players?.t1} vetoData={vetoData} teamName={'t1'} />)}
                </div>
                {!vetoEnded ? (type != "side_pick" ? <div className="col-4 p-0 m-0 row align-items-center">
                    {maps.map((mapObj, index) => {
                        return (
                            <div className={`map p-0 mx-0 my-1 col-12 ${!mapObj.state ? type : ''} ${mapObj.state ? `map-${mapObj.state}` : ''}`} onClick={() => onMapAction(mapObj.id, type)} style={(mapObj.state || !(isTurn && canUserSelect)) ? { pointerEvents: "none" } : {}}>
                                <div style={{ backgroundImage: `url(${mapObj.img})` }} className="map-bg"></div>
                                {!mapObj.state ? (<><span className="map-title">{mapObj.name}</span>
                                    <span className={`map-title-${type} map-action`}>{`${type} MAP`}</span></>) :
                                    (mapObj.state == "picked" && (mapObj.t || mapObj.ct)) ?
                                        <div className={`d-flex flex-row justify-content-between map-title-pick  ${isTurn ? "pick-side" : ''}`}>
                                            {<span className="side-pick-btn ml-3">{mapObj.t == matchData.t1._id ? "T" : mapObj.ct == matchData.t1._id ? "CT" : ''}</span>}
                                            <span>{`${mapObj.name} - ${mapObj.state}`}</span>
                                            <span className="side-pick-btn mr-3"> {mapObj.t == matchData.t2._id ? "T" : mapObj.ct == matchData.t2._id ? "CT" : ''} </span>
                                        </div> :
                                        <span className={mapObj.state}>{`${mapObj.name} - ${mapObj.state}`}</span>
                                }
                            </div>
                        )
                    })}
                </div> : (stepData && stepData.map) ?
                    <div className="col-4 d-flex flex-column align-items-start" style={{ textTransform: "uppercase", justifyContent: "flex-start" }}>
                        <div className={`map p-0 my-3 col-12`} style={{ pointerEvents: "none" }}>
                            <div style={{ backgroundImage: `url(${stepData.map.img})`, color: '#bbc8db' }} className="map-bg"></div>
                            <><span className="map-title bold text-14">{`${isTurn ? 'Opponent' : 'You'} picked ${stepData.map.name}`}</span></>
                        </div>
                        <h5 className={`${isTurn ? "turn" : "wait"}-heading`}> {`${isTurn ? "Pick Side" : "Opponent is picking side"} for ${stepData.map.name}`} </h5>
                        {sides.map(side => {
                            return (
                                <div className={`map p-0 my-2 col-12 pick`} onClick={() => onMapAction(stepData.map.id, type, side.value)} style={(!(isTurn && canUserSelect)) ? { pointerEvents: "none" } : {}}>
                                    <div style={{ backgroundImage: `url(${stepData.map.img})` }} className="map-bg"></div>
                                    <><span className="map-title bold">{side.label}</span>
                                        <span className={`map-title-pick map-action`}>{`PICK SIDE`}</span></>
                                </div>

                            )
                        })}


                    </div> : <></>) :
                    <div className="col-4 p-0 m-0 row align-items-center" style={{ alignItems: "baseline" }}>
                        <div className="col-12 p-0 m-0 d-flex flex-column color-success align-items-start" style={{ justifyContent: "center" }}>
                            {showServerData && <div className="p-0 m-0 col-12">
                                {vetoServerData && vetoServerData.connectionString ?
                                    // <Button name="join-button" onClick={() => window.open(vetoServerData.connectionString, "_blank")}> JOIN SERVER </Button>
                                    ""
                                    : <Button name="waiting-server-button"> WAITING FOR SERVER </Button>
                                }

                            </div>}
                            {showServerData && <div className="p-0 m-0 col-12">
                                <div className="col-12 d-flex flex-column">
                                    <h6 className="color-white">CONNECT WITH IN-GAME CONSOLE</h6>
                                    {!!consoleString ? <div className="col-12 p-0 py-2 m-0 row game-link justify-content-center">
                                        <div className="col-8 p-0 m-0 pl-2" style={{ textAlign: "left", fontSize: "12px" }}>{linkVisible ? consoleString : "HIDDEN"}</div>
                                        <div onClick={toggleLinkVisibility} className="p-0 m-0 col-2 link-icon">
                                            {linkVisible ? <RiEyeOffLine size="1.2rem" color="#68717E" /> : <RiEyeLine size="1.2rem" color="#68717E" />}
                                        </div>
                                        <div className="col-2 p-0 m-0  link-icon"
                                            onClick={() => {
                                                copy(consoleString)
                                                toast.success("Copied to clipboard")
                                            }
                                            }>
                                            <RiFileCopyLine size="1.2rem" color="#68717E" />
                                        </div>
                                    </div> : <div className="col-12 p-0 py-2 m-0 row game-link justify-content-center"> You will see the connection string once the server is set up</div>}
                                </div>

                            </div>}
                            <h5 className="turn-heading"> MAPS OVERVIEW</h5>
                            <div className="d-flex flex-column">
                                {maps.map((mapObj, index) => {
                                    return (
                                        (!mapObj.state || mapObj.state == "picked") ? <div className={`map p-0 m-0 px-1 mt-2 completed-map col-12 ${mapObj.order ? `order-${mapObj.order}` : 'order-0'}`} style={{ pointerEvents: "none" }}>
                                            <div style={{ backgroundImage: `url(${mapObj.img})` }} className="map-bg"></div>
                                            <>
                                                <div className="row p-0 m-0">
                                                    <span className="col-1 m-0 p-0">{(mapObj.t || mapObj.ct) ? mapObj.t == matchData.t1._id ? "T" : mapObj.ct == matchData.t1._id ? "CT" : '' : ''}</span>
                                                    <span className="col-10 map-title">{mapObj.name}</span>
                                                    <span className="col-1 m-0 p-0"> {(mapObj.t || mapObj.ct) ? mapObj.t == matchData.t2._id ? "T" : mapObj.ct == matchData.t2._id ? "CT" : '' : ''} </span>
                                                </div>

                                            </>
                                        </div> : <></>)
                                })}
                            </div>
                        </div>
                    </div>
                }


                <div className="col-4 p-2 m-0">
                    {matchData && (statsComponent == "Map Stats" ? <MapStats gameData={gameData} maps={maps} team={matchData.t2} stats={matchData?.stats?.t2Stats || {}} /> : <TeamStats gameData={gameData} team={matchData.t2} stats={matchData?.stats?.t2Stats || {}} playerData={matchData.csgoData?.players?.t2} vetoData={vetoData} teamName={'t2'} />)}

                </div>
            </div>
        </div>
    )
}

export default Step
