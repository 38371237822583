import React from "react";
import { Icon } from "@common";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { hexToRgbA } from "../../../utils/additional-helpers";
import styled from "styled-components";
import useTranslated from "../../../helpers/translationHelper";

const UrlPagination = ({ page = 1, setPage, pages, tag }) => {
  const history = useHistory();
  let page_array = [];
  const pageinationpage=useTranslated('id_news_page_9')
  const paginationof=useTranslated('id_news_page_10')
  if (page < 3) {
    if (pages > 4) {
      page_array = [1, 2, 3, 4, 5];
    } else {
      page_array = [...Array(pages).keys()];
      page_array = page_array.map((i) => i + 1);
    }
  } else if (page > pages - 2) {
    page_array = [pages - 4, pages - 3, pages - 2, pages - 1, pages];
  } else {
    page_array = [page - 2, page - 1, page, page + 1, page + 2];
  }

  page_array = page_array.filter((i) => {
    return i > 0;
  });

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
      history.push({ pathname: history.location.pathname, search: `?page=${+page - 1}` });
    }
  };

  const nextPage = () => {
    if (page < pages) {
      setPage(page + 1);
      history.push({ pathname: history.location.pathname, search: `?page=${+page + 1}` });
    }
  };

  const selectedGameSelected = localStorage.getItem(["selectedGame"]);
  let mainColor = "";
  if (selectedGameSelected && JSON.parse(selectedGameSelected).mainColour) {
    mainColor = JSON.parse(selectedGameSelected).mainColour;
  }

  mainColor = hexToRgbA(mainColor);

  return (
    <>
      <div className="pagination">
        <button
          className={page === 1 ? "fitstPageButton disabled" : "fitstPageButton"}
          onClick={() => {
            setPage(1);
            history.push({ pathname: history.location.pathname, search: "?page=1" });
          }}
        >
          <AiOutlineDoubleLeft size={`1.6em`} />
        </button>
        <button className={page === 1 ? "prev-button disabled" : "prev-button"} onClick={prevPage}>
          <Icon name="prev-page" />
        </button>
        {page_array.map((i) => (
          <button
            key={i}
            className={page === i ? `pagination-link active` : `pagination-link`}
            style={{ backgroundColor: (mainColor !== "" || mainColor !== undefined) && page === i ? mainColor : `gba(62, 171, 240, 0.1);` }}
            onClick={() => {
              setPage(i);
              history.push({ pathname: history.location.pathname, search: `?page=${i}` });
            }}
          >
            {i}
          </button>
        ))}
        <button className={page === pages ? "next-button disabled" : "next-button"} onClick={nextPage}>
          <Icon name="next-page" />
        </button>
        <button
          className={page === pages ? "lastPageButton disabled" : "lastPageButton"}
          onClick={() => {
            setPage(pages);
            history.push({ pathname: history.location.pathname, search: `?page=${pages}` });
          }}
        >
          <AiOutlineDoubleRight size={`1.6em`} />
        </button>
      </div>

      <div className="text-12 text-uppercase pageStats">
      {pageinationpage ? pageinationpage : "Page"} &nbsp; <strong>{page} </strong> &nbsp; {paginationof? paginationof : "of"} &nbsp;<strong>{pages} </strong>
      </div>
    </>
  );
};

export default UrlPagination;
