import React, { useState } from 'react'

const JoinAsParty = ({ partyName, setPartyName }) => {
    const [step, setStep] = useState(0);

    return (
        <div className="col-12 p-0 m-0">
            <p className="bold">
                PARTY NAME
            </p>
            <p className="text-12 text-grey">
                Your party name will be displayed in the tournament.
            </p>
            <input
                value={partyName}
                onChange={e => setPartyName(e.target.value)}
                className="form-input" />
            <p className="bold">
                UPLOAD LOGO FOR YOUR PARTY (OPTIONAL)
            </p>
            <p className="text-12 text-grey">
                The logo will be displayed during the tournament. Preferred file size: 520 x 520 px. The image file has to be JPG or PNG.
            </p>
            <label htmlFor="party-image-picker" className="button wide-primary light p-3 text-left">Click or drag to attach a file</label>
            <input id="party-image-picker" type="file" className="d-none" />
            <div className="col-12 p-0 m-0 pt-3">
                <button className="button primary col-12">
                    CREATE PARTY
                </button>
            </div>
        </div>
    )
}

export default JoinAsParty;
