import React, { useState } from "react";
import Modal from "react-modal";
import { RiCloseLine, RiPencilLine } from "react-icons/ri";
import { toast } from "react-toastify";
import Axios from "axios";

const EditRoleModal = ({ type, playerId, game, currentRole, afterUpdate }) => {
  const [show, setShow] = useState(false);
  const [newRole, setNewRole] = useState(currentRole);

  const onUpdate = async () => {
    try {
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/players/${playerId}/updateRole`, { type: `${type}Role`, newRole });

      toast.success(`Updated ${type} role successfully`);
      setShow(false);
      afterUpdate(newRole);
    } catch (e) {
      toast.error("Could not update role");
    }
  };

  const roles = game.configuration.playerTypes || [];

  return (
    <>
      <Modal isOpen={show} onRequestClose={() => setShow(false)} className="modal">
        <div className="modal-close" onClick={() => setShow(false)}>
          <RiCloseLine />
        </div>
        <div className="modal-header">
          <h2>Edit {type} Role</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <select name="newRole" id="newRole" className="form-input" value={newRole} onChange={(e) => setNewRole(e.target.value)}>
              {roles.map(({ name }) => (
                !name.includes('SUB') && <option value={name}>{name}</option>
              ))}
            </select>
          </div>

          <button className="button primary" onClick={onUpdate} disabled={newRole === currentRole}>
            Update
          </button>
        </div>
      </Modal>

      <button onClick={() => setShow(true)} className="role-edit-btn">
        <RiPencilLine />
      </button>
    </>
  );
};

export default EditRoleModal;
