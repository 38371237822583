import React, { useContext, useState } from "react";
import { ScrimsFinder } from "./ScrimsTabs";
import { PageWithTabs } from "@common";
import cover_image from "@img/ScrimFinderBanner.jpg";
import cover_image_csgo from "@img/ScrimFinderBannerCsgo.jpg";
import esport_series_logo from "@img/esport-series-logo.png";
import { AppContext } from "../../context/AppContext";

const Scrims = () => {
  const { authenticated, selectedGame } = useContext(AppContext);
  const [count, setCount] = useState(0);

  const pages = {
    "Scrim finder": <ScrimsFinder setPageNameWithCount={(num) => setCount(num)} />,
    ...(authenticated && {
      "My Active scrims": <ScrimsFinder my_active count={Number(count)} setPageNameWithCount={(num) => setCount(num)} />,
    }),
  };

  return (
    <div className="">
       <PageWithTabs
      showAdsInTabs
      title="Find a Scrim"
      above_title="Scrims"
      below_title={<React.Fragment>Find other teams and set up a scrim</React.Fragment>}
      pages={pages}
      cover_image={
        selectedGame && selectedGame.scrimFinderBanner
          ? selectedGame.scrimFinderBanner
          : selectedGame && selectedGame.shortName === "CSGO"
          ? cover_image_csgo
          : cover_image
      }
      base_route="/scrims"
      image={selectedGame?.whiteIcon || selectedGame?.blackIcon || esport_series_logo}
      croppedLogo={false}
    />
    </div>
   
  );
};

export default Scrims;
