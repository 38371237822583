import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { RichEditor } from "../../../_common/RichEditor/RichEditor";
import Axios from "axios";
import { toast } from "react-toastify";
import { Checkbox } from "@common";
import { Link } from "react-router-dom";
import default_team_logo from "@img/default-team-logo.png";
import useDebounce from "../../../_common/Debounce/useDebounce";
import { BsLink45Deg } from "react-icons/bs";
import ConnectOrganization from "../../../_modals/ConnectOrganization/ConnectOrganization";
import { RiEditLine } from "react-icons/ri";
import EditImageModal from "../../../_modals/EditImageModal/EditImageModal";
import DeleteTeamModal from "./DeleteTeamModal/DeleteTeamModal";
import { countries } from "@utils";
import { AppContext } from "../../../../context/AppContext";

import useTranslated from "../../../../helpers/translationHelper";



const EditTeam = ({ team, setTeam, organisation = null }) => {
  const [tm, setTm] = useState(team)
  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(false)
  const [connect, setConnect] = useState(false)
  const [results, setResults] = useState([])
  const debounceSearch = useDebounce(search, 500)
  const { selectedGame } = useContext(AppContext);
  const { handleSubmit, register, setValue, errors } = useForm();
  const [about, setAbout] = useState(team.about || "");
  const [logoCropped, setLogoCropped] = useState(team.logoCropped === undefined ? true : team.logoCropped);
  const [org, setOrg] = useState(organisation ? organisation : team.organisationInvitationStatus === 'pending' ? team.pendingTeamOrganisationInvites.organisation : null)
  const [nationalities, setNationalities] = useState(countries
    .filter(({ demonym }) => demonym)
    .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    .sort((a, b) => a.demonym.localeCompare(b.demonym)));
  const [modals, setModals] = useState({
    logo: false,
    banner: false,
  });
  const [showDeleteTeam, setShowDeleteTeam] = useState(false);

  const lableLogo=useTranslated("id_pop-up_-_upload_image_2")
  const lableBanner=useTranslated("id_pop-up_-_upload_image_3")

  const lableName=useTranslated("id_manage_team_(team)_0")
  const lableNamePlaceholder=useTranslated("id_manage_team_(team)_1")
  const lableInitials=useTranslated("id_manage_team_(team)_2")
  const lableInitialsPlaceholder=useTranslated("id_manage_team_(team)_3")
  const lableTagline=useTranslated("id_manage_team_(team)_4")
  const lableTaglinePlaceholder=useTranslated("id_manage_team_(team)_5")
  const lablePublicEmail=useTranslated("id_manage_team_(team)_6")
  const lableOrganizationCom=useTranslated("id_manage_team_(team)_7")
  const lableNationality=useTranslated("id_manage_team_(team)_8")
  const lablewebsite=useTranslated("id_manage_team_(team)_9")
  const lableLeagues=useTranslated("id_manage_team_(team)_10")
  const lableAbout=useTranslated("id_manage_team_(team)_11")
  const lableWriteSomething=useTranslated("id_manage_team_(team)_12")
  const lableLogoCropped=useTranslated("id_manage_team_(team)_13")
  const lableLogo1=useTranslated("id_manage_team_(team)_14")
  const lableBanner1=useTranslated("id_manage_team_(team)_15")
  const lableSave=useTranslated("id_manage_team_(team)_16")
  const lableDeleteTeam=useTranslated("id_manage_team_(team)_17")
  
  console.log("organisation details from team", org)
  // Connect Organisation Implementation
  const toggleOrganisation = (data) => {
    Axios.post(`${process.env.REACT_APP_CORE_API}/api/${ tm.organisationInvitationStatus === 'pending' ? `teams/remove/organisation/request` : `teams/leave/organisation`}`, data).then(response => {
      setOrg(null)
      setTm(response.data.team)
      toast.success(response.data.msg)
    }).catch(error => {
      if (error.response) {
        toast.error(error.response.data.msg)
      }
      toast.error(error.message)
    })
  }

  const searchOrganisation = () => {
    setLoading(true)
    Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/search`, {query: debounceSearch}).then(async response => {
    	if (response.data?.length) {
        	let orgList = await response.data.map(org => ({_id: org._id, title: org.name, type: 'Organisation', img: org.logo, subtitle: false, logoCropped: org.banner}))
        	setResults(orgList)
      }
      setLoading(false)
    }).catch(error => {
    	setLoading(false)
      	if (error.response) {
        	toast.error(error.response.data.msg)
      	}
      	toast.error(error.message)
    })
  }

  const connectOrganisation = (org) => {
	  setResults([])
	  setSearch(null)
	  setConnect(false)
	Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/join/organisation`, {teamID: team?._id, organisation: org._id}).then(async response => {
		setTm(response.data.team)
		setOrg(response.data.organisation)
    	toast.success(response.data.msg)
    }).catch(error => {
      	if (error.response) {
        	toast.error(error.response.data.msg)
      	}
      	toast.error(error.message)
    })
  }

  useEffect(() => {
    setValue("nationality", JSON.stringify(team.nationality));
    if (debounceSearch) searchOrganisation()
  }, [team.nationality, debounceSearch]);

  const onEditImage = async (type, image) => {
    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}/image`, { type, image });
      const modalConfig = type === "originalLogo" ? { ...modals, ["logo"]: false } : { ...modals, [type]: false };
      setModals(modalConfig);
      setTeam({ ...team, logo: data.updatedTeam.logo, banner: data.updatedTeam.banner });
    } catch (err) {
      toast.error("There was a problem updating your team's images");
    }
  };

  const onDeleteTeam = () => setShowDeleteTeam(true);

  const onSubmit = async (data) => {
    try {
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}?gameId=${team.game._id}`, { ...data, about, logoCropped });
      toast.success("Saved");
    } catch (e) {
      const error = e.response.data ? e.response.data.msg : "There was a problem saving";
      toast.error(error);
    }
  };

  const onLogoUpdate = async (value) => {
    try {
      let body = {
        email: team.email,
        initials: team.initials,
        name: team.name,
        nationality: team.nationality,
        tagline: team.tagline,
        website: team.website,
        logoCropped: value,
        about: team.about,
      };
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}?gameId=${team.game._id}`, { ...body });
    } catch (e) {
      const error = e.response.data ? e.response.data.msg : "There was a problem saving";
      toast.error(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="account-settings-form">
        <div className="row">
          <div className="col-4">
            <div className="form-row">
              <label htmlFor="name">{lableName/* Name */}</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 200, message: "Name too long" } })}
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                className={`form-input ${errors.name ? "invalid" : ""}`}
                defaultValue={team.name}
                placeholder={lableNamePlaceholder}/* "Name" */
              />
              {errors.name && <div className="form-input-error">{errors.name.message}</div>}
            </div>
          </div>

          <div className="col-2">
            <div className="form-row">
              <label htmlFor="initials">{lableInitials/* initials (tricode) */}</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 4, message: "Initials too long" } })}
                type="text"
                id="initials"
                name="initials"
                autoComplete="off"
                className={`form-input ${errors.initials ? "invalid" : ""}`}
                defaultValue={team.initials}
                placeholder={lableInitialsPlaceholder}/* "Initials" */
              />
              {errors.initials && <div className="form-input-error">{errors.initials.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="tagline">{lableTagline/* Tagline */}</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ maxLength: { value: 50, message: "Tagline too long" } })}
                type="text"
                id="tagline"
                name="tagline"
                autoComplete="off"
                maxLength={50}
                className={`form-input ${errors.tagline ? "invalid" : ""}`}
                defaultValue={team.tagline}
                placeholder={lableTaglinePlaceholder}/* "Tagline" */
              />
              {errors.tagline && <div className="form-input-error">{errors.tagline.message}</div>}
            </div>
          </div>

          <div className="col-4">
            <div className="form-row">
              <label htmlFor="email">{lablePublicEmail/* Public Contact Email */}</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                type="text"
                id="email"
                name="email"
                autoComplete="off"
                className={`form-input ${errors.email ? "invalid" : ""}`}
                defaultValue={team.email}
                placeholder={lableOrganizationCom}/* "Organisation@mail.com" */
              />
              {errors.email && <div className="form-input-error">{errors.email.message}</div>}
            </div>
          </div>

          <div className="col-2">
            <div className="form-row">
              <label htmlFor="nationality">{lableNationality/* Nationality */}</label>
              <select
                ref={register({ validate: (val) => (val && val !== "DEFAULT" ? true : "Required") })}
                id="nationality"
                name="nationality"
                className={`form-input ${errors.nationality ? "invalid" : ""}`}
                defaultValue={team.nationality || "DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Choose nationality
                </option>
                {nationalities.map(({ alpha2Code, demonym }) => (
                  <option key={alpha2Code} value={JSON.stringify({ code: alpha2Code, name: demonym })}>
                    {demonym}
                  </option>
                ))}
              </select>
              {errors.nationality && <div className="form-input-error">{errors.nationality.message}</div>}
            </div>
          </div>

          <div className="col-6">
            <div className="form-row">
              <label htmlFor="website">{lablewebsite/* Website */}</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({
                  pattern: {
                    value: /[-a-zA-Z0-9@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%+.~#?&//=]*)?/gi,
                    message: "Invalid URL",
                  },
                })}
                type="text"
                id="website"
                name="website"
                autoComplete="off"
                className={`form-input ${errors.website ? "invalid" : ""}`}
                defaultValue={team.website}
                placeholder={lableLeagues}/* "Leagues.gg" */
              />
              {errors.website && <div className="form-input-error">{errors.website.message}</div>}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-row">
              <label htmlFor="about">{lableAbout/* About */}</label>
              <RichEditor placeholder={lableWriteSomething}/* "Write something about the team" */ defaultValue={about} onChange={setAbout} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-row">
              <Checkbox onChange={() => setLogoCropped(!logoCropped)} checked={logoCropped} label={lableLogoCropped}/* "Logo Cropped" */ className="check-type1 logo-cropped-box" game={selectedGame.name} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12" style={{ display: "flex" }}>
            <div className="form-row" style={{ marginRight: 30 }}>
              <label htmlFor="logo">{lableLogo1/* Logo */}</label>
              <div
                className={!logoCropped ? "upload-image-btn" : "upload-image-btn-cropped"}
                style={{ backgroundImage: `url(${team.logo})` }}
                onClick={() => setModals({ ...modals, logo: true })}
              >
                <RiEditLine />
              </div>
            </div>

            <EditImageModal
              name={lableLogo}/* "Logo" */
              width={250}
              height={250}
              borderRadius={250}
              open={modals.logo}
              onClose={() => setModals({ ...modals, logo: false })}
              logoCropped={(value) => {
                setLogoCropped(value);
                onLogoUpdate(value);
              }}
              afterImageUpload={(file) => onEditImage("logo", file)}
              afterImageUploadFullSize={(file) => onEditImage("originalLogo", file)}
            />

            <div className="form-row" style={{ marginRight: 30 }}>
              <label htmlFor="logo">{lableBanner1/* Banner */}</label>

              <div
                className="upload-image-btn"
                style={{ backgroundImage: `url(${team.banner})`, width: 800 }}
                onClick={() => setModals({ ...modals, banner: true })}
              >
                <RiEditLine />
              </div>
            </div>

            <EditImageModal
              name={lableBanner}/* "Banner" */
              description="The image ratio is 6:1 with a default size of 1920x320 and a minimum size of 1200x200."
              width={1200}
              height={200}
              crop={true}
              rotate={false}
              borderRadius={0}
              open={modals.banner}
              onClose={() => setModals({ ...modals, banner: false })}
              afterImageUpload={(file) => onEditImage("banner", file)}
            />
            {/* Organisation Banner */}
            <div className="form-row">
            <label htmlFor="logo">Organisation</label>
            { console.log("organisation details = ", org) }
              {org && (
                <div>
                  <div
                    style={{
                      backgroundImage: `linear-gradient(to top, rgb(3, 6, 13) 15%, rgba(3, 6, 13, 0) 140%),url(${org.banner})`,
                      height: "150px",
                      width: "726px",
                      objectFit: "fill",
                      backgroundPostions: "center",
                      backgroundSize: "cover",
              opacity: tm.pendingTeamOrganisationInvites ? .5 : 1
                    }}
                  >
                    {tm.teamOwner && <button className="button danger float-right mt-2 mr-2" onClick={() => toggleOrganisation({teamID: tm?._id, organisation: org._id})}>{tm.organisationInvitationStatus === 'pending' ? 'Remove Request' : 'Remove Organisation' }</button>}
                    <Link to={`/organisation/${org._id}`} className={"text-decoration-none"}>
                      <div className="wide-page-with-tabs-heading-wrapper without_margin" style={{ padding: "2%" }}>
                        <div className="wide-page-with-tabs-heading">
                          <img
                            className={
                              org.logoCropped || org.logoCropped === undefined
                                ? "wide-page-with-tabs-logo"
                                : "wide-page-with-tabs-logo_withoutCropped"
                            }
                            alt="Team"
                            src={org.logo ?? default_team_logo}
                            style={{ height: "50px", width:"50px" }}
                          />
                          <div className="wide-page-with-tabs-heading-text">
                            <h4>{org.name} {tm.organisationInvitationStatus === 'pending' ? '(Pending)' : ''}</h4>
                            <span className="text-faded uppercase text-white bold">
                              {tm.organisationInvitationStatus === 'pending' ? 'waiting for acceptance' : org.countTeams <= 1 ? `${org.countTeams} Team` : `${org.countTeams} Teams`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {!org && tm.teamOwner && (tm.organisationInvitationStatus === 'default' || tm.organisationInvitationStatus === 'declined') && (
                <div>
                <div
                  className="test-organization" style={{ width: 726, height: 150 }} onClick={() => setConnect(true)}>
                  {/* <Link to={`/organisation/`} className={"text-decoration-none"}> */}
                    <div className="wide-page-with-tabs-heading-wrapper without_margin">
                      <div className="wide-page-with-tabs-heading flx-column align-center">
                        <BsLink45Deg className="ex-link" size={50} onClick={() => setConnect(true)}/>
                        <div className="wide-page-with-tabs-heading-text ng-m-t-10 text-center">
                          <h3>CONNECT ORGANIZATION</h3>
                          <span className="text-faded uppercase text-white bold des-text">
                            Click and search to connect this team to your organisation
                          </span>
                        </div>
                      </div>
                    </div>
                  {/* </Link> */}
                </div>
                <ConnectOrganization open={connect} onClose={() => {setSearch(null); setResults([]); setConnect(false)}} loading={loading} results={results} onChange={(search) => setSearch(search)} onClick={connectOrganisation}/>
              </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <button type="submit" className="button primary">
              {lableSave/* Save */}
            </button>
          </div>
          <div className="col-6 text-right">
            <button type="button" className="button danger" onClick={onDeleteTeam}>
              {lableDeleteTeam/* Delete Team */}
            </button>
          </div>
        </div>
      </form>

      <DeleteTeamModal isOpen={showDeleteTeam} onClose={() => setShowDeleteTeam(false)} team={team} />
    </>
  );
};

export default EditTeam;