import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../../context/AppContext";
import { Loading } from "@common";

const TermsAndConditions = ({ termsLoaded }) => {
  const slug = "terms-and-conditions";

  const { ghost } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});

  useEffect(() => {
    ghost.pages.read({ slug }).then((page) => {
      setPage(page);
      setLoading(false);
      termsLoaded();
    });
  }, [ghost, termsLoaded]);

  return loading ? (
    <div className="center">
      <Loading />
    </div>
  ) : (
    <div dangerouslySetInnerHTML={{ __html: page.html }} />
  );
};
export default TermsAndConditions;
