import React, { useState, useEffect, useContext } from "react";
import HomeHeroCustomized from "./HomeSegments/HomeHeroCustomized";
import Slider from "./Slider/Slider";
import { Button, SliderItem, Icon, Advert, Loading } from "@common";
import { AppContext } from "../../context/AppContext";
import Axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import ActiveTournaments from "./ActiveTournaments/ActiveTournaments";
import RenderNewsItems from "./HomeSegments/RenderNewsItems";
import RenderFeaturedNewsItem from "./HomeSegments/RenderFeaturedNewsItem";
import DotPattern from "./HomeSegments/DotPattern";
import ImageWithContent from "./HomeSegments/ImageWithContent";
import { RiCheckLine } from "react-icons/ri";

const Home = () => {

  
  const [pageData,setPageData] = useState([]);
  const [loading,setLoading] = useState(true);
  const { selectedGame, setSelectedGame, authenticated, user } = useContext(AppContext);
  
  useEffect(() => {
    if(selectedGame && selectedGame.frontpage){
            
      setPageData(selectedGame.frontpage.ProUser); //If Loggedin user is Org Updated
           
    }
    setLoading(false);
}, [selectedGame]);

  return (
    <>
      <div className="home-hero-container" > 
        <DotPattern />
        <HomeHeroCustomized 
          backgroundType={`image`} // image or video
          backgroundMedia={selectedGame.frontPageImage || '/static/media/image.fadeddec.jpg'} // File URL
          subheading='Win with style (PRO USER)' // Small Top Text
          heading='GIVE YOUR ACCOUNT PRO' //Big Heading
          showButton={true}
          buttonText="TRY PRO FREE TODAY"
          buttonLink="#"
        />
      </div>
      
      <div className="container-fluid" style={{marginTop:'-225px'}} >
        <div className="cool-heading-container">
          <div className="icon-cross">
            <Icon name="cross" />
          </div>

          <div className="icon-play">
            <Icon name="play" />
          </div>

          <DotPattern />

         
        </div>
        {loading ? <Loading />  : 
          pageData.map((value,key) => {
          return <ImageWithContent 
            isOdd={!(key % 2  == 0)} 
            Heading={value.segmentData && value.segmentData.Heading} 
            SubHeading={value.segmentData && value.segmentData.SubHeading} 
            Image={value.segmentData && value.segmentData.image} 
            Description={value.segmentData && value.segmentData.Description} 
          />
        })}


        <div className="row smaller-gutter-row image-with-content-area">
            
            <div className="col-6 right">
              <div className="col-container mb-5 mt-5">
                <div className="pricing floatright card-deck flex-column flex-md-row mb-3">
                  <div className="card card-pricing prouser text-left px-3 mb-4">
                    <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm status">SAVE 33%</span>
                        
                    <div className="bg-transparent card-header pt-4 border-0 text-center">
                      <span className="planPrice">YEARLY</span>
                      <h1 className="h1 font-weight-normal text-primary text-center mb-0" data-pricing-value="15"><span className="h6 text-muted ml-2 text-left">$7.99 / YEAR</span></h1>
                      
                     
                    </div>
                    <div className="card-body pt-3 text-center">
                     
                        <Button name="cta-button" >
                          Try today
                        </Button>
                        
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="col-6 right">
              <div className="col-container mb-5 mt-5">
                <div className="pricing floatright card-deck flex-column flex-md-row mb-3">
                  <div className="card card-pricing prouser text-left px-3 mb-4">
                  
                    <div className="bg-transparent card-header pt-4 border-0 text-center">
                      <span className="planPrice">MONTHLY</span>
                      <h1 className="h1 font-weight-normal text-primary text-center mb-0" data-pricing-value="15"><span className="h6 text-muted ml-2 text-left">$0.99 / MONTH</span></h1>
                      
                     
                    </div>
                    <div className="card-body pt-3 text-center">
                     
                        <Button name="cta-button" >
                          Try today
                        </Button>
                        
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

<p className='trailNote'>Terms and conditions apply. 7 day free trial not available for users who have already tried PRO.<br /><a href="">Contact Us</a> if you have questions.</p>
      </div>
    
    </>
  );
};

export default Home;
