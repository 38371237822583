import React, { useState, useEffect, useRef } from "react";
import { ChampionsList, TournamentListItem, PositionDisplay, Loading } from "@common";
import moment from "moment";
import RankData from "../../../GameProfile/GameProfileTabs/includes/RankData";
import default_profile_pic from "@img/default-team-member.png";
import Axios from "axios";

const TeamStatItem = ({ item, playerId }) => {
  const [globalData, setGlobalData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [rankedflexsr, setRankedflexsr] = useState([]);
  const [rankedsolo, setRankedsolo] = useState([]);

  const [icon, setIcon] = useState("");
  const [title, setTitle] = useState("");
  const [wins, setWins] = useState("");
  const [losses, setLosses] = useState("");
  const [leaguePoints, setLeaguePoints] = useState("0");

  const [iconSolo, setIconSolo] = useState("");
  const [titleSolo, setTitleSolo] = useState("");
  const [winsSolo, setWinsSolo] = useState("");
  const [lossesSolo, setLossesSolo] = useState("");
  const [leaguePointsSolo, setLeaguePointsSolo] = useState("0");

  const [spellsData, setSpellsData] = useState([]);
  const [championsLoading, setChampionsLoading] = useState(true);
  const [spellsLoading, setSpellsLoading] = useState(true);

  const [championsData, setChampionsData] = useState([]);

  useEffect(() => {
    setDataLoading(true);

    Axios.get(`${process.env.REACT_APP_LOL_API}/api/players/${playerId}/aggregratedStats`, { params: { type: "ranked" } }).then(
      ({ data }) => {
        let rankedflexsrData = data.playerData.lolData.leagueEntries.filter((leagueEntry) => leagueEntry.queueType === "RANKED_FLEX_SR");
        setRankedflexsr(rankedflexsrData[0]);
        // let { icon, title, subtitle, wins, losses, leaguePoints } = rankedflexsrData[0];

        // If the entry data is from riot we need to format it
        if (rankedflexsrData[0] && rankedflexsrData[0].leagueId) {
          const tierSentenceCase = rankedflexsrData[0].tier.charAt(0).toUpperCase() + rankedflexsrData[0].tier.substr(1).toLowerCase();
          let icon = `https://leagues-static.s3.eu-west-2.amazonaws.com/riot_imgs/ranked-emblems/Emblem_${tierSentenceCase}.png`;
          setIcon(icon);
          let title = `${tierSentenceCase} ${rankedflexsrData[0].rank}`;
          setTitle(title);
          let wins = rankedflexsrData[0].wins;
          setWins(wins);
          let losses = rankedflexsrData[0].losses;
          setLosses(losses);
          let leaguePoints = rankedflexsrData[0].leaguePoints;
          setLeaguePoints(leaguePoints);
        }

        let rankedsoloData = data.playerData.lolData.leagueEntries.filter((leagueEntry) => leagueEntry.queueType === "RANKED_SOLO_5x5");
        setRankedsolo(rankedsoloData);
        // let { icon, title, subtitle, wins, losses, leaguePoints } = rankedsoloData[0];
        if (rankedsoloData[0] && rankedsoloData[0].leagueId) {
          const tierSentenceCase = rankedsoloData[0].tier.charAt(0).toUpperCase() + rankedsoloData[0].tier.substr(1).toLowerCase();
          let iconSolo = `https://leagues-static.s3.eu-west-2.amazonaws.com/riot_imgs/ranked-emblems/Emblem_${tierSentenceCase}.png`;
          setIconSolo(iconSolo);
          let titleSolo = `${tierSentenceCase} ${rankedsoloData[0].rank}`;
          setTitleSolo(titleSolo);
          // let subtitleSolo = rankedsoloData[0].queueType;
          // setSubtitleSolo(subtitleSolo);
          let winsSolo = rankedsoloData[0].wins;
          setWinsSolo(winsSolo);
          let lossesSolo = rankedsoloData[0].losses;
          setLossesSolo(lossesSolo);
          let leaguePointsSolo = rankedsoloData[0].leaguePoints;
          setLeaguePointsSolo(leaguePointsSolo);
        }

        setGlobalData(data);
        setDataLoading(false);
      }
    );
  }, [playerId]);


  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_LOL_API}/api/riot/champions`).then(({ data }) => {
      setChampionsData(data);
      setChampionsLoading(false);
    });

    Axios.get(`${process.env.REACT_APP_LOL_API}/api/riot/spells`).then(({ data }) => {
      setSpellsData(data);
      setSpellsLoading(false);
    });
  }, []);

  let sum = wins + losses;
  let wr = Math.round((wins / sum) * 100);

  const championIdToImg = (championId) => {
    const championData = championsData[championId];
    const championVers = championData && championData.version;
    const championImage = championData && championData.image.full;

    return `https://ddragon.leagueoflegends.com/cdn/${championVers}/img/champion/${championImage}`;
  };

  const championIdToName = (championId) => {
    const championData = championsData[championId];
    return championData && championData.name;
  };

  const champions = globalData.statistics && globalData.statistics.mostPlayedChampions.map(({ championId, wins, losses, kills, deaths, assists, games }) => ({
    image: championIdToImg(championId),
    name: championIdToName(championId),
    kda: deaths !== 0 ? parseFloat((kills + assists) / deaths).toFixed(2) : "Perfect",
    wr: Math.round((wins / (wins + losses)) * 100),
    games,
  }));

  return (
    dataLoading ? 'Loading...' :
      <>
        {/* <div className="col-3"> */}
        {!dataLoading ? (<>
          <div className="panel">
            <header className="panel-header">
              <div className="champions-list pl-4">
                <img src={item.img} className="player-logo" alt="Player" />
                {item.title}
              </div>
            </header>
          </div>
          <PositionDisplay mostPlayed={globalData.statistics.mostPlayedPositions[0] && globalData.statistics.mostPlayedPositions[0].name} />
          <RankData leagueEntries={globalData.playerData.lolData.leagueEntries && globalData.playerData.lolData.leagueEntries} />
          {!!champions.length && (
            <div className="panel">
              <header className="panel-header">MOST PLAYED CHAMPIONS</header>

              <div className="panel-body">
                <ChampionsList champions={champions} />
              </div>

            </div>
          )}
        </>) : (
          <div className="col-12 text-center p-5">
            <Loading />
          </div>
        )}

      </>
  );
};

export default TeamStatItem;
