import { useEffect, useRef, useState, useContext } from "react";
import socketIOClient from "socket.io-client";
import { AppContext } from "../context/AppContext";
import Axios from "axios";

const NEW_CHAT_MESSAGE_EVENT = "newChatMessage";
const SOCKET_SERVER_URL = process.env.REACT_APP_CORE_API; //"http://localhost:5000";


// message: "sadasd"
// ownedByCurrentUser: true
// senderId: "Vzv2WcEchtArFHouAAAP"
// userId: "5f651ae6446ceb29df6f4883"


const useChat = (roomId) => {
  const [messages, setMessages] = useState([]);
  const socketRef = useRef();
  const { user } = useContext(AppContext);
  useEffect(() => {

    Axios.get(`${process.env.REACT_APP_CORE_API}/api/scrimChat/${roomId}`).then(({ data }) => {
     
      if(data.length > 0){
        data.map((message) => {
          const incomingMessage = {
            ...message,
            ownedByCurrentUser: message.userId._id === user.state._id,
          };
          setMessages((messages) => [...messages, incomingMessage]);
        })
      }
      // setLoading(false);
      // console.log('data',data)
    });

    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { roomId },
    });

    socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, (message) => {
      const incomingMessage = {
        ...message,
        ownedByCurrentUser: message.senderId === socketRef.current.id,
      };
      console.log("incomingMessage-", incomingMessage);
      setMessages((messages) => [...messages, incomingMessage]);
      // console.log('messages',messages)
    });
    
    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);

  const sendMessage = (messageBody) => {
    socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, {
      message: messageBody,
      userId: user.state,
      senderId: socketRef.current.id,
    });
  };

  return { messages, sendMessage };
};

export default useChat;
