import React,{useState} from "react";
import { MirrorText, SimplePageWithTabs } from "@common";
import NewsTab from "./NewsTab/NewsTab";
import useTranslated from "../../helpers/translationHelper";
import { useEffect } from "react";
const News = () => {
const Alltab=useTranslated('id_news_page_1')
const Announcementstab=useTranslated('id_news_page_2')
const Leaguestab=useTranslated('id_news_page_3')
const Tournamentstab=useTranslated('id_news_page_4')
const L4t3st=useTranslated('id_news_page_5')
const FITLERBY=useTranslated('id_news_page_6')
const Recentnews=useTranslated('id_news_page_7')

 const tabs = { [Alltab]: <NewsTab />,
  [Announcementstab ]: <NewsTab filter="tag:announcement" />,
  [Leaguestab]: <NewsTab filter="tag:league" />,
  [Tournamentstab]: <NewsTab filter="tag:tournament" />,
}


  return (
    <>
      <SimplePageWithTabs
        title="News"
        tabs={tabs}
        top_right={
          <React.Fragment>
            <MirrorText text={[L4t3st]} is_static />
          </React.Fragment>
        }
        tabs_props={{
          show_filter: true,
          custom_filter: (
            <React.Fragment>
              <span className="text-12">{FITLERBY}</span>
              <select className="form-input">
                <option>{Recentnews}</option>
              </select>
            </React.Fragment>
          ),
          // base_route: "/news",
        }}
      />
    </>
  );
};

export default News;
