import React, { useEffect, useState } from "react";
import { SimpleTabs, LeaguesResultItem, List, Icon, Loading } from "@common";
import Axios from "axios";
import { toast } from "react-toastify";
import { MatchListingByDate } from "../../../../_common";

// const tabs = ["Ongoing & Upcoming", "Results"];

const Matches = ({ league }) => {
  // const [page, setPage] = useState(0);
  // const [loading, setLoading] = useState(false);
  // const [matches, setMatches] = useState([]);

  // useEffect(() => {
  //   // On page change...
  //   setLoading(true);

  //   let currentTab = tabs[page];
  //   let filter = currentTab === "Ongoing & Upcoming" ? "upcoming" : currentTab === "Results" ? "completed" : "all";

  //   setMatches([]);

  //   Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}/matches`, { params: { filter } })
  //     .then(({ data }) => {
  //       if (currentTab === "Results") data.sort((a, b) => b._id.week - a._id.week);
  //       else data.sort((a, b) => a._id.week - b._id.week);
  //       console.log(data);
  //       setMatches(data);
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       toast.error("There was a problem retrieving matches");
  //     });
  // }, [league._id, page]);

  return (
    <React.Fragment>
      <MatchListingByDate id={league._id} page={"LEAGUE"} fullScreen={true} />
      {/* <SimpleTabs pages={tabs} page={page} setPage={setPage} />

      {loading ? (
        <div className="text-center league-no-matches">
          <Loading width={50} height={50} />
        </div>
      ) : !matches.length ? (
        <div className="league-no-matches">
          <Icon name="calendar" />
          <p>There are no matches to show</p>
        </div>
      ) : (
        matches.map(({ _id, matches = [] }) => (
          <div className="row smaller-gutter-row mb-4 in-small-gutter1">
            <div className="col-12">
              <div className="mt-4 uppercase bold">Week {_id.week}</div>

              <List>
                {matches.map((match) => (
                  <LeaguesResultItem key={match._id} show_result={page} match={match} />
                ))}
              </List>
            </div>
          </div>
        ))
      )} */}
    </React.Fragment>
  );
};

export default Matches;
