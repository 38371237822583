import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { PageWithTabs, Loading } from "@common";
import {
  Overview,
  Matches,
  Leaderboard,
  TopPlayers,
  TopPlayersCsgo,
  Rules,
  Prizes,
  Announcements,
  Participants,
} from "./MultiStageLeaguesSingleTabs";
import Brackets from "./MultiStageLeaguesSingleTabs/Brackets/Brackets.jsx";
import Axios from "axios";
import moment from "moment";
import { AppContext } from "@context/AppContext";

const MultiStageLeaguesSingle = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [league, setMultiStageLeague] = useState({});
  const { selectedGame, setSelectedGame } = useContext(AppContext);
  const [leagueFromGame, setLeagueFromGame] = useState(null);
  const [bracketData, setBracketData] = useState();
  const [pages, setPages] = useState();

  const fetchBracketData = (leagues) => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/bracketData`)
      .then(({ data }) => {
        setBracketData(data.stages);
        validateTitle(data.stages, leagues);
      })
      .catch(() => validateTitle([], leagues))
      .finally(() => setLoading(false));
  };
  const populateStagesArray = (leagues, isBrackets, showBracket, bracketData) => {
    if (!showBracket) {
      setPages({
        Overview: <Overview id={id} league={leagues} />,
        Matches: <Matches id={id} league={leagues} />,
        Participants: <Participants id={id} league={leagues} />,
        Leaderboard: <Leaderboard id={id} leagueId={leagues._id} league={leagues} />,
        "Top Players":
          leagueFromGame === "CSGO" ? <TopPlayersCsgo leagueId={leagues._id} /> : <TopPlayers id={id} leagueId={leagues._id} />,
        Prizes: <Prizes id={id} teams={leagues.teams} prizes={leagues.prizes} leagueFinished={leagues.leagueFinished} />,
        Rules: <Rules slug={leagues.rulesGhostSlug} />,
        // "Announcements (2)": <Announcements />,
      });
    } else if (isBrackets) {
      setPages({
        Overview: <Overview id={id} league={leagues} />,
        Matches: <Matches id={id} league={leagues} />,
        Brackets: <Brackets id={id} league={leagues} data={bracketData} />,
        Participants: <Participants id={id} league={leagues} />,
        Leaderboard: <Leaderboard id={id} leagueId={leagues._id} league={leagues} />,
        "Top Players":
          leagueFromGame === "CSGO" ? <TopPlayersCsgo leagueId={leagues._id} /> : <TopPlayers id={id} leagueId={leagues._id} />,
        Prizes: <Prizes id={id} teams={leagues.teams} prizes={leagues.prizes} leagueFinished={leagues.leagueFinished} />,
        Rules: <Rules slug={leagues.rulesGhostSlug} />,
        // "Announcements (2)": <Announcements />,
      });
    } else {
      setPages({
        Overview: <Overview id={id} league={leagues} />,
        Matches: <Matches id={id} league={leagues} />,
        Bracket: <Brackets id={id} league={leagues} pages={pages} data={bracketData} />,
        Participants: <Participants id={id} league={leagues} />,
        Leaderboard: <Leaderboard id={id} leagueId={league._id} league={leagues} />,
        "Top Players":
          leagueFromGame === "CSGO" ? <TopPlayersCsgo leagueId={leagues._id} /> : <TopPlayers id={id} leagueId={leagues._id} />,
        Prizes: <Prizes id={id} teams={leagues.teams} prizes={leagues.prizes} leagueFinished={leagues.leagueFinished} />,
        Rules: <Rules slug={leagues.rulesGhostSlug} />,
      });
    }
    setLoading(false);
  };
  const validateTitle = (data, leagues) => {
    // There is a stage with the “bracket” format and “double elimination” type.
    // There are two or more stages with the “bracket” format.
    // There is at least one stage with the “bracket” format with two or more groups.
    let isDoubleElimination = false;
    let groupLengthCheck = false;
    let stageLengthCheck = false;
    let isBrackets = false;

    if (data && data.length !== 0) {
      isBrackets = true;
      data.map((item, index) => {
        if (item.bracketType === "Double Elimination") {
          isDoubleElimination = true;
        }
        if (item.groups.length >= 2) {
          groupLengthCheck = true;
        }
      });
      if (data.length >= 2) {
        stageLengthCheck = true;
      }
    }
    populateStagesArray(leagues, isDoubleElimination || groupLengthCheck || stageLengthCheck, isBrackets, data);
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}`).then(({ data }) => {
      setLeagueFromGame(data.multiStageLeague.season.game.shortName);
      if (data.multiStageLeague.season.game.shortName !== selectedGame.shortName) {
        setSelectedGame(data.multiStageLeague.season.game);
        return window.location.reload();
      } else {
        setMultiStageLeague(data.multiStageLeague);
        // setLoading(false);
      }
    });
  }, [id]);

  useEffect(() => {
    if (!bracketData && Object.keys(league).length > 0) {
      fetchBracketData(league);
    }
  }, [league]);

  if (loading) {
    return (
      <div className="col-12 text-center">
        <Loading />
      </div>
    );
  }
  return loading ? (
    <div className="col-12 text-center">
      <Loading />
    </div>
  ) : (
    <>
      {pages && (
        <PageWithTabs
          pages={pages}
          cover_image={league.coverImage}
          image={league.logoImage}
          base_route={`/single-competition/${id}`}
          above_title="Multi Stage League"
          title={league.name}
          custom_header={<div className="league-header"></div>}
          hide_ads={true}
          below_title={`${moment(league.startDate).format("DD MMM, YYYY")} - ${
            league.season.name + (league.sponsor ? ` - Hosted by ${league.sponsor}` : "")
          }`}
        />
      )}
    </>
  );
};

export default MultiStageLeaguesSingle;
