//Library imports
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Switch } from "antd";

const MatchesSwitchItem = ({ active, onSwitch, isActive, isMultiSelect, checked }) => {
  const [switchValue, setSwitchValue] = useState(true);
  const onChangeHandler = (value) => {
    setSwitchValue(!switchValue);
    if (onSwitch) {
      onSwitch(value);
    }
  };
  return (
    <Wrapper>
      <div className="d-flex align-items-center">
        <Switch onChange={(e) => onChangeHandler(e)} checked={checked} />
      </div>
    </Wrapper>
  );
};
export default MatchesSwitchItem;

const Wrapper = styled.div`
  vertical-align: middle;
  margin-right: 0.5em;
  color: #717171;
  font-weight: 700;
  font-size: 16px;
  font-family: "robotoregular";
  @media all and (max-width: 1439px), (max-height: 729px), (-webkit-device-pixel-ratio: 1.25) {
    font-size: 12px;
  }
`;
