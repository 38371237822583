import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@common";
import default_picture from "@img/finder-card-image.png";
import { makeCountdownString } from "@utils";
import moment from "moment";

const FinderCard = ({ color, scrim, button_label, button_link_to, item }) => {
  let classes = "";
  const [contentShow, setContentShow] = useState(false);

  const [countDown, setCountDown] = useState("");

  //  console.log('_id==',item)

  const {
    date,
    size,
    scrimFormat,
    rank,
    // team: { initials, name, logo, nationality },
    playerFinderData: userData,
    selectedGame,
    // members,
    team: {
      _id: playerId,
      logo: playerAvatarImage,
      name: playerName,
      nationality: playernationality,

      // primaryRole:playerprimaryRole,
      // secondaryRole:playersecondaryRole,
      game,
      lolData,
    },
    finderCardFor = null,
    createdBy,
  } = item || {};

  const getLaneIcon = (role) => {
    const data =
      selectedGame && selectedGame.configuration && selectedGame.configuration.playerTypes.find(({ name }) => role.includes(name));

    return data ? data.icon : false;
  };

  // console.log('player--',userData)
  useEffect(() => {
    if (date) {
      const _interval = setInterval(() => {
        const now = moment();
        const _date = moment(date);
        var duration = _date.diff(now);
        if (duration > 0) setCountDown(makeCountdownString(duration));
        else {
          clearInterval(_interval);
        }
      }, 1000);
    }
  }, []);

  if (typeof scrim !== "undefined") {
    classes += "scrim-finder";
  }

  // const {
  //   rank,
  //   team: { intials, logo, name, nationality },
  // } = item;

  const _reg_date = date ? moment(date) : undefined;
  const reg_date = date
    ? _reg_date.format("DD/MM/YYYY") !== moment().format("DD/MM/YYYY")
      ? _reg_date.format("MM/DD-YYYY")
      : ""
    : undefined;

  // const rankedSolo = (lolData.leagueEntries || []).find(({ queueType }) => queueType === "RANKED_SOLO_5x5");
  // const rankedSoloWinRate = rankedSolo ? Math.round((rankedSolo.wins / (rankedSolo.wins + rankedSolo.losses)) * 100) : "No rank found";

  return (
    <div className={`finder-card bg-faded mt-3 mb-3 ${color} ${classes}`}>
      <header>
        <div className="finder-card-info">
          <img src={playerAvatarImage || default_picture} alt="PP" className="finder-card-image" />
          <div className="finder-card-info-text">
            <div className="finder-card-info-top">
              <span className="bold">{playerName}</span>
              <span className="pill">
                {!!playernationality && (
                  <img
                    src={`https://flagcdn.com/w20/${playernationality.code?.toLowerCase()}.png`}
                    width={22}
                    height={22}
                    alt={playernationality.name}
                    className="ml-2"
                  />
                )}
                {playernationality.name}
              </span>
              {/* {countDown && <span className="pill warning"><Icon name="clock" />{countDown}</span>} */}
            </div>
            <div className="finder-card-info-bottom">{/* <span className="faded">{userData.player.user.firstName}</span> */}</div>
          </div>
        </div>

        <div className="finder-card-actions">
          <Link to={button_link_to ?? "/"}>
            <button className="button primary">{button_label ?? "Invite to team"}</button>
          </Link>
          <button className="content-toggle" onClick={() => setContentShow(!contentShow)}>
            <Icon name="down" />
          </button>
        </div>
      </header>
      {typeof scrim === "undefined" ? (
        <div className={contentShow ? "finder-card-content show" : "finder-card-content"}>
          <div className="row smaller-gutter-row">
            <div className="col-8">
              <div className="faded bold uppercase">Description</div>
              <p>{userData.playerDescription}</p>
              <br />
              <a href={`/teams/${playerId}`} className="primary-link">
                View Team <Icon name="arrow-up-right" />
              </a>
            </div>
            <div className="col-4">
              <div className="faded bold uppercase pb-2 ">Languages</div>
              {userData.language.length && userData.language.map((singleLang) => <span>{singleLang.name}, </span>)}
            </div>
          </div>
        </div>
      ) : null}
      <footer>
        <div>
          <div className="faded bold uppercase">Team Members</div>
          <div className="finder-card-info displayFlex">
            {userData.team.members.length > 0
              ? userData.team.members.map((singleLang) => (
                  <img
                    src={
                      (singleLang.user && singleLang.user.avatarImage) ||
                      (singleLang.player && singleLang.player.avatarImage) ||
                      default_picture
                    }
                    alt="PP"
                    className="common-profile-images"
                  />
                ))
              : "No Members"}
            {/* <img src={playerAvatarImage || default_picture} alt="PP" className="common-profile-images" />
            <img src={playerAvatarImage || default_picture} alt="PP" className="common-profile-images" /> */}
          </div>
          {/* <div className="bold"><img src={getLaneIcon(playerprimaryRole)} alt={playerprimaryRole} width={18} /> {playerprimaryRole}</div> */}
        </div>
        <div>
          <div className="faded bold uppercase">Seeking Roles</div>
          {/* <div className="bold">{scrimFormat}</div> */}
          {userData.teamSeekingRoles.length > 0 ? userData.teamSeekingRoles.map((singleLang) => <span>{singleLang}, </span>) : ""}
        </div>
        {/*<div>
          <div className="faded bold uppercase">Ranked solo stats</div>
          {/* <div className="bold">{rankedSolo ? `${rankedSolo.tier} ${rankedSolo.rank}` : "No rank found"}</div> */}

        {/* <span>{rankedSolo && <>
            <span className="text-10 bold uppercase color-success">{`${rankedSolo.wins}W`}</span>{' '}
            <span className="text-10 bold uppercase faded">{`${rankedSolo.losses}L`}</span>{' '}
            {`(${rankedSoloWinRate}% WR)`}
          </>}</span> 
        </div>*/}
      </footer>
    </div>
  );
};

export default FinderCard;
