import React, { useEffect, useState, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import TeamsItem from "@components/TeamsRouter/TeamsItem/TeamsItem";
import { SimpleTabs, Button, Icon } from "@common";
import { CreateTeamModal } from "../../../../_modals/CreateTeamModal/CreateTeamModal";
import EditCreateCategoryModal from "../../../../_modals/EditCreateCategoryModal/EditCreateCategoryModal";
import "./_teams.scss";
import { AppContext } from "../../../../../context/AppContext";
import { getUserRole } from "../../../../../utils/checkReschedule";
import Tippy from "@tippyjs/react";
import "tippy.js/animations/shift-toward.css";
import Axios from "axios";
import { toast } from "react-toastify";
import { FaPen } from "react-icons/fa";
import { getGameBackground } from "../../../../../utils/additional-helpers";
import useTranslated from "../../../../../helpers/translationHelper";
const Teams = ({
  teams = [],
  categories = [],
  organisationOwners = [],
  organisationName = "",
  organisationId = "",
  afterUpdate = () => {},
}) => {
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [teamsByGame, setTeamsByGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [createTeam, setCreateTeam] = useState(false);
  const [teamCategories, setTeamCategories] = useState(categories);
  const { user } = useContext(AppContext);
  const [isEditCreateModalOpen, setIsEditCreateModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);

  const alltab = useTranslated("id_organisation_page_(teams)_0");
  const creqteteqm = useTranslated("id_organisation_page_(teams)_1");
  const Editteamcategory = useTranslated("id_organisation_page_(teams)_2");
  const Deletecategory = useTranslated("id_organisation_page_(teams)_3");
  const CreateNewTeam = useTranslated("id_organisation_page_(teams)_5");
  const Categorydeletedsuccessfully = useTranslated("id_organisation_page_(teams)_8");

  const userRole = useMemo(() =>
    getUserRole({ members: [], owners: organisationOwners }, { members: [], owners: [] }, user.state._id)
  ).toLowerCase();

  useEffect(() => {
    const games = [...new Set(teams.map((team) => team?.game?.name))];
    const temp = games.map((game) => teams.filter((team) => team?.game?.name === game)).sort((a, b) => b.length - a.length);
    setTeamsByGames([teams, ...temp]);
    setPages([`${alltab} (${teams.length})`, ...temp.map((_teams, index) => `${_teams[0].game.name} (${_teams.length})`)]);
    setTeamCategories(categories);
  }, [teams, categories,alltab]);

  useEffect(() => {
    if (teamsByGame && teamsByGame[page] && page !== 0) {
      setSelectedGame(teamsByGame[page][0]?.game);
    } else {
      setSelectedGame(null);
    }
  }, [page]);

  const deleteCategory = async (categoryId) => {
    try {
      const { data } = await Axios.delete(
        `${process.env.REACT_APP_CORE_API}/api/organisations/${organisationId}/delete-category/${categoryId}`
      );
      setTeamCategories(data);
      toast.success(Categorydeletedsuccessfully);
    } catch (err) {
      toast.error(err?.response?.data?.msg || "Failed to delete team category");
    }
  };

  return (
    <>
      <div className="row teams__tabs">
        <div className="col-10 pr-4">
          <SimpleTabs setPage={setPage} page={page} pages={pages} />
        </div>
        <div className="col-2" style={{ marginLeft: "-8px" }}>
          {(userRole === "owner" || userRole === "manager") && (
            <Button
              name={"teams__create_category"}
              onClick={() => {
                setCurrentCategory(null);
                setIsEditCreateModalOpen(true);
              }}
            >
              {creqteteqm}
            </Button>
          )}
        </div>
      </div>
      <div>
        {teamCategories.map((category) => (
          <>
            {(!!category.teams.length && teamsByGame[page]?.filter((_team) => category.teams.includes(_team._id)).length) ||
            userRole === "owner" ||
            userRole === "manager" ? (
              <>
                <div className="teams__category_title">
                  <div>
                    <h3>{category?.name}</h3>
                  </div>
                  {(userRole === "owner" || userRole === "manager") && (
                    <div className="teams__category_title_button">
                      <Button
                        name={"teams__category_title_button-primary"}
                        onClick={() => {
                          setCurrentCategory(category);
                          setIsEditCreateModalOpen(true);
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <Icon pureIcon={<FaPen size="0.7rem" color={"white"} />} />
                          {Editteamcategory}
                        </div>
                      </Button>
                      <Tippy disabled={teamCategories.length > 1} content="An organization must have at least one category">
                        <span>
                          <Button
                            name={`${teamCategories.length > 1 ? "teams__category_title_button-danger" : ""}`}
                            disabled={teamCategories.length === 1}
                            onClick={() => deleteCategory(category._id)}
                          >
                            {Deletecategory}
                          </Button>
                        </span>
                      </Tippy>
                    </div>
                  )}
                </div>
                <hr />
              </>
            ) : (
              ""
            )}
            <div className="row mb-4">
              {teamsByGame[page]
                ?.filter((_team) => category.teams.includes(_team._id))
                .map((_team) => (
                  <TeamsItem
                    classes="col-3"
                    key={_team._id}
                    croppedLogo={_team.logoCropped}
                    {..._team}
                    showMembers
                    link_to={`/teams/${_team._id}`}
                  />
                ))}
            </div>
          </>
        ))}
      </div>

      {createTeam && (
        <CreateTeamModal
          open={createTeam}
          onClose={() => setCreateTeam(false)}
          afterCreate={() => afterUpdate()}
          organisation={organisationName}
          organisationId={organisationId}
          game={selectedGame}
        />
      )}

      {(userRole === "owner" || userRole === "manager") && (
        <button className={`button wide-primary`} onClick={() => setCreateTeam(true)} style={{ backgroundColor: getGameBackground() }}>
          {CreateNewTeam}
        </button>
      )}

      {isEditCreateModalOpen && (
        <EditCreateCategoryModal
          open={isEditCreateModalOpen}
          onClose={() => setIsEditCreateModalOpen(false)}
          teams={teams}
          organisation={organisationId}
          afterCreateOrUpdate={() => {
            afterUpdate();
            setIsEditCreateModalOpen(false);
          }}
          category={currentCategory}
        />
      )}
    </>
  );
};

export default Teams;
