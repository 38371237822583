import React from "react";
import PrizesTable from "./PrizesTable";

const Prizes = (props) => {
  return (
    <div>
      <div className="row smaller-gutter-row player-overview-row pt-3 pb-5">
        <div className="col-3 row-title">
          <span className="text-16 faded bold uppercase">Prizes</span>
        </div>
        <div className="col-9 row-content">
          <PrizesTable {...props} />
        </div>
      </div>
    </div>
  );
};

export default Prizes;
